import React, { useState, useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState, ICodingBackpackMatrix, codingBackpackCreateFolder, codingBackpackCreateMatrix, codingBackpackDuplicateMatrix, codingBackpackDeleteFolder, codingBackpackDeleteMatrix, selectorGetMatrixEditor } from 'store';

import Modal from 'components/Modal/Modal';

import './EditorTools.scss';

type OwnProps = {
    codingProjectIndex: number;
    fileIndex: number;
}

type Props = PropsFromRedux & OwnProps

const EditorTools: React.FC<Props> = (props: Props) => {
    const [newMatrixName, setNewMatrixName] = useState('');
    const [newMatrixModalIsOpen, setNewMatrixModalIsOpen] = useState(false);
    const [newAnimationName, setNewAnimationName] = useState('');
    const [newAnimationModalIsOpen, setNewAnimationModalIsOpen] = useState(false);

    const inputImportMatrixRef = useRef(null);
    //const inputImportAnimationRef = useRef(null);

    const handleDuplicate = () => {
        props.codingBackpackDuplicateMatrix(props.codingProjectIndex, props.fileIndex, props.selectedMatrixId);
    }

    const handleDeleteButton = () => {
        props.codingBackpackDeleteMatrix(props.codingProjectIndex, props.fileIndex, props.selectedMatrixId);
    }

    const handleImportMatrix = () => {
        //@ts-ignore
        inputImportMatrixRef?.current?.click();
    }

    const onChangeImportMatrixFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        let reader = new FileReader();
        reader.onload = r => {
            //@ts-ignore
            const importMatrix = JSON.parse(r?.target?.result) as ICodingBackpackMatrix;

            props.codingBackpackCreateMatrix(props.codingProjectIndex, props.fileIndex, undefined, importMatrix)
        };
        reader.onerror = e => {
            console.error(e);
        }
        //@ts-ignore
        reader.readAsText(files[0]);
        //@ts-ignore
        e.target.value = null;
    }

    /* const handleImportAnimation = () => {
        //@ts-ignore
        inputImportAnimationRef?.current?.click();
    } */

    /* const onChangeImportAnimationFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        let reader = new FileReader();
        reader.onload = r => {
            //@ts-ignore
            const importAnimation = JSON.parse(r?.target?.result) as ICodingBackpackMatrix;

            //props.codingBackpackCreateMatrix(props.codingProjectIndex, props.fileIndex, undefined, importMatrix)
        };
        reader.onerror = e => {
            console.error(e);
        }
        //@ts-ignore
        reader.readAsText(files[0]);
        //@ts-ignore
        e.target.value = null;
    } */

    const handleDeleteFolder = () => {
        props.codingBackpackDeleteFolder(props.codingProjectIndex, props.fileIndex, props.selectedFolderId);
    }

    const handlCreateNewMatrix = () => {
        if(props.selectedFolderId === 'root') return setNewMatrixModalIsOpen(true);
        
        props.codingBackpackCreateMatrix(props.codingProjectIndex, props.fileIndex, undefined, {name: props.selectedFolderName})
    }

    const handleCloseNewMatrixModal = () => {
        setNewMatrixModalIsOpen(false);
    }

    const handleSaveNewMatrixModal = () => {
        props.codingBackpackCreateMatrix(props.codingProjectIndex, props.fileIndex, undefined, {name: newMatrixName});
        setNewMatrixName('');
        setNewMatrixModalIsOpen(false);
    }

    const handleMatrixNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewMatrixName(e.target.value);
    }

    const handleMatrixNameKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key !== 'Enter') return;
        handleSaveNewMatrixModal();
    }

    const handleOpenNewAnimationModal = () => {
        setNewAnimationModalIsOpen(true);
    }

    const handleCloseNewAnimationModal = () => {
        setNewAnimationModalIsOpen(false);
    }

    const handleSaveNewAnimationModal = () => {
        props.codingBackpackCreateFolder(props.codingProjectIndex, props.fileIndex, newAnimationName);
        setNewAnimationName('');
        setNewAnimationModalIsOpen(false);
    }

    const handleAnimationNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewAnimationName(e.target.value);
    }

    const handleAnimationNameKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key !== 'Enter') return;
        handleSaveNewAnimationModal();
    }

    return (
        <div className="c-editorTools">
            <div className="c-editorTools__top">
                <button className="c-editorTools__button" onClick={handlCreateNewMatrix} disabled={props.animationPlaying}>Create New Matrix</button>
                <button className="c-editorTools__button" onClick={handleDuplicate} disabled={props.animationPlaying}>Duplicate</button>
                <button className="c-editorTools__button c-editorTools__button--delete" onClick={handleDeleteButton} disabled={props.animationPlaying}>Delete</button>
                <input type="file" ref={inputImportMatrixRef} accept=".ScratchLinkMatrix" onChange={onChangeImportMatrixFile} hidden/>
                <button className="c-editorTools__button" onClick={handleImportMatrix} disabled={props.animationPlaying}>Import Matrix</button>
            </div>
            <div className="c-editorTools__bottom">
                <button className="c-editorTools__button" onClick={handleOpenNewAnimationModal} disabled={props.animationPlaying}>Create New Animation</button>
                {
                    props.selectedFolderId !== 'root' && <button className="c-editorTools__button c-editorTools__button--delete" onClick={handleDeleteFolder} disabled={props.animationPlaying}>Delete Animation</button>
                }
                {/* <input type="file" ref={inputImportAnimationRef} accept=".ScratchLinkAnimation" onChange={onChangeImportAnimationFile} hidden/>
                <button className="c-editorTools__button" onClick={handleImportAnimation} disabled={props.animationPlaying}>Import Animation</button> */}
            </div>
            <Modal title="Create New Matrix" isOpen={newMatrixModalIsOpen} closeModal={handleCloseNewMatrixModal} type="save" onSave={handleSaveNewMatrixModal}>
                <h5 style={{margin: 0}}>Matrix Name</h5>
                <input autoFocus className="c-editorTools__newMatrixNameInput" type="text" value={newMatrixName} onChange={handleMatrixNameChange} onKeyPress={handleMatrixNameKeyPress} />
            </Modal>
            <Modal title="Create New Animation" isOpen={newAnimationModalIsOpen} closeModal={handleCloseNewAnimationModal} type="save" onSave={handleSaveNewAnimationModal}>
                <h5 style={{margin: 0}}>Animation Name</h5>
                <input autoFocus className="c-editorTools__newMatrixNameInput" type="text" value={newAnimationName} onChange={handleAnimationNameChange} onKeyPress={handleAnimationNameKeyPress} />
            </Modal>
        </div>
    )
}
const mapStateToProps = (state: IRootState, ownProps: OwnProps): StateProps => {
    return {
        selectedFolderId: selectorGetMatrixEditor(ownProps.codingProjectIndex, ownProps.fileIndex)(state).backpack.selectedFolderId,
        selectedFolderName: selectorGetMatrixEditor(ownProps.codingProjectIndex, ownProps.fileIndex)(state).backpack.folders.byIds[selectorGetMatrixEditor(ownProps.codingProjectIndex, ownProps.fileIndex)(state).backpack.selectedFolderId].name,
        selectedMatrixId: selectorGetMatrixEditor(ownProps.codingProjectIndex, ownProps.fileIndex)(state).backpack.selectedMatrixId,
        animationPlaying: selectorGetMatrixEditor(ownProps.codingProjectIndex, ownProps.fileIndex)(state).animation.playing
    }
}

const mapDispatchToProps = {
    codingBackpackCreateFolder,
    codingBackpackCreateMatrix,
    codingBackpackDuplicateMatrix,
    codingBackpackDeleteFolder,
    codingBackpackDeleteMatrix
}

interface StateProps {
    selectedFolderId: string;
    selectedFolderName: string;
    selectedMatrixId: string;
    animationPlaying: boolean;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditorTools);