import React from 'react';

import './Button.scss';

type AdditionalButtonStyles = 'square' | 'full width';

interface ButtonProps  {
    className?: string;
    type?: 'success' | 'info' | 'danger' | 'grey' | 'transparent';
    size?: 'sm' | 'md' | 'lg';
    onClick?: () => void;
    style?: React.CSSProperties;
    addStyles?: AdditionalButtonStyles[];
    disabled?: boolean;
    onContextMenu?: React.MouseEventHandler<HTMLButtonElement>;
}

const Button: React.FC<ButtonProps> = ({ className, style, type, onClick, size, addStyles = [], disabled = false, children, onContextMenu, ...extraProps }) => {
    let classes = "c-button";
    if(type) classes += ` c-button__${type}`;
    if(size) classes += ` c-button__${size}`;
    if(className) classes += ` ${className}`;
    if(addStyles.includes('square')) classes += ` c-button--square`;
    if(addStyles.includes('full width')) classes += ` c-button--fullWidth`;

    return (
        <button style={style} className={classes} onClick={onClick} disabled={disabled} onContextMenu={onContextMenu} {...extraProps}>{children}</button>
    )
};

export default Button;