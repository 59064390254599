import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState } from 'store';
import { DeviceConnectionManager } from 'lib/DeviceConnection';
import { toast } from 'react-toastify';

import Page from 'containers/Page/Page';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import Console from 'containers/Console/Console';

import './ConsolePage.scss';


type Props = PropsFromRedux & {

}

const ConsolePage: React.FC<Props> = (props) => {
    const [commandInputValue, setCommandInputValue] = useState('');

    const onCommandInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCommandInputValue(e.target.value);
    };
    const onSendCommandClick = () => {
        const sent = DeviceConnectionManager.sendCommand(commandInputValue);
        if(sent) toast.success(`Sent Command: ${commandInputValue}`);
    };

    return (
        <Page
            title="Coding Editor | ScratchLink"
            background="#E6F0FF"
        >
            <div className="p-consolePage">
                <br />
                <div className="p-consolePage__buttons">
                    <Button type="info" onClick={() => DeviceConnectionManager.sendCommand('read;')}>Read Data</Button>
                    <Button type="info" onClick={() => DeviceConnectionManager.sendCommand('config info;')}>Config Info</Button>
                    <Button type="info" onClick={() => DeviceConnectionManager.sendCommand('stream on;')}>Stream On</Button>
                    <Button type="info" onClick={() => DeviceConnectionManager.sendCommand('stream off;')}>Stream Off</Button>
                </div>
                <br />
                <div className="p-consolePage__sendCommandArea">
                    <Input value={commandInputValue} onChange={onCommandInputChange} />
                    <Button style={{marginLeft: '10px'}} type="success" onClick={onSendCommandClick}>Send Command</Button>
                </div>
                <br />
                <Console style={{height: '75vh'}} acceptedSources={['ScratchLinkIncoming', 'ScratchLinkIncomingEvent', 'Info']} />
            </div>
        </Page>
    )
}

const mapStateToProps = (state: IRootState): StateProps => {
    return {
    }
}

const mapDispatchToProps = {
}

interface StateProps {
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ConsolePage);