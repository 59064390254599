import shortid from 'shortid';

import { ICodingState } from '.';
import { matrixEditorInitialState } from './matrixEditor/matrixEditorReducer';

export const codingInitialState: ICodingState = {
    running: false,
    codeHasError: false,
    pyEnvReady: false,
    userSelectedRightPanel: 'help',
    displayHelpPopout: true,
    activeProjectIndex: 1,
    createProjectModalOpen: false,
    projectSettingsModalOpen: false,
    codingProjects: [
        {
            name: 'Matrix Only Example',
            id: shortid(),
            type: 'matrix',
            files: [
                {
                    name: 'Matrix Editor',
                    id: shortid(),
                    matrixEditorData: {
                        matrixEditor: matrixEditorInitialState
                    },
                    type: 'matrixEditor'
                }
            ],
            hardwareMode: 'ebot_2wd',
            blockLevelMode: 'Core',
            gamepadMode: 'no_gamepad',
            activeFileIndex: 0,
            projectVersion: '1.0'
        },
        {
            name: 'HTML&JS Example',
            id: shortid(),
            type: 'js',
            files: [
                /* {
                    name: 'Flow Chart 1',
                    id: shortid(),
                    flowChartData: {
                        data: {}
                    },
                    type: 'flowChart'
                }, */
                {
                    name: 'HTML',
                    id: shortid(),
                    htmlData: {
                        html: `<html>\n    <head>\n        <title>JS Project</title>\n    </head>\n    <body>\n\n    </body>\n</html>`,
                    },
                    type: 'html',
                },
                {
                    name: 'Main',
                    id: shortid(),
                    jsData: {
                        code: `console.log("hello world");\n\neventsGamepad1.addEventListener(\'A Button\', function() {\n     console.log('A Pressed');\n})`
                    },
                    type: 'js'
                },
                {
                    name: 'Visual HTML Editor',
                    id: shortid(),
                    textData: {
                        text: '<p>Some Example Text</p>'
                    },
                    type: 'text'
                },
                {
                    name: 'Image Manager',
                    id: shortid(),
                    imageData: {
                        images: []
                    },
                    type: 'imageManager'
                },
                {
                    name: 'Global Variables',
                    id: shortid(),
                    globalVarData: {
                        vars: []
                    },
                    type: 'globalVars'
                },
                {
                    name: 'Flow Chart 1',
                    id: shortid(),
                    flowChartData: {
                        data: {}
                    },
                    type: 'flowChart'
                }
            ],
            hardwareMode: 'javascript',
            blockLevelMode: 'Core',
            gamepadMode: 'no_gamepad',
            activeFileIndex: 0,
            projectVersion: '1.0'
        },
        {
            name: "JS4Scratch Example",
            id: shortid(),
            type: "jsscratch",
            files: [
                {
                    name: "ball",
                    id: shortid(),
                    jsData: {
                        code: `// ball sprite\r\nball_sprite.whenFlag(function () {                                  // same as Scratch "When Flag"\r\n    ball_sprite.show()\r\n    ball_sprite.setSize(25)\r\n    ball_sprite.goTo(0, 170)\r\n    ball_sprite.pointInDirection(randomRange(130, 230))\r\n        \r\n    while(forever === true){                                       // forever loop\r\n        ball_sprite.move(10)\r\n        ball_sprite.ifOnEdgeBounce()\r\n        \r\n        if (ball_sprite.isTouching(paddle_sprite)) {                // rebound ball off the paddle\r\n            ball_sprite.pointInDirection(randomRange(-45, 45))      // point downwards but randomly 45 degrees either side of straight up\r\n            console.log(\'HIT\')\r\n            Score = Score + 1\r\n        }  \r\n        if (ball_sprite.touchingEdge() === \'bottom\'){\r\n            Score = Score - 5\r\n            console.log("MISS")\r\n        }\r\n    }       \r\n})`
                    },
                    type: "js"
                }, 
                {
                    name: "paddle",
                    id: shortid(),
                    jsData: {
                        code: `// ball sprite\r\nball_sprite.whenFlag(function () {                                  // same as Scratch "When Flag"\r\n    ball_sprite.show()\r\n    ball_sprite.setSize(25)\r\n    ball_sprite.goTo(0, 170)\r\n    ball_sprite.pointInDirection(randomRange(130, 230))\r\n        \r\n    while(forever === true){                                       // forever loop\r\n        ball_sprite.move(10)\r\n        ball_sprite.ifOnEdgeBounce()\r\n        \r\n        if (ball_sprite.isTouching(paddle_sprite)) {                // rebound ball off the paddle\r\n            ball_sprite.pointInDirection(randomRange(-45, 45))      // point downwards but randomly 45 degrees either side of straight up\r\n            console.log(\'HIT\')\r\n            Score = Score + 1\r\n        }  \r\n        if (ball_sprite.touchingEdge() === \'bottom\'){\r\n            Score = Score - 5\r\n            console.log("MISS")\r\n        }\r\n    }       \r\n})`
                    },
                    type: "js"
                }, 
                {
                    name: "SetUp",
                    id: shortid(),
                    type: "js",
                    jsData: {
                        code: `/* EXAMPLE: Pong- Core */\r\n// all the text in green like this are "comments and not run"- create comments with "//" or encase like this: /*comments*/\r\n\r\nmyTimer.reset()                                             // declare variable timer1 as a new Timer()\r\nforever = true                                                          // set variable forever to true- used to control loop breakout\r\nTotal_Game_Time = 60                                                    // set variable Total_Game_Time to 60 (seconds)\r\nGame_Time = 0                                                           // set variable Game_Time to zero\r\nScore = 0                                                               // set variable Score to zero\r\n\r\nexampleSprite_sprite.hide()                                             // hide this sprite as it isn\'t needed just now\r\n\r\n\r\n`
                    }
                }, 
                {
                    name: "timer",
                    id: shortid(),
                    jsData: {
                        code: `// Game Timer\r\npaddle_sprite.whenFlag(function () {                                    // start this code segment when Flag is clicked\r\n    myTimer.reset();                                                     // reset the timer1 variable\r\n    while(forever === true){                                            // Forever LOOP\r\n        Game_Time = Math.round (Total_Game_Time - myTimer.getTime())     // calculate the Game_Time variable and round\r\n        this.wait(1)                                                    // wait 1 second as we don\'t need this calculation very often and it eases browser memory load\r\n        if (Game_Time < 1) {                                            // If the Game_Time var gets to zero i.e. < 1 - don\'t use if(variable = 0) in your code ever\r\n            paddle_sprite.say("GAME OVER")                              // since the time is up, say "Game Over"\r\n            forever = false                                             // STOP all the code by setting "forever" to "false"\r\n        }\r\n    }\r\n})`
                    },
                    type: "js"
                }, 
                {
                    name: "stage",
                    id: shortid(),
                    jsData: {
                        code: "// Cycle the backdrops on the stage\r\nstage.whenFlag(function(){\r\n    while(forever === true){\r\n        stage.wait(0.5)\r\n        stage.nextBackdrop()\r\n    }\r\n})"
                    },
                    type: "js"
                }, 
                {
                    name: "Sprites",
                    id: shortid(),
                    type: "assetManager",
                    assetManagerData: {
                        sprites: [{
                            name: "ball_sprite",
                            scratchName: "Ball",
                            costumes: [{
                                name: "ball-a",
                                asset: "3c6241985b581284ec191f9d1deffde8.svg"
                            }, 
                            {
                                name: "ball-b",
                                asset: "ad7dc51cafd73e8279073e33b0eab335.svg"
                            }, 
                            {
                                name: "ball-c",
                                asset: "f221a2edf87aff3615c0c003e616b31b.svg"
                            }, 
                            {
                                name: "ball-d",
                                asset: "db144b2a19f4f1ab31e30d58f00447dc.svg"
                            }, 
                            {
                                name: "ball-e",
                                asset: "1c44b7494dec047371f74c705f1d99fc.svg"
                            }],
                            type: "mit"
                        }, 
                        {
                            name: "paddle_sprite",
                            scratchName: "Paddle",
                            costumes: [{
                                name: "paddle",
                                asset: "15864fac7d38bb94c1ec3a199de96c26.svg"
                            }],
                            type: "mit"
                        }, 
                        {
                            name: "exampleSprite_sprite",
                            scratchName: "Abby",
                            costumes: [{
                                name: "abby-a",
                                asset: "809d9b47347a6af2860e7a3a35bce057.svg"
                            }, 
                            {
                                name: "abby-b",
                                asset: "920f14335615fff9b8c55fccb8971984.svg"
                            }, 
                            {
                                name: "abby-c",
                                asset: "34a175600dc009a521eb46fdbbbeeb67.svg"
                            }, 
                            {
                                name: "abby-d",
                                asset: "45de34b47a2ce22f6f5d28bb35a44ff5.svg"
                            }],
                            type: "mit"
                        }],
                        backdrops: [{
                            name: "exampleBackdrop_backdrop",
                            asset: "e7c147730f19d284bcd7b3f00af19bb6.svg",
                            type: "mit",
                            scratchName: "Blue Sky"
                        }, 
                        {
                            name: "moon_backdrop",
                            asset: "0b1d2eaf22d62ef88de80ccde5578fba.png",
                            type: "mit",
                            scratchName: "Moon"
                        }, 
                        {
                            name: "boardwalk_backdrop",
                            asset: "de0e54cd11551566f044e7e6bc588b2c.png",
                            type: "mit",
                            scratchName: "Boardwalk"
                        }, 
                        {
                            name: "circles_backdrop",
                            asset: "c9847be305920807c5597d81576dd0c4.svg",
                            type: "mit",
                            scratchName: "Circles"
                        }]
                    }
                }, 
                {
                    name: "Global Variables",
                    id: shortid(),
                    globalVarJS4ScratchData: {
                        vars: [{
                            name: "forever",
                            "display": false,
                            type: "boolean"
                        }, 
                        {
                            name: "Total_Game_Time",
                            display: false,
                            type: "number"
                        }, 
                        {
                            name: "Game_Time",
                            "display": true,
                            type: "number"
                        }, 
                        {
                            name: "Score",
                            "display": true,
                            type: "number"
                        },
                        {
                            name: 'myTimer',
                            display: false,
                            type: 'ScratchLinkTimer'
                          }
                        ]
                    },
                    type: "globalVarsJS4Scratch"
                },
                {
                    name: 'Flow Chart 1',
                    id: shortid(),
                    flowChartData: {
                        data: {}
                    },
                    type: 'flowChart'
                }
            ],
            activeFileIndex: 0,
            hardwareMode: "javascript",
            blockLevelMode: "Beginner",
            gamepadMode: 'no_gamepad',
            projectVersion: '1.0'
        },
        {
            name: 'JS4eBot Example',
            id: shortid(),
            type: 'hardwarejs',
            files: [
                {
                    name: 'Main',
                    id: shortid(),
                    jsData: {
                        code: 'console.log("hello world");\n\nled.colourRGBCmd(100,0,0);\n\ncontrol.wait(2);\n\nled.offCmd();\n\neventsEbot.addEventListener(\'EventButtonA\', function() {\n   led.flashCmd();\n});',
                    },
                    type: 'js',
                },
                {
                    name: 'File 2',
                    id: shortid(),
                    jsData: {
                        code: '//Comment\n\nconsole.log("hello world 2");',
                    },
                    type: 'js',
                },
                {
                    name: 'Event Buttons',
                    id: shortid(),
                    eventButtonData: {
                        buttons: ['EventButtonA'],
                    },
                    type: 'eventButtons'
                },
                {
                    name: 'Notes',
                    id: shortid(),
                    textData: {
                        text: 'Some Example Text'
                    },
                    type: 'text'
                },
                {
                    name: 'Global Variables',
                    id: shortid(),
                    globalVarData: {
                        vars: []
                    },
                    type: 'globalVars'
                }, 
                {
                    name: 'Matrix Editor',
                    id: shortid(),
                    matrixEditorData: {
                        matrixEditor: matrixEditorInitialState
                    },
                    type: 'matrixEditor'
                },
                {
                    name: 'Flow Chart 1',
                    id: shortid(),
                    flowChartData: {
                        data: {}
                    },
                    type: 'flowChart'
                }
            ],
            hardwareMode: 'ebot_example',
            blockLevelMode: 'Core',
            gamepadMode: 'no_gamepad',
            activeFileIndex: 0,
            projectVersion: '1.0'
        },
        {
            name: 'PY4eBot Example',
            id: shortid(),
            type: 'hardwarepy',
            files: [
                {
                    name: 'Main',
                    id: shortid(),
                    type: 'py',
                    pyData: {
                        code: `
print("Hello World")
#Comment

#Custom function
def myCustomAddFunction(number1, number2):
    return number1+number2

print(myCustomAddFunction(5, 3))

#Forever loop
def myForeverLoop():
    print("forever loop")
    print(eBot.sensors)
    control.wait(1)

control.forever(myForeverLoop)


#Events
def onEventButtonA():
    print("Button A Pressed")

eventsEbot.addEventListener('EventButtonA', onEventButtonA)`
                    }
                },
                {
                    name: 'Event Buttons',
                    id: shortid(),
                    eventButtonData: {
                        buttons: ['EventButtonA'],
                    },
                    type: 'eventButtons'
                }
            ],
            hardwareMode: 'ebot_example',
            blockLevelMode: 'Core',
            gamepadMode: 'no_gamepad',
            activeFileIndex: 0,
            projectVersion: '1.0'
        },
        {
            name: 'Portfolio',
            id: shortid(),
            type: 'portfolio',
            files: [
                {
                    name: 'Portfolio',
                    id: shortid(),
                    textData: {
                        text: ''
                    },
                    type: 'text',
                }
            ],
            hardwareMode: 'javascript',
            blockLevelMode: 'Core',
            gamepadMode: 'no_gamepad',
            activeFileIndex: 0,
            projectVersion: '1.0'
        }
    ]
};