import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Select, { ValueType } from 'react-select';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useDebounce } from 'use-debounce';
import { IRootState, ISettingsJavascriptEditorToolType, settingsSetMonacoFontSize, ISettingsMonacoFontSize } from 'store';

import Console from 'containers/Console/Console';
import commitVersion from 'commitVersion.json';

import {
    settingsSetFileName,
    settingsSetJavascriptEditorToolType,
    settingsSetDeviceDirectIpConnectionIp,
    settingsSetDevMode
} from 'store';

import './Settings.scss';

type Props = PropsFromRedux & {

}

const editorTypeOptions: ReactSelectStringOption[] = [
    {value: 'auto', label: 'Auto'},
    {value: 'monaco', label: 'Monaco'},
    {value: 'codemirror', label: 'Code Mirror'}
];

const devModeOptions: ReactSelectStringOption[] = [
    {value: 'off', label: 'Off'},
    {value: 'on', label: 'On'}
];

const fontSizeOptions: ReactSelectStringOption[] = [
    {value: '14', label: '14px'},
    {value: '16', label: '16px'},
    {value: '18', label: '18px'},
    {value: '20', label: '20px'},
    {value: '22', label: '22px'},
    {value: '24', label: '24px'},
    {value: '26', label: '26px'}
];

const SettingsPage: React.FC<Props> = ({
    fileNameProps,
    javascriptEditorToolType,
    deviceDirectIpConnectionIpProps,
    devModeProps,
    monacoFontSize,
    settingsSetFileName,
    settingsSetJavascriptEditorToolType,
    settingsSetDeviceDirectIpConnectionIp,
    settingsSetDevMode,
    settingsSetMonacoFontSize
 }) => {
    const [fileName, setFileName] = useState(fileNameProps);
    const [debouncedFileName] = useDebounce(fileName, 300);
    const [deviceDirectIpConnectionIp, setDeviceDirectIpConnectionIp] = useState(deviceDirectIpConnectionIpProps);
    const [debouncedDeviceDirectIpConnectionIp] = useDebounce(deviceDirectIpConnectionIp, 300);

    useEffect(() => {
        settingsSetFileName(debouncedFileName);
        settingsSetDeviceDirectIpConnectionIp(debouncedDeviceDirectIpConnectionIp);
    }, [debouncedFileName, debouncedDeviceDirectIpConnectionIp, settingsSetFileName, settingsSetDeviceDirectIpConnectionIp])

    const handleFileNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFileName(e.target.value);
    }

    const handleJavascriptEditorToolTypeChange = (option: ValueType<ReactSelectStringOption, false>) => {
        settingsSetJavascriptEditorToolType(option?.value as ISettingsJavascriptEditorToolType || 'auto');
    }

    const handleDevModeChange = (option: ValueType<ReactSelectStringOption, false>) => {
        if(option) settingsSetDevMode(option.value === 'on');
    }

    const handleDeviceDirectIpConnectionIpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDeviceDirectIpConnectionIp(e.target.value);
    }

    const handleMonacoFontSizeChange = (option: ValueType<ReactSelectStringOption, false>) => {
        if(option) settingsSetMonacoFontSize(Number(option.value) as ISettingsMonacoFontSize);
    }

    const selectedJavascriptEditorToolType = editorTypeOptions.find(option => option.value === javascriptEditorToolType);
    const devMode = devModeProps ? devModeOptions[1] : devModeOptions[0];
    
    const fontSize = fontSizeOptions.find(option => Number(option.value) === monacoFontSize);

    return (
        <div className="c-settings">
            <Tabs>
                <TabList>
                    <Tab>General</Tab>
                    <Tab>Dev</Tab>
                    <Tab>About Info</Tab>
                </TabList>
                <TabPanel>
                    <h5>File Name</h5>
                    <input className='border-2 p-2' value={fileName} onChange={handleFileNameChange}/>

                    <h5>Javascript Editor Type</h5>
                    <Select className="c-settings__JavascriptEditorType" options={editorTypeOptions} value={selectedJavascriptEditorToolType} onChange={handleJavascriptEditorToolTypeChange} />
                    <h5>Direct IP Connection IP</h5>
                    <input className='border-2 p-2' value={deviceDirectIpConnectionIp} onChange={handleDeviceDirectIpConnectionIpChange}/>
                    <h5>Editor Font Size</h5>
                    <Select className="c-settings__JavascriptEditorType" options={fontSizeOptions} value={fontSize} onChange={handleMonacoFontSizeChange} />
                </TabPanel>
                <TabPanel>
                    <h5>Dev Mode</h5>
                    <Select options={devModeOptions} value={devMode} onChange={handleDevModeChange} />
                    <Console acceptedSources={['ScratchLinkIncoming', 'ScratchLinkIncomingEvent', 'ScratchLinkOutgoing', 'JSLog', 'JSSystem', 'Info']} style={{height: '60vh', marginTop: '20px'}}/>
                </TabPanel>
                <TabPanel>
                    <p><b>Commit Url:</b> <a target="_blank" rel="noopener noreferrer" href={commitVersion.app.commitUrl}>{commitVersion.app.commitUrl}</a></p>
                    <p><b>Commit Time:</b> {commitVersion.app.commitTime}</p>
                </TabPanel>
            </Tabs>
        </div>
    )
}

const mapStateToProps = (state: IRootState) => {
    return {
        fileNameProps: state.settings.fileName,
        javascriptEditorToolType: state.settings.javascriptEditorToolType,
        deviceDirectIpConnectionIpProps: state.settings.deviceDirectIpConnectionIp,
        devModeProps: state.settings.devMode,
        monacoFontSize: state.settings.monacoFontSize
    }
}

const mapDispatchToProps = {
    settingsSetFileName,
    settingsSetJavascriptEditorToolType,
    settingsSetDeviceDirectIpConnectionIp,
    settingsSetDevMode,
    settingsSetMonacoFontSize
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SettingsPage);