import { createSelector } from 'reselect';
import { IRootState } from 'store';

import { selectorGetMatrixEditor } from './selectorGetMatrixEditor';

export const selectorGetBackpackFolders = (codingProjectIndex: number, fileIndex: number, showRootFolder: boolean = true) => createSelector(
    (state: IRootState) => selectorGetMatrixEditor(codingProjectIndex, fileIndex)(state).backpack.folders,
    (folders) => {
        return folders.allIds.map(folderId => folders.byIds[folderId]).filter(folder => !(!showRootFolder && folder.id === 'root'))
    }
);