import React from 'react';
import { IConsoleDecorator } from '../ConsoleDecorator';

export const BatteryConsoleDecorator: IConsoleDecorator = ({ msgObject }) => {

    if(msgObject['VA'] === undefined) return (
        <>
            Battery Off
        </>
    )

    return (
        <>
            Battery On | Voltage: {msgObject['VA'][0]}V | Amps: {msgObject['VA'][1]}A
        </>
    )
};

BatteryConsoleDecorator.decoratorTest = (msgObject) => {
    if(msgObject['battery'] !== undefined) return true;

    return false;
}

BatteryConsoleDecorator.consoleClass = () => 'c-consoleDecorator__Battery';