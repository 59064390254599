import store, { codingSetRunning, consoleAddConsoleLog, ICodingProject, ICodingFile, codingGlobalVarDefaults } from 'store';
import getScratchAssetUri from 'utils/getScratchAssetUri';

import JSErrorHandler from './JSErrorHandler';
import processScratchLinkJSCode from 'utils/processScratchLinkJSCode';
import createScratchJSCodeImportHeader from 'utils/createScratchJSCodeImportHeader';

interface IWorkerJSMessage {
    type: string;
    data: any;
}

//JS runner for blocklike and plain javascript/html
class JSRunner {
    currentBlobUrl: string | undefined;
    currentJsFiles: ICodingFile[];
    currentRunCode: string;

    constructor() {
        const messageLitsener = (e: any) => {
            if(!this.currentBlobUrl || e.source?.frameElement?.src !== this.currentBlobUrl) return; //Check message comes from iframe

            this.wokerMessageHandler(e.data);
        }

        this.currentJsFiles = [];
        this.currentRunCode = '';

        window.addEventListener('message', messageLitsener);
    }

    generateJS4ScratchIFrameCode(codingProject: ICodingProject): [string, string]  {
        const jsFiles =  codingProject.files.filter(file => file.type === 'js');
        const assetManagerFile = codingProject.files.find(file => file.type === 'assetManager');
        const globalVars = codingProject.files.find(file => file.type === 'globalVarsJS4Scratch')?.globalVarJS4ScratchData?.vars || [];
        
        if(!jsFiles || !assetManagerFile || !assetManagerFile.assetManagerData) throw new Error('');

        let headerJs = ``;

        //Init Classes
        assetManagerFile.assetManagerData.sprites.forEach(sprite => {
            headerJs += `\n\n`;
            sprite.costumes.forEach((costume, index) => {
                headerJs += `const ${sprite.name+'Costume'+index} = new blockLike.Costume({image: '${getScratchAssetUri(costume.asset, sprite.type)}'});\n\n`;
            })
            headerJs += `const ${sprite.name} = new blockLike.Sprite({costume: ${sprite.name+'Costume0'}});\n\n`;
        })
        headerJs += `\n`;
        assetManagerFile.assetManagerData.backdrops.forEach(backdrop => {
            headerJs += `const ${backdrop.name} = new blockLike.Backdrop({image: '${getScratchAssetUri(backdrop.asset, backdrop.type)}'});\n`;
        })
        headerJs += `\n\n`;

        const firstBackdrop = assetManagerFile.assetManagerData.backdrops[0];
        headerJs += `\nconst stage = new blockLike.Stage(${firstBackdrop ? `{sensing: true, backdrop: ${firstBackdrop.name}}` : '{sensing: true}'});\n\n`;

        //Connect Classes
        assetManagerFile.assetManagerData.sprites.forEach(sprite => {
            headerJs += `stage.addSprite(${sprite.name})\n`;
            sprite.costumes.forEach((costume, index) => {
                if(index === 0) return;
                headerJs += `${sprite.name}.addCostume(${sprite.name+'Costume'+index});\n`;
            })
            headerJs += `${sprite.name}.hide();\n`;
        })
        assetManagerFile.assetManagerData.backdrops.forEach((backdrop, index) => {
            if(index === 0) return;
            headerJs += `stage.addBackdrop(${backdrop.name});\n`;
        })

        headerJs += '\n\n';

        if(globalVars.find(globalVar => globalVar.display)) headerJs += 'const jsDisplayVariables = new DisplayVariables();\n\n';

        for(let i=0; i<globalVars.length; i++) {
            if(globalVars[i].type === 'function') continue; //exclude funcs as it causes issues defining the function later in the editor

            headerJs += `\n window.${globalVars[i].name} = ${codingGlobalVarDefaults[globalVars[i].type]};`;

            if(globalVars[i].display) headerJs+= `\n jsDisplayVariables.add('${globalVars[i].name}');`;
        }

        const jsFilesData = jsFiles.map(file => {
            if(!file.jsData) throw new Error();
            return file.jsData;
        })

        const code = processScratchLinkJSCode(jsFilesData, [], '', false, false, false, false, false);

        const iframeCode = `
            <html>
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0">
                    <title>${codingProject.name}</title>
                    <style>
                        .blockLike-flag {
                            cursor: pointer;
                        }
                        .displayVariableBox {
                            left: 2px !important;
                            top: 2px !important;
                            font-family: 'Arial';
                            font-size: 14px;
                            text-align: center;
                            line-height: 20px;
                            border-radius: 6px;
                            border: 1px solid #444;
                            color: white;
                            background: rgb(92, 165, 255) !important;
                            padding: 2px 6px;
                            text-align: left;
                            z-index: 100 !important;
                        }
                        .displayVariableBox span {
                            font-weight: 600;
                            margin-right: 5px;
                            color: rgb(255, 187, 1);
                        }
                    </style>
                </head>
                <body>
                    <script src="${window.location.origin}/blocklike.js"></script>
                    <script src="${window.location.origin}/ScratchLink.js"></script>
                    <script src="${window.location.origin}/jsscratchFunctions.js"></script>
                    <script>
                        window.addEventListener('error', err => {
                            window.parent.postMessage({
                                type: 'logError', 
                                data: { 
                                    error: {
                                        lineno: err.lineno,
                                        message: err.message
                                    } 
                                }
                            }, '*')
                        })

                        window.addEventListener('unhandledrejection', err => {
                            window.parent.postMessage({
                                type: 'logError', 
                                data: { 
                                    error: {
                                        lineno: 0, //Cant get line number for promise rejections
                                        message: err.reason.message
                                    } 
                                }
                            }, '*')
                        })
                    </script>
                    <script>
                        /*
                        * HEADER LINE
                        */
                        ${createScratchJSCodeImportHeader('javascript')}
                        eBot.postMessage({type: 'status', data: 'started'});
            
                        console.log = function() {
                            eBot.print(arguments[0]);
                        }
                        const runCodeOnStart = (runFunc) => {
                            setTimeout(runFunc, 0)
                        }
            
                        ${headerJs}
            
                        ${code}
                    </script>
                </body>
            </html>
        `;
        const blobUrl = URL.createObjectURL(new Blob([iframeCode], {type: 'text/html'}));

        this.currentBlobUrl = blobUrl;
        this.currentJsFiles = jsFiles;
        this.currentRunCode = iframeCode;

        return [blobUrl, iframeCode];        
    }

    generateJSIFrameCode(codingProject: ICodingProject): [string, string] {
        const globalVars = codingProject.files.find(file => file.type === 'globalVars')?.globalVarData?.vars || [];
        const images = codingProject.files.find(file => file.type === 'imageManager')?.imageData?.images || [];
        const jsFiles = codingProject.files.filter(file => file.type === 'js');
        const htmlFile = codingProject.files.find(file => file.type === 'html');

        let html = htmlFile?.htmlData?.html || `<html>\n    <head>\n        \n    </head>\n    <body>\n\n    </body>\n</html>`;

        const parser = new DOMParser();
        const htmlParsed = parser.parseFromString(html, 'text/html');

        const head = htmlParsed.querySelector('head');
        if(!head) {
            console.error('ERROR NO HEAD');
            return ['', ''];
        }

        htmlParsed.querySelectorAll('img').forEach(img => { //replace images
            const src = img.getAttribute('src');
            if(!src) return;
            const image = images.find(image => image.name === src);
            if(!image) return;
            if(image.type === 'img') {
                img.setAttribute('src', image.data);
            } else {
                const video = document.createElement('video');
                video.setAttribute('src', image.data);
                video.setAttribute('autoplay', img.getAttribute('autoplay') || 'true');
                video.setAttribute('loop', img.getAttribute('loop') || 'true');
                video.setAttribute('muted', img.getAttribute('muted') || 'true');
                video.setAttribute('controls', img.getAttribute('controls') || 'true');
                video.setAttribute('height', img.getAttribute('height') || 'auto');
                video.setAttribute('width', img.getAttribute('width') || 'auto');

                img.replaceWith(video);
            }
        })

        head.innerHTML = `${head.innerHTML}
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0">
            <script src="${window.location.origin}/ScratchLink.js"></script>
        `;

        if(jsFiles.length > 0) {
            const jsFilesData = jsFiles.map(file => {
                if(!file.jsData) throw new Error();
                return file.jsData;
            })

            const code = processScratchLinkJSCode(jsFilesData, globalVars, '', false, true, true, true);

            head.innerHTML = `${head.innerHTML}
                <script>
                    window.addEventListener('error', err => {
                        window.parent.postMessage({
                            type: 'logError', 
                            data: { 
                                error: {
                                    lineno: err.lineno,
                                    message: err.message
                                } 
                            }
                        }, '*')
                    })

                    window.addEventListener('unhandledrejection', err => {
                        window.parent.postMessage({
                            type: 'logError', 
                            data: { 
                                error: {
                                    lineno: 0, //Cant get line number for promise rejections
                                    message: err.reason.message
                                } 
                            }
                        }, '*')
                    })
                </script>
                <script>
                    window.addEventListener('load', () => {
                        /*
                        * HEADER LINE
                        */
                        ${createScratchJSCodeImportHeader('javascript')}

                        /*
                        * EXTRA
                        */

                        console.log = function() {
                            eBot.print(arguments[0]);
                        }

                        const runCodeOnStart = (runFunc) => {
                            setTimeout(runFunc, 0)
                        }

                        eBot.postMessage({type: 'status', data: 'started'});

                        /*
                        * CODE
                        */

                        ${code}
                    })
                </script>
            `
        } else { //NO JS FILES
            head.innerHTML = `${head.innerHTML}
                <script>
                    window.addEventListener('load', async () => {
                        const eBot = JSScratchLink.javascriptCore({});
                        eBot.postMessage({type: 'status', data: 'started'});
                    })
                </script>
            `
        }
        
        const iframeCode = htmlParsed.documentElement.outerHTML;
        const blobUrl = URL.createObjectURL(new Blob([iframeCode], {type: 'text/html'}));

        this.currentBlobUrl = blobUrl;
        this.currentJsFiles = jsFiles;
        this.currentRunCode = iframeCode;

        return [blobUrl, iframeCode];
    }

    private wokerMessageHandler(message: IWorkerJSMessage): void {
        if(message.type === 'status') {
            if(message.data === 'started') {
                //@ts-ignore
                store.dispatch(codingSetRunning(true))
                store.dispatch(consoleAddConsoleLog('Started Running', 'JSSystem', 'JSSystemConsoleDecorator'))
                return;
            }
            if(message.data === 'ended') {
                store.dispatch(consoleAddConsoleLog('Stopped Running', 'JSSystem', 'JSSystemConsoleDecorator'))
                return;
            }
        }

        if(message.type === 'log') {
            store.dispatch(consoleAddConsoleLog(message.data.msg, 'JSLog'));
            return;
        }

        if(message.type === 'logLibError') {
            store.dispatch(consoleAddConsoleLog(message.data.msg, 'JSLibError', 'JSLibraryErrorConsoleDecorator'));
            return;
        }

        if(message.type === 'logError') {
            JSErrorHandler.handleError(
                message.data.error, 
                this.currentJsFiles, 
                this.currentRunCode
            );
            return;
        }
    }
}

export default new JSRunner(); //Singleton JSRunner