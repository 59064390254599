import JSON5 from 'json5';
import { toast } from 'react-toastify';
import store, { projectSetDeviceConneting } from 'store';

import { DeviceConnectionManagerClass, IDeviceConnectionWorkerMessageConnected, IDeviceConnectionWorkerMessageSendConnectCommands, IDeviceConnectionWorkerMessageDisconnect, IDeviceConnectionWorkerMessageMessageData, IDeviceConnectionWorkerFirmwareError, IDeviceConnectionWorkerSecurityFailed, IDeviceConnectionWorkerModemDetected, IDeviceConnectionWorkerMessageJSON5Malformed, IDeviceConnectionWorkerMessageDeviceInfo, IDeviceConnectionWorkerMessageLostConnection } from '.';
import wait from 'utils/wait';

export class DeviceConnectionSerial {
    connectionManager: DeviceConnectionManagerClass;
    serial: SerialPort | undefined;

    lineBuffer: string = "";
    writer: WritableStreamDefaultWriter | undefined;
    reader: ReadableStreamDefaultReader | undefined;

    serialOpenMessageErrorTimeout: undefined | ReturnType<typeof setTimeout>;
    serialReceivedValidPacket: boolean = false;

    modemTCPConnectTimeout: undefined | ReturnType<typeof setTimeout>;
    modemTCPConnected: boolean = false;
    modemTCPMessageLastTime: number = Date.now();

    connected: boolean = false;

    constructor(connectionManager: DeviceConnectionManagerClass) {
        this.connectionManager = connectionManager;
    }

    async connectPort() {
        try {
            this.serial = await navigator.serial.requestPort({ filters: [{usbProductId: 0xEA60, usbVendorId: 0x10C4}]});
            await this.serial.open({ baudRate: 921600 });

            await wait(2000); //Wait 2sec to flush serial junk errors

            this.lineBuffer = '';
            this.readLoop();

            this.connected = true;

            this.serialOpenMessageErrorTimeout = setTimeout(() => {
                if(this.serialOpenMessageErrorTimeout) clearTimeout(this.serialOpenMessageErrorTimeout);
                this.serialOpenMessageErrorTimeout = undefined;
            }, 500);            

            this.connectionManager.workerMessageHandler({
                type: 'DEVICE_CONNECTION_WORKER_MESSAGE_CONNECTED',
                data: {}
            } as IDeviceConnectionWorkerMessageConnected);

            setTimeout(() => {
                this.connectionManager.workerMessageHandler({
                    type: 'DEVICE_CONNECTION_WORKER_MESSAGE_SEND_CONNECT_COMMANDS',
                    data: {}
                } as IDeviceConnectionWorkerMessageSendConnectCommands);
            }, 3000)

            setTimeout(() => {
                if(this.serialReceivedValidPacket) return; //Check firmware error
                this.connectionManager.workerMessageHandler({
                    type: 'DEVICE_CONNECTION_WORKER_FIRMWARE_ERROR',
                    data: {}
                } as IDeviceConnectionWorkerFirmwareError);
                this.disconnect();
            }, 6000)
        } catch(e) {
            console.log(`Error opening serial port`, e)
            toast.error(`Error opening serial port. Make sure you are using Chrome or Edge (Any Chromium Based Browser).`);
            store.dispatch(projectSetDeviceConneting(false));
        }
    }

    async connectPortModem() {
        try {
            this.serial = await navigator.serial.requestPort({ filters: [{usbProductId: 0xEA60, usbVendorId: 0x10C4}]});
            await this.serial.open({ baudRate: 921600 });

            await wait(2000); //Wait 2sec to flush serial junk errors

            this.lineBuffer = '';
            this.readLoop();

            this.modemTCPConnected = false;
            await wait(200);

            this.modemTCPConnectTimeout = setTimeout(() => {
                this.clearModemTCPConnectTimeout();
            }, 5000);
            this.write('**reboot;');

            this.serialOpenMessageErrorTimeout = setTimeout(() => {
                if(this.serialOpenMessageErrorTimeout) clearTimeout(this.serialOpenMessageErrorTimeout);
                this.serialOpenMessageErrorTimeout = undefined;
            }, 1000);

            await this.waitForModemTCPConnect();
            if(!this.modemTCPConnected) {
                console.error('ERROR: Modem TCP Connection Timeout')
                store.dispatch(projectSetDeviceConneting(false));
                this.disconnect();
                toast.error(`Error: USB Modem could not connect to robot. Make sure no other devices are connected to your robot's hotspot. Is the robot turned on?`)
                return;
            }

            this.connected = true;

            this.connectionManager.workerMessageHandler({
                type: 'DEVICE_CONNECTION_WORKER_MESSAGE_CONNECTED',
                data: {}
            } as IDeviceConnectionWorkerMessageConnected);

            setTimeout(() => {
                this.connectionManager.workerMessageHandler({
                    type: 'DEVICE_CONNECTION_WORKER_MESSAGE_SEND_CONNECT_COMMANDS',
                    data: {}
                } as IDeviceConnectionWorkerMessageSendConnectCommands);
            }, 3000);

            this.startModemTCPTimeout();
        } catch(e) {
            console.log(`Error opening serial port`, e)
            toast.error(`Error opening serial port. Make sure you are using Chrome or Edge (Any Chromium Based Browser).`);
            store.dispatch(projectSetDeviceConneting(false));
        }
    }

    clearModemTCPConnectTimeout() {
        if(this.modemTCPConnectTimeout) clearTimeout(this.modemTCPConnectTimeout);
        this.modemTCPConnectTimeout = undefined;
    }

    async waitForModemTCPConnect() {
        return new Promise((resolve: any) => {
            const checkFinishedConnecting = () => {
                if(this.modemTCPConnectTimeout === undefined || this.modemTCPConnected) {
                    this.clearModemTCPConnectTimeout();
                    return resolve();
                }

                setTimeout(checkFinishedConnecting, 25);
            }

            setTimeout(checkFinishedConnecting, 25);
        })
    }

    //Litsen for a TCP timeout from modem
    startModemTCPTimeout() {
        const modemTCPTimeout = setInterval(() => {
            if((this.connected && Date.now() - this.modemTCPMessageLastTime > 5000) || !this.modemTCPConnected) { //Connected and havnt recived message in 5sec or tcp disconnected
                console.error('USB MODEM TCP CONNECTION TIMEOUT');
                clearInterval(modemTCPTimeout);
                this.connectionManager.workerMessageHandler({
                    type: 'DEVICE_CONNECTION_WORKER_MESSAGE_LOST_CONNECTION', 
                    data: {}
                } as IDeviceConnectionWorkerMessageLostConnection);
                this.disconnect();
            }
        }, 50)
    }

    //If recieved EVENT_WIFI_TCPOUT_ERROR try to recover, else disconnect
    async recoverModemWifiTCPOutError() {
        this.modemTCPConnected = false; //Set TCP Disconnected then litsen for the reconnect

        toast.warn(`Modem connection interrupted. Attempting to recover connection...`);
        await this.write(`**reboot;`); //Reboot modem while serial is connected

        const reconnectTimeStart = Date.now();
        let modemConnectionRecoverLoop = setInterval(async () => {
            if(this.connected && this.modemTCPConnected) {
                this.connectionManager.workerMessageHandler({
                    type: 'DEVICE_CONNECTION_WORKER_MESSAGE_SEND_CONNECT_COMMANDS',
                    data: {}
                } as IDeviceConnectionWorkerMessageSendConnectCommands);
                toast.success(`Successfully re-established connection.`);
                clearInterval(modemConnectionRecoverLoop); //End Loop
                return;
            }

            if(Date.now() - reconnectTimeStart > 2500) { //Timeout
                await this.disconnect();
                clearInterval(modemConnectionRecoverLoop); //End Loop
                return;
            }
        }, 50);
    }

    async connectPortSilent() {
        try {
            
            const ports = await navigator.serial.getPorts();
            await ports[0].open({ baudRate: 921600 })
            /* this.serial = await navigator.serial.requestPort();
            
            await this.serial.open({ baudRate: 921600 });

            this.writeInit();
            this.readLoop();

            this.connectionManager.workerMessageHandler(<IDeviceConnectionWorkerMessageConnected>{
                type: 'DEVICE_CONNECTION_WORKER_MESSAGE_CONNECTED',
                data: {}
            });

            this.connected = true; */
        } catch(e) {
            toast.error(`Error opening serial port. Make sure you are using Chrome or Edge (Any Chromium Based Browser).`);
            store.dispatch(projectSetDeviceConneting(false));
        }
    }

    async disconnect() {
        try {
            await this.reader?.cancel();
            await this.serial?.close();
        } catch(e) {
            console.error(`Error in disconnect function`, e);
        }

        this.lineBuffer = ''; //Clear buffer
        
        if(this.connected) this.connectionManager.workerMessageHandler({
            type: 'DEVICE_CONNECTION_WORKER_MESSAGE_DISCONNECT',
            data: {}
        } as IDeviceConnectionWorkerMessageDisconnect);

        this.connected = false;
    }

    async write(data: string): Promise<void> {
        try {
            const encoder = new TextEncoder();
            if(this.writer) this.writer.releaseLock();
            this.writer = this.serial?.writable?.getWriter();
            await this.writer?.write(encoder.encode(data));
            this.writer?.releaseLock();
        } catch(e) {
            console.error(`Error writing to serial port | message: ${data}`, e);
        }
        
        return Promise.resolve();
    }

    private async readLoop() {
        if(!this.serial || this.serial.readable === null) {
            console.error(`Fatal error in WebSerial connection process`);
            return;
        };

        while(this.serial.readable) {
            this.reader = this.serial.readable.getReader();

            try {
                const { value, done } = await this.reader.read();
                this.reader.releaseLock();

                if (done) { //Reader Cancelled
                    console.log('done', done)
                    break;
                }

                //Create line buffer
                const valueString = String.fromCharCode.apply(null, new Uint8Array(value) as any);
                this.lineBuffer += valueString;

                const lines = this.lineBuffer.split('\n');
                for(let i=0; i<lines.length-1; i++) {
                    this.handleMessageData(lines[i]); //Unload buffer
                    this.lineBuffer = this.lineBuffer.substr(this.lineBuffer.indexOf('\n')+1, this.lineBuffer.length);
                }

            } catch (error) {
                console.log(`Read Loop Error`, error);
                this.lineBuffer = '';
            }
        }

        this.disconnect();
    }

    private handleMessageData(messageString: string) {
        let messageData: any;
        
        this.modemTCPMessageLastTime = Date.now();

        try {
            messageData = JSON5.parse(messageString);
        } catch(e) {
            if(this.serialOpenMessageErrorTimeout) return; //Ignore errors
            console.error(`Error passing JSON5 packet | ${messageString} |`, e);
            if(messageString.indexOf('') !== -1 || messageString.indexOf('i2c driver install error') !== -1) return; //Filter garbage

            this.connectionManager.workerMessageHandler({
                type: 'DEVICE_CONNECTION_WORKER_MESSAGE_JSON5_MALFORMED', 
                data: {
                    message: messageString
                }
            } as IDeviceConnectionWorkerMessageJSON5Malformed)
            return;
        }

        this.serialReceivedValidPacket = true;

        //Convert BW packets
        if(messageData.BW) messageData.BW = [
            messageData.BW[0] === 0 ? 'White' : 'Black', 
            messageData.BW[1] === 0 ? 'White' : 'Black', 
            messageData.BW[2]
        ];

        if(messageData !== undefined) {
            this.connectionManager.workerMessageHandler({
                type: 'DEVICE_CONNECTION_WORKER_MESSAGE_MESSAGE_DATA', 
                data: {
                    messageData
                }
            } as IDeviceConnectionWorkerMessageMessageData);

            if(messageData['Profile'] && messageData['Name']) this.connectionManager.workerMessageHandler({
                type: 'DEVICE_CONNECTION_WORKER_MESSAGE_DEVICE_INFO', 
                data: {
                    deviceName: messageData['Name'],
                    deviceProfile:  messageData['Profile']
                }
            } as IDeviceConnectionWorkerMessageDeviceInfo);

            if(messageData['evt'] && messageData['evt'] === 171) { //EVENT_WIFI_TCPOUT_INFO_CONNECTED - EVT 171
                this.modemTCPConnected = true;
            }

            if(messageData['evt'] && messageData['evt'] === 172) { //EVENT_WIFI_TCPOUT_INFO_DISCONNECTED - EVT 172
                console.log('TCP Disconnected - EVENT_WIFI_TCPOUT_INFO_DISCONNECTED - EVT 172');
                this.modemTCPConnected = false;
            }

            if(messageData['evt'] && messageData['evt'] === 211) {
                this.connectionManager.workerMessageHandler({
                    type: 'DEVICE_CONNECTION_WORKER_SECURITY_FAILED', 
                    data: {}
                } as IDeviceConnectionWorkerSecurityFailed);

                this.disconnect();
            }

            if(messageData.Modem && messageData.Modem === 1) {
                this.connectionManager.workerMessageHandler({
                    type: 'DEVICE_CONNECTION_WORKER_MODEM_DETECTED', 
                    data: {}
                } as IDeviceConnectionWorkerModemDetected);
            }

            if(messageData['evt'] && messageData['evt'] === 212) { //EVENT_WIFI_TCPOUT_ERROR - EVT 212
                if(this.serialOpenMessageErrorTimeout) return; //Ignore errors at startup
                this.recoverModemWifiTCPOutError();
            }
        }
    }
}