import React from 'react';
import { IConsoleDecorator } from '../ConsoleDecorator';

export const NormalConsoleDecorator: IConsoleDecorator = ({ msgObject }) => {
    return (
        <>
            { msgObject }
        </>
    )
};

NormalConsoleDecorator.decoratorTest = () => true;

NormalConsoleDecorator.consoleClass = () => 'c-consoleDecorator__Normal';