import immer from 'immer';
import { toast } from 'react-toastify';
import shortid from 'shortid';

import { STORE_IMPORT } from '..';
import { ICodingActions, ICodingState, codingInitialState } from '.';
import { matrixEditorReducer, matrixEditorInitialState } from './matrixEditor/matrixEditorReducer';

import {
    CODING_SET_RUNNING,
    CODING_SET_CODE_HAS_ERROR,
    CODING_SET_PY_ENV_READY,
    CODING_SET_USER_SELECTED_RIGHT_PANEL,
    CODING_SET_CREATE_PROJECT_MODAL_OPEN,
    CODING_SET_PROJECT_SETTINGS_MODAL_OPEN,
    CODING_SET_ACTIVE_PROJECT_INDEX,
    CODING_SET_PROJECT_SET_FILES,
    CODING_SET_PROJECT_NAME,
    CODING_SET_PROJECT_HARDWARE_MODE,
    CODING_SET_PROJECT_BLOCK_LEVEL_MODE,
    CODING_SET_PROJECT_GAMEPAD_MODE,
    CODING_PROJECT_ADD,
    CODING_PROJECT_DELETE,
    CODING_PROJECT_FILE_ADD,
    CODING_PROJECT_FILE_DELETE,
    CODING_SET_PROJECT_FILE_NAME,
    CODING_SET_PROJECT_FILE_BUTTONS,
    CODING_DELETE_PROJECT_FILE_EVENT_BUTTONS_DELETE_BUTTON,
    CODING_SET_PROJECT_FILE_ACTIVE_INDEX,
    CODING_SET_PROJECT_FILE_CODE,
    CODING_SET_PROJECT_FILE_TEXT,
    CODING_SET_PROJECT_FILE_GLOBAL_VARS,
    CODING_SET_PROJECT_FILE_GLOBAL_VARS_JS4SCRATCH,
    CODING_SET_PROJECT_FILE_ASSET_MANAGER_SPRITES,
    CODING_SET_PROJECT_FILE_ASSET_MANAGER_BACKDROPS,
    CODING_SET_PROJECT_FILE_IMAGE_MANAGER_IMAGES,
    CODING_SET_PROJECT_FILE_FLOWCHART_DATA,
    CODING_IMPORT_PROJECT
} from '.';

export const codingReducer = (state: ICodingState = codingInitialState, action: ICodingActions): ICodingState => {
    const stateWithMatrix = immer(state, (draftState: ICodingState) => {
        for(let i=0; i<draftState.codingProjects.length; i++) {
            for(let j=0; j<draftState.codingProjects[i].files.length; j++) {
                const file = draftState.codingProjects[i].files[j];
                if(file.type === 'matrixEditor' && file.matrixEditorData) {
                    draftState.codingProjects[i].files[j] = {
                        ...draftState.codingProjects[i].files[j],
                        matrixEditorData: {
                            matrixEditor: matrixEditorReducer(file.matrixEditorData.matrixEditor, action as any, i, j)
                        }
                    }
                }
            }
        }
    })

    switch (action.type) {
        case CODING_SET_RUNNING:
            return immer(stateWithMatrix, (draftState: ICodingState) => {
                draftState.running = action.running;
            });
        case CODING_SET_CODE_HAS_ERROR:
            return immer(stateWithMatrix, (draftState: ICodingState) => {
                draftState.codeHasError = action.codeHasError;
            });
        case CODING_SET_PY_ENV_READY:
            return immer(stateWithMatrix, (draftState: ICodingState) => {
                draftState.pyEnvReady = action.pyEnvReady;
            });
        case CODING_SET_USER_SELECTED_RIGHT_PANEL:
            return immer(stateWithMatrix, (draftState: ICodingState) => {
                draftState.userSelectedRightPanel = action.userSelectedRightPanel;
            }); 
        case CODING_SET_CREATE_PROJECT_MODAL_OPEN:
            return immer(stateWithMatrix, (draftState: ICodingState) => {
                draftState.createProjectModalOpen = action.isOpen;
            });
        case CODING_SET_PROJECT_SETTINGS_MODAL_OPEN:
            return immer(stateWithMatrix, (draftState: ICodingState) => {
                draftState.projectSettingsModalOpen = action.isOpen;
            });
        case CODING_SET_ACTIVE_PROJECT_INDEX:
            return immer(stateWithMatrix, (draftState: ICodingState) => {
                draftState.activeProjectIndex = action.activeProjectIndex;
            });
        case CODING_SET_PROJECT_SET_FILES:
            return immer(stateWithMatrix, (draftState: ICodingState) => {
                draftState.codingProjects[action.projectIndex].files = action.files;
            });
        case CODING_SET_PROJECT_NAME:
            return immer(stateWithMatrix, (draftState: ICodingState) => {
                draftState.codingProjects[action.projectIndex].name = action.projectName;
            });
        case CODING_SET_PROJECT_HARDWARE_MODE:
            return immer(stateWithMatrix, (draftState: ICodingState) => {
                draftState.codingProjects[action.projectIndex].hardwareMode = action.hardwareMode;
            });
        case CODING_SET_PROJECT_BLOCK_LEVEL_MODE:
            return immer(stateWithMatrix, (draftState: ICodingState) => {
                draftState.codingProjects[action.projectIndex].blockLevelMode = action.blockLevelMode;
            });
        case CODING_SET_PROJECT_GAMEPAD_MODE:
            return immer(stateWithMatrix, (draftState: ICodingState) => {
                draftState.codingProjects[action.projectIndex].gamepadMode = action.gamepadMode;
            });
        case CODING_PROJECT_ADD:
            return immer(stateWithMatrix, (draftState: ICodingState) => {
                draftState.codingProjects.push(action.codingProject);
            });
        case CODING_PROJECT_DELETE:
            return immer(stateWithMatrix, (draftState: ICodingState) => {
                if(action.projectIndex !== -1) draftState.codingProjects.splice(action.projectIndex, 1);
            });
        case CODING_PROJECT_FILE_ADD:
            return immer(stateWithMatrix, (draftState: ICodingState) => {
                if(action.fileType === 'js') {
                    draftState.codingProjects[action.projectIndex].files.push({
                        name: action.fileName,
                        id: shortid(),
                        jsData: {
                            code: ''
                        },
                        type: 'js'
                    })
                }
                if(action.fileType === 'html') {
                    if(stateWithMatrix.codingProjects[action.projectIndex].files.find(file => file.type === 'html')) {
                        toast.error(`Cannot have more than one html file`);
                        return;
                    }
                    draftState.codingProjects[action.projectIndex].files.push({
                        name: action.fileName,
                        id: shortid(),
                        htmlData: {
                            html: `<html>\n    <head>\n        <title>JS Project</title>\n    </head>\n    <body>\n\n    </body>\n</html>`
                        },
                        type: 'html'
                    })
                }
                if(action.fileType === 'text') {
                    draftState.codingProjects[action.projectIndex].files.push({
                        name: action.fileName,
                        id: shortid(),
                        textData: {
                            text: ''
                        },
                        type: 'text'
                    })
                }
                if(action.fileType === 'eventButtons') {
                    if(stateWithMatrix.codingProjects[action.projectIndex].files.find(file => file.type === 'eventButtons')) {
                        toast.error(`Cannot have more than one event buttons file`);
                        return;
                    }
                    draftState.codingProjects[action.projectIndex].files.push({
                        name: action.fileName,
                        id: shortid(),
                        eventButtonData: {
                            buttons: []
                        },
                        type: 'eventButtons'
                    })
                }
                if(action.fileType === 'globalVars') {
                    if(stateWithMatrix.codingProjects[action.projectIndex].files.find(file => file.type === 'globalVars')) {
                        toast.error(`Cannot have more than one global variables file`);
                        return;
                    }
                    draftState.codingProjects[action.projectIndex].files.push({
                        name: action.fileName,
                        id: shortid(),
                        globalVarData: {
                            vars: []
                        },
                        type: 'globalVars'
                    })
                }
                if(action.fileType === 'globalVarsJS4Scratch') {
                    if(stateWithMatrix.codingProjects[action.projectIndex].files.find(file => file.type === 'globalVarsJS4Scratch')) {
                        toast.error(`Cannot have more than one global variables file`);
                        return;
                    }
                    draftState.codingProjects[action.projectIndex].files.push({
                        name: action.fileName,
                        id: shortid(),
                        globalVarJS4ScratchData: {
                            vars: []
                        },
                        type: 'globalVarsJS4Scratch'
                    })
                }
                if(action.fileType === 'imageManager') {
                    if(stateWithMatrix.codingProjects[action.projectIndex].files.find(file => file.type === 'imageManager')) {
                        toast.error(`Cannot have more than one image manager file`);
                        return;
                    }

                    draftState.codingProjects[action.projectIndex].files.push({
                        name: action.fileName,
                        id: shortid(),
                        imageData: {
                            images: []
                        },
                        type: 'imageManager'
                    })
                }
                if(action.fileType === 'matrixEditor') {
                    if(stateWithMatrix.codingProjects[action.projectIndex].files.find(file => file.type === 'matrixEditor')) {
                        toast.error(`Cannot have more than one matrix editor file`);
                        return;
                    }
                    draftState.codingProjects[action.projectIndex].files.push({
                        name: action.fileName,
                        id: shortid(),
                        matrixEditorData: {
                            matrixEditor: matrixEditorInitialState
                        },
                        type: 'matrixEditor'
                    })
                }
                if(action.fileType === 'flowChart') {
                    draftState.codingProjects[action.projectIndex].files.push({
                        name: action.fileName,
                        id: shortid(),
                        flowChartData: {
                            data: {}
                        },
                        type: 'flowChart'
                    })
                }
            });
        case CODING_PROJECT_FILE_DELETE:
            return immer(stateWithMatrix, (draftState: ICodingState) => {
                if(action.fileIndex !== -1) draftState.codingProjects[action.projectIndex].files.splice(action.fileIndex, 1);
            });
        case CODING_SET_PROJECT_FILE_NAME:
            return immer(stateWithMatrix, (draftState: ICodingState) => {
                draftState.codingProjects[action.projectIndex].files[action.fileIndex].name = action.name;
            });
        case CODING_SET_PROJECT_FILE_BUTTONS:
            return immer(stateWithMatrix, (draftState: ICodingState) => {
                draftState.codingProjects[action.projectIndex].files[action.fileIndex].eventButtonData = {
                    buttons: action.buttons
                };
            });
        case CODING_DELETE_PROJECT_FILE_EVENT_BUTTONS_DELETE_BUTTON:
            return immer(stateWithMatrix, (draftState: ICodingState) => {
                draftState.codingProjects[action.projectIndex].files[action.fileIndex].eventButtonData?.buttons.splice(action.buttonIndex, 1);
            });
        case CODING_SET_PROJECT_FILE_ACTIVE_INDEX:
            return immer(stateWithMatrix, (draftState: ICodingState) => {
                draftState.codingProjects[draftState.activeProjectIndex].activeFileIndex = action.fileIndex;
            });
        case CODING_SET_PROJECT_FILE_CODE:
            return immer(stateWithMatrix, (draftState: ICodingState) => {
                if(action.fileType === 'js') {
                    draftState.codingProjects[action.projectIndex].files[action.fileIndex].jsData = {
                        code: action.code
                    };
                    return;
                }
                if(action.fileType === 'html') {
                    draftState.codingProjects[action.projectIndex].files[action.fileIndex].htmlData = {
                        html: action.code
                    };
                    return;
                }
                if(action.fileType === 'py') {
                    draftState.codingProjects[action.projectIndex].files[action.fileIndex].pyData = {
                        code: action.code
                    };
                    return;
                }
            });
        case CODING_SET_PROJECT_FILE_TEXT:
            return immer(stateWithMatrix, (draftState: ICodingState) => {
                draftState.codingProjects[action.projectIndex].files[action.fileIndex].textData = {
                    text: action.text
                };
            });
        case CODING_SET_PROJECT_FILE_GLOBAL_VARS:
            return immer(stateWithMatrix, (draftState: ICodingState) => {
                draftState.codingProjects[action.projectIndex].files[action.fileIndex].globalVarData = {
                    vars: action.globalVars
                };
            });
        case CODING_SET_PROJECT_FILE_GLOBAL_VARS_JS4SCRATCH:
            return immer(stateWithMatrix, (draftState: ICodingState) => {
                draftState.codingProjects[action.projectIndex].files[action.fileIndex].globalVarJS4ScratchData = {
                    vars: action.globalVars
                };
            });
        case CODING_SET_PROJECT_FILE_ASSET_MANAGER_SPRITES:
            return immer(stateWithMatrix, (draftState: ICodingState) => {
                draftState.codingProjects[action.projectIndex].files[action.fileIndex].assetManagerData = {
                    backdrops: stateWithMatrix.codingProjects[action.projectIndex].files[action.fileIndex].assetManagerData?.backdrops || [],
                    sprites: action.sprites
                }
            })
        case CODING_SET_PROJECT_FILE_ASSET_MANAGER_BACKDROPS:
            return immer(stateWithMatrix, (draftState: ICodingState) => {
                draftState.codingProjects[action.projectIndex].files[action.fileIndex].assetManagerData = {
                    sprites: stateWithMatrix.codingProjects[action.projectIndex].files[action.fileIndex].assetManagerData?.sprites || [],
                    backdrops: action.backdrops
                }
            })
        case CODING_SET_PROJECT_FILE_IMAGE_MANAGER_IMAGES:
            return immer(stateWithMatrix, (draftState: ICodingState) => {
                draftState.codingProjects[action.projectIndex].files[action.fileIndex].imageData = {
                    images: action.images
                }
            })
        case CODING_SET_PROJECT_FILE_FLOWCHART_DATA:
            return immer(stateWithMatrix, (draftState: ICodingState) => {
                draftState.codingProjects[action.projectIndex].files[action.fileIndex].flowChartData = {
                    data: action.data
                };
            })
        case CODING_IMPORT_PROJECT:
            return immer(stateWithMatrix, (draftState: ICodingState) => {
                draftState.codingProjects.push(action.data);
            })
        case STORE_IMPORT:
            return action.data.coding;
        default:
            return { ...stateWithMatrix };
    }
}