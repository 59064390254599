import store, { IThunkResult, selectorGetMatrixEditor } from 'store';

import {
    IAnimationSetFpsAction,
    IAnimationSetPlayingAction,
    IAnimationTickAction
} from '.';

import {
    ANIMATION_SET_FPS,
    ANIMATION_SET_PLAYING,
    ANIMATION_START_PLAYING,
    ANIMATION_STOP_PLAYING,
    ANIMATION_TICK
} from '.';

export const animationSetFps = (codingProjectIndex: number, fileIndex: number, fps: number): IAnimationSetFpsAction => {
    return {
        type: ANIMATION_SET_FPS,
        codingProjectIndex,
        fileIndex,
        fps
    }
}

export const animationSetPlaying = (codingProjectIndex: number, fileIndex: number,playing: boolean): IAnimationSetPlayingAction => {
    return {
        type: ANIMATION_SET_PLAYING,
        codingProjectIndex,
        fileIndex,
        playing
    }
}

let playingTimer: any = undefined;

export const animationStartPlaying = (codingProjectIndex: number, fileIndex: number,): IThunkResult<any> => {
    return dispatch => {
        const state = store.getState();
        const matrixEditor = selectorGetMatrixEditor(codingProjectIndex, fileIndex)(state);

        dispatch({
            type: ANIMATION_START_PLAYING,
            codingProjectIndex,
            fileIndex
        })
        dispatch(animationSetPlaying(codingProjectIndex, fileIndex, true))

        if(matrixEditor.animation.playing) dispatch(animationStopPlaying(codingProjectIndex, fileIndex)) //Stop playing if already going

        playingTimer = setInterval(() => {
            dispatch(animationTick(codingProjectIndex, fileIndex))
        }, 1000/matrixEditor.animation.fps)
        dispatch(animationTick(codingProjectIndex, fileIndex))
    }
}

export const animationStopPlaying = (codingProjectIndex: number, fileIndex: number,): IThunkResult<any> => {
    return dispatch => {
        dispatch({
            type: ANIMATION_STOP_PLAYING,
            codingProjectIndex,
            fileIndex
        });
        dispatch(animationSetPlaying(codingProjectIndex, fileIndex, false));
        clearInterval(playingTimer);
        playingTimer = undefined;
    }
}

export const animationTick = (codingProjectIndex: number, fileIndex: number,): IAnimationTickAction => {
    return  {
        type: ANIMATION_TICK,
        codingProjectIndex,
        fileIndex
    }
}