import { ICodingJSFileData, ICodingGlobalVarVariable, codingGlobalVarDefaults } from 'store';
import { toast } from 'react-toastify';

import { eBotSensorDataIncompleteAccessList } from 'scratchlinkjs/src/SensorDataIncompleteAccessList';

const addAwait = (codeString: string, key: string) => 
    codeString.split(`${key}.`)
    .reduce((prev, curr) => 
        //Excludes
        !curr.startsWith('addEventListener') && 
        !curr.startsWith('forever') && 
        !curr.startsWith('onStart') && 
        !curr.startsWith('globalVariables') &&
        !curr.startsWith('timer') &&
        !curr.startsWith('sensors') &&
        !curr.startsWith('gamepad1') &&
        !curr.startsWith('gamepad2') &&
        !prev.endsWith('window.') && //window is used for custom functions in iframes
        !prev.endsWith('await eBot.') //Dont add await if already added for eBot
        ? prev + `await ${key}.` + curr : prev + `${key}.` + curr
    );

const processScratchLinkJSCode = (jsFilesData: ICodingJSFileData[], globalVars: ICodingGlobalVarVariable[], codeHeader = '', includeConsoleLog = false, runCodeOnStartFunction = true, useAwaits = true, hasWindow = false, loopProtection = true) => {
    const consoleLog = `
        console.log = function() {
            eBot.print(arguments[0]);
        }
    `;

    codeHeader += `
        ${includeConsoleLog ? consoleLog : ''}
    `;

    if(globalVars.length > 0) {
        codeHeader += `
        /*
         *  GLOBAL VARIABLES
        */`;

        for(let i=0; i<globalVars.length; i++) {
            if(!hasWindow) codeHeader += `\n let ${globalVars[i].name} = ${codingGlobalVarDefaults[globalVars[i].type]};`;
            else codeHeader += `\n window.${globalVars[i].name} = ${codingGlobalVarDefaults[globalVars[i].type]};`;
        }
    }

    codeHeader += `\n\n`

    let code = '';
    if(runCodeOnStartFunction) { //For use to make multiple files run in parralel 
        code = jsFilesData.reduce((prev, curr, index) => (`
            ${prev}
            runCodeOnStart(async () => {
                eBot.wait(0.1); //Wait for eBot to be ready and sensor data to be updated
                //JSFILE-${index}-START
                ${curr.code}
                //JSFILE-${index}-END
            })
        `), '');
    } else {
        code = jsFilesData.reduce((prev, curr, index) => (`
            ${prev}
            //JSFILE-${index}-START
            ${curr.code}
            //JSFILE-${index}-END
        `), '');
    }

    //Add window. to custom functions
    if(hasWindow) {
        for(let i=0; i<globalVars.length; i++) {
            if(globalVars[i].type !== 'function') continue;
            code = code.split(`function ${globalVars[i].name}`).join(`window.${globalVars[i].name} = function`);
        }
    }

    //Add awaits
    if(useAwaits) {
        code = addAwait(code, 'eBot');
        code = addAwait(code, 'wheels');
        code = addAwait(code, 'led');
        code = addAwait(code, 'matrix');
        code = addAwait(code, 'crane');
        code = addAwait(code, 'eventsEbot');
        code = addAwait(code, 'control');
        code = addAwait(code, 'config');

        code = code.split('function').join('async function');

        //Add async await to custom functions
        let asyncFunctionNames: string[] = [];
        code.split(' ').join('').split('\n').join('').split('runCodeOnStart').join('') //filter junk
            .split('asyncfunction').forEach(segment => asyncFunctionNames.push(segment.split('(')[0])); //get async functions

        if(asyncFunctionNames.length < 2) asyncFunctionNames = []; //If we dont find more than one then no real async functions were found so empty the array. This is due to the nature of the split function

        for(let i=0; i<asyncFunctionNames.length; i++) {
            const functionName = asyncFunctionNames[i];
            if(functionName === '') continue;

            let segments = code.split(functionName);
            for(let j=1; j<segments.length; j++) {
                if(segments[j-1].endsWith('async function ')) {
                    segments[j] = `${functionName}${segments[j]}`;
                    continue;
                }

                segments[j] = `await ${functionName}${segments[j]}`;
            }

            code = segments.join('')
        }
    }

    if(loopProtection) {
        //loop protection. Insert await control.wait(0); into loops
        let loopMatches = [...code.matchAll(/while[\s]*\(.*\)[\s]*{|for[\s]*\(.*\)[\s]*{|do[\s]*{/g)];
        let offset = 0;  // Offset to account for insertions
        loopMatches.forEach(match => {
            if(match.index === undefined) return;  // Check against undefined
            if(match[0].startsWith('while')) toast.warn(`While loops are not supported. Please use control.forever instead. Refer to the help section on "Control" for more info.`)
            if(match[0].startsWith('do')) toast.warn(`Do While loops are not supported. Please use control.forever instead. Refer to the help section on "Control" for more info.`)

            const codeInsert = `\nawait control.wait(0);`;

            const loopEndStartIndex = match.index + match[0].length + offset;
            code = code.slice(0, loopEndStartIndex) + codeInsert + code.slice(loopEndStartIndex);
            offset += codeInsert.length;  // Length of the inserted string "//test"
        });
    }

    //Warn incomplete sensor data access (is not using the complete object)
    const sensorDataIncompleteAccessListKeys = Object.keys(eBotSensorDataIncompleteAccessList);
    for(const key of sensorDataIncompleteAccessListKeys) {
        const codeParts = code.split(`sensors.${key}`);
        for(let i=1; i<codeParts.length; i++) {
            const codePart = codeParts[i];
            const codePartFirstChar = codePart[0];
            if(codePartFirstChar === '.' || codePartFirstChar === '[') continue; //Check if the code is accessing a sub property of the sensor data

            //@ts-ignore
            toast.warn(`eBot.sensors.${key} is incomplete. Please specify the sensor. Example: ${eBotSensorDataIncompleteAccessList[key]}`)
        }
    }

    return `${codeHeader}\n\n${code}`;
}

export default processScratchLinkJSCode;