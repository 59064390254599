import store, { codingSetRunning, consoleAddConsoleLog, codingSetPyEnvReady, ICodingGlobalVarVariable, ICodingFile, ICodingPyFileData } from 'store';
import { toast } from 'react-toastify';
/* eslint-disable-next-line import/no-webpack-loader-syntax */
import Worker from 'worker-loader!./PyWorker.ts';
import { DeviceConnectionManager } from 'lib/DeviceConnection';
import processScratchLinkPyCode from 'utils/processScratchLinkPyCode';
import PyErrorHandler from './PyErrorHandler';
//import wait from 'utils/wait';

import {
    PyRunnerInitMessage,
    PyRunnerExecuteCodeMessage
} from './PyRunnerTypes';

import {
    PyWorkerMessageType,
    PyWorkerMessages,
    PyWorkerReadyMessage,
    PyWorkerRunningMessage,
    PyWorkerEndedMessage,
    PyWorkerErrorMessage,

    PyWorkerLogMessage,
    PyWorkerLogLibErrorMessage,
    PyWorkerCommandMessage
} from './PyWorkerTypes';

class PyRunner {
    worker: Worker | null = null;
    runningCode: string | null = null;
    pyFiles: ICodingFile[] = [];

    constructor() {
        this.sendDataPacket = this.sendDataPacket.bind(this);
    }

    async startPyEnv() {
        console.log("startPyEnv")
        this.worker = new Worker();

        this.workerLitsen();

        this.sendData({ //Init Pyodide
            type: "Init",
            data: {
                packages: []
            }
        } as PyRunnerInitMessage)
    }

    workerLitsen() {
        if(!this.worker) throw new Error("Worker not initialized");

        this.worker.onmessage = async (payload) => {
            const message: PyWorkerMessages = payload.data;

            switch(message.type) {
                case PyWorkerMessageType.Ready:
                    this.onReady(message);
                    break;
                case PyWorkerMessageType.Running:
                    this.onRunning(message);
                    break;
                case PyWorkerMessageType.Ended:
                    this.onEnded(message);
                    break;
                case PyWorkerMessageType.Error:
                    this.onError(message);
                    break;
                case PyWorkerMessageType.Log:
                    this.onLog(message);
                    break;
                case PyWorkerMessageType.LogLibError:
                    this.onLogLibError(message);
                    break;
                case PyWorkerMessageType.Command:
                    this.onCommand(message);
                    break;
            }
        }
    }

    onReady(message: PyWorkerReadyMessage) {
        store.dispatch(codingSetPyEnvReady(true));

        DeviceConnectionManager.addEventListener('data', this.sendDataPacket);
        this.sendDataPacket({ detail: DeviceConnectionManager.data });
    }

    onRunning(message: PyWorkerRunningMessage) {
        //@ts-ignore
        store.dispatch(codingSetRunning(true))
        store.dispatch(consoleAddConsoleLog('Started Running', 'PySystem', 'JSSystemConsoleDecorator'))
    }

    onEnded(message: PyWorkerEndedMessage) {
        this.stop();
    }

    onError(message: PyWorkerErrorMessage) {
        const lineNoString = message.data.error.match(/File "<exec>", line (\d+)/);
        const lineNo = lineNoString ? parseInt(lineNoString[1], 10) : -1;

        let errorMsgString = message.data.error.match(/File "<exec>", line \d+\n([\s\S]*?)(?=(\n{2}|$))/);
        if(!errorMsgString) errorMsgString = message.data.error.match(/File "<exec>", line \d+, in <module>\n(.*?)(?=\n|$)/);

        const cleanedErrorMsgString = errorMsgString ? errorMsgString[1].split('\n').slice(2).join('\n').trim() : null;
        const errorMsg = cleanedErrorMsgString ? cleanedErrorMsgString : errorMsgString ? errorMsgString[1].trim() : 'Unknown Error - Check dev console for more info';

        const error = {
            lineno: lineNo,
            message: errorMsg
        }

        console.error("Python Error", message.data.error, error, cleanedErrorMsgString);

        if(this.pyFiles && this.runningCode) PyErrorHandler.handleError(error, this.pyFiles, this.runningCode)
        else {
            toast.error(`Fatal Python Error - Check dev console for more info`);
            console.error("Fatal Python Error", message, this.pyFiles, this.runningCode);
        }

        this.stop();
    }

    onLog(message: PyWorkerLogMessage) {
        store.dispatch(consoleAddConsoleLog(message.data.msg, 'PyLog', 'NormalConsoleDecorator'));
    }

    onLogLibError(message: PyWorkerLogLibErrorMessage) {
        store.dispatch(consoleAddConsoleLog(message.data.msg, 'PyError', 'JSLibraryErrorConsoleDecorator'));
    }

    onCommand(message: PyWorkerCommandMessage) {
        DeviceConnectionManager.sendCommand(message.data);
    }

    async runCode(codingProjectFiles: ICodingFile[], codeHeader: string, hardwareModeAutoDetected: string, activeCodingProjectHardwareMode: string) {        
        const pyFiles = codingProjectFiles.filter(file => file.type === 'py');
        const pyFilesData = pyFiles.map(file => file.pyData) as ICodingPyFileData[];
        const globalVars = codingProjectFiles.find(file => file.type === 'globalVars')?.globalVarData?.vars;
        
        const runCode = this.generateRunCode(
            pyFilesData, 
            globalVars || [],
            codeHeader
        );

        console.log("runCode", runCode);

        this.runningCode = runCode;
        this.pyFiles = pyFiles;

        //@ts-ignore
        this.sendData({
            type: "ExecuteCode",
            data: {
                code: runCode
            }
        } as PyRunnerExecuteCodeMessage);
    }

    generateRunCode(pyFilesData: ICodingPyFileData[], globalVars: ICodingGlobalVarVariable[], codeHeader: string): string {
        const code = processScratchLinkPyCode(pyFilesData, globalVars, codeHeader, true);
    
        const runCode = `
import JSScratchLink
from pyodide.ffi import create_proxy
import time

${code.trim()}`;

        return runCode.trim();
    }

    stop() {
        this.worker?.terminate();
        this.startPyEnv();

        //@ts-ignore
        store.dispatch(codingSetRunning(false));
        store.dispatch(codingSetPyEnvReady(false));
        store.dispatch(consoleAddConsoleLog('Stopped Running', 'PySystem', 'JSSystemConsoleDecorator'));

        DeviceConnectionManager.removeEventListener('data', this.sendDataPacket)
    }

    sendData(data: any) {
        this.worker?.postMessage(data);
    }

    sendButtonEvent(buttonId: string) {
        this.sendData({type: 'buttonEvent', data: buttonId})
    }

    sendDataPacket(data: any) {
        this.sendData({type: 'sensorData', data: data.detail})
    }
}

export default new PyRunner(); //Singleton