import React, { useState } from 'react';

import './DropDown.scss';

interface DropDownProps {
    title?:string
    name: string;
    icon?: React.ReactElement;
    className?: string;
    nameStyle?: React.CSSProperties;
    buttonStyle?: React.CSSProperties;
    contents: {
        name: string;
        icon?: React.ReactElement;
        link?: string;
        customComponent?: React.FC;
        onClick?: () => void;
    }[]
}

const DropDown: React.FC<DropDownProps> = ({title, name, icon, className = '', nameStyle = {}, buttonStyle = {}, contents}) => {
    const [open, setOpen] = useState(false);

    const onShouldOpen = () => {
        setOpen(true);
    }

    const onShouldClose = () => {
        setOpen(false);
    }

    const contentClasses = `c-dropDown__content ${open ? 'c-dropDown__content--open' : ''} ${className}}`;
    return (
        <div className="c-dropDown" onMouseEnter={onShouldOpen} onMouseLeave={onShouldClose}>
            <button className="c-dropDown__button" style={buttonStyle} onClick={onShouldOpen}>
                <h5 className='font-bold'>
                    {
                        title && title
                    }
                </h5>
                <div className='flex items-center content-center'>
                    {icon} <span style={nameStyle}>{name}</span>
                </div>
            </button>
            <div className={contentClasses} onClick={onShouldClose}>
                {
                    contents.map(content => {
                        if(content.customComponent) return (
                            <content.customComponent key={content.name} />
                        )

                        if(content.link) return (
                            <button key={content.name} onClick={content.onClick}>
                                {content.icon} <span className='ml-1'><a className='text-white hover:text-white' href={content.link} target="_blank" rel="noopener noreferrer">{content.name}</a></span>
                            </button>
                        )

                        return (
                            <button key={content.name} onClick={content.onClick}>
                                {content.icon} <span className='ml-1'>{content.name}</span>
                            </button>
                        )
                    })
                }
            </div>
        </div>
    );
}


export default DropDown;