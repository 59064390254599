import store, { IThunkResult, selectorGetMatrixEditor } from 'store';

import {
    ICodingMatrixEditorSetDemoPlayingAction,
    ICodingMatrixEditorDemoTickAction
} from '.';

import {
    MATRIXEDITOR_SET_DEMO_PLAYING,
    MATRIXEDITOR_START_DEMO_PLAYING,
    MATRIXEDITOR_STOP_DEMO_PLAYING,
    MATRIXEDITOR_DEMO_TICK
} from '.';

export const matrixEditorSetDemoPlaying = (codingProjectIndex: number, fileIndex: number, demoPlaying: boolean): ICodingMatrixEditorSetDemoPlayingAction => {
    return {
        type: MATRIXEDITOR_SET_DEMO_PLAYING,
        codingProjectIndex,
        fileIndex,
        demoPlaying
    }
}

let matrixEditorDemoPlayingTimer: undefined | ReturnType<typeof setInterval> = undefined;

export const matrixEditorStartDemoPlaying = (codingProjectIndex: number, fileIndex: number): IThunkResult<any> => {
    return dispatch => {
        const state = store.getState();
        const matrixEditor = selectorGetMatrixEditor(codingProjectIndex, fileIndex)(state);

        dispatch({
            type: MATRIXEDITOR_START_DEMO_PLAYING,
            codingProjectIndex,
            fileIndex
        })
        dispatch(matrixEditorSetDemoPlaying(codingProjectIndex, fileIndex, true))

        if(matrixEditor.demoPlaying) dispatch(matrixEditorSetDemoPlaying(codingProjectIndex, fileIndex, false)) //Stop playing if already going

        matrixEditorDemoPlayingTimer = setInterval(() => {
            const state = store.getState();
            const matrixEditor = selectorGetMatrixEditor(codingProjectIndex, fileIndex)(state);
            if(!matrixEditor.demoPlaying) return matrixEditorStopDemoPlaying(codingProjectIndex, fileIndex);

            dispatch(matrixEditorDemoTick(codingProjectIndex, fileIndex))
        }, 500)
        dispatch(matrixEditorDemoTick(codingProjectIndex, fileIndex))
    }
}

export const matrixEditorStopDemoPlaying = (codingProjectIndex: number, fileIndex: number): IThunkResult<any> => {
    return dispatch => {
        dispatch({
            type: MATRIXEDITOR_STOP_DEMO_PLAYING,
            codingProjectIndex,
            fileIndex
        });
        dispatch(matrixEditorSetDemoPlaying(codingProjectIndex, fileIndex, false));
        if(matrixEditorDemoPlayingTimer !== undefined) clearInterval(matrixEditorDemoPlayingTimer);
        matrixEditorDemoPlayingTimer = undefined;
    }
}

export const matrixEditorDemoTick = (codingProjectIndex: number, fileIndex: number): ICodingMatrixEditorDemoTickAction => {
    return {
        type: MATRIXEDITOR_DEMO_TICK,
        codingProjectIndex,
        fileIndex
    }
}