import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState, selectorGetActiveScratchLinkConfigDeviceProfile } from 'store';
import { TiBatteryFull, TiBatteryHigh, TiBatteryMid, TiBatteryLow } from 'react-icons/ti';

import './BatteryIndicator.scss';

type Props = PropsFromRedux & {

}

const BatteryIndicator: React.FC<Props> = ({ deviceBatteryPercent, deviceConnected, deviceProfile, scratchlinkConfig }) => {
    let classes = 'c-batteryIndicator';
    let BatteryIcon = TiBatteryFull;
    let shouldHide = true;

    if(
        deviceProfile && 
        deviceProfile.battery &&
        scratchlinkConfig.generalConfig?.batteryIndicator[deviceProfile.battery] &&
        deviceBatteryPercent && 
        deviceConnected
    ) {
        const batteryIndicatorConfig = scratchlinkConfig.generalConfig.batteryIndicator[deviceProfile.battery];
        if(deviceBatteryPercent <= batteryIndicatorConfig['1BarPercent']) {
            classes += ' c-batteryIndicator__error';
            BatteryIcon = TiBatteryLow;
        }
        else if(deviceBatteryPercent <= batteryIndicatorConfig['2BarPercent']) {
            classes += ' c-batteryIndicator__warning';
            BatteryIcon = TiBatteryMid;
        } else if(deviceBatteryPercent <= batteryIndicatorConfig['3BarPercent']) {
            BatteryIcon = TiBatteryHigh;
        }

        shouldHide = false
    }

    return (
        <div className={classes} style={{opacity: shouldHide ? '0' : '1'}}>
            <BatteryIcon size='2.2em' style={{margin: '-5px 0'}} />
        </div>
    )
};

const mapStateToProps = (state: IRootState) => {
    return {
        deviceBatteryPercent: state.project.deviceBatteryPercent,
        deviceConnected: state.project.deviceConnected,
        deviceProfile: selectorGetActiveScratchLinkConfigDeviceProfile(state),
        scratchlinkConfig: state.project.scratchlinkConfig
    }
}

const mapDispatchToProps = {
    
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(BatteryIndicator);