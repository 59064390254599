import React from 'react';
import { IConsoleDecorator } from '../ConsoleDecorator';

export const ScratchLinkOutgoingConsoleDecorator: IConsoleDecorator = ({ msgObject }) => {

    return (
        <>
            eBot Outgoing Command: { msgObject }
        </>
    )
};

ScratchLinkOutgoingConsoleDecorator.decoratorTest = () => true;

ScratchLinkOutgoingConsoleDecorator.consoleClass = () => 'c-consoleDecorator__ScratchLinkOutgoing';