import { IStoreImportAction } from '..';

export const SETTINGS_SET_FILENAME = 'SETTINGS/SET_FILENAME';
export const SETTINGS_SET_REMOTE_USER_ID = 'SETTINGS/SET_REMOTE_USER_ID';
export const SETTINGS_SET_JAVASCRIPT_EDITOR_TOOL_TYPE = 'SETTINGS/SET_JAVASCRIPT_EDITOR_TOOL_TYPE';
export const SETTINGS_SET_DEVICE_DIRECT_IP_CONNECTION_IP = 'SETTINGS/SET_DEVICE_DIRECT_IP_CONNECTION_IP';
export const SETTINGS_SET_DEV_MODE = 'SETTINGS/SET_DEV_MODE';
export const SETTINGS_SET_MONACO_FONT_SIZE = 'SETTINGS/SET_MONACO_FONT_SIZE';

export interface ISettingsSetFileNameAction {
    type: typeof SETTINGS_SET_FILENAME;
    fileName: string;
}

export interface ISettingsSetRemoteUserIdAction {
    type: typeof SETTINGS_SET_REMOTE_USER_ID;
    userId: string;
}

export interface ISettingsSetJavascriptEditorToolTypeAction {
    type: typeof SETTINGS_SET_JAVASCRIPT_EDITOR_TOOL_TYPE;
    javascriptEditorToolType: ISettingsJavascriptEditorToolType;
}

export interface ISettingsSetDeviceDirectIpConnectionIpAction {
    type: typeof SETTINGS_SET_DEVICE_DIRECT_IP_CONNECTION_IP;
    deviceDirectIpConnectionIp: string;
}

export interface ISettingsSetDevMode {
    type: typeof SETTINGS_SET_DEV_MODE;
    devMode: boolean;
}

export interface ISettingsSetMonacoFontSize {
    type: typeof SETTINGS_SET_MONACO_FONT_SIZE;
    fontSize: ISettingsMonacoFontSize;
}


export type ISettingsActions = IStoreImportAction | ISettingsSetFileNameAction | ISettingsSetRemoteUserIdAction | ISettingsSetJavascriptEditorToolTypeAction | ISettingsSetDeviceDirectIpConnectionIpAction | ISettingsSetDevMode | ISettingsSetMonacoFontSize;

export type ISettingsJavascriptEditorToolType = 'auto' | 'monaco' | 'codemirror';

export type ISettingsMonacoFontSize = 14 | 16 | 18 | 20 | 22 | 24 | 26;

export interface ISettingsState {
    fileName: string;
    remoteUserId: string;
    javascriptEditorToolType: ISettingsJavascriptEditorToolType;
    deviceDirectIpConnectionIp: string;
    devMode: boolean;
    monacoFontSize: ISettingsMonacoFontSize;
    workspaceVersion: '1.0';
}