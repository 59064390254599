export default `
[data-templ="text"] textarea {
    text-align: left;
}

[data-connect], [data-templ="select-start"] {
    opacity: 0.51;
    stroke: rgb(108 187 247);
    fill: rgb(108 187 247);
}

[data-connect="in"].hovered {
    stroke-width: 25px;
}

[data-templ] [data-connect] {
    display: none;
}

[data-connect="in"].connected {
    display: unset;
    fill: transparent;
    stroke: transparent;
}

[data-connect="in"].connected.selected {
    display: unset;
    opacity: 0.51;
    stroke: rgb(108 187 247);
    fill: rgb(108 187 247);
}

[data-templ].selected [data-connect="out"] {
    display: unset;
}

[data-templ].selected [data-connect="in"] {
    display: none;
}

[data-templ].selected.highlighted [data-connect="out"] {
    display: none;
}

[data-templ].highlighted .main {
    paint-order: stroke;
    stroke-width: 10px;
    stroke: rgb(108 187 247 / 51%);
}

[data-templ="rhomb"].highlighted [data-key="border"] {
    stroke-width: 28px;
    stroke: rgb(108 187 247 / 51%);
}

[data-templ="text"].highlighted .main, [data-templ="text"].selected .main, [data-templ="text"].hovered .main {
    stroke-width: 2px;
    stroke: rgb(108 187 247 / 51%);
}

[data-templ].hovered [data-connect="in"] {
    display: unset;
    stroke: rgb(108 187 247);
    fill: rgb(108 187 247);
}

[data-templ].disabled {
    pointer-events: none;
}

[data-templ="path"] [data-key="selected"] {
    stroke-width: 10px !important;
    stroke: rgb(108 187 247 / 30%);
    display: none;
}

[data-templ="path"].selected [data-key="selected"] {
    display: unset;
}

@media (pointer: coarse) {

    [data-connect],
    [data-connect] circle,
    [data-templ="path"] [data-key="arrow"] circle {
        stroke-width: 15px;
    }

    [data-connect="in"].hovered, [data-templ="select-start"] {
        stroke-width: 55px;
    }
}


/* shape settings styles */

[data-templ="circle"].cl-red [data-key="main"],
[data-templ="rect"].cl-red [data-key="main"],
[data-templ="connectorNode"].cl-red [data-key="main"],
[data-templ="rhomb"].cl-red [data-key="main"] { fill: #E74C3C; }
[data-templ="rhomb"].cl-red [data-key="main"] { stroke: #E74C3C;}
[data-templ="text"].cl-red text { fill: #E74C3C;}

[data-templ="circle"].cl-orange [data-key="main"],
[data-templ="rect"].cl-orange [data-key="main"],
[data-templ="connectorNode"].cl-orange [data-key="main"],
[data-templ="rhomb"].cl-orange [data-key="main"] { fill: #ff6600; }
[data-templ="rhomb"].cl-orange [data-key="main"] { stroke: #ff6600;}
[data-templ="text"].cl-orange text { fill: #ff6600;}


[data-templ="circle"].cl-green [data-key="main"],
[data-templ="rect"].cl-green [data-key="main"],
[data-templ="connectorNode"].cl-green [data-key="main"],
[data-templ="rhomb"].cl-green [data-key="main"] { fill: #19bc9b; }
[data-templ="rhomb"].cl-green [data-key="main"] { stroke: #19bc9b;}
[data-templ="text"].cl-green text { fill: #19bc9b;}

[data-templ="circle"].cl-blue [data-key="main"],
[data-templ="rect"].cl-blue [data-key="main"],
[data-templ="connectorNode"].cl-blue [data-key="main"],
[data-templ="rhomb"].cl-blue [data-key="main"] { fill: #1aaee5; }
[data-templ="rhomb"].cl-blue [data-key="main"] { stroke: #1aaee5;}
[data-templ="text"].cl-blue text { fill: #1aaee5;}

[data-templ="circle"].cl-dblue [data-key="main"],
[data-templ="rect"].cl-dblue [data-key="main"],
[data-templ="connectorNode"].cl-dblue [data-key="main"],
[data-templ="rhomb"].cl-dblue [data-key="main"] { fill: #1D809F; }
[data-templ="rhomb"].cl-dblue [data-key="main"] { stroke: #1D809F;}
[data-templ="text"].cl-dblue text { fill: #1D809F;}

[data-templ="circle"].cl-dgray [data-key="main"],
[data-templ="rect"].cl-dgray [data-key="main"],
[data-templ="connectorNode"].cl-dgray [data-key="main"],
[data-templ="rhomb"].cl-dgray [data-key="main"] { fill: #495057; }
[data-templ="rhomb"].cl-dgray [data-key="main"] { stroke: #495057;}
[data-templ="text"].cl-dgray text { fill: #495057;}

[data-templ="connectorNode"] text { display: none; },
`