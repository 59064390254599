import React from 'react';
import { IConsoleDecorator } from '../ConsoleDecorator';

export const JSLibraryErrorConsoleDecorator: IConsoleDecorator = ({ msgObject }) => {

    return (
        <>
            <span>JS Library Error: </span> { msgObject }
        </>
    )
};

JSLibraryErrorConsoleDecorator.decoratorTest = () => true;

JSLibraryErrorConsoleDecorator.consoleClass = () => 'c-consoleDecorator__jsLibError';