import { toast } from 'react-toastify';
import store, { cleanStateForSave, IRootState, storeImport, codingImportProject, settingsSetRemoteUserId, ICodingProject, projectSetHardwareModeAutoDetected } from 'store';
import { load } from 'redux-localstorage-simple';
import { saveAs } from 'file-saver';
import { firebaseStorage } from '.';
import { ref, uploadBytes } from 'firebase/storage';

import RestoreProjectNotification from 'components/RestoreProjectNotification/RestoreProjectNotification';

const hasLocalStorageRedux = Object.keys(localStorage).find(item => item.startsWith('reduxStorage'));
let localStoreRestoreToastId: null | string | number = null;

const clearReduxLocalStorage = () => {
    const localReduxStores = Object.keys(localStorage).filter(key => key.includes('reduxStorage')).sort();
    localReduxStores.splice(localReduxStores.length-1, 1); //Dont delete current (lastest)

    localReduxStores.forEach(storeName => localStorage.removeItem(storeName));
}

if(hasLocalStorageRedux) {
    localStoreRestoreToastId = toast.info(RestoreProjectNotification, {
        closeOnClick: false,
        onClose: clearReduxLocalStorage
    })
}

const startSaveReminderInterval = () => {
    return setInterval(() => {
        toast.warn(`Save Reminder: It's been 5 minutes since last save. Remember to save your work`);
    }, 5 * 60 * 1000);
}

const restartSaveReminder = () => {
    if(saveReminderInterval) clearInterval(saveReminderInterval);
    saveReminderInterval = undefined;
    
    saveReminderInterval = startSaveReminderInterval() //restart loop
}

let saveReminderInterval: undefined | ReturnType<typeof setInterval> = startSaveReminderInterval();

export const restoreProjectStateFromLocalStorage = () => {
    const localReduxStores = Object.keys(localStorage).filter(key => key.includes('reduxStorage')).sort();
    const lastSessionStoreKey = localReduxStores[localReduxStores.length-2]; //get second last as current session will have generated new store already

    const localStorageState = cleanStateForSave(load({ namespace: lastSessionStoreKey }) as IRootState);
    store.dispatch(storeImport(localStorageState));

    clearReduxLocalStorage();

    if(localStoreRestoreToastId) toast.dismiss(localStoreRestoreToastId);
    toast.success(`Restored Last Session State`);
}

export const saveToLocalFile = () => {
    const state = store.getState();

   /*  if(state.settings.devMode) { //Dev mode includes everything in save file
        const blob = new Blob([JSON.stringify(state)], {type: "text/json;charset=utf-8"});
        saveAs(blob, `${state.settings.fileName}.ScratchLinkDev`);
        restartSaveReminder()
        return;
    } */

    const cleanState = cleanStateForSave(state);
    const blob = new Blob([JSON.stringify(cleanState)], {type: "text/json;charset=utf-8"});
    saveAs(blob, `${state.settings.fileName}.ScratchLink`);

    restartSaveReminder()
}

export const openLocalWorkspace = (fileString: string): void => {
    let fileData: any;

    try {
        fileData = JSON.parse(fileString);
    } catch(e) {
        console.error(e);
        return;
    }

    store.dispatch(storeImport(fileData))
    toast.success('Opened File')
}

export const openLocalProject = (fileString: string): void => {
    let fileData: ICodingProject;

    try {
        fileData = JSON.parse(fileString);
    } catch(e) {
        console.error(e);
        return;
    }

    //@ts-ignore
    store.dispatch(codingImportProject(fileData))
    toast.success(`Imported ${fileData.name}`)
}

export const saveRemoteFile = async (userId:string, fileName: string) => {
    store.dispatch(settingsSetRemoteUserId(userId));

    const state = store.getState();

    try {
        const cleanState = cleanStateForSave(state);
        const blob = new Blob([JSON.stringify(cleanState)], {type: "text/json;charset=utf-8"});
        const storageRef = ref(firebaseStorage, `${userId}/${fileName}.ScratchLink`);

        await uploadBytes(storageRef, blob);
    } catch(e) {
        toast.error(`ERROR: Could not save file. Check console for more information.`);
        console.log(e);
        return;
    }

    toast.success(`Filed saved with name: ${fileName}`);
    return;
}

