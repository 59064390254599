//import immer from 'immer';

import { ICodingBackpackState, ICodingBackpackActionTypes, codingBackpackFoldersReducer, codingBackpackFoldersInitialState, codingBackpackMatrixesReducer, codingBackpackMatrixesInitialState } from '.';
import { ICodingMatrixEditorAction } from 'store';

import {
    CODING_PROJECT_FILE_BACKPACK_CHANGE_SELECTED_FOLDER,
    CODING_PROJECT_FILE_BACKPACK_CHANGE_SELECTED_MATRIX
} from '.';

import {
    ANIMATION_TICK
} from '../animation';

export const backpackInitialState: ICodingBackpackState = {
    folders: codingBackpackFoldersInitialState,
    matrixes: codingBackpackMatrixesInitialState,
    selectedFolderId: 'root',
    selectedMatrixId: 'initMatrix'
}

export const backpackReducer = (state: ICodingBackpackState, action: ICodingBackpackActionTypes, codingProjectIndex: number, fileIndex: number): ICodingBackpackState => {
    const folders = codingBackpackFoldersReducer(state.folders, action as any, codingProjectIndex, fileIndex)
    const matrixes = codingBackpackMatrixesReducer(state.matrixes, action as any, codingProjectIndex, fileIndex);

    const currAction = action as ICodingMatrixEditorAction;
    if(currAction.codingProjectIndex !== codingProjectIndex || currAction.fileIndex !== fileIndex) return {...state, folders, matrixes};
    
    switch (action.type) {
        case CODING_PROJECT_FILE_BACKPACK_CHANGE_SELECTED_FOLDER:
            return {...state, folders, matrixes, selectedFolderId: action.folderId};

        case CODING_PROJECT_FILE_BACKPACK_CHANGE_SELECTED_MATRIX:
            return {...state, folders, matrixes, selectedMatrixId: action.matrixId};

        case ANIMATION_TICK:
            const cFolder = state.folders.byIds[state.selectedFolderId];
            const selectedBackpackMatrixIndex = cFolder.matrixIds.indexOf(state.selectedMatrixId);
            let nextIndex = selectedBackpackMatrixIndex+1;
            if(nextIndex > cFolder.matrixIds.length-1) nextIndex = 0;
            const nextMatrixId = cFolder.matrixIds[nextIndex];
            
            return {...state, folders, matrixes, selectedMatrixId: nextMatrixId};
            
        default:
            return {...state, folders, matrixes};
    }
}