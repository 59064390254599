import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState, projectSetDeviceDeviceIp } from 'store';
import { DeviceConnectionManager } from 'lib/DeviceConnection';

import Input from 'components/Input/Input';
import Button from 'components/Button/Button';

import './DirectIpPhase.scss';

type Props = PropsFromRedux & {
    onBackClick: () => void;
}

const ConnectPhase: React.FC<Props> = ({ onBackClick, directIp, projectSetDeviceDeviceIp }) => {
    const [ip, setIp] = useState(directIp);
    const [ipFinished, setIpFinished] = useState<undefined | ReturnType<typeof setTimeout>>(undefined);

    const handleIpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIp(e.target.value);

        if(ipFinished) clearTimeout(ipFinished);
        setIpFinished(undefined);

        setIpFinished(setTimeout(() => {
            projectSetDeviceDeviceIp(ip);
        }, 300));
    }

    const onBackClickEvent = () => {
        onBackClick();
    }

    const onConnectClick = () => {
        projectSetDeviceDeviceIp(ip);
        DeviceConnectionManager.connect();
    }

    return (
        <div className="c-directIpPhase">
            <h5 style={{margin: 0}}>eBot IP:</h5>
            <Input value={ip} onChange={handleIpChange}/>

            <br /><br />
            <div className="c-directIpPhase__buttons">
                <Button type="danger" addStyles={['square']} onClick={onBackClickEvent}>Back</Button>
                <Button type="success" addStyles={['square']} onClick={onConnectClick}>Connect</Button>
            </div>
        </div>
    )
}

const mapStateToProps = (state: IRootState): StateProps => {
    return {
        directIp: state.project.deviceDirectIp
    }
}

const mapDispatchToProps = {
    projectSetDeviceDeviceIp
}

interface StateProps {
    directIp: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ConnectPhase);