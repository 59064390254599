import { ICodingPyFileData, ICodingGlobalVarVariable } from 'store';

const addAwait = (codeString: string, key: string) => 
    codeString.split(`${key}.`)
    .reduce((prev, curr) => 
        //Excludes
        !curr.startsWith('addEventListener') && 
        !curr.startsWith('eventsEbot') &&
        !curr.startsWith('forever') && 
        !curr.startsWith('onStart') && 
        !curr.startsWith('globalVariables') &&
        !curr.startsWith('timer') &&
        !curr.startsWith('sensors') &&
        !prev.endsWith('await eBot.') //Dont add await if already added for eBot
        ? prev + `await ${key}.` + curr : prev + `${key}.` + curr
    );

const processScratchLinkPyCode = (pyFilesData: ICodingPyFileData[], globalVars: ICodingGlobalVarVariable[], codeHeader = '', includeConsoleLog = false, runCodeOnStartFunction = true, useAwaits = true) => {  
    //get code from pyFilesData
    let code = pyFilesData.reduce((prev, curr, index) => (`
${prev}
#PYFILE-${index}-START
${curr.code}
#PYFILE-${index}-END`
), '');
    
    code = code.replace(/\.addEventListener\(\s*['"](\w+)['"]\s*,\s*(\w+)\s*\)/mg, ".addEventListener('$1', create_proxy($2))");
    code = code.replace(/\.forever\((\w+)\)/mg, ".forever(create_proxy($1))");
    code = code.replace(/\.repeat\(\s*(\d+)\s*,\s*(\w+)\s*\)/mg, ".repeat($1, create_proxy($2))");

    code = code.replace(/print\((['"]?)(.*?)\1\)/g, 'eBot.print($1$2$1)');

    if(useAwaits) {
        code = addAwait(code, 'eBot');
        code = addAwait(code, 'wheels');
        code = addAwait(code, 'led');
        code = addAwait(code, 'matrix');
        code = addAwait(code, 'crane');
        code = addAwait(code, 'eventsEbot');
        code = addAwait(code, 'control');
        code = addAwait(code, 'config');

        //
        //Add async await to all functions
        //

        //Add async before def
        code = code.replace(/def\s+(\w+)\s*\(([^)]*)\)/g, "async def $1($2)");        
        
        let asyncFunctionNames: string[] = [];
        code.split(' ').join('').split('\n').join('') //filter junk
            .split('asyncdef').forEach(segment => asyncFunctionNames.push(segment.split('(')[0])); //get async functions

        if(asyncFunctionNames.length < 2) asyncFunctionNames = []; //If we dont find more than one then no real async functions were found so empty the array. This is due to the nature of the split function

        for(let i=0; i<asyncFunctionNames.length; i++) {
            const functionName = asyncFunctionNames[i];
            if(functionName === '') continue;

            let segments = code.split(functionName);
            for(let j=1; j<segments.length; j++) { //Start at 1 because the first segment is before the first function call
                if(!segments[j].startsWith('(') || segments[j-1].trim().endsWith('async def')) { //If the segment does not start with a ( then it is not a function call or if the previous segment ends with async def then it is a function definition
                    segments[j] = `${functionName}${segments[j]}`;
                    continue;
                }

                segments[j] = `await ${functionName}${segments[j]}`;
            }

            code = segments.join('')
        }
    }

    return `${codeHeader}\n${code}\ncontrol.wait(60 * 60)`;
}

export default processScratchLinkPyCode;