import React, { useState, useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Select, { OptionProps, SingleValueProps } from 'react-select';
import { IRootState, ICodingCostume, ICodingSpriteType, ICodingSprite, ICodingBackdrop, codingSetProjectFileAssetManagerSprites, codingSetProjectFileAssetManagerBackdrops } from 'store';
import { toast } from 'react-toastify';
import { FaChevronLeft } from 'react-icons/fa'
import getScratchAssetUri from 'utils/getScratchAssetUri';
import spriteAssetsJson from 'assets/scratchAssets/sprites.json';
import backdropAssetsJson from 'assets/scratchAssets/backdrops.json';
import SpriteFileDecoder from 'lib/SpriteFileDecoder';

import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';

interface ICodingSpriteAssetData {
    name: string;
    costumes: {
        name: string;
        asset: string;
    }[];
    type: 'mit' | 'scratchlink'
}

interface ICodingBackdropAssetData {
    name: string;
    asset: string;
    type: 'mit' | 'scratchlink'
}

interface AssetOption extends ReactSelectStringOption {
    assetUrl: string
}

type SpriteSelectMethod = 'select' | 'import';

const spriteAssets = spriteAssetsJson as ICodingSpriteAssetData[];
const spriteTypeSelect: AssetOption[] = spriteAssets.map(spriteAsset => ({
    label: spriteAsset.name,
    value: spriteAsset.name,
    assetUrl: getScratchAssetUri(spriteAsset.costumes[0].asset, spriteAsset.type)
}));

const AssetOption: React.FC<OptionProps<AssetOption, false>> = ({ innerProps, isDisabled, data, label }) => {
    if(isDisabled) return null;
    const assetUrl: string = data.assetUrl;

    return ( //@ts-ignore
        <div {...innerProps} className="h-12 border-b-2 flex items-center">
            <img src={assetUrl} className="w-12 max-h-12 p-1" />
            <div className="border-r-2 h-full px-1" />
            <span className="pl-2">{label}</span>
        </div>
    )
}

const AssetValueContainer: React.FC<SingleValueProps<AssetOption>> = ({ isDisabled, data, children }) => {
    if(isDisabled) return null;
    const assetUrl: string = data.assetUrl;

    return ( //@ts-ignore
        <div className="flex items-center">
            <img src={assetUrl} className="w-12 max-h-12 p-1" />
            <div className="border-r-2 h-full px-1" />
            <span className="pl-2">{children}</span>
        </div>
    )
}

const backdropAssets = backdropAssetsJson as ICodingBackdropAssetData[];
const backdropTypeSelect: AssetOption[] = backdropAssets.map(backdropAsset => ({
    label: backdropAsset.name,
    value: backdropAsset.name,
    assetUrl: getScratchAssetUri(backdropAsset.asset, backdropAsset.type)
}));

const spriteImportButtonChildDefault = <span>Import Scratch Sprite (.sprite3)</span>;

type OwnProps = {
    codingProjectIndex: number;
    fileIndex: number;
}

type Props = PropsFromRedux & OwnProps;

const SpriteCostumeCarousel: React.FC<{type: ICodingSpriteType, costumes: ICodingCostume[]}> = ({ type, costumes }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const onCarouselClick = (direction: 'left' | 'right') => {
        if(direction === 'left') {
            const newIndex = currentIndex - 1;
            if(newIndex === -1) return setCurrentIndex(costumes.length-1);
            setCurrentIndex(newIndex);
        }
        if(direction === 'right') {
            const newIndex = currentIndex + 1;
            if(newIndex > costumes.length-1) return setCurrentIndex(0);
            setCurrentIndex(newIndex);
        }
    }

    return (
        <div className="flex flex-row justify-center items-center h-24">
            <div className="flex justify-center items-center">
                <span className="p-1 cursor-pointer">
                    <FaChevronLeft className="text-gray-500 mr-5" size="1.3em" onClick={() => onCarouselClick('left')}/>
                </span>
                <img className="w-16 max-h-20" src={getScratchAssetUri(costumes[currentIndex].asset, type)} />
                <span className="p-1 cursor-pointer">
                    <FaChevronLeft className="text-gray-500 ml-5" style={{transform: 'rotate(180deg)'}} size="1.3em" onClick={() => onCarouselClick('right')} />
                </span>
            </div>
            <div className="text-left ml-4">
                <div>
                    Costume Number: {currentIndex}
                </div>
            </div>
        </div>
    )
}

const CodingAssetManager: React.FC<Props> = ({ codingProjectIndex, fileIndex, codingFile, codingSetProjectFileAssetManagerSprites, codingSetProjectFileAssetManagerBackdrops }) => {
    if(codingFile.type !== 'assetManager') throw new Error('CodingAssetManager: codingFile.type is not assetManager');
    if(!codingFile.assetManagerData) throw new Error();
    const sprites = codingFile.assetManagerData.sprites;
    const backdrops = codingFile.assetManagerData.backdrops;

    const spriteImportInputRef = useRef(null);

    const [createSpriteName, setCreateSpriteName] = useState('');
    const [createSpriteAsset, setCreateSpriteAsset] = useState<AssetOption | null>();
    const [createSpriteModalOpen, setCreateSpriteModalOpen] = useState(false);
    const [spriteSelectMethod, setSpriteSelectMethod] = useState<SpriteSelectMethod>('select');
    const [spriteImportButtonChild, setSpriteImportButtonChild] = useState<React.ReactElement>(spriteImportButtonChildDefault);
    const [spriteImportData, setSpriteImportData] = useState<ICodingSprite>()

    const [createBackdropName, setCreateBackdropName] = useState('');
    const [createBackdropAsset, setCreateBackdropAsset] = useState<AssetOption | null>();
    const [createBackdropModalOpen, setCreateBackdropModalOpen] = useState(false);

    const openCreateSpriteModal = () => {
        setCreateSpriteModalOpen(true);
    }

    const closeCreateSpriteModal = () => {
        setCreateSpriteModalOpen(false);
    }

    const onSpriteNameChange = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
        setCreateSpriteName(changeEvent.target.value.split('').filter(char => /[a-zA-Z_]/.test(char)).join(''))
    }

    const onSpriteSelectChange = (val: AssetOption | null) => {
        setCreateSpriteAsset(val)
    }

    const onSpriteSelectMethodChange = (selectMethod: SpriteSelectMethod) => {
        setSpriteSelectMethod(selectMethod);
    }

    const onSpriteImportClick = () => {
        //@ts-ignore
        spriteImportInputRef?.current?.click();
    }

    const onSpriteImportChangeFile = async(e: React.ChangeEvent<HTMLInputElement>) => {
        //@ts-ignore
        const file = e.target.files[0];

        const sprite = await SpriteFileDecoder.decode(file);

        if(sprite !== null) {
            setSpriteImportData(sprite);
            setSpriteImportButtonChild(<span>Loaded {sprite.name}</span>)
        } else {
            toast.error(`ERROR: Problem loading file. Make sure you have exported it from the ScratchLink Block Coder`)
        }
    }

    const onSaveSprite = () => {
        if(createSpriteName.length === 0) return toast.error(`ERROR: Sprite must have a name`);
        if(sprites.find(sprite => sprite.name === createSpriteName)) return toast.error(`ERROR: Sprite name already exists`);
        if(backdrops.find(backdrop => backdrop.name === createSpriteName)) return toast.error(`ERROR: Backdrop with that name already exists`);

        if(spriteSelectMethod === 'select') {
            if(!createSpriteAsset) return toast.error(`ERROR: Must select a sprite`);
        
            let spriteAsset = spriteAssets.find(spriteAsset => spriteAsset.name === createSpriteAsset.label);
            if(!spriteAsset) throw new Error();

            codingSetProjectFileAssetManagerSprites(codingProjectIndex, fileIndex, [
                ...sprites,
                {
                    name: createSpriteName,
                    scratchName: createSpriteAsset.label,
                    costumes: spriteAsset.costumes,
                    type: spriteAsset.type
                }
            ])
        } else if(spriteSelectMethod === 'import') {
            if(!spriteImportData) return toast.error(`ERROR: Must import a sprite first`);

            codingSetProjectFileAssetManagerSprites(codingProjectIndex, fileIndex, [
                ...sprites,
                {
                    ...spriteImportData,
                    name: createSpriteName,
                }
            ])
        }

        setCreateSpriteName('');
        setCreateSpriteAsset(null);
        setCreateSpriteModalOpen(false);
        setSpriteImportData(undefined);
        setSpriteImportButtonChild(spriteImportButtonChildDefault);
    }

    const onDeleteSprite = (index: number) => {
        const newSprites: ICodingSprite[] = JSON.parse(JSON.stringify(sprites));

        newSprites.splice(index, 1);

        codingSetProjectFileAssetManagerSprites(
            codingProjectIndex, 
            fileIndex,
            newSprites
        )
    }

    const openCreateBackdropModal = () => {
        setCreateBackdropModalOpen(true);
    }

    const closeCreateBackdropModal = () => {
        setCreateBackdropModalOpen(false);
    }

    const onBackdropNameChange = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
        setCreateBackdropName(changeEvent.target.value.split('').filter(char => /[a-zA-Z_]/.test(char)).join(''))
    }

    const onBackdropSelectChange = (val: AssetOption | null) => {
        setCreateBackdropAsset(val)
    }

    const onSaveBackdrop = () => {
        if(createBackdropName.length === 0) return toast.error(`ERROR: Backdrop must have a name`);
        if(backdrops.find(backdrop => backdrop.name === createBackdropName)) return toast.error(`ERROR: Backdrop name already exists`);
        if(sprites.find(sprite => sprite.name === createBackdropName)) return toast.error(`ERROR: Sprite with that name already exists`);
        if(!createBackdropAsset) return toast.error(`ERROR: Must select a backdrop before saving`);
        
        const backdropAsset = backdropAssets.find(backdropAsset => backdropAsset.name === createBackdropAsset.label) as ICodingBackdropAssetData;

        codingSetProjectFileAssetManagerBackdrops(codingProjectIndex, fileIndex,[
            ...backdrops,
            {
                ...backdropAsset,
                name: createBackdropName,
                scratchName: createBackdropAsset.label
            }
        ])

        setCreateBackdropName('');
        setCreateBackdropAsset(null);
        setCreateBackdropModalOpen(false);
    }

    const onDeleteBackdrop = (index: number) => {
        const newBackdrops: ICodingBackdrop[] = JSON.parse(JSON.stringify(backdrops));

        newBackdrops.splice(index, 1);

        codingSetProjectFileAssetManagerBackdrops(
            codingProjectIndex, 
            fileIndex,
            newBackdrops
        )
    }
    
    return (
        <>
            <Modal
                type='save'
                size='md'
                isOpen={createSpriteModalOpen}
                closeModal={closeCreateSpriteModal}
                onSave={onSaveSprite}
                title="Create Sprite"
                footerLeftComponent={
                    <div className='h-full flex flex-col'>
                        <a className='font-semibold text-sm text-black cursor-pointer hover:underline hover:text-black' href='https://scratchlink.au/resources/create-sprites' target="_blank">
                            How to create and export/import scratch sprites
                        </a>
                        <a className='mt-2 font-semibold text-sm text-black cursor-pointer hover:underline hover:text-black' href='https://scratchlink.au/resources/extra-sprites/' target="_blank">
                            Extra Sprite Resources
                        </a>
                    </div>
                }
            >
                <div className='flex mb-2'>
                    <div className='pr-2 border-r-2'>
                        <Input className="h-12" maxLength={20} type='text' value={createSpriteName} placeholder="Sprite Name" onChange={onSpriteNameChange}/>
                        <div className='w-full mt-2'>
                            <form className='flex'>
                                <span className='mr-1 cursor-pointer' onClick={e => onSpriteSelectMethodChange('select')}>
                                    <input className='mr-1 cursor-pointer' type="radio" checked={spriteSelectMethod === 'select'} readOnly />
                                    Select Sprite
                                </span>
                                <span className='mr-1 ml-2 cursor-pointer' onClick={e => onSpriteSelectMethodChange('import')}>
                                    <input className='mr-1 cursor-pointer' type="radio" checked={spriteSelectMethod === 'import'} readOnly />
                                    Import Sprite
                                </span>
                            </form>
                        </div>
                    </div>
                    <div className='ml-2 w-2/3'>
                        {
                            spriteSelectMethod === 'select'&& (
                                <>
                                    <Select 
                                        className='w-full'
                                        options={spriteTypeSelect}
                                        placeholder='Search...'
                                        components={{ Option: AssetOption, SingleValue: AssetValueContainer }}
                                        value={createSpriteAsset} 
                                        onChange={onSpriteSelectChange}
                                    />
                                </>
                            )
                        }
                        {
                            spriteSelectMethod === 'import' && (
                                <div className='w-full h-full flex justify-center items-center'>
                                    <input type="file" ref={spriteImportInputRef} accept=".sprite3" onChange={onSpriteImportChangeFile} hidden/>
                                    <Button type='success' addStyles={['square']} onClick={onSpriteImportClick}>{spriteImportButtonChild}</Button>
                                </div>
                            )
                        }
                    </div>
                </div>
            </Modal>
            <Modal
                type='save'
                size='md'
                isOpen={createBackdropModalOpen}
                closeModal={closeCreateBackdropModal}
                onSave={onSaveBackdrop}
                title="Create Backdrop"
            >
                <div className="flex justify-center items-center h-16">
                    <Input className="h-12" maxLength={20} type='text' value={createBackdropName} placeholder="Backdrop Name" onChange={onBackdropNameChange}/>
                    <Select 
                        className='ml-4 w-2/3'
                        options={backdropTypeSelect}
                        placeholder='Search...'
                        components={{ Option: AssetOption, SingleValue: AssetValueContainer }}
                        value={createBackdropAsset} 
                        onChange={onBackdropSelectChange}
                    />
                </div>
            </Modal>
            <div className="h-full w-full p-2 overflow-y-scroll">
                <table className="w-full text-center border-2 border-gray-800">
                    <thead className="bg-gray-400 text-white text-2xl  font-semibold">
                        <tr>
                            <th className="border-2 border-gray-800 w-1/3">
                                Sprite Name
                            </th>
                            <th className="border-2 border-gray-800 w-2/3">
                                Sprite
                            </th>
                            <th className="border-2 border-gray-800">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sprites.map((sprite, index) => (
                                <tr className="bg-white text-xl" key={index}>
                                    <td className="border-2 border-gray-400">
                                        {sprite.name}
                                    </td>
                                    <td className="border-2 border-gray-400">
                                        <SpriteCostumeCarousel type={sprite.type} costumes={sprite.costumes} />
                                    </td>
                                    <td className="border-2 border-gray-400">
                                        <Button type='danger' addStyles={['square']} onClick={() => onDeleteSprite(index)}>Delete</Button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                <div className="mt-2 w-full">
                    <Button className="float-right" type="success" addStyles={['square']} onClick={openCreateSpriteModal}>Add Sprite</Button>
                </div>
                <div className="py-16" />
                <table className="w-full text-center border-2 border-gray-800">
                    <thead className="bg-gray-400 text-white text-2xl  font-semibold">
                        <tr>
                            <th className="border-2 border-gray-800 w-1/3">
                                Backdrop Name
                            </th>
                            <th className="border-2 border-gray-800 w-2/3">
                                Backdrop
                            </th>
                            <th className="border-2 border-gray-800">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            backdrops.map((backdrop, index) => (
                                <tr className="bg-white text-xl" key={index}>
                                    <td className="border-2 border-gray-400">
                                        {backdrop.name}
                                    </td>
                                    <td className="border-2 border-gray-400">
                                        <div className="w-full flex justify-center">
                                            <img className="h-24" src={getScratchAssetUri(backdrop.asset, backdrop.type)} />
                                        </div>
                                    </td>
                                    <td className="border-2 border-gray-400">
                                        <Button type='danger' addStyles={['square']} onClick={() => onDeleteBackdrop(index)}>Delete</Button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                <div className="mt-2 w-full">
                    <Button className="float-right" type="success" addStyles={['square']} onClick={openCreateBackdropModal}>Add Backdrop</Button>
                </div>
            </div>
        </>
    )
};

const mapStateToProps = (state: IRootState, ownProps: OwnProps) => {
    return {
        codingFile: state.coding.codingProjects[ownProps.codingProjectIndex].files[ownProps.fileIndex]
    }
}

const mapDispatchToProps = {
    codingSetProjectFileAssetManagerSprites,
    codingSetProjectFileAssetManagerBackdrops
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CodingAssetManager);