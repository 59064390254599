import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import { IRootState } from 'store';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { DeviceConnectionManager } from 'lib/DeviceConnection';
import wait from 'utils/wait';

import Page from 'containers/Page/Page';
import Input from 'components/Input/Input';
import InputNumber from 'components/InputNumber/InputNumber';
import Button from 'components/Button/Button';
import ConfigCrane from './ConfigCrane';
import ConfigRFID from './ConfigRFID';
import ConfigInfoTab from './ConfigInfoTab';

import './ConfigPage.scss';

type Props = PropsFromRedux & {

}

const wifiModeOptions: ReactSelectStringOption[] = [
    {value: 'Off', label: 'Off'},
    {value: 'AP', label: 'AccessPoint'},
    {value: 'Station', label: 'Station'}
];

const wifiChannelOptions: ReactSelectStringOption[] = [
    {value: '', label: 'Auto'},
    {value: '1', label: '1'},
    {value: '2', label: '2'},
    {value: '3', label: '3'},
    {value: '4', label: '4'},
    {value: '5', label: '5'},
    {value: '6', label: '6'},
    {value: '7', label: '7'},
    {value: '8', label: '8'},
    {value: '9', label: '9'},
    {value: '10', label: '10'},
    {value: '11', label: '11'},
    {value: '12', label: '12'},
];

const colourCalibrateOptions: ReactSelectStringOption[] = [
    {value: 'white', label: 'White'},
    {value: 'black', label: 'Black'},
    {value: 'red', label: 'Red'},
    {value: 'green', label: 'Green'},
    {value: 'blue', label: 'Blue'},
    {value: 'silver', label: 'Silver'},
];

const htmlEnabledOptions: ReactSelectStringOption[] = [
    {value: 'true', label: 'Enabled'},
    {value: 'false', label: 'Disabled'},
];

const camPowerOptions: ReactSelectStringOption[] = [
    {value: 'Low', label: 'Low (25mW)'},
    {value: 'Medium', label: 'Medium (100mW)'},
    {value: 'High', label: 'High (250mW)'}
];

const ConfigPage: React.FC<Props> = ({ configPageSettingValues, deviceBatteryPercent, scratchlinkConfig, hardwareModeAutoDetected, deviceConnected, deviceConnectionMethod, push }) => {
    const [confirmWatchCommand, setConfirmWatchCommand] = useState('');
    const [confirmTimeout, setConfirmTimeout] = useState<undefined | ReturnType<typeof setTimeout>>();
    const onNewDataPacket = React.useCallback((e: any) => { //Data event litsener
        if(confirmTimeout === undefined || e === undefined || e.detail === undefined) return;
        const data = e.detail;

        if(data['evt'] && data['evt'] === 150 && data['i'] === 'Cmd OK' && data['d'].trim() === confirmWatchCommand) {
            toast.success(`Config Updated Successfully`);
            setConfirmWatchCommand('');
        }
    }, [confirmTimeout, confirmWatchCommand]);

    useEffect(() => {//Attach event litseners for data
        DeviceConnectionManager.addEventListener('data', onNewDataPacket);
        return () => {
            DeviceConnectionManager.removeEventListener('data', onNewDataPacket);
        }
    }, [onNewDataPacket]);

    const watchForConfirm = (command: string, maxTime: number = 400) => { //Watch for confirm packet of successful config
        setConfirmWatchCommand(command);
        setConfirmTimeout(setTimeout(() => {
            if(confirmTimeout) clearInterval(confirmTimeout);
            setConfirmTimeout(undefined);
            setConfirmWatchCommand('');
        }, maxTime));
    }

    const deviceProfileOptions: ReactSelectStringOption[] = scratchlinkConfig.deviceProfiles.map((deviceProfile: any) => ({
        value: deviceProfile.profile,
        label: deviceProfile.displayName
    }));

    const [deviceName, setDeviceName] = useState('');
    const [wifiSSID, setWifiSSID] = useState('');
    const [wifiMode, setWifiMode] = useState<ReactSelectStringOption | null>(null);
    const [wifiPass, setWifiPass] = useState('');
    const [wifiIP, setWifiIP] = useState('');
    const [wifiChannel, setWifiChannel] = useState<ReactSelectStringOption | null>(null);
    const [lineLHSWhite, setLineLHSWhite] = useState<number | undefined>(scratchlinkConfig.configPage.defaults.LHSLineWhite);
    const [lineRHSWhite, setLineRHSWhite] = useState<number | undefined>(scratchlinkConfig.configPage.defaults.RHSLineWhite);
    const [lineLHSBlack, setLineLHSBlack] = useState<number | undefined>(scratchlinkConfig.configPage.defaults.LHSLineBlack);
    const [lineRHSBlack, setLineRHSBlack] = useState<number | undefined>(scratchlinkConfig.configPage.defaults.RHSLineBlack);
    const [ultraLHSMin, setUltraLHSMin] = useState<number | undefined>(scratchlinkConfig.configPage.defaults.LHSUltraMin);
    const [ultraRHSMin, setUltraRHSMin] = useState<number | undefined>(scratchlinkConfig.configPage.defaults.RHSUltraMin);
    const [ultraLHSMax, setUltraLHSMax] = useState<number | undefined>(scratchlinkConfig.configPage.defaults.LHSUltraMax);
    const [ultraRHSMax, setUltraRHSMax] = useState<number | undefined>(scratchlinkConfig.configPage.defaults.RHSUltraMax);
    const [craneProximityLHSCutoff, setCraneProximityLHSCutoff] = useState<number | undefined>(scratchlinkConfig.configPage.defaults.CraneProximityLHSCutoff);
    const [craneProximityRHSCutoff, setCraneProximityRHSCutoff] = useState<number | undefined>(scratchlinkConfig.configPage.defaults.CraneProximityRHSCutoff);
    const [colourCalibrateColour, setColourCalibrateColour] = useState<ReactSelectStringOption | null>(null);
    const [colourCalibrateBand, setColourCalibrateBand] = useState<number | undefined>();
    const [wheelsCirc, setWheelsCirc] = useState<number | undefined>(scratchlinkConfig.configPage.defaults.WheelsCircumference);
    const [wheelsTrack, setWheelsTrack] = useState<number | undefined>(scratchlinkConfig.configPage.defaults.WheelsTrack);
    const [streamFreq, setStreamFreq] = useState<number | undefined>(scratchlinkConfig.configPage.defaults.StreamFrequency);
    const [deviceProfile, setDeviceProfile] = useState<ReactSelectStringOption | null>(null);
    const [htmlEnabled, setHTMLEnabled] = useState<ReactSelectStringOption | null>(null);
    const [camChannel, setCamChannel] = useState<number | undefined>();
    const [camPower, setCamPower] = useState<ReactSelectStringOption | null>(null);

    const wifiSSIDConfigValue = configPageSettingValues.wifiMode === 'Off' ? '' : configPageSettingValues.wifiSSID;
    const wifiIPConfigValue = configPageSettingValues.wifiMode === 'Off' ? '' : configPageSettingValues.wifiIP;
    const wifiPassConfigValue = configPageSettingValues.wifiMode === 'Off' ? '' : configPageSettingValues.wifiPassword;
    const wifiChannelConfigValue = configPageSettingValues.wifiMode !== 'AccessPoint' ? '' : configPageSettingValues.wifiChannel;
    const htmlEmabledConfigValue = configPageSettingValues.htmlEnabled === undefined ? '' : configPageSettingValues.htmlEnabled  ? 'Enabled' : 'Disabled';
    
    const deviceProfileDisplayName = deviceProfileOptions.find(deviceProfileOption => deviceProfileOption.value === hardwareModeAutoDetected)?.label;
    const deviceProfileConfigValue = deviceProfileDisplayName ? deviceProfileDisplayName : hardwareModeAutoDetected === 'none' ? '' : hardwareModeAutoDetected;

    const scratchlinkConfigDeviceProfile = scratchlinkConfig.deviceProfiles.find((deviceProfile: any) => deviceProfile.profile === hardwareModeAutoDetected);
    const deviceHasRFID: boolean = scratchlinkConfigDeviceProfile ? scratchlinkConfigDeviceProfile.rfid : true;
    const deviceHasSteppers: boolean = scratchlinkConfigDeviceProfile ? scratchlinkConfigDeviceProfile.stepperEbot : true;
    const deviceHasLHSBW: boolean = scratchlinkConfigDeviceProfile ? scratchlinkConfigDeviceProfile.sensors.includes('LHS Line') || scratchlinkConfigDeviceProfile.sensors.includes('Line') : true;
    const deviceHasRHSBW: boolean = scratchlinkConfigDeviceProfile ? scratchlinkConfigDeviceProfile.sensors.includes('RHS Line') : true;
    const deviceHasLHSUltra: boolean = scratchlinkConfigDeviceProfile ? scratchlinkConfigDeviceProfile.sensors.includes('LHS Ultra (cm)') || scratchlinkConfigDeviceProfile.sensors.includes('Ultra (cm)') : true;
    const deviceHasRHSUltra: boolean = scratchlinkConfigDeviceProfile ? scratchlinkConfigDeviceProfile.sensors.includes('RHS Ultra (cm)') : true;
    //const deviceHasLHSRHSCraneProximity: boolean = scratchlinkConfigDeviceProfile ? scratchlinkConfigDeviceProfile.sensors.includes('Proximity LHS') : true;
    const deviceHasColor: boolean = scratchlinkConfigDeviceProfile ? scratchlinkConfigDeviceProfile.sensors.find((sensor: any) => sensor.indexOf('Colour') !== -1) : true;

    const lhsLineWhiteCurrentValue = configPageSettingValues.lineLHSWhite === undefined ? '' : 100-configPageSettingValues.lineLHSWhite;
    const rhsLineWhiteCurrentValue = configPageSettingValues.lineRHSWhite === undefined ? '' : 100-configPageSettingValues.lineRHSWhite;
    const lhsLineBlackCurrentValue = configPageSettingValues.lineLHSBlack === undefined ? '' : 100-configPageSettingValues.lineLHSBlack;
    const rhsLineBlackCurrentValue = configPageSettingValues.lineRHSBlack === undefined ? '' : 100-configPageSettingValues.lineRHSBlack;

    const onInputNumberChange = (inputNumber: number | undefined, setter: React.Dispatch<React.SetStateAction<number | undefined>>) => {
        setter(inputNumber);
    }

    const onInputChange = (changeEvent: React.ChangeEvent<HTMLInputElement>, setter: React.Dispatch<React.SetStateAction<string>>, filterChars?: RegExp) => {
        let value = changeEvent.target.value;
        if(filterChars) value = value.split('').filter(char => filterChars.test(char)).join('');
        setter(value);
    }

    const onSelectChange = (option: ReactSelectStringOption | null, setter: React.Dispatch<React.SetStateAction<ReactSelectStringOption | null>>) => {
        setter(option);
    }

    const handleDeviceNeedsSerial = () => {
        toast.error(`eBot must be connected through USB Serial to change this setting`);
    }

    const sendCameraCommands = ({ssid, pass, channel}: {ssid?: string, pass?: string, channel?: string}) => {
        DeviceConnectionManager.sendCommand(`serial2 output camera config [${ssid || configPageSettingValues.wifiSSID},${pass || configPageSettingValues.wifiPassword},${channel || configPageSettingValues.wifiChannel}];`);
    }
    
    const onSetDeviceName = () => {
        if(deviceConnectionMethod !== 'serial' && deviceConnectionMethod !== 'modem') return handleDeviceNeedsSerial();
        const hasDash = deviceName.indexOf('-') !== -1;
        const wifiName = deviceName.substring(0, hasDash ? deviceName.indexOf('-') : deviceName.length); //Set wifi name to ebot name before dash

        const command = `config name '${deviceName}';`;
        watchForConfirm(command);
        DeviceConnectionManager.sendCommand(`config confirm on; ${command}; config confirm off; config info;`);

        //Update wifi name
        DeviceConnectionManager.sendCommand(`wifi config ${configPageSettingValues.wifiMode === 'AccessPoint' ? 'AP' : 'station'} '${wifiName}' '${configPageSettingValues.wifiPassword}';`);
        
        setDeviceName('');
    }

    const onSetWifiSSID = () => {
        if(deviceConnectionMethod !== 'serial' && deviceConnectionMethod !== 'modem') return handleDeviceNeedsSerial();
        const command = `wifi config ${configPageSettingValues.wifiMode === 'AccessPoint' ? 'AP' : 'station'} '${wifiSSID}' '${configPageSettingValues.wifiPassword}'`;
        watchForConfirm(command);
        DeviceConnectionManager.sendCommand(`${command};`);
        setWifiSSID('');
        sendCameraCommands({ssid: wifiSSID});
    }

    const onSetWifiMode = () => {
        if(deviceConnectionMethod !== 'serial' && deviceConnectionMethod !== 'modem') return handleDeviceNeedsSerial();
        if(!wifiMode) return;
        let command = '';
        
        if(wifiMode.value === 'Off') command = 'wifi enable off';
        else command = `wifi enable ${wifiMode.value} on`;

        watchForConfirm(command);
        DeviceConnectionManager.sendCommand(`${command};`);
        setTimeout(() => {
            DeviceConnectionManager.sendCommand(`wifi config info;`);
        }, 2000)

        toast.warn(`Changing Wifi Mode. Allow up to 10sec to take effect.`)
        setWifiMode(null);
        sendCameraCommands({});
    }

    const onSetWifiPass = () => {
        if(deviceConnectionMethod !== 'serial' && deviceConnectionMethod !== 'modem') return handleDeviceNeedsSerial();
        const command = `wifi config ${configPageSettingValues.wifiMode === 'AccessPoint' ? 'AP' : 'station'} '${configPageSettingValues.wifiSSID}' '${wifiPass}' '${configPageSettingValues.wifiIP}'`;
        watchForConfirm(command);
        DeviceConnectionManager.sendCommand(`${command};`);
        setWifiPass('');
        sendCameraCommands({pass: wifiPass});
    }

    const onSetWifiIP = () => {
        if(deviceConnectionMethod !== 'serial' && deviceConnectionMethod !== 'modem') return handleDeviceNeedsSerial();
        const command = `wifi config ${configPageSettingValues.wifiMode === 'AccessPoint' ? 'AP' : 'station'} '${configPageSettingValues.wifiSSID}' '${configPageSettingValues.wifiPassword}' '${wifiIP}'`;
        watchForConfirm(command);
        DeviceConnectionManager.sendCommand(`${command};`);
        setWifiIP('');
        sendCameraCommands({});
    }

    const onSetWifiChannel = async () => {
        if(deviceConnectionMethod !== 'serial' && deviceConnectionMethod !== 'modem') return handleDeviceNeedsSerial();
        if(!wifiChannel) return;
        const command = `wifi config AP '${configPageSettingValues.wifiSSID}' '${configPageSettingValues.wifiPassword}' '${configPageSettingValues.wifiIP}' ${wifiChannel.value}`;
        watchForConfirm(command);
        DeviceConnectionManager.sendCommand(`${command};`);
        setWifiChannel(null);
        sendCameraCommands({channel: wifiChannel.value});
    }

    const onSetLineLHSWhite = () => {
        if(!lineLHSWhite) return;
        if(!configPageSettingValues.lineRHSWhite) return;
        const command = `bw set white ${100-lineLHSWhite} ${100-configPageSettingValues.lineRHSWhite}`;
        watchForConfirm(command);
        DeviceConnectionManager.sendCommand(`config confirm on; ${command}; config confirm off; config info;`);
        setLineLHSWhite(undefined);
    }

    const onSetLineRHSWhite = () => {
        if(!lineRHSWhite) return;
        if(!configPageSettingValues.lineLHSWhite) return;
        const command = `bw set white ${100-configPageSettingValues.lineLHSWhite} ${100-lineRHSWhite}`;
        watchForConfirm(command);
        DeviceConnectionManager.sendCommand(`config confirm on; ${command}; config confirm off; config info;`);
        setLineRHSWhite(undefined);
    }

    const onSetLineLHSBlack = () => {
        if(!lineLHSBlack) return;
        if(!configPageSettingValues.lineRHSBlack) return;
        const command = `bw set black ${100-lineLHSBlack} ${100-configPageSettingValues.lineRHSBlack}`;
        watchForConfirm(command);
        DeviceConnectionManager.sendCommand(`config confirm on; ${command}; config confirm off; config info;`);
        setLineLHSWhite(undefined);
    }

    const onSetLineRHSBlack = () => {
        if(!lineRHSBlack) return;
        if(!configPageSettingValues.lineLHSBlack) return;
        const command = `bw set black ${100-configPageSettingValues.lineLHSBlack} ${100-lineRHSBlack}`;
        watchForConfirm(command);
        DeviceConnectionManager.sendCommand(`config confirm on; ${command}; config confirm off; config info;`);
        setLineRHSWhite(undefined);
    }
    
    const onSetUltraLHSMin = () => {
        if(deviceConnectionMethod !== 'serial' && deviceConnectionMethod !== 'modem') return handleDeviceNeedsSerial();
        if(!ultraLHSMin) return;
        if(configPageSettingValues.ultraLHSMin === undefined) return;
        const command = `ultra0 config min ${ultraLHSMin*10}`;
        watchForConfirm(command);
        DeviceConnectionManager.sendCommand(`config confirm on; ${command}; config confirm off; config info;`);
        setUltraLHSMin(undefined);
    }

    const onSetUltraRHSMin = () => {
        if(deviceConnectionMethod !== 'serial' && deviceConnectionMethod !== 'modem') return handleDeviceNeedsSerial();
        if(!ultraRHSMin) return;
        if(configPageSettingValues.ultraRHSMin === undefined) return;
        const command = `ultra1 config min ${ultraRHSMin*10}`;
        watchForConfirm(command);
        DeviceConnectionManager.sendCommand(`config confirm on; ${command}; config confirm off; config info;`);
        setUltraRHSMin(undefined);
    }

    const onSetUltraLHSMax = () => {
        if(deviceConnectionMethod !== 'serial' && deviceConnectionMethod !== 'modem') return handleDeviceNeedsSerial();
        if(!ultraLHSMax) return;
        if(configPageSettingValues.ultraLHSMax === undefined) return;
        const command = `ultra0 config max ${ultraLHSMax*10}`;
        watchForConfirm(command);
        DeviceConnectionManager.sendCommand(`config confirm on; ${command}; config confirm off; config info;`);
        setUltraLHSMax(undefined);
    }

    const onSetUltraRHSMax = () => {
        if(deviceConnectionMethod !== 'serial' && deviceConnectionMethod !== 'modem') return handleDeviceNeedsSerial();
        if(!ultraRHSMax) return;
        if(configPageSettingValues.ultraRHSMax === undefined) return;
        const command = `ultra1 config max ${ultraRHSMax*10}`;
        watchForConfirm(command);
        DeviceConnectionManager.sendCommand(`config confirm on; ${command}; config confirm off; config info;`);
        setUltraRHSMax(undefined);
    }

    const onSetCraneProximityLHSCutoff = () => {
        if(deviceConnectionMethod !== 'serial' && deviceConnectionMethod !== 'modem') return handleDeviceNeedsSerial();
        if(!craneProximityLHSCutoff) return;
        if(configPageSettingValues.craneProximityLHSCutoff === undefined) return;
        const command = `proximity0 config set ${craneProximityLHSCutoff}`;
        watchForConfirm(command);
        DeviceConnectionManager.sendCommand(`config confirm on; ${command}; config confirm off; config info;`);
        setCraneProximityLHSCutoff(undefined);
    }

    const onSetCraneProximityRHSCutoff = () => {
        if(deviceConnectionMethod !== 'serial' && deviceConnectionMethod !== 'modem') return handleDeviceNeedsSerial();
        if(!craneProximityRHSCutoff) return;
        if(configPageSettingValues.craneProximityRHSCutoff === undefined) return;
        const command = `proximity1 config set ${craneProximityRHSCutoff}`;
        watchForConfirm(command);
        DeviceConnectionManager.sendCommand(`config confirm on; ${command}; config confirm off; config info;`);
        setCraneProximityRHSCutoff(undefined);
    }

    const onSetColourCalibrate = () => {
        if(deviceConnectionMethod !== 'serial' && deviceConnectionMethod !== 'modem') return handleDeviceNeedsSerial();
        if(!colourCalibrateColour) return;
        if(deviceBatteryPercent === 0) return toast.error(`Error battery power off. Turn on eBot and reconnect or check batteries.`) 
        const command = `color config set ${colourCalibrateColour.value} ${colourCalibrateBand}`;
        watchForConfirm(command);
        DeviceConnectionManager.sendCommand(`config confirm on; ${command}; config confirm off; config info;`);
        setColourCalibrateColour(null);
    }

    const onSetWheelCircum = () => {
        if(deviceConnectionMethod !== 'serial' && deviceConnectionMethod !== 'modem') return handleDeviceNeedsSerial();
        if(!wheelsCirc) return;
        const command = `wheels config set ${wheelsCirc} ${configPageSettingValues.wheelsTrack}`;
        watchForConfirm(command);
        DeviceConnectionManager.sendCommand(`config confirm on; ${command}; config confirm off; config info;`);
        setWheelsCirc(undefined);
    }

    const onSetWheelTrack = () => {
        if(deviceConnectionMethod !== 'serial' && deviceConnectionMethod !== 'modem') return handleDeviceNeedsSerial();
        if(!wheelsTrack) return;
        const command = `wheels config set ${configPageSettingValues.wheelsCircum} ${wheelsTrack}`;
        watchForConfirm(command);
        DeviceConnectionManager.sendCommand(`config confirm on; ${command}; config confirm off; config info;`);
        setWheelsTrack(undefined);
    }

    const onSetStreamFreq = () => {
        if(deviceConnectionMethod !== 'serial' && deviceConnectionMethod !== 'modem') return handleDeviceNeedsSerial();
        if(!streamFreq) return;
        const command = `config stream ${streamFreq} all`;
        watchForConfirm(command);
        DeviceConnectionManager.sendCommand(`config confirm on; ${command}; config confirm off; config info;`);
        setStreamFreq(undefined);
    }

    const onSetDeviceProfile = async () => {
        if(deviceConnectionMethod !== 'serial' && deviceConnectionMethod !== 'modem') return handleDeviceNeedsSerial();
        if(!deviceProfile) return;

        toast.warn(`Setting eBot profile to ${deviceProfile.label}. Please Wait.`);

        const deviceProfileConfig = scratchlinkConfig.deviceProfiles.find((device: any) => device.profile === deviceProfile.value);
        for(let i=0; i<deviceProfileConfig.configCommands.length; i++) {
            DeviceConnectionManager.sendCommand(`${deviceProfileConfig.configCommands[i]}`);
            await wait(300);
        }

        toast.success(`Set eBot profile to ${deviceProfile.label}`);
        if(scratchlinkConfig.configPage.rebootDeviceAfterSetProfile) {         
            DeviceConnectionManager.sendCommand(`reboot;`);
            toast.warn('Please power cycle eBot and refresh browser now');
        };
        setDeviceProfile(null);
    }

    const onSetHTMLEnabled = () => {
        if(deviceConnectionMethod !== 'serial' && deviceConnectionMethod !== 'modem') return handleDeviceNeedsSerial();
        if(!htmlEnabled) return;
        const command = `wifi config html ${htmlEnabled.value === 'true' ? 'on' : 'off'}`;
        watchForConfirm(command);
        DeviceConnectionManager.sendCommand(`config confirm on; ${command}; config confirm off; wifi config info;`);
        setHTMLEnabled(null);
    }

    const onSetCamChannel = () => {
        if(deviceConnectionMethod !== 'serial' && deviceConnectionMethod !== 'modem') return handleDeviceNeedsSerial();
        if(!camChannel) return;
        const command = ``;
        watchForConfirm(command);
        DeviceConnectionManager.sendCommand(`config confirm on; ${command}; config confirm off;`);
        setCamChannel(undefined);
    }

    const onSetCamPower = () => {
        if(deviceConnectionMethod !== 'serial' && deviceConnectionMethod !== 'modem') return handleDeviceNeedsSerial();
        if(!camPower) return;
        const command = ``;
        watchForConfirm(command);
        DeviceConnectionManager.sendCommand(`config confirm on; ${command}; config confirm off;`);
        setCamPower(null);
    }

    const onUltraMaxValueValidStateChange = (valid: boolean, inputValue: number) => {
        if(!valid && inputValue < 1000) toast.warn(`Ultra max value must be at least 10 greater than the min`);
    }

    const onUltraMinValueValidStateChange = (valid: boolean, inputValue: number) => {
        if(!valid && inputValue > 30) toast.warn(`Ultra min value must be at least 10 less than the max`);
    }

    const onWhiteValueValidStateChange = (valid: boolean, inputValue: number) => {
        if(!valid && inputValue > 1) toast.warn(`BW white value must be at least 5 more than the black`);
    }

    const onBlackValueValidStateChange = (valid: boolean, inputValue: number) => {
        if(!valid && inputValue > 1) toast.warn(`BW black value must be at least 5 less than the white`);
    }

    return (
        <Page
            title="Coding Editor | ScratchLink"
            background="#E6F0FF"
        >
            <div className="p-configPage">
                <Tabs className="p-configPage__tabs">
                    <TabList>
                        <Tab>General</Tab>
                        <Tab>Wifi</Tab>
                        <Tab>RFID</Tab>
                        <Tab>Crane</Tab>
                        <Tab>Info</Tab>
                        <Button className="p-configPage__modemBinding" size='sm' type="info" addStyles={['square']} onClick={() => push('/modemBinding')}>Modem Binding</Button>
                    </TabList>
                    <TabPanel>
                        <div className="p-configPage__tableContainer">
                            <table className="p-configPage__table">
                                <tbody>
                                    <tr className="p-configPage__tableHeader">
                                        <th>Setting Name</th>
                                        <th>Current Value</th>
                                        <th>New Value</th>
                                        <th>Set</th>
                                    </tr>
                                    <tr className="p-configPage__tableRow">
                                        <td className="p-configPage__tableSettingName">eBot Name</td>
                                        <td className="p-configPage__tableSettingValue">{configPageSettingValues.deviceName}</td>
                                        <td><Input maxLength={30} value={deviceName} onChange={e => onInputChange(e, setDeviceName, /[a-zA-Z1-9\- ]/)}/></td>
                                        <td><Button type="success" addStyles={['square']} disabled={!deviceConnected} onClick={onSetDeviceName}>Set New Value</Button></td>
                                    </tr>
                                    { 
                                        deviceHasLHSBW && 
                                            <>
                                                <tr className="p-configPage__tableRow">
                                                    <td className="p-configPage__tableSettingName">LHS Line White</td>
                                                    <td className="p-configPage__tableSettingValue">{lhsLineWhiteCurrentValue}</td>
                                                    <td><InputNumber min={Number(lineLHSBlack) + 5} max={100} decimals={0} value={lineLHSWhite} onInputNumberChange={val => onInputNumberChange(val, setLineLHSWhite)} onValidStateChange={onWhiteValueValidStateChange}/></td>
                                                    <td><Button type="success" addStyles={['square']} disabled={!deviceConnected} onClick={onSetLineLHSWhite}>Set New Value</Button></td>
                                                </tr>
                                                <tr className="p-configPage__tableRow">
                                                    <td className="p-configPage__tableSettingName">LHS Line Black</td>
                                                    <td className="p-configPage__tableSettingValue">{lhsLineBlackCurrentValue}</td>
                                                    <td><InputNumber min={1} max={Number(lineLHSWhite) - 5} decimals={0} value={lineLHSBlack} onInputNumberChange={val => onInputNumberChange(val, setLineLHSBlack)} onValidStateChange={onBlackValueValidStateChange}/></td>
                                                    <td><Button type="success" addStyles={['square']} disabled={!deviceConnected} onClick={onSetLineLHSBlack}>Set New Value</Button></td>
                                                </tr>
                                            </>
                                    }   
                                    { 
                                        deviceHasRHSBW && 
                                            <>
                                                <tr className="p-configPage__tableRow">
                                                    <td className="p-configPage__tableSettingName">RHS Line White</td>
                                                    <td className="p-configPage__tableSettingValue">{rhsLineWhiteCurrentValue}</td>
                                                    <td><InputNumber min={Number(lineRHSBlack) + 5} max={100} decimals={0} value={lineRHSWhite} onInputNumberChange={val => onInputNumberChange(val, setLineRHSWhite)} onValidStateChange={onWhiteValueValidStateChange}/></td>
                                                    <td><Button type="success" addStyles={['square']} disabled={!deviceConnected} onClick={onSetLineRHSWhite}>Set New Value</Button></td>
                                                </tr>
                                                <tr className="p-configPage__tableRow">
                                                    <td className="p-configPage__tableSettingName">RHS Line Black</td>
                                                    <td className="p-configPage__tableSettingValue">{rhsLineBlackCurrentValue}</td>
                                                    <td><InputNumber min={1} max={Number(lineRHSWhite) - 5} decimals={0} value={lineRHSBlack} onInputNumberChange={val => onInputNumberChange(val, setLineRHSBlack)} onValidStateChange={onBlackValueValidStateChange}/></td>
                                                    <td><Button type="success" addStyles={['square']} disabled={!deviceConnected} onClick={onSetLineRHSBlack}>Set New Value</Button></td>
                                                </tr>
                                            </>
                                    }  
                                    {
                                        deviceHasLHSUltra && (
                                            <>
                                                <tr className="p-configPage__tableRow">
                                                    <td className="p-configPage__tableSettingName">LHS Ultra Min (cm)</td>
                                                    <td className="p-configPage__tableSettingValue">{configPageSettingValues.ultraLHSMin}</td>
                                                    <td><InputNumber min={3} max={Number(ultraLHSMax) - 10} decimals={0} value={ultraLHSMin} onInputNumberChange={val => onInputNumberChange(val, setUltraLHSMin)} onValidStateChange={onUltraMinValueValidStateChange} /></td>
                                                    <td><Button type="success" addStyles={['square']} disabled={!deviceConnected} onClick={onSetUltraLHSMin}>Set New Value</Button></td>
                                                </tr>
                                                <tr className="p-configPage__tableRow">
                                                    <td className="p-configPage__tableSettingName">LHS Ultra Max (cm)</td>
                                                    <td className="p-configPage__tableSettingValue">{configPageSettingValues.ultraLHSMax}</td>
                                                    <td><InputNumber min={Number(ultraLHSMin) + 10} max={200} decimals={0} value={ultraLHSMax} onInputNumberChange={val => onInputNumberChange(val, setUltraLHSMax)} onValidStateChange={onUltraMaxValueValidStateChange} /></td>
                                                    <td><Button type="success" addStyles={['square']} disabled={!deviceConnected} onClick={onSetUltraLHSMax}>Set New Value</Button></td>
                                                </tr>
                                            </>
                                        )
                                    }
                                    {
                                        deviceHasRHSUltra && (
                                            <>
                                                
                                                <tr className="p-configPage__tableRow">
                                                    <td className="p-configPage__tableSettingName">RHS Ultra Min (cm)</td>
                                                    <td className="p-configPage__tableSettingValue">{configPageSettingValues.ultraRHSMin}</td>
                                                    <td><InputNumber min={3} max={Number(ultraRHSMax) - 10} decimals={0} value={ultraRHSMin} onInputNumberChange={val => onInputNumberChange(val, setUltraRHSMin)} onValidStateChange={onUltraMinValueValidStateChange} /></td>
                                                    <td><Button type="success" addStyles={['square']} disabled={!deviceConnected} onClick={onSetUltraRHSMin}>Set New Value</Button></td>
                                                </tr>
                                                <tr className="p-configPage__tableRow">
                                                    <td className="p-configPage__tableSettingName">RHS Ultra Max (cm)</td>
                                                    <td className="p-configPage__tableSettingValue">{configPageSettingValues.ultraRHSMax}</td>
                                                    <td><InputNumber min={Number(ultraRHSMin) + 10} max={200} decimals={0} value={ultraRHSMax} onInputNumberChange={val => onInputNumberChange(val, setUltraRHSMax)} onValidStateChange={onUltraMaxValueValidStateChange} /></td>
                                                    <td><Button type="success" addStyles={['square']} disabled={!deviceConnected} onClick={onSetUltraRHSMax}>Set New Value</Button></td>
                                                </tr>
                                            </>
                                        )
                                    }
                                    {/* {
                                        deviceHasLHSRHSCraneProximity && (
                                            <>
                                                <tr className="p-configPage__tableRow">
                                                    <td className="p-configPage__tableSettingName">Proximity LHS Cutoff</td>
                                                    <td className="p-configPage__tableSettingValue">{configPageSettingValues.craneProximityLHSCutoff}</td>
                                                    <td><InputNumber min={20} max={80} decimals={0} value={craneProximityLHSCutoff} onInputNumberChange={val => onInputNumberChange(val, setCraneProximityLHSCutoff)} /></td>
                                                    <td><Button type="success" addStyles={['square']} disabled={!deviceConnected} onClick={onSetCraneProximityLHSCutoff}>Set New Value</Button></td>
                                                </tr>
                                                <tr className="p-configPage__tableRow">
                                                    <td className="p-configPage__tableSettingName">Proximity RHS Cutoff</td>
                                                    <td className="p-configPage__tableSettingValue">{configPageSettingValues.craneProximityRHSCutoff}</td>
                                                    <td><InputNumber min={20} max={80} decimals={0} value={craneProximityRHSCutoff} onInputNumberChange={val => onInputNumberChange(val, setCraneProximityRHSCutoff)} /></td>
                                                    <td><Button type="success" addStyles={['square']} disabled={!deviceConnected} onClick={onSetCraneProximityRHSCutoff}>Set New Value</Button></td>
                                                </tr>
                                            </>
                                        )
                                    } */}
                                    {
                                        deviceHasSteppers && (
                                            <>
                                                <tr className="p-configPage__tableRow">
                                                    <td className="p-configPage__tableSettingName">Wheels Circumference</td>
                                                    <td className="p-configPage__tableSettingValue">{configPageSettingValues.wheelsCircum}</td>
                                                    <td><InputNumber min={0} max={200} decimals={0} value={wheelsCirc} onInputNumberChange={val => onInputNumberChange(val, setWheelsCirc)}/></td>
                                                    <td><Button type="success" addStyles={['square']} disabled={!deviceConnected} onClick={onSetWheelCircum}>Set New Value</Button></td>
                                                </tr>
                                                <tr className="p-configPage__tableRow">
                                                    <td className="p-configPage__tableSettingName">Wheels Track</td>
                                                    <td className="p-configPage__tableSettingValue">{configPageSettingValues.wheelsTrack}</td>
                                                    <td><InputNumber min={0} max={200} decimals={0} value={wheelsTrack} onInputNumberChange={val => onInputNumberChange(val, setWheelsTrack)}/></td>
                                                    <td><Button type="success" addStyles={['square']} disabled={!deviceConnected} onClick={onSetWheelTrack}>Set New Value</Button></td>
                                                </tr>
                                            </>
                                        )
                                    }
                                    <tr className="p-configPage__tableRow">
                                        <td className="p-configPage__tableSettingName">Stream Frequency</td>
                                        <td className="p-configPage__tableSettingValue p-configPage__tableSettingValue--black"></td>
                                        <td><InputNumber min={0} max={1000} decimals={0} value={streamFreq} onInputNumberChange={val => onInputNumberChange(val, setStreamFreq)}/></td>
                                        <td><Button type="success" addStyles={['square']} disabled={!deviceConnected} onClick={onSetStreamFreq}>Set New Value</Button></td>
                                    </tr>
                                    {
                                        deviceHasColor && (
                                            <>
                                                <tr className="p-configPage__tableRow">
                                                    <td className="p-configPage__tableSettingName">Colour Calibrate</td>
                                                    <td className="p-configPage__tableSettingValue p-configPage__tableSettingValue--black"></td>
                                                    <td className='flex flex-row'>
                                                        <Select className='w-1/2 mr-1' classNamePrefix="p-configPage__select" options={colourCalibrateOptions} value={colourCalibrateColour} onChange={val => onSelectChange(val, setColourCalibrateColour)}/>
                                                        <InputNumber placeholder='Band Value' min={1} max={99} decimals={0} value={colourCalibrateBand} onInputNumberChange={val => onInputNumberChange(val, setColourCalibrateBand)}/>
                                                    </td>
                                                    <td><Button type="success" addStyles={['square']} disabled={!deviceConnected} onClick={onSetColourCalibrate}>Set New Value</Button></td>
                                                </tr>
                                            </>
                                        )
                                    }
                                    <tr className="p-configPage__tableRow">
                                        <td className="p-configPage__tableSettingName">eBot Profile</td>
                                        <td className="p-configPage__tableSettingValue">{deviceProfileConfigValue}</td>
                                        <td><Select classNamePrefix="p-configPage__select" options={deviceProfileOptions} onChange={val => onSelectChange(val, setDeviceProfile)}/></td>
                                        <td><Button type="success" addStyles={['square']} disabled={!deviceConnected} onClick={onSetDeviceProfile}>Set New Value</Button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="p-configPage__tableContainer">
                            <table className="p-configPage__table">
                                <tbody>
                                    <tr className="p-configPage__tableHeader">
                                        <th>Setting Name</th>
                                        <th>Current Value</th>
                                        <th>New Value</th>
                                        <th>Set</th>
                                    </tr>
                                    {
                                        deviceConnectionMethod !== 'modem' && (
                                            <>
                                                <tr className="p-configPage__tableRow">
                                                    <td className="p-configPage__tableSettingName">Wifi Mode</td>
                                                    <td className="p-configPage__tableSettingValue">{configPageSettingValues.wifiMode}</td>
                                                    <td><Select classNamePrefix="p-configPage__select" options={wifiModeOptions} value={wifiMode} onChange={val => onSelectChange(val, setWifiMode)}/></td>
                                                    <td><Button type="success" addStyles={['square']} disabled={!deviceConnected} onClick={onSetWifiMode}>Set New Value</Button></td>
                                                </tr>
                                                <tr className="p-configPage__tableRow">
                                                    <td className="p-configPage__tableSettingName">Wifi SSID</td>
                                                    <td className={`p-configPage__tableSettingValue ${configPageSettingValues.wifiMode === 'Off' ? 'p-configPage__tableSettingValue--black' : ''}`}>{wifiSSIDConfigValue}</td>
                                                    <td><Input value={wifiSSID} onChange={e => onInputChange(e, setWifiSSID, /[a-zA-Z0-9\- ]/)}/></td>
                                                    <td><Button type="success" addStyles={['square']} disabled={!deviceConnected} onClick={onSetWifiSSID}>Set New Value</Button></td>
                                                </tr>
                                                <tr className="p-configPage__tableRow">
                                                    <td className="p-configPage__tableSettingName">Wifi Password</td>
                                                    <td className={`p-configPage__tableSettingValue ${configPageSettingValues.wifiMode === 'Off' ? 'p-configPage__tableSettingValue--black' : ''}`}>{wifiPassConfigValue}</td>
                                                    <td><Input value={wifiPass} onChange={e => onInputChange(e, setWifiPass, /[a-zA-Z0-9\-]/)}/></td>
                                                    <td><Button type="success" addStyles={['square']} disabled={!deviceConnected} onClick={onSetWifiPass}>Set New Value</Button></td>
                                                </tr>
                                                <tr className="p-configPage__tableRow">
                                                    <td className="p-configPage__tableSettingName">Wifi IP</td>
                                                    <td className={`p-configPage__tableSettingValue ${configPageSettingValues.wifiMode === 'Off' ? 'p-configPage__tableSettingValue--black' : ''}`}>{wifiIPConfigValue}</td>
                                                    <td><Input value={wifiIP} onChange={e => onInputChange(e, setWifiIP)}/></td>
                                                    <td><Button type="success" addStyles={['square']} onClick={onSetWifiIP} disabled={configPageSettingValues.wifiMode !== 'AccessPoint' || !deviceConnected}>Set New Value</Button></td>
                                                </tr>
                                                <tr className="p-configPage__tableRow">
                                                    <td className="p-configPage__tableSettingName">Wifi Channel (AP Only)</td>
                                                    <td className={`p-configPage__tableSettingValue ${configPageSettingValues.wifiMode !== 'AccessPoint' ? 'p-configPage__tableSettingValue--black' : ''}`}>{wifiChannelConfigValue}</td>
                                                    <td><Select classNamePrefix="p-configPage__select" options={wifiChannelOptions} value={wifiChannel} onChange={val => onSelectChange(val, setWifiChannel)}/></td>
                                                    <td><Button type="success" addStyles={['square']} onClick={onSetWifiChannel} disabled={configPageSettingValues.wifiMode !== 'AccessPoint' || !deviceConnected}>Set New Value</Button></td>
                                                </tr>
                                                <tr className="p-configPage__tableRow">
                                                    <td className="p-configPage__tableSettingName">HTML Enabled</td>
                                                    <td className="p-configPage__tableSettingValue">{htmlEmabledConfigValue}</td>
                                                    <td><Select classNamePrefix="p-configPage__select" options={htmlEnabledOptions} value={htmlEnabled} onChange={val => onSelectChange(val, setHTMLEnabled)}/></td>
                                                    <td><Button type="success" addStyles={['square']} onClick={onSetHTMLEnabled} disabled={!deviceConnected}>Set New Value</Button></td>
                                                </tr>
                                            </>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        {
                            deviceHasRFID && <ConfigRFID />
                        }
                    </TabPanel>
                    <TabPanel>
                        <ConfigCrane />
                    </TabPanel>
                    <TabPanel>
                        <ConfigInfoTab />
                    </TabPanel>
                </Tabs>
            </div>
        </Page>
    )
}

const mapStateToProps = (state: IRootState) => {
    return {
        deviceBatteryPercent: state.project.deviceBatteryPercent,
        configPageSettingValues: state.project.configPageSettingValues,
        scratchlinkConfig: state.project.scratchlinkConfig,
        hardwareModeAutoDetected: state.project.hardwareModeAutoDetected,
        deviceConnected: state.project.deviceConnected,
        deviceConnectionMethod: state.project.deviceConnectionMethod
    }
}

const mapDispatchToProps = {
    push
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ConfigPage);