import React, { useState, useEffect } from 'react';
import Input from 'components/Input/Input';

import './InputNumber.scss';

interface InputNumberProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    className?: string;
    value: number | undefined;
    onInputNumberChange: (inputNumber: number | undefined) => void;
    onValidStateChange?: (inputValid: boolean, inputValue: number) => void;
    min?: number;
    max?: number;
    decimals?: number;
    step?: number;
}

const InputNumber: React.FC<InputNumberProps> = ({className, min, max, decimals, step = 1, value, onInputNumberChange, onValidStateChange, ...props}) => {
    const [inputValue, setInputValue] = useState('');
    const [inputValid, setInputValid] = useState(true);

    let classes = "c-inputNumber ";
    if(className) classes += className;
    if(!inputValid) classes += " c-inputNumber__invalid ";

    useEffect(() => {
        if(value) setInputValue(value.toString());
    }, [value])

    useEffect(() => {
        if(onValidStateChange) onValidStateChange(inputValid, Number(inputValue));
    }, [ inputValid, inputValue, onValidStateChange ])

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const [valid] = validator(e.target.value);
        setInputValid(valid);
        setInputValue(e.target.value);
        onInputNumberChange(valid ? Number(e.target.value) : undefined);
    }

    const onBlur = () => {
        const [, value] = validator(inputValue);
        setTimeout(() => { //Add timeout since the onClick handler of a button will run after the onBlur otherwise and cause the value to be updated while the button is pressed
            setInputValid(true);
            setInputValue(value);
            onInputNumberChange(Number(value));
        }, 100)
    }

    const validator = (input: string): [boolean, string] => {
        let returnArray: [boolean | null, string | null] = [null, null];

        if(input === '') return returnArray = [true, '']; //Return true and empty string if empty

        let valNumber = Number(input);
        if(isNaN(valNumber)) return returnArray = [false, input]; //Return error and self if not a number

        if(decimals) { //Round to decimals if decimals is set
            valNumber = Number(valNumber.toFixed(decimals));
            returnArray = [false, null];
        }

        if(max && valNumber > max) { //Set to max if max is set and value is higher
            valNumber = max;
            returnArray = [false, null];
        }

        if(min && valNumber < min) { //Set to min if min is set and value is lower
            valNumber = min;
            returnArray = [false, null];
        }

        if(returnArray[0] === false) return returnArray = [false, valNumber.toString()]; //Return error and value if error
        else return returnArray = [true, valNumber.toString()]; //Return true and value if no error
    }

    return (
        <Input className={classes} type="number" value={inputValue} onChange={onInputChange} onBlur={onBlur} pattern="[0-9]" step={step} {...props}/>
    )
};

export default InputNumber;