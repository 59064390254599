import {
    ISettingsSetFileNameAction,
    ISettingsSetRemoteUserIdAction,
    ISettingsSetJavascriptEditorToolTypeAction,
    ISettingsSetDeviceDirectIpConnectionIpAction,
    ISettingsSetDevMode,
    ISettingsSetMonacoFontSize,

    ISettingsMonacoFontSize
} from '.';

import {
    SETTINGS_SET_FILENAME,
    SETTINGS_SET_REMOTE_USER_ID,
    SETTINGS_SET_JAVASCRIPT_EDITOR_TOOL_TYPE,
    SETTINGS_SET_DEVICE_DIRECT_IP_CONNECTION_IP,
    SETTINGS_SET_DEV_MODE,
    SETTINGS_SET_MONACO_FONT_SIZE
} from '.';

import { ISettingsJavascriptEditorToolType } from './';

export const settingsSetFileName = (fileName: string): ISettingsSetFileNameAction => {
    return {
        type: SETTINGS_SET_FILENAME,
        fileName
    }
}

export const settingsSetRemoteUserId = (userId: string): ISettingsSetRemoteUserIdAction => {
    return {
        type: SETTINGS_SET_REMOTE_USER_ID,
        userId
    }
}

export const settingsSetJavascriptEditorToolType = (javascriptEditorToolType: ISettingsJavascriptEditorToolType): ISettingsSetJavascriptEditorToolTypeAction => {
    return {
        type: SETTINGS_SET_JAVASCRIPT_EDITOR_TOOL_TYPE,
        javascriptEditorToolType
    }
}

export const settingsSetDeviceDirectIpConnectionIp = (deviceDirectIpConnectionIp: string): ISettingsSetDeviceDirectIpConnectionIpAction => {
    return {
        type: SETTINGS_SET_DEVICE_DIRECT_IP_CONNECTION_IP,
        deviceDirectIpConnectionIp
    }
}

export const settingsSetDevMode = (devMode: boolean): ISettingsSetDevMode => {
    return {
        type: SETTINGS_SET_DEV_MODE,
        devMode
    }
}

export const settingsSetMonacoFontSize = (fontSize: ISettingsMonacoFontSize): ISettingsSetMonacoFontSize => {
    return {
        type: SETTINGS_SET_MONACO_FONT_SIZE,
        fontSize
    }
}