import { IStoreImportAction } from '..';

export const RFID_SET_TAGS = 'RFID/SET_RFID_TAGS';

export interface IRFIDSetTags {
    type: typeof RFID_SET_TAGS;
    tags: IRFIDTag[];
}

export type IRFIDActions = IStoreImportAction | IRFIDSetTags;

export interface IRFIDTag {
    tagId: number[];
    tagName: string;
}

export interface IRFIDState {
    tags: IRFIDTag[];
}