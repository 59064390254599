import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyC8R5GyoDSX-wde497N52mTHDQumMV0aMU",
    authDomain: "scratchlink-app.firebaseapp.com",
    projectId: "scratchlink-app",
    storageBucket: "scratchlink-app.appspot.com",
    messagingSenderId: "467088688587",
    appId: "1:467088688587:web:244af602050cf707d0a24e",
    measurementId: "G-B4FKM1457V"
};

const firebaseApp = initializeApp(firebaseConfig);
export const firebaseStorage = getStorage(firebaseApp, 'gs://storage.scratchlink.codes');