import { createSelector } from 'reselect';
import { IRootState } from 'store';

import { selectorGetMatrixEditor } from './selectorGetMatrixEditor';

/**
 * Get Backpack Matrixes in a selected folder
 * @param state {IRootState} - Entire Root State
 * @param useRootFolder {boolean} - Use the root folder instead of the selected?
 */
export const selectorGetBackpackMatrixesInSelectedFolder = (codingProjectIndex: number, fileIndex: number, useRootFolder: boolean = false) => createSelector(
    (state: IRootState) => selectorGetMatrixEditor(codingProjectIndex, fileIndex)(state).backpack, 
    (backpack) => {
        const folderItemIds = backpack.folders.byIds[useRootFolder? 'root' : backpack.selectedFolderId].matrixIds; //get all item ids for the current folder
        const items = [];
        for(const itemId of folderItemIds) {
            const item = backpack.matrixes.byIds[itemId];
            items.push(item); //This will make a fine addition to my collection
        }
        return items;
    }
);