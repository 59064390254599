import React, { useState, useEffect, useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState, projectSetCodingNavBarCollapsed, projectSetCodingHelpPopoutSelectedSectionIndex, codingAddProject, codingSetActiveProjectIndex, selectorGetActiveCodingProject, selectorGetActiveRightCodingPanel } from 'store';
import { FaChevronLeft } from 'react-icons/fa';

import Button from 'components/Button/Button';

const jsExamples = require('scratchlink-projects-data/jsExamples');

type IHelpMenuConfig = {
    name: string;
    iframeUrl: string;
}

type IHelpMenuConfigArray = IHelpMenuConfig[];

type OwnProps = {

}

type Props = PropsFromRedux & OwnProps;

const CodingProjectsHelpPopout: React.FC<Props> = ({ codingProjectType, scratchlinkConfig, codingNavBarCollapsed, codingHelpPopoutSelectedSectionIndex, projectSetCodingHelpPopoutSelectedSectionIndex, projectSetCodingNavBarCollapsed, codingAddProject, codingSetActiveProjectIndex, codingProjectsLength }) => {
    if(!scratchlinkConfig.codingEditor?.helpPopout?.hardwarejs || !scratchlinkConfig.codingEditor?.helpPopout?.js || !scratchlinkConfig.codingEditor?.helpPopout?.jsscratch) throw new Error(`Edit config is missing help popout menu configs`);

    const getNoCacheId = () => (new Date()).getTime() + Math.floor(Math.random() * 1000000);

    const [sectionLoaded, setSectionLoaded] = useState(false);
    const [noCacheId, setNoCacheId] = useState(getNoCacheId());
    const iframeRef = useRef<HTMLIFrameElement>(null);

    //Only regenerate this id on component load or menu section change so that component rerenders dont cause the iframe to reload too by changing the url
    useEffect(() => {
        setNoCacheId(getNoCacheId())
    }, [codingHelpPopoutSelectedSectionIndex])

    const onIframeMessage = (message: MessageEvent) => {
        if(typeof message.data !== 'string') return;
        if(!iframeRef.current || !iframeRef.current.contentWindow) return;
        try {
            const msgData = JSON.parse(message.data);
            if(!msgData.type) return;
            if(msgData.type === 'scratchLinkHelpReady') iframeRef.current.contentWindow.postMessage(JSON.stringify({type: 'scratchLinkHelpExampleLoad'}), '*')
            if(msgData.type === 'scratchLinkHelpExampleButtonClick') {
                const exampleProject = jsExamples[msgData.exampleId];
                if(!exampleProject) {
                    console.error(`Could not find example project with id=${msgData.exampleId} in jsExamples.js file`);
                    return;
                }

                codingAddProject(exampleProject);
                codingSetActiveProjectIndex(codingProjectsLength)
                projectSetCodingNavBarCollapsed(false)
            }
        } catch(e) {}
    }

    useEffect(() => {
        window.addEventListener('message', onIframeMessage)
        return () => {
            window.removeEventListener('message', onIframeMessage)
        }
    }, [])

    const onMenuSectionClick = (index: number) => {
        if(!codingNavBarCollapsed) projectSetCodingNavBarCollapsed(true);

        setSectionLoaded(false);

        if(index === codingHelpPopoutSelectedSectionIndex) return projectSetCodingHelpPopoutSelectedSectionIndex(null);
        projectSetCodingHelpPopoutSelectedSectionIndex(index);
    }

    const onCloseSectionClick = () => {
        projectSetCodingHelpPopoutSelectedSectionIndex(null);
        setSectionLoaded(false);
    }

    const onSectionLoaded = () => {
        setSectionLoaded(true);
    }

    let menuConfig: IHelpMenuConfigArray | undefined;
    if(codingProjectType === 'hardwarejs') menuConfig = scratchlinkConfig.codingEditor.helpPopout.hardwarejs;
    if(codingProjectType === 'js') menuConfig = scratchlinkConfig.codingEditor.helpPopout.js;
    if(codingProjectType === 'jsscratch') menuConfig = scratchlinkConfig.codingEditor.helpPopout.jsscratch;

    if(!menuConfig || menuConfig.length < 1) return <>No Menu Data in Edit Config</>;

    return (
        <>
            <div 
                className={`absolute bg-gray-800 shadow-xl border-r-2 border-gray-500 h-full z-20 ${codingHelpPopoutSelectedSectionIndex !== null ? 'border-l-2' : ''}`} 
                style={{width: codingHelpPopoutSelectedSectionIndex !== null ? '440px' : '0', right: '120px', transition: 'width 0.1s ease-in-out'}}
            >
                {
                    codingHelpPopoutSelectedSectionIndex !== null && menuConfig[codingHelpPopoutSelectedSectionIndex].iframeUrl && (
                        <>
                            <div className='flex justify-between items-center'>
                                <h4 className='ml-2 text-white'>
                                    {menuConfig[codingHelpPopoutSelectedSectionIndex].name} Coding Help

                                </h4>
                                <button className='m-1 rounded-full hover:bg-gray-700 text-white' onClick={onCloseSectionClick}>
                                    <FaChevronLeft className='text-sm' style={{ /* marginRight: '2px', */ transform: 'rotate(180deg)' }} />
                                </button>
                            </div>
                            {
                                !sectionLoaded && (
                                    <div className='text-white w-full text-center mt-10'>
                                        Loading...
                                    </div>
                                )
                            }
                            <iframe 
                                className={`relative w-full h-full ${sectionLoaded ? 'opacity-100' : 'opacity-0'}`} 
                                src={menuConfig[codingHelpPopoutSelectedSectionIndex].iframeUrl+`?noCache=${noCacheId}`} 
                                ref={iframeRef}
                                onLoad={onSectionLoaded}
                            />
                        </>
                    )
                }
                {
                    codingHelpPopoutSelectedSectionIndex !== null && !menuConfig[codingHelpPopoutSelectedSectionIndex].iframeUrl && (
                        <div className='text-white'>
                            No iframe url in edit config
                        </div>
                    )
                }
            </div>
            <div className='relative h-full bg-gray-800 text-white overflow-y-auto' style={{width: '120px'}}>
                <div className='border-b-2 border-gray-500'>
                    <h4 className='w-full text-center p-2 font-bold'>Help</h4>
                </div>
                {
                    menuConfig.map((menu, index) => (
                        <div key={menu.name} className={`hover:bg-gray-600 ${codingHelpPopoutSelectedSectionIndex === index ? 'bg-gray-600' : ''}`} onClick={() => onMenuSectionClick(index)}>
                            <Button className='font-medium ' type='transparent' addStyles={['full width', 'square']}>{menu.name}</Button>
                        </div>
                    ))
                }
            </div>
        </>
    )
}

const mapStateToProps = (state: IRootState, ownProps: OwnProps) => {
    return {
        codingProjectType: selectorGetActiveCodingProject(state).type,
        scratchlinkConfig: state.project.scratchlinkConfig,
        activeRightPanel: selectorGetActiveRightCodingPanel(state),
        codingNavBarCollapsed: state.project.codingNavBarCollapsed,
        codingHelpPopoutSelectedSectionIndex: state.project.codingHelpPopoutSelectedSectionIndex,
        codingProjectsLength: state.coding.codingProjects.length
    }
}

const mapDispatchToProps = {
    projectSetCodingNavBarCollapsed,
    projectSetCodingHelpPopoutSelectedSectionIndex,
    codingAddProject, 
    codingSetActiveProjectIndex
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CodingProjectsHelpPopout);