import React, { useState, useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState, ICodingImage, codingSetProjectFileImageManagerImages } from 'store';

import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import { toast } from 'react-toastify';

type OwnProps = {
    codingProjectIndex: number;
    fileIndex: number;
}

type Props = PropsFromRedux & OwnProps;

const imageImportButtonChildDefault = <span>Import Image</span>;

const CodingImageManager: React.FC<Props> = ({ codingProjectIndex, fileIndex, codingFile, codingSetProjectFileImageManagerImages }) => {
    if(codingFile.type !== 'imageManager') throw new Error('CodingImageManager: codingFile.type is not imageManager');
    if(!codingFile.imageData) throw new Error();

    const images = codingFile.imageData.images;

    const imageImportInputRef = useRef(null);
    const [createImageName, setCreateImageName] = useState('');
    const [createImageModalOpen, setCreateImageModalOpen] = useState(false);
    const [imageImportButtonChild, setImageImportButtonChild] = useState(imageImportButtonChildDefault);
    const [createImageData, setCreateImageData] = useState<null | string>(null);
    const [createImageDataType, setCreateImageDataType] = useState<ICodingImage['type']>('img');
    
    const openCreateImageModal = () => {
        setCreateImageModalOpen(true);
    }

    const closeCreateImageModal = () => {
        setCreateImageModalOpen(false);
    }

    const onDeleteImage = (index: number) => {
        const newImages: ICodingImage[] = JSON.parse(JSON.stringify(images));

        newImages.splice(index, 1);

        codingSetProjectFileImageManagerImages(
            codingProjectIndex, 
            fileIndex,
            newImages
        )
    }

    const onCopyImageHtml = (index: number) => {
        const image = images[index];
        const html = `<img src="${image.name}" alt="${image.name}" />`;

        if(!navigator.clipboard) return alert(html);

        navigator.clipboard.writeText(html).then(
        () => {
            toast.success('Copied HTML to clipboard');
        },
        () => {
            alert(html);
        })
    }

    const onCreateImageNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCreateImageName(e.target.value.split('').filter(char => /[a-zA-Z_]/.test(char)).join(''));
    }

    const onImageImportChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
         //@ts-ignore
        const file = e.target.files[0];

        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target?.result;
            setCreateImageData(null); //reset
            setImageImportButtonChild(imageImportButtonChildDefault); //reset

            if(!data || typeof data !== 'string') return;
            //max size 2MB
            if(data.length > 1024 * 1024 * 2) {
                //clear the input
                //@ts-ignore
                imageImportInputRef.current.value = '';
                return toast.error('Image is too large. Max size is 2MB');
            }

            console.log(data)

            setCreateImageData(data as string);
            setCreateImageDataType(data.startsWith('data:video/mp4') ? 'video' : 'img');
            setImageImportButtonChild(<span>Image Imported</span>);
        }
        reader.readAsDataURL(file);
    }

    const onImageImportClick = () => {
         //@ts-ignore
         imageImportInputRef?.current?.click();
    }

    const onCreateImageSave = () => {
        if(!createImageName) return toast.error('ERROR: Image name cannot be empty');
        if(images.find(image => image.name === createImageName)) return toast.error('ERROR: Image name already exists');
        if(!createImageData) return toast.error('ERROR: Image cannot be empty');

        const newImage: ICodingImage = {
            name: createImageName,
            data: createImageData,
            type: createImageDataType
        }

        codingSetProjectFileImageManagerImages(codingProjectIndex, fileIndex, [...images, newImage]);
        setCreateImageName('');
        setCreateImageData(null);
        closeCreateImageModal();
    }

    return (
        <>
            <div className="h-full w-full p-2 overflow-y-scroll">
                <table className="w-full text-center border-2 border-gray-800">
                    <thead className="bg-gray-400 text-white text-2xl  font-semibold">
                        <tr>
                            <th className="border-2 border-gray-800 w-1/3">
                                Image Name
                            </th>
                            <th className="border-2 border-gray-800 w-2/3">
                                Image
                            </th>
                            <th className="border-2 border-gray-800">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            images.map((image, index) => (
                                <tr className="bg-white text-xl" key={index}>
                                    <td className="border-2 border-gray-400">
                                        {image.name}
                                    </td>
                                    <td className="border-2 border-gray-400 flex items-center justify-center">
                                        {
                                            image.type === 'img' ? (
                                                <img src={image.data} alt={image.name} className="max-h-64 m-2"/>
                                            ) : (
                                                <video src={image.data} className="max-h-64 m-2" controls/>
                                            )
                                        }
                                    </td>
                                    <td className="border-2 border-gray-400">
                                        <Button type='success' addStyles={['square']} onClick={() => onCopyImageHtml(index)}>Copy HTML</Button>
                                        <Button className='mt-1' type='danger' addStyles={['square']} onClick={() => onDeleteImage(index)}>Delete</Button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                <div className="mt-2 w-full">
                    <Button className="float-right" type="success" addStyles={['square']} onClick={openCreateImageModal}>Add Image</Button>
                </div>
            </div>
            <Modal
                type='save'
                size='md'
                isOpen={createImageModalOpen}
                closeModal={closeCreateImageModal}
                onSave={onCreateImageSave}
                title="Create Image"
            >
                <div className='flex mb-2'>
                    <div className='pr-2 border-r-2'>
                        <Input className="h-12" maxLength={20} type='text' value={createImageName} placeholder="Image Name" onChange={onCreateImageNameChange}/>
                    </div>
                    <div className='ml-2 w-2/3'>
                        <div className='w-full h-full flex flex-wrap justify-center items-center'>
                            <input type="file" ref={imageImportInputRef} accept=".jpg, .png, .gif, .webp, .mp4" onChange={onImageImportChangeFile} hidden/>
                            <Button type='success' addStyles={['square']} onClick={onImageImportClick}>{imageImportButtonChild}</Button>
                            <div className='mt-4'>
                                {
                                    createImageData && (
                                        createImageDataType === 'img' ? (
                                            <img src={createImageData} alt="create image" className='max-h-64'/>
                                        ) : createImageData && createImageDataType === 'video' && (
                                            <video src={createImageData} className='max-h-64' controls/>
                                        )
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

const mapStateToProps = (state: IRootState, ownProps: OwnProps) => {
    return {
        codingFile: state.coding.codingProjects[ownProps.codingProjectIndex].files[ownProps.fileIndex]
    }
}

const mapDispatchToProps = {
    codingSetProjectFileImageManagerImages
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CodingImageManager);