import { IStoreImportAction } from '..';
import { ConsoleDecoratorNames } from 'components/ConsoleDecorator/ConsoleDecorator';

export const CONSOLE_ADD_CONSOLE_LOG = 'CONSOLE/ADD_CONSOLE_LOG';
export const CONSOLE_ADD_CONSOLE_LOG_BATCH = 'CONSOLE/ADD_CONSOLE_LOG_BATCH';
export const CONSOLE_CLEAR_CONSOLE = 'CONSOLE/CLEAR_CONSOLE';

export interface IConsoleAddConsoleLog {
    type: typeof CONSOLE_ADD_CONSOLE_LOG;
    log: IConsoleLog;
}

export interface IConsoleAddConsoleLogBatch {
    type: typeof CONSOLE_ADD_CONSOLE_LOG_BATCH;
    logs: IConsoleLog[];
}

export interface IConsoleClearConsole {
    type: typeof CONSOLE_CLEAR_CONSOLE;
}

export type IConsoleActions = IStoreImportAction | IConsoleAddConsoleLog | IConsoleAddConsoleLogBatch | IConsoleClearConsole;

export type IConsoleSource  = 
    'ScratchLinkIncoming' |
    'ScratchLinkIncomingEvent' |
    'ScratchLinkOutgoing' |
    'Info' |
    'JSSystem' |
    'JSLog' | 
    'JSLibError' |
    'JSError' |
    'PySystem' |
    'PyLog' |
    'PyLibError' |
    'PyError';

export interface IConsoleLog {
    id: string;
    content: string;
    timestamp: string;
    timestampMiliseconds: number;
    source: IConsoleSource;
    decorator?: ConsoleDecoratorNames;
}

export interface IConsoleState {
    messages: IConsoleLog[];
    clearedTime: number;
}