import { createSelector } from 'reselect';
import { IRootState, IDeviceConnectionType } from 'store';

export const selectorGetProjectDeviceConnectionType = createSelector(
    (state: IRootState) => state.project.deviceConnectionMethod,
    (deviceConnectionMethod): IDeviceConnectionType => {
        if(deviceConnectionMethod === 'serial') return 'serial';
        if(deviceConnectionMethod === 'modem') return 'modem';
        return 'websocket';
    }
);