import React, { useState, useEffect, useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState } from 'store';

const jsExamples = require('scratchlink-projects-data/jsExamples');

type OwnProps = {

}

type Props = PropsFromRedux & OwnProps;

const CodingHelpTestPage: React.FC<Props> = ({ }) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        const jsExampleIds = Object.keys(jsExamples);
        window.addEventListener('message', function (e) {
            const dataString = e.data;
            try {
                const data = JSON.parse(dataString);

                if(data.type === 'scratchLinkHelpReady') { //When document loaded in iframe then send example load to display buttons
                    iframeRef?.current?.contentWindow?.postMessage(JSON.stringify({
                        type: 'scratchLinkHelpExampleLoad'
                    }), '*')
                }

                if(data.type !== 'scratchLinkHelpExampleButtonClick') return;

                console.log('IFRAME BUTTON CLICK', data);

                if(data.exampleId && jsExampleIds.includes(data.exampleId)) console.log('FOUND EXAMPLE PROJECT FOR EXAMPLE ID')
                else console.error('COULD NOT FIND EXAMPLE PROJECT FOR EXAMPLE ID')
            } catch(e) {}
        });
    }, []);

    return (
        <>
            <div className='w-full h-full'>
                <iframe ref={iframeRef} className='w-full' style={{height: '800px'}} src="https://scratchlink.au/js4scratch-toc/js4scratch-reference" allow="clipboard-write;" />
            </div>
        </>
    )
}

const mapStateToProps = (state: IRootState) => {
    return {

    }
}

const mapDispatchToProps = {
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CodingHelpTestPage);