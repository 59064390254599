export const hexToBinary = (hex: string): string[] => (
    hex.split('').reduce((acc, i) => (
        acc + ('000' + parseInt(i, 16).toString(2)).substr(-4, 4)
    ), '').split('')
)

export const binaryToHex = (binary: string[]): string => (
    binary.join('').match(/.{4}/g)!.reduce((acc, i) => (
        acc + parseInt(i, 2).toString(16)
    ), '')
)

//We have 2 different orders for keeping binary matrix data in
//Standard Binary (stdBinary) is left to right, top to bottom on a 16x16 matrix
//Scratch Link Binary (scrBinary) is the order that scratch link displays the hex in on the hardware displays
//We need to convert between these forms for simplicity in displaying the data on displays other than the hardware matrix, such as the matrix editor
//srcBinary is the only form that should ever be displayed or saved for the user, as to not get confused with the console commands

export const stdBinaryTosrcBinary = (stdBinary: string[]): string[] => {
    const srcBinary: string[] = [];

    for (let x=16; x>0; x--) {
        for (let y=1; y<9; y++) {
            srcBinary.push(stdBinary[((x-1)*16)+y-1]);
        }
    }
    for (let x=16; x>0; x--) {
        for (let y=9; y<17; y++) {
            srcBinary.push(stdBinary[((x-1)*16)+y-1]);
        }
    }

    return srcBinary;
}

export const srcBinaryTostdBinary = (srcBinary: string[]): string[] => {
    const stdBinary: string[] = [];

    for(let x=16; x>0; x--) {
        for(let y=0; y<8; y++) {
            stdBinary.push(srcBinary[((x-1)*8)+y])
        }

        for(let y=8; y<16; y++) {
            stdBinary.push(srcBinary[((x-1)*8)+127+(y-7)])
        }
    }

    return stdBinary;
}