import React, { useState, useEffect, useCallback } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState, codingSetProjectFileButtons, codingDeleteProjectFileEventButtonsButton } from 'store';
import { toast } from 'react-toastify';
import { Menu, Item, contextMenu, ItemParams } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';

import ScratchLinkJSRunner from 'lib/ScratchLinkJSRunner';
import PyRunner from 'lib/Python/PyRunner';

import Input from 'components/Input/Input';
import Button from 'components/Button/Button';

type OwnProps = {
    codingProjectIndex: number;
    fileIndex: number;
}

type Props = PropsFromRedux & OwnProps;

interface ICodingButton {
    name: string;
    onClick: () => void;
}

const CodingEventButtons: React.FC<Props> = ({ codingProjectIndex, fileIndex, codingFile, codingSetProjectFileButtons, codingDeleteProjectFileEventButtonsButton }) => {
    if(codingFile.type !== 'eventButtons') throw new Error('CodingEventButtons: codingFile.type is not eventButtons');
    const codingButtons = codingFile.eventButtonData?.buttons || [];

    const jsButtonsToButtons = useCallback(
        (): ICodingButton[] => {
            return codingButtons.map(codingButton => ({
                name: codingButton,
                onClick: () => {
                    ScratchLinkJSRunner.sendButtonEvent(codingButton);
                    PyRunner.sendButtonEvent(codingButton);
                }
            }))   
        },
        [ codingButtons ]
    );

    const [buttons, setButtons] = useState(jsButtonsToButtons());
    const [buttonName, setButtonName] = useState('');

    useEffect(() => {
        setButtons(jsButtonsToButtons());
    }, [ codingButtons, jsButtonsToButtons ]);

    const handleEvent = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, buttonIndex: number) => {
        e.preventDefault();

        contextMenu.show({
            id: `contextMenu__codingEventButtons__button${codingProjectIndex}`,
            event: e,
            props: {
                buttonIndex
            }
        });
    };

    const handleOnButtonDelete = (args: ItemParams<any, any>) => {
        const buttonIndex: number = (args.props as any).buttonIndex;

        codingDeleteProjectFileEventButtonsButton(codingProjectIndex, fileIndex, buttonIndex);
    }

    const onButtonClickHandler = (buttonName: string) => {
        ScratchLinkJSRunner.sendButtonEvent(buttonName);
        PyRunner.sendButtonEvent(buttonName);
    }

    const onClickAddButton = () => {
        if(buttonName === '') return;
        if(buttons.find(button => button.name === buttonName)) return toast.error(`Button name already exists`)

        let newButtons: ICodingButton[] = JSON.parse(JSON.stringify(buttons)); //deep copy
        newButtons.push({
            name: buttonName,
            onClick: () => onButtonClickHandler(buttonName)
        });

        codingSetProjectFileButtons(newButtons.map(newButton => newButton.name), codingProjectIndex, fileIndex);
        setButtonName('');
    }

    const onButtonNameChange = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
        const value = changeEvent.target.value;

        setButtonName(value.split('').filter(char => /[a-zA-Z1-9]/.test(char)).join(''));
    }

    return (
        <div className="flex flex-row flex-nowrap content-between h-full w-full">
            <Menu id={`contextMenu__codingEventButtons__button${codingProjectIndex}`}>
                <Item onClick={handleOnButtonDelete}>Delete Button</Item>
            </Menu>
            <div className="flex flex-col flex-nowrap p-2 w-full">
                <div className="flex flex-col justify-center w-64">
                    <Input value={buttonName} onChange={onButtonNameChange} maxLength={12} placeholder="Button Name" />
                    <Button className="mt-2" type='info' onClick={onClickAddButton}>Add Button</Button>
                </div>
                <div className="my-2 w-full border-t-2 border-gray-500" />
                {
                    buttons.map((button, index) => (
                        <Button 
                            className="mt-2 w-32" 
                            key={button.name} 
                            type='success' 
                            onClick={button.onClick}
                            onContextMenu={e => handleEvent(e, index)}
                        >
                            {button.name}
                        </Button>
                    ))
                }
            </div>
        </div>
    )
};

const mapStateToProps = (state: IRootState, ownProps: OwnProps) => {
    return {
        codingFile: state.coding.codingProjects[ownProps.codingProjectIndex].files[ownProps.fileIndex]
    }
}

const mapDispatchToProps = {
    codingSetProjectFileButtons,
    codingDeleteProjectFileEventButtonsButton
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CodingEventButtons);