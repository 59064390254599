const createScratchJSCodeImportHeader = (hardwareMode: string) => {
    //TODO: Use JSScratchLink.${hardwareMode}({}) again once all the hardware modes are implemented in JSScratchLink
    if(hardwareMode.toLowerCase() === 'javascript') 
        return `
            const eBot = JSScratchLink.javascript({});
            const eventsGamepad1 = eBot.eventsGamepad1;
            const eventsGamepad2 = eBot.eventsGamepad2;
            const control = {
                wait: eBot.wait,
                forever: eBot.forever,
                repeat: eBot.repeat,
                repeatUntil: eBot.repeatUntil,
                waitUntil: eBot.waitUntil,
                stopAll: eBot.stopAll
            };
        `;

    return `
        const eBot = JSScratchLink.ebot_example({});
        const wheels = eBot.wheels;
        const led = eBot.led;
        const matrix = eBot.matrix;
        const crane = eBot.crane;
        const servo = eBot.servo;
        const eventsEbot = eBot.eventsEbot;
        const eventsGamepad1 = eBot.eventsGamepad1;
        const eventsGamepad2 = eBot.eventsGamepad2;
        const control = {
            wait: eBot.wait,
            forever: eBot.forever,
            repeat: eBot.repeat,
            repeatUntil: eBot.repeatUntil,
            waitUntil: eBot.waitUntil,
            stopAll: eBot.stopAll
        };
        const config = {
            print: eBot.print
        };
    `;
}

export default createScratchJSCodeImportHeader;