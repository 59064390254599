import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Helmet } from 'react-helmet';
import { IRootState } from 'store';

import PageBoundary from './PageBoundary/PageBoundary';
import './Page.scss';

export type Props = PropsFromRedux & {
    title: string;
    style?: string;
    styles?: React.CSSProperties;
    className?: string;
    background: string;
    inner?: boolean;
    children: any;
}

const Page: React.FC<Props> = ({ title, style, styles, className, children, background, inner = true }) => {
    //Switch classes
    let classes = `c-page ${className || ""}`;

    //Setup page title
    let titleHelmet;
    if((!title || !title.length) && style !== "home-page") console.error(`This page (${style || className}) does not have a title!`);
    titleHelmet = <title>{ title }</title>

    //Extras
    let bg;
    if(background) {
        bg = <div className="c-page__background" style={{background: background}}/>;
        classes += ' has-background';
    }

    return (
        <div className={classes} style={styles}>
            <Helmet >
            { titleHelmet }
            </Helmet>
            { bg }
            {   inner && 
                <div className="c-page__inner">
                    { children }
                </div>
            }
            {
                !inner && children
            }
        </div>
    );
};

const mapStateToProps = (state: IRootState) => {
    return {
    }
}

const mapDispatchToProps = {
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Page);

export {
    PageBoundary
}