import React from 'react';
import { IConsoleDecorator } from '../ConsoleDecorator';

export const SensorConsoleDecorator: IConsoleDecorator = ({ msgObject }) => {
    return (
        <>
            { JSON.stringify(msgObject) }
        </>
    )
};

SensorConsoleDecorator.decoratorTest = (msgObject) => {
    if(msgObject['evt'] !== undefined && msgObject['conf'] !== undefined) return false;

    return true;
}

SensorConsoleDecorator.consoleClass = () => 'c-consoleDecorator__sensor c-consoleDecorator__whiteTimestamp';