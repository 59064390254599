import { 
    IThunkResult, 
    selectorGetProjectDeviceConnectionIp, 
    selectorGetProjectDeviceConnectionType, 
    consoleClearConsole, 
    RFIDSetTags,
    codingSetRunning, 
    codingSetProjectGamepadMode, 
    codingSetProjectHardwareMode
} from 'store';
import { replace } from 'connected-react-router';
import { toast } from 'react-toastify';
import { ScratchLinkGamepadData } from 'lib/GamepadConnection/GamepadData';

import {
    IProjectSetPageDimensionsAction,
    IProjectSetRightPanelDimensionsAction,
    IProjectSetCodingNavBarCollapsedAction,
    IProjectSetCodingHelpPopoutSelectedSectionIndex,
    IProjectSetDeviceConnected,
    IProjectSetDeviceConnecting,
    IProjectSetDeviceConnectionMethod,
    IProjectSetDeviceDirectIp,
    IProjectSetDeviceSerialPort,
    IProjectSetDeviceName,
    IProjectSetDeviceBatteryVoltage,
    IProjectSetHardwareMode,
    IProjectSetBlockLevelMode,
    IProjectSetHardwareModeAutoDetected,
    IProjectSetGamepadConnected,
    IProjectSetGamepadData,
    IProjectSetConfigPageSettingValue,
    IProjectResetConfigPageSettingValues,
    IProjectSetScratchLinkConfig
} from '.';

import {
    PROJECT_SET_PAGE_DIMENSIONS,
    PROJECT_SET_RIGHT_PANEL_DIMENSIONS,
    PROJECT_SET_CODING_NAVBAR_COLLAPSED,
    PROJECT_SET_CODING_HELP_POPOUT_SELECTED_SECTION_INDEX,
    PROJECT_SET_DEVICE_CONNECTED,
    PROJECT_SET_DEVICE_CONNECTING,
    PROJECT_SET_DEVICE_CONNECTION_METHOD,
    PROJECT_SET_DEVICE_DIRECT_IP,
    PROJECT_SET_DEVICE_SERIAL_PORT,
    PROJECT_SET_DEVICE_NAME,
    PROJECT_SET_DEVICE_BATTERY_VOLTAGE,
    PROJECT_SET_HARDWARE_MODE,
    PROJECT_SET_BLOCK_LEVEL_MODE,
    PROJECT_SET_HARDWARE_MODE_AUTO_DETECTED,
    PROJECT_SET_GAMEPAD_CONNECTED,
    PROJECT_SET_GAMEPAD_DATA,
    PROJECT_SET_CONFIG_PAGE_SETTING_VALUE,
    PROJECT_RESET_CONFIG_PAGE_SETTING_VALUES,
    PROJECT_SET_SCRATCHLINK_CONFIG
} from '.';

import { IDivDimensions, IDeviceConnectionMethod, BlockLevelModes, IConfigPageSetting, IConfigPageValue, GamePadTypes } from './';

export const projectSetPageDimensions = (pageDimensions: IDivDimensions): IProjectSetPageDimensionsAction => {
    return {
        type: PROJECT_SET_PAGE_DIMENSIONS,
        pageDimensions
    }
}

export const projectSetRightPanelDimensions = (rightPanelDimensions: IDivDimensions): IProjectSetRightPanelDimensionsAction => {
    return {
        type: PROJECT_SET_RIGHT_PANEL_DIMENSIONS,
        rightPanelDimensions
    }
}

export const projectSetCodingNavBarCollapsed = (collapsed: boolean): IProjectSetCodingNavBarCollapsedAction => {
    return {
        type: PROJECT_SET_CODING_NAVBAR_COLLAPSED,
        collapsed
    }
}

export const projectSetCodingHelpPopoutSelectedSectionIndex = (index: null | number): IProjectSetCodingHelpPopoutSelectedSectionIndex => {
    return {
        type: PROJECT_SET_CODING_HELP_POPOUT_SELECTED_SECTION_INDEX,
        index
    }
}

export const projectSetDeviceConnected = (deviceConnected: boolean): IThunkResult<any> => {
    return (dispatch, getState) => {
        const state = getState()
        const deviceConnectionIp = selectorGetProjectDeviceConnectionIp(state);
        const deviceConnectionMethod = selectorGetProjectDeviceConnectionType(state);

        dispatch({
            type: PROJECT_SET_DEVICE_CONNECTED,
            deviceConnected
        } as IProjectSetDeviceConnected);

        dispatch({
            type: PROJECT_SET_DEVICE_CONNECTING,
            deviceConnecting: false
        } as IProjectSetDeviceConnecting);

        dispatch(replace(`/${state.router.location.pathname.split('/')[1]}`)); //Close modal

        if(deviceConnected && state.console.messages.length > 0) {
            dispatch(consoleClearConsole());
            toast.warn('Console Cleared!');
        }

        if(!deviceConnected) { //Clear tags on disconnect
            dispatch(RFIDSetTags([]));
            dispatch(codingSetRunning(false));
        }

        if(deviceConnected && deviceConnectionMethod === 'websocket') toast.success(`Connected to eBot on IP: ${deviceConnectionIp}`);
        if(deviceConnected && deviceConnectionMethod === 'serial') toast.success(`Connected to eBot on Serial Port`);
        if(deviceConnected && deviceConnectionMethod === 'modem') toast.success(`Connected to eBot on USB Modem`);
        if(!deviceConnected && state.project.deviceConnected) toast.error(`eBot Disconnected`);
    }
}

export const projectSetDeviceConneting = (deviceConnecting: boolean): IProjectSetDeviceConnecting => {
    return {
        type: PROJECT_SET_DEVICE_CONNECTING,
        deviceConnecting
    }
}

export const projectSetDeviceConnectionMethod = (deviceConnectionMethod: IDeviceConnectionMethod): IProjectSetDeviceConnectionMethod => {
    return {
        type: PROJECT_SET_DEVICE_CONNECTION_METHOD,
        deviceConnectionMethod
    }
}

export const projectSetDeviceDeviceIp = (deviceDirectIp: string): IProjectSetDeviceDirectIp => {
    return {
        type: PROJECT_SET_DEVICE_DIRECT_IP,
        deviceDirectIp
    }
}

export const projectSetDeviceDeviceSerialPort = (serialPort: string): IProjectSetDeviceSerialPort => {
    return {
        type: PROJECT_SET_DEVICE_SERIAL_PORT,
        serialPort
    }
}

export const projectSetDeviceName = (name: string): IProjectSetDeviceName => {
    return {
        type: PROJECT_SET_DEVICE_NAME,
        name
    }
}

export const projectSetDeviceBatteryVoltage = (voltage: number): IProjectSetDeviceBatteryVoltage => {
    return {
        type: PROJECT_SET_DEVICE_BATTERY_VOLTAGE,
        voltage
    }
}

export const projectSetHardwareMode = (hardwareMode: string): IProjectSetHardwareMode => {
    return {
        type: PROJECT_SET_HARDWARE_MODE,
        hardwareMode
    }
};

export const projectSetBlockLevelMode = (blockLevelMode: keyof typeof BlockLevelModes): IProjectSetBlockLevelMode => {
    return {
        type: PROJECT_SET_BLOCK_LEVEL_MODE,
        blockLevelMode
    }
};

export const projectSetHardwareModeAutoDetected = (mode: string): IThunkResult<any> => {
    return (dispatch, getState) => {
        dispatch(<IProjectSetHardwareModeAutoDetected>{
            type: PROJECT_SET_HARDWARE_MODE_AUTO_DETECTED,
            mode
        });

        const state = getState();
        const connected = state.project.deviceConnected;
        if(!connected) return;

        const activeCodingProjectIndex = state.coding.activeProjectIndex;

        //Force hardware mode to be set to the hardware mode that was just auto detected
        dispatch(codingSetProjectHardwareMode(activeCodingProjectIndex, mode));
    }
};

export const projectSetGamepadConnected = (gamepadIndex: number, gamepadConnected: boolean, gamepadType: GamePadTypes): IThunkResult<any> => {
    return (dispatch, getState) => {
        dispatch(<IProjectSetGamepadConnected>{
            type: PROJECT_SET_GAMEPAD_CONNECTED,
            gamepadIndex,
            gamepadConnected,
            gamepadType
        })

        if(gamepadIndex !== 0 || gamepadType === 'no_gamepad') return; //Only set gamepad mode for gamepad 0

        const state = getState();
        const activeCodingProjectIndex = state.coding.activeProjectIndex;

        //Force gamepad mode to be set to the gamepad that was just connected
        dispatch(codingSetProjectGamepadMode(activeCodingProjectIndex, gamepadType));
    }
}

export const projectSetGamepadData = (gamepadIndex: number, gamepadData: ScratchLinkGamepadData): IProjectSetGamepadData => {
    return {
        type: PROJECT_SET_GAMEPAD_DATA,
        gamepadIndex,
        gamepadData
    }
}

export const projectSetConfigPageSettingValue = (setting: IConfigPageSetting, value: IConfigPageValue): IProjectSetConfigPageSettingValue => {
    return {
        type: PROJECT_SET_CONFIG_PAGE_SETTING_VALUE,
        setting,
        value
    }
}

export const projectResetConfigPageSettingValues = (): IProjectResetConfigPageSettingValues => {
    return {
        type: PROJECT_RESET_CONFIG_PAGE_SETTING_VALUES,
    }
}

export const projectSetScratchlinkConfig = (config: string): IProjectSetScratchLinkConfig => {
    return {
        type: PROJECT_SET_SCRATCHLINK_CONFIG,
        config
    }
}