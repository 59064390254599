import store, { codingSetRunning, consoleAddConsoleLog, consoleAddConsoleLogBatch, consoleCreateLog, selectorGetProjectHardwareModeJavascript, selectorGetMatrixEditor, IConsoleLog, ICodingFile, ICodingJSFileData, ICodingGlobalVarVariable, codingSetUserSelectedRightPanel } from 'store';
import { DeviceConnectionManager } from 'lib/DeviceConnection';
import wait from 'utils/wait';

import GamepadConnection from 'lib/GamepadConnection/GamepadConnection';
import JSErrorHandler from './JSErrorHandler';
import processScratchLinkJSCode from 'utils/processScratchLinkJSCode';

interface IWorkerJSMessage {
    type: string;
    data: any;
}

//ScratchLink JS runner for the hardware library
class ScratchLinkJSRunner {
    private runWorker: Worker | undefined;
    private logQueue: IConsoleLog[] = [];
    private logDispatchTimeout: undefined | ReturnType<typeof setTimeout>;

    constructor() {
        this.onDeviceDataPacket = this.onDeviceDataPacket.bind(this);
        this.onGamepadEvent = this.onGamepadEvent.bind(this);
        this.onGamepad1Data = this.onGamepad1Data.bind(this);
        this.onGamepad2Data = this.onGamepad2Data.bind(this);
        this.handleGamepadData = this.handleGamepadData.bind(this);
    }

    stopCode() {
        DeviceConnectionManager.sendCommand('wheels off; led off; matrix clear;'); //Stop everything on robot

        this.stopWorker();
    }

    /**
     * Terminate Javascript Worker
     */
    stopWorker(): void {
        if(this.runWorker) {
            this.runWorker.terminate();
            DeviceConnectionManager.removeEventListener('data', this.onDeviceDataPacket);
            GamepadConnection.removeEventListener('gamepadEvent', this.onGamepadEvent);
            //@ts-ignore
            store.dispatch(codingSetRunning(false));
        }
    }

    onDeviceDataPacket(data: any) {
        this.sendData({type: 'sensorData', data: data.detail})
    }

    sendButtonEvent(buttonId: string) {
        this.sendData({type: 'buttonEvent', data: buttonId})
    }

    onGamepadEvent(event: any) {
        this.sendData({type: 'gamepadEvent', data: event.detail})
    }

    onGamepad1Data(event: any) {
        this.handleGamepadData(1, event.detail.data)
    }

    onGamepad2Data(event: any) {
        this.handleGamepadData(2, event.detail.data)
    }

    handleGamepadData(gamepadNumber: number, data: any) {
        this.sendData({type: 'gamepadData', data: {gamepadNumber, gamepadPacket: data }})
    }
    
    async runCode(codingProjectFiles: ICodingFile[], codeHeader: string, hardwareModeAutoDetected: string, activeCodingProjectHardwareMode: string) {
        this.stopWorker();

        const jsFiles = codingProjectFiles.filter(file => file.type === 'js') as ICodingFile[];
        const jsFilesData = jsFiles.map(file => file.jsData) as ICodingJSFileData[];
        const globalVars = codingProjectFiles.find(file => file.type === 'globalVars')?.globalVarData?.vars;

        const runCode = this.generateRunCode(
            jsFilesData, 
            globalVars || [],
            codeHeader
        );
        
        const workerJavascriptBlobURL = (window.URL ? URL : window.webkitURL).createObjectURL(new Blob([runCode])); //{type: 'application/javascript; charset=utf-8'}

        console.log(`STARTING JAVASCRIPT WORKER`);

        console.log(runCode);

        this.runWorker = new Worker(workerJavascriptBlobURL);

        this.runWorker.onmessage = (messageData) => this.wokerMessageHandler(messageData.data);

        this.runWorker.onerror = (ev: ErrorEvent) => {
            JSErrorHandler.handleError(ev, jsFiles, runCode)
        }

        DeviceConnectionManager.addEventListener('data', this.onDeviceDataPacket);
        this.onDeviceDataPacket({ detail: DeviceConnectionManager.data });
        GamepadConnection.gamepad1EventHandler.addEventListener('gamepadEvent', this.onGamepadEvent);
        GamepadConnection.gamepad2EventHandler.addEventListener('gamepadEvent', this.onGamepadEvent);
        GamepadConnection.addEventListener('gamepad1Data', this.onGamepad1Data)
        GamepadConnection.addEventListener('gamepad2Data', this.onGamepad2Data)
    }

    generateRunCode(jsFilesData: ICodingJSFileData[], globalVars: ICodingGlobalVarVariable[], codeHeader: string): string {
        const code = processScratchLinkJSCode(jsFilesData, globalVars, codeHeader, true);
        //Add await to function besides event litsener

        const runCode = `
            self.importScripts('${window.location.origin}/ScratchLink.js');

            const started = () => {
                self.postMessage({type: 'status', data: 'started'});
            }
            const ended = () => {
                self.postMessage({type: 'status', data: 'ended'});
            }
            const runCodeOnStart = (runFunc) => {
                setTimeout(runFunc, 0)
            }

            (() => {
                started();
                ${code}
            })();
        `;
    
        return runCode;
    }

    sendData(data: object) {
        const obj = JSON.parse(JSON.stringify(data));
        this.runWorker?.postMessage(obj);
    }

    private wokerMessageHandler(message: IWorkerJSMessage): void {
        if(message.type === 'status') {
            if(message.data === 'started') {
                //@ts-ignore
                store.dispatch(codingSetRunning(true))
                store.dispatch(consoleAddConsoleLog('Started Running', 'JSSystem', 'JSSystemConsoleDecorator'))
                return;
            }
            if(message.data === 'ended') {
                this.stopWorker();
                //@ts-ignore
                store.dispatch(codingSetRunning(false));
                store.dispatch(consoleAddConsoleLog('Stopped Running', 'JSSystem', 'JSSystemConsoleDecorator'))
                return;
            }
        }

        if(message.type === 'log') {
            this.addLogQueue(consoleCreateLog(message.data.msg, 'JSLog', 'NormalConsoleDecorator'));
            return;
        }

        if(message.type === 'logLibError') {
            store.dispatch(consoleAddConsoleLog(message.data.msg, 'JSError', 'JSLibraryErrorConsoleDecorator'));
            //@ts-ignore
            store.dispatch(codingSetUserSelectedRightPanel('console'));
            this.stopWorker();
            store.dispatch(consoleAddConsoleLog('Stopped Running', 'JSSystem', 'JSSystemConsoleDecorator'))
            return;
        }

        if(message.type === 'command') {
            DeviceConnectionManager.sendCommand(message.data);
            return;
        }

        if(message.type === 'displayMatrix') {
            this.displayMatrixFunction(message.data);
            return;
        }

        if(message.type === 'displayMatrixAnimation') {
            this.displayMatrixAnimationFunction(message.data);
            return;
        }

        if(message.type === 'sendEbotCranePreset') {
            this.sendEbotCranePreset(message.data);
            return;
        }
    }

    //dispatch logs in batches else it will stall the main thread if the user has many console.log commands
    addLogQueue(log: IConsoleLog) {
        this.logQueue.push(log);

        if(this.logDispatchTimeout === undefined) this.logDispatchTimeout = setTimeout(() => {
            store.dispatch(consoleAddConsoleLogBatch(this.logQueue))

            this.logQueue = [];
            this.logDispatchTimeout = undefined;
        }, 100)
    }

    displayMatrixFunction(matrixName: string) {
        const storeData = store.getState();
        const matrixEditor = selectorGetMatrixEditor(storeData.coding.activeProjectIndex)(storeData);

        const matrixIds = matrixEditor.backpack.matrixes.allIds;
        for(let i=0; i<matrixIds.length; i++) {
            const matrix = matrixEditor.backpack.matrixes.byIds[matrixIds[i]]
            if(matrix.name === matrixName) {
                DeviceConnectionManager.sendCommand(`matrix show 0x${matrix.hex};`)
                break;
            }
        }
    }

    async displayMatrixAnimationFunction(animationName: string) {
        const storeData = store.getState();
        const matrixEditor = selectorGetMatrixEditor(storeData.coding.activeProjectIndex)(storeData);
        
        const folderIds = matrixEditor.backpack.folders.allIds;
        for(let i=0; i<folderIds.length; i++) {
            const animation = matrixEditor.backpack.folders.byIds[folderIds[i]]
            if(animation.name === animationName) {

                for(let j=0; j<animation.matrixIds.length; j++) {
                    const matrix = matrixEditor.backpack.matrixes.byIds[animation.matrixIds[j]];
                    DeviceConnectionManager.sendCommand(`matrix show 0x${matrix.hex};`);
                    await wait(1000/matrixEditor.animation.fps);
                }

                DeviceConnectionManager.sendCommand(`matrix clear;`);

                break;
            }
        }
    }

    async sendEbotCranePreset(preset: string) {
        const storeData = store.getState();
        const scratchlinkConfig = storeData.project.scratchlinkConfig;
        const hardwareMode = selectorGetProjectHardwareModeJavascript(storeData);

        const deviceProfile = scratchlinkConfig.deviceProfiles.find((deviceProfile: any) => deviceProfile.profile === hardwareMode);
        if(!deviceProfile) return;

        const hardwareDOFMode = deviceProfile.crane || 'none';
        const cranePresets: {name: string; commands: string[]}[] = scratchlinkConfig.generalConfig.crane[`${hardwareDOFMode}Presets`] || [];

        const cranePreset = cranePresets.find(cranePreset => cranePreset.name === preset);
        if(!cranePreset) return;

        cranePreset.commands.forEach(command => {
            DeviceConnectionManager.sendCommand(command);
        });
    }
}

export default new ScratchLinkJSRunner(); //Singleton ScratchLinkJSRunner