import React from 'react';

import {
    BatteryConsoleDecorator,
    ConfigConsoleDecorator,
    DeviceInfoConsoleDecorator,
    WifiConnectedConsoleDecorator,
    EventConsoleDecorator,
    UltraConsoleDecorator,
    VAConsoleDecorator,
    SensorConsoleDecorator,
    StreamConsoleDecorator,
    NormalConsoleDecorator,
    JSLibraryErrorConsoleDecorator,
    JSErrorConsoleDecorator,
    JSSystemConsoleDecorator,
    ScratchLinkOutgoingConsoleDecorator
} from './Decorators';

import './ConsoleDecorator.scss';

export interface ConsoleDecoratorProps {
    className: string;
    style?: React.CSSProperties;
    msg: string;
    timeStamp: string;
    decorator?: ConsoleDecoratorNames;
    shouldDecorate: boolean;
}

export interface ConsoleDecoratorChildProps {
    msgObject: any;
}

const identifyConsoleDecorator = (msg: string): [IConsoleDecorator, any] | [undefined, undefined] => {
    try {
        const msgObject: any = JSON.parse(msg);

        const consoleDecoratorNamesArr = Object.keys(ConsoleDecorators);
        
        for(let i=0; i<consoleDecoratorNamesArr.length; i++) {
            //@ts-ignore
            if(ConsoleDecorators[consoleDecoratorNamesArr[i]].decoratorTest(msgObject)) return [ConsoleDecorators[consoleDecoratorNamesArr[i]], msgObject];
        }

        return [undefined, undefined];
    } catch(e) {
        return [undefined, undefined];
    }
}

const ConsoleDecorator: React.FC<ConsoleDecoratorProps> = ({ className, style = {},  msg, timeStamp, decorator, shouldDecorate }) => {
    //Attempt to identify decorator automatically
    let [ThisConsoleDecorator, msgObject] = identifyConsoleDecorator(msg);

    //If no decorator found, use normal decorator
    if(!ThisConsoleDecorator) {
        ThisConsoleDecorator = ConsoleDecorators['NormalConsoleDecorator'];
        msgObject = msg;
    }

    //If decorator is specified, use that decorator
    if(decorator) {
        ThisConsoleDecorator = ConsoleDecorators[decorator];
        msgObject = msg;
    }

    //Get class for console
    const consoleClass = ThisConsoleDecorator?.consoleClass(msgObject);

    //Cut off hours section if has not reached hours yet
    let newTimeStamp = timeStamp[0] === '0' ? timeStamp.substring(3, timeStamp.length) : timeStamp;

    //Only show single digit mins if not into 10's yet
    if(newTimeStamp[0] === '0') newTimeStamp = newTimeStamp.substring(1, newTimeStamp.length);

    return (
        <div className={`${className} ${shouldDecorate? '' : consoleClass}`} style={style}>
            <span className="c-console__logTimestamp">{newTimeStamp}</span>
            {
                shouldDecorate || !ThisConsoleDecorator? msg : ThisConsoleDecorator && msgObject !== undefined && <ThisConsoleDecorator msgObject={msgObject} />
            }
        </div>
    )
};

export interface IConsoleDecorator extends React.FunctionComponent<ConsoleDecoratorChildProps> {
    decoratorTest: (msgObject: any) => boolean;
    consoleClass: (msgObject: any) => string;
}

//Will evaluate a match for each decorator in order of this list
export const ConsoleDecorators = {
    BatteryConsoleDecorator,
    ConfigConsoleDecorator,
    DeviceInfoConsoleDecorator,
    WifiConnectedConsoleDecorator,
    EventConsoleDecorator,
    UltraConsoleDecorator,
    VAConsoleDecorator,
    StreamConsoleDecorator,
    SensorConsoleDecorator,
    NormalConsoleDecorator,
    JSLibraryErrorConsoleDecorator,
    JSErrorConsoleDecorator,
    JSSystemConsoleDecorator,
    ScratchLinkOutgoingConsoleDecorator
}

export type ConsoleDecoratorNames = keyof typeof ConsoleDecorators;

export default ConsoleDecorator;