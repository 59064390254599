import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState, selectorGetBackpackSelectedFolderName, selectorGetMatrixEditor } from 'store';

import MatrixEditor from 'containers/MatrixEditor/MatrixEditor';
import Backpack from 'containers/Backpack/Backpack';
import EditorTools from 'containers/EditorTools/EditorTools';
import AnimationControls from 'containers/AnimationControls/AnimationControls';

import './CodingMatrixEditor.scss';

type OwnProps = {
    codingProjectIndex: number;
    fileIndex: number;
}

type Props = PropsFromRedux & OwnProps;

const CodingMatrixEditor: React.FC<Props> = (props) => {
    return (
        <div className="c-matrixPage">
            <div className="c-matrixPage__left">
                <div className="c-matrixPage__left-top">
                    <h5 className="c-matrixPage__backpackPageTitle">{props.selectedFolderId !== 'root' ? `${props.selectedFolderName} Animation` : props.selectedFolderName}</h5>
                    <Backpack codingProjectIndex={props.codingProjectIndex} fileIndex={props.fileIndex} allowNavigation={true} allDragging={true} showRootFolder={true} />
                </div>
                <div className="c-matrixPage__left-bottom">
                    <EditorTools codingProjectIndex={props.codingProjectIndex} fileIndex={props.fileIndex} />
                </div>
            </div>
            <div className="c-matrixPage__right">
                <MatrixEditor codingProjectIndex={props.codingProjectIndex} fileIndex={props.fileIndex} />
                <AnimationControls codingProjectIndex={props.codingProjectIndex} fileIndex={props.fileIndex} />
            </div>
        </div>
    )
}
const mapStateToProps = (state: IRootState, ownProps: OwnProps): StateProps => {
    return {
        selectedFolderName: selectorGetBackpackSelectedFolderName(ownProps.codingProjectIndex, ownProps.fileIndex)(state),
        selectedFolderId: selectorGetMatrixEditor(ownProps.codingProjectIndex, ownProps.fileIndex)(state).backpack.selectedFolderId,
        allFolderIds: selectorGetMatrixEditor(ownProps.codingProjectIndex, ownProps.fileIndex)(state).backpack.folders.allIds
    }
}

const mapDispatchToProps = {
}

interface StateProps {
    selectedFolderName: string;
    selectedFolderId: string;
    allFolderIds: string[];
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CodingMatrixEditor);