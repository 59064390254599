import { IStoreImportAction } from '../';
import scratchlinkConfig from 'scratchlinkcodes-config/config.json';
import { isEqual } from 'lodash';
import { ScratchLinkGamepad, ScratchLinkGamepadData } from 'lib/GamepadConnection/GamepadData';

const GAMEPAD_TYPES = require('scratchlink-projects-data/constants').GAMEPAD_TYPES; 

export const PROJECT_SET_PAGE_DIMENSIONS = 'PROJECT/SET_PAGE_DIMENSIONS';
export const PROJECT_SET_RIGHT_PANEL_DIMENSIONS = 'PROJECT/SET_RIGHT_PANEL_DIMENSIONS';
export const PROJECT_SET_CODING_NAVBAR_COLLAPSED = 'PROJECT/SET_CODING_NAVBAR_COLLAPSED';
export const PROJECT_SET_CODING_HELP_POPOUT_SELECTED_SECTION_INDEX = 'PROJECT/SET_CODING_HELP_POPOUT_SELECTED_SECTION_INDEX';
export const PROJECT_SET_DEVICE_CONNECTED = 'PROJECT/SET_DEVICE_CONNECTED';
export const PROJECT_SET_DEVICE_CONNECTING = 'PROJECT/SET_DEVICE_CONNECTING';
export const PROJECT_SET_DEVICE_CONNECTION_METHOD = 'PROJECT/SET_DEVICE_CONNECTION_METHOD';
export const PROJECT_SET_DEVICE_DIRECT_IP = 'PROJECT/SET_DEVICE_DIRECT_IP';
export const PROJECT_SET_DEVICE_SERIAL_PORT = 'PROJECT/SET_DEVICE_SERIAL_PORT';
export const PROJECT_SET_DEVICE_NAME = 'PROJECT/SET_DEVICE_NAME';
export const PROJECT_SET_DEVICE_BATTERY_VOLTAGE = 'PROJECT/SET_DEVICE_BATTERY_VOLTAGE';
export const PROJECT_SET_HARDWARE_MODE = 'PROJECT/SET_HARDWARE_MODE';
export const PROJECT_SET_BLOCK_LEVEL_MODE = 'PROJECT/SET_BLOCK_LEVEL_MODE';
export const PROJECT_SET_HARDWARE_MODE_AUTO_DETECTED = 'PROJECT/SET_HARDWARE_MODE_AUTO_DETECTED';
export const PROJECT_SET_GAMEPAD_CONNECTED = 'PROJECT/SET_GAMEPAD_CONNECTED';
export const PROJECT_SET_GAMEPAD_DATA = 'PROJECT/SET_GAMEPAD_DATA';
export const PROJECT_SET_CONFIG_PAGE_SETTING_VALUE = 'PROJECT/SET_CONFIG_PAGE_SETTING_VALUE';
export const PROJECT_RESET_CONFIG_PAGE_SETTING_VALUES = 'PROJECT/RESET_CONFIG_PAGE_SETTING_VALUES';
export const PROJECT_SET_SCRATCHLINK_CONFIG = 'PROJECT/SET_SCRATCHLINK_CONFIG';

export enum BlockLevelModes {
    Beginner = 'Beginner',
    Core = 'Core',
    Advanced = 'Advanced'
}

export interface IProjectSetPageDimensionsAction {
    type: typeof PROJECT_SET_PAGE_DIMENSIONS;
    pageDimensions: IDivDimensions;
}

export interface IProjectSetRightPanelDimensionsAction {
    type: typeof PROJECT_SET_RIGHT_PANEL_DIMENSIONS;
    rightPanelDimensions: IDivDimensions;
}

export interface IProjectSetCodingNavBarCollapsedAction {
    type: typeof PROJECT_SET_CODING_NAVBAR_COLLAPSED;
    collapsed: boolean;
}

export interface IProjectSetCodingHelpPopoutSelectedSectionIndex {
    type: typeof PROJECT_SET_CODING_HELP_POPOUT_SELECTED_SECTION_INDEX;
    index: number | null;
}

export interface IProjectSetDeviceConnected {
    type: typeof PROJECT_SET_DEVICE_CONNECTED;
    deviceConnected: boolean;
}

export interface IProjectSetDeviceConnecting {
    type: typeof PROJECT_SET_DEVICE_CONNECTING;
    deviceConnecting: boolean;
}

export interface IProjectSetDeviceConnectionMethod {
    type: typeof PROJECT_SET_DEVICE_CONNECTION_METHOD;
    deviceConnectionMethod: IDeviceConnectionMethod;
}

export interface IProjectSetDeviceDirectIp {
    type: typeof PROJECT_SET_DEVICE_DIRECT_IP;
    deviceDirectIp: string;
}

export interface IProjectSetDeviceSerialPort {
    type: typeof PROJECT_SET_DEVICE_SERIAL_PORT;
    serialPort: string;
}

export interface IProjectSetDeviceName {
    type: typeof PROJECT_SET_DEVICE_NAME;
    name: string;
}

export interface IProjectSetDeviceBatteryVoltage {
    type: typeof PROJECT_SET_DEVICE_BATTERY_VOLTAGE;
    voltage: number;
}

export interface IProjectSetHardwareMode {
    type: typeof PROJECT_SET_HARDWARE_MODE;
    hardwareMode: string;
}

export interface IProjectSetBlockLevelMode {
    type: typeof PROJECT_SET_BLOCK_LEVEL_MODE;
    blockLevelMode: keyof typeof BlockLevelModes;
}

export interface IProjectSetHardwareModeAutoDetected {
    type: typeof PROJECT_SET_HARDWARE_MODE_AUTO_DETECTED;
    mode: string;
}

export interface IProjectSetGamepadConnected {
    type: typeof PROJECT_SET_GAMEPAD_CONNECTED;
    gamepadIndex: number;
    gamepadConnected: boolean;
    gamepadType: GamePadTypes;
}

export interface IProjectSetGamepadData {
    type: typeof PROJECT_SET_GAMEPAD_DATA;
    gamepadIndex: number;
    gamepadData: ScratchLinkGamepadData;
}

export interface IProjectSetConfigPageSettingValue {
    type: typeof PROJECT_SET_CONFIG_PAGE_SETTING_VALUE;
    setting: IConfigPageSetting;
    value: IConfigPageValue;
}

export interface IProjectResetConfigPageSettingValues {
    type: typeof PROJECT_RESET_CONFIG_PAGE_SETTING_VALUES;
}

export interface IProjectSetScratchLinkConfig {
    type: typeof PROJECT_SET_SCRATCHLINK_CONFIG;
    config: string;
}

export type IProjectActions = IStoreImportAction | IProjectSetPageDimensionsAction | IProjectSetRightPanelDimensionsAction | IProjectSetCodingNavBarCollapsedAction | IProjectSetCodingHelpPopoutSelectedSectionIndex | IProjectSetDeviceConnected | IProjectSetDeviceConnecting | IProjectSetDeviceConnectionMethod | IProjectSetDeviceDirectIp | IProjectSetDeviceSerialPort | IProjectSetHardwareMode | IProjectSetBlockLevelMode | IProjectSetHardwareModeAutoDetected | IProjectSetGamepadConnected | IProjectSetGamepadData | IProjectSetDeviceName | IProjectSetDeviceBatteryVoltage | IProjectSetConfigPageSettingValue | IProjectResetConfigPageSettingValues | IProjectSetScratchLinkConfig;

export type IDeviceConnectionType = 'websocket' | 'serial' | 'modem';

export type IDeviceConnectionMethod = 'none' | 'ap' | 'ip' | 'serial' | 'modem';

export type IDeviceConnectionIp = 'none' | string;

export interface IDivDimensions {
    width: number;
    height: number;
}

export const HardwareModes: {[profile: string]: string} = scratchlinkConfig.deviceProfiles.reduce(
    (prev, profile) => ({
        ...prev, 
        [profile.profile]: profile.displayName 
    }), 
    {
        AUTO: 'Auto'
    }
);

export const getHardwareModeName = (id: string): ValueOf<typeof HardwareModes> => (id.startsWith('Auto') ? id : (HardwareModes as any)[id]);

export const getBlockLevelModeName = (id: string): ValueOf<typeof BlockLevelModes> => ((BlockLevelModes as any)[id]);

export enum GamePadTypesEnum {
    no_gamepad = 'No Gamepad',
    t3 = "T3 Bluetooth GamePad",
    xbox = 'Xbox 360 GamePad',
    retro = 'Retro GamePad'
}

export type GamePadTypes = keyof typeof GamePadTypesEnum;

if(!isEqual(Object.entries(GamePadTypesEnum), Object.entries(GAMEPAD_TYPES))) throw new Error('GamePadTypesEnum and GAMEPAD_TYPES are out of sync'); 

const wifiChannels = ['auto','1','2','3','4','5','6','7','8','9','10','11','12'] as const;
export type WifiChannels = typeof wifiChannels[number];

type ConfigPageSettingServo = [number, number];

export interface IConfigPageSettingValues {
    deviceName?: string;
    wifiMode?: 'AccessPoint' | 'Station' | 'Off';
    wifiSSID?: string;
    wifiPassword?: string;
    wifiIP?: string;
    wifiChannel?: WifiChannels;
    lineLHSWhite?: number;
    lineRHSWhite?: number;
    lineLHSBlack?: number;
    lineRHSBlack?: number;
    ultraLHSMin?: number;
    ultraLHSMax?: number;
    ultraRHSMin?: number;
    ultraRHSMax?: number;
    craneProximityLHSCutoff?: number;
    craneProximityRHSCutoff?: number;
    wheelsCircum?: number;
    wheelsTrack?: number;
    tcpSocketEnabled?: boolean;
    tcpSocketIP?: string;
    htmlEnabled?: boolean;
    servo0?: ConfigPageSettingServo;
    servo1?: ConfigPageSettingServo;
    servo2?: ConfigPageSettingServo;
    servo3?: ConfigPageSettingServo;
    servo4?: ConfigPageSettingServo;
    servo5?: ConfigPageSettingServo;
    servo6?: ConfigPageSettingServo;
    securityKey?: string;
    serialNumber?: string;
    securityKeyEnabled?: boolean;
    esp32FirmwareVersion?: string;
    nanoFirmwareVersion?: string;
    rfidVersion?: string;
}

export type IConfigPageSetting = Exclude<keyof IConfigPageSettingValues, undefined>;
export type IConfigPageValue = Exclude<IConfigPageSettingValues[keyof IConfigPageSettingValues], undefined>;

export interface IProjectState {
    pageDimensions: IDivDimensions;
    rightPanelDimensions: IDivDimensions;
    codingNavBarCollapsed: boolean;
    codingHelpPopoutSelectedSectionIndex: null | number;
    deviceConnected: boolean;
    deviceConnecting: boolean;
    deviceConnectionMethod: IDeviceConnectionMethod;
    deviceDirectIp: string;
    deviceSerialPort: string;
    deviceName: string;
    deviceBatteryVoltage: number;
    deviceBatteryPercent: number | null;
    hardwareMode: string;
    blockLevelMode: keyof typeof BlockLevelModes;
    hardwareModeAutoDetected: string;
    gamepad0: ScratchLinkGamepad;
    gamepad1: ScratchLinkGamepad;
    configPageSettingValues: IConfigPageSettingValues;
    scratchlinkConfig: any;
}