import { createSelector } from 'reselect';
import { IRootState, HardwareModes } from 'store';

export const selectorGetProjectHardwareModeDisplayName = createSelector(
    [
        (state: IRootState) => state.project.hardwareMode,
        (state: IRootState) => state.project.hardwareModeAutoDetected
    ],
    (hardwareMode, hardwareModeAutoDetected): string => {
        if(hardwareMode === 'AUTO') {
            if(hardwareModeAutoDetected === 'none') return 'Auto';
            const hardwareName = HardwareModes[hardwareModeAutoDetected]; //Get hardware name and use that, else use the raw profile value from firmware
            return `Auto (${hardwareName ? hardwareName : hardwareModeAutoDetected})`;
        }
        return hardwareMode;
    }
);