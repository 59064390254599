import React from 'react';
import { ICodingFileType } from 'store';

import CodeEditor from 'containers/CodeEditor/CodeEditor';
import CodingEventButtons from 'containers/CodingEventButtons/CodingEventButtons';
import CodingGlobalVariables from 'containers/CodingGlobalVariables/CodingGlobalVariables';
import CodingGlobalVariablesJS4Scratch from 'containers/CodingGlobalVariablesJS4Scratch/CodingGlobalVariablesJS4Scratch';
import CodingMatrixEditor from 'containers/CodingMatrixEditor/CodingMatrixEditor';
import CodingAssetManager from 'containers/CodingAssetManager/CodingAssetManager';
import CodingImageManager from 'containers/CodingImageManager/CodingImageManager';
import CodingFlowChartEditor from 'containers/CodingFlowChartEditor/CodingFlowChartEditor';

interface Props {
    codingProjectIndex: number;
    fileIndex: number;
    fileType: ICodingFileType;
}

const CodingFileContent: React.FC<Props> = ({ codingProjectIndex, fileIndex, fileType }) => {
    return (
        <>
            {
                fileType === 'js' && (
                    <CodeEditor codingProjectIndex={codingProjectIndex} fileIndex={fileIndex} />
                )
            }
            {
                fileType === 'py' && (
                    <CodeEditor codingProjectIndex={codingProjectIndex} fileIndex={fileIndex} />
                )
            }
            {
                fileType === 'html' && (
                    <CodeEditor codingProjectIndex={codingProjectIndex} fileIndex={fileIndex} />
                )
            }
            {
                fileType === 'eventButtons' && (
                    <CodingEventButtons codingProjectIndex={codingProjectIndex} fileIndex={fileIndex} />
                )
            }
            {
                fileType === 'text' && (
                    <CodeEditor codingProjectIndex={codingProjectIndex} fileIndex={fileIndex} />
                )
            }
            {
                fileType === 'globalVars' && (
                    <CodingGlobalVariables codingProjectIndex={codingProjectIndex} fileIndex={fileIndex} />
                )
            }
            {
                fileType === 'globalVarsJS4Scratch' && (
                    <CodingGlobalVariablesJS4Scratch codingProjectIndex={codingProjectIndex} fileIndex={fileIndex} />
                )
            }
            {
                fileType === 'matrixEditor' && (
                    <CodingMatrixEditor codingProjectIndex={codingProjectIndex} fileIndex={fileIndex} />
                )
            }
            {
                fileType === 'assetManager' && (
                    <CodingAssetManager codingProjectIndex={codingProjectIndex} fileIndex={fileIndex} />
                )
            }
            {
                fileType === 'imageManager' && (
                    <CodingImageManager codingProjectIndex={codingProjectIndex} fileIndex={fileIndex} />
                )
            }
            {
                fileType === 'flowChart' && (
                    <CodingFlowChartEditor codingProjectIndex={codingProjectIndex} fileIndex={fileIndex} />
                )
            }
        </>
    )
};

export default CodingFileContent;