import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState } from 'store';
import { toast } from 'react-toastify';

import { DeviceConnectionManager } from 'lib';

import Slider, { SliderValue } from 'components/Slider/Slider';
import InputNumber from 'components/InputNumber/InputNumber';

type SelectorSet = React.Dispatch<React.SetStateAction<number[]>>;

const CraneTypes = [
    '1DOF',
    '2DOF',
    '3DOF',
    '5DOF',
    '7DOF',
    'none'
] as const;

type CraneType = typeof CraneTypes[number];

type Props = PropsFromRedux & {

}

const ConfigCrane: React.FC<Props> = ({ scratchlinkConfig, hardwareModeAutoDetected, deviceServos, deviceConnected, deviceConnectionMethod }) => {
    const craneMin: number = scratchlinkConfig.configPage.crane.CraneMin;
    const craneMax: number = scratchlinkConfig.configPage.crane.CraneMax;
    const minMaxStepComponentProps = {
        min: craneMin,
        max: craneMax,
        step: 25
    }

    const defaultMinMax: number[] = [
        scratchlinkConfig.configPage.defaults.CraneMin,
        scratchlinkConfig.configPage.defaults.CraneMax
    ];

    const [flipperLeft, setFlipperLeft] = useState(defaultMinMax);
    const [flipperRight, setFlipperRight] = useState(defaultMinMax);
    const [claw, setClaw] = useState(defaultMinMax);
    const [wrist, setWrist] = useState(defaultMinMax);
    const [wristRotate, setWristRotate] = useState(defaultMinMax);
    const [elbowRotate, setElbowRotate] = useState(defaultMinMax);
    const [elbow, setElbow] = useState(defaultMinMax);
    const [shoulder, setShoulder] = useState(defaultMinMax);
    const [baseRotate, setBaseRotate] = useState(defaultMinMax);

    const [sendPWMCommand, setSendPWMCommand] = useState<undefined | ReturnType<typeof setTimeout>>(); //Debounce

    const joints = {
        "Claw": setClaw,
        "Wrist Rotate": setWristRotate,
        "Wrist": setWrist,
        "Elbow Rotate": setElbowRotate,
        "Elbow": setElbow,
        "Shoulder": setShoulder,
        "Base Rotate": setBaseRotate
    }

    const moveJoint = (set: SelectorSet, sliderVal: number[], index: number) => {
        const value = sliderVal as number[];

        const servoNo = getServoNoFromSetter(set);
        if(servoNo === undefined) throw new Error(`Could not find servo no. from setter`);
        const pin = scratchlinkConfig.configPage.crane.servoPins[servoNo];

        if(sendPWMCommand) clearTimeout(sendPWMCommand);
        setSendPWMCommand(setTimeout(() => {
            DeviceConnectionManager.sendCommand(`servo${servoNo} config set ${pin} ${value[0]} ${value[1]}; servo${servoNo} pwm ${value[index]};`)
        }, 75))
    }

    const onMinChange = (set: SelectorSet, sliderVal: number[], inputNum: number | undefined) => {
        if(inputNum) set([
            inputNum, 
            sliderVal[1]
        ]);
        moveJoint(set, sliderVal, 0);
    }

    const onMaxChange = (set: SelectorSet, sliderVal: number[], inputNum: number | undefined) => {
        if(inputNum) set([
            sliderVal[0],
            inputNum
        ]);
        moveJoint(set, sliderVal, 1);
    }

    const getCraneType = (): CraneType => {
        const scratchLinkProfile = scratchlinkConfig.deviceProfiles.find((deviceProfile: any) => deviceProfile.profile === hardwareModeAutoDetected); //Get profile
        if(!scratchLinkProfile) return '7DOF'; //Did not find a valid profile so display all joints
        if(scratchLinkProfile.crane === undefined) return 'none';
        if(CraneTypes.includes(scratchLinkProfile.crane)) return scratchLinkProfile.crane; //If found a defined crane type then use that as it is valid
        return '7DOF'; //Not found anything then display all
    }

    const craneJoints: string[] = scratchlinkConfig.configPage.crane[getCraneType()];

    const getJointNameFromSetter = (setter: SelectorSet): keyof typeof joints | undefined => (Object.keys(joints) as (keyof typeof joints)[]).find(jointName => joints[jointName] === setter);

    const getServoNoFromSetter = (setter: SelectorSet): 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined => {
        const jointName = getJointNameFromSetter(setter);
        if(jointName === undefined) return undefined

        return craneJoints.indexOf(jointName) as any;
    }

    useEffect(() => {
        //@ts-ignore weird hacky fuckery
        const getDeviceServoSettingFromSetter = (setter: SelectorSet) => deviceServos[`servo${getServoNoFromSetter(setter)}`];

        if(sendPWMCommand) return; //Do not reset if command is being sent. Important
        if(!craneJoints) return; //No crane for device

        //Get values from device
        setClaw(getDeviceServoSettingFromSetter(setClaw) || defaultMinMax);
        setWristRotate(getDeviceServoSettingFromSetter(setWristRotate) || defaultMinMax);
        setWrist(getDeviceServoSettingFromSetter(setWrist) || defaultMinMax);
        setElbowRotate(getDeviceServoSettingFromSetter(setElbowRotate) || defaultMinMax);
        setElbow(getDeviceServoSettingFromSetter(setElbow) || defaultMinMax);
        setShoulder(getDeviceServoSettingFromSetter(setShoulder) || defaultMinMax);
        setBaseRotate(getDeviceServoSettingFromSetter(setBaseRotate) || defaultMinMax);
    }, [deviceServos]);

    useEffect(() => {
        DeviceConnectionManager.sendCommand(`servo config info;`);
        toast.warn(`eBot needs to be connected with USB Serial to change crane min-max settings`);
    }, [deviceConnected])

    const onSliderChange = (set: SelectorSet, sliderVal: SliderValue, index: number) => {
        const value = sliderVal as number[];
        set(value);

        moveJoint(set, value, index);
    }

    const onFlipperLeftSliderChange = (sliderVal: SliderValue, index: number) => {
        const value = sliderVal as number[];
        setFlipperLeft(value);

        if(sendPWMCommand) clearTimeout(sendPWMCommand);
        setSendPWMCommand(setTimeout(() => {
            DeviceConnectionManager.sendCommand(`servo0 config set 32 ${value[0]} ${value[1]}; servo0 pwm ${value[index]};`)
        }, 75))
    }

    const onFlipperRightSliderChange = (sliderVal: SliderValue, index: number) => {
        const value = sliderVal as number[];
        setFlipperRight(value);

        if(sendPWMCommand) clearTimeout(sendPWMCommand);
        setSendPWMCommand(setTimeout(() => {
            DeviceConnectionManager.sendCommand(`servo1 config set 33 ${value[0]} ${value[1]}; servo1 config reverse yes; servo1 pwm ${value[index]};`)
        }, 75))
    }

    const disabled = !deviceConnected || (deviceConnectionMethod !== 'serial' && deviceConnectionMethod !== 'modem');

    if(!craneJoints) return <div></div>;

    return (
        <div className="p-configPage__tableContainer">
            <table className="p-configPage__table">
                <tbody>
                    <tr className="p-configPage__tableHeader">
                        <th>Crane Joint</th>
                        <th>Min - Max</th>
                    </tr>
                    {
                        craneJoints.includes('Flipper') && (
                            <>
                                <tr className="p-configPage__tableRow">
                                    <td className="p-configPage__tableSettingNameCrane">
                                        Flipper Left
                                    </td>
                                    <td className="p-configPage__tableSettingValueCrane">
                                        <InputNumber disabled={disabled} {...minMaxStepComponentProps} value={flipperLeft[0]} onInputNumberChange={inputNum => onMinChange(setFlipperLeft, flipperLeft, inputNum)}  />
                                        <Slider disabled={disabled} {...minMaxStepComponentProps} value={flipperLeft} defaultValue={flipperLeft} onChange={(sliderVal, index) => onFlipperLeftSliderChange(sliderVal, index)} />
                                        <InputNumber disabled={disabled} {...minMaxStepComponentProps} value={flipperLeft[1]} onInputNumberChange={inputNum => onMaxChange(setFlipperLeft, flipperLeft, inputNum)}  />
                                    </td>
                                    </tr>
                                <tr className="p-configPage__tableRow">
                                    <td className="p-configPage__tableSettingNameCrane">
                                        Flipper Right
                                    </td>
                                    <td className="p-configPage__tableSettingValueCrane">
                                        <InputNumber disabled={disabled} {...minMaxStepComponentProps} value={flipperRight[0]} onInputNumberChange={inputNum => onMinChange(setFlipperRight, flipperRight, inputNum)}  />
                                        <Slider disabled={disabled} {...minMaxStepComponentProps} value={flipperRight} defaultValue={flipperRight} onChange={(sliderVal, index) => onFlipperRightSliderChange(sliderVal, index)} />
                                        <InputNumber disabled={disabled} {...minMaxStepComponentProps} value={flipperRight[1]} onInputNumberChange={inputNum => onMaxChange(setFlipperRight, flipperRight, inputNum)}  />
                                    </td>
                                </tr>
                            </>
                        )
                    }
                    {
                        craneJoints.includes('Claw') && (
                            <tr className="p-configPage__tableRow">
                                <td className="p-configPage__tableSettingNameCrane">
                                    Claw
                                </td>
                                <td className="p-configPage__tableSettingValueCrane">
                                    <InputNumber disabled={disabled} {...minMaxStepComponentProps} value={claw[0]} onInputNumberChange={inputNum => onMinChange(setClaw, claw, inputNum)}  />
                                    <Slider disabled={disabled} {...minMaxStepComponentProps} value={claw} defaultValue={claw} onChange={(sliderVal, index) => onSliderChange(setClaw, sliderVal, index)} />
                                    <InputNumber disabled={disabled} {...minMaxStepComponentProps} value={claw[1]} onInputNumberChange={inputNum => onMaxChange(setClaw, claw, inputNum)}  />
                                </td>
                            </tr>
                        )
                    }
                    {
                        craneJoints.includes('Wrist Rotate') && (
                            <tr className="p-configPage__tableRow">
                                <td className="p-configPage__tableSettingNameCrane">
                                    Wrist Rotate
                                </td>
                                <td className="p-configPage__tableSettingValueCrane">
                                    <InputNumber disabled={disabled} {...minMaxStepComponentProps} value={wristRotate[0]} onInputNumberChange={inputNum => onMinChange(setWristRotate, wristRotate, inputNum)}  />
                                    <Slider disabled={disabled} {...minMaxStepComponentProps} value={wristRotate} defaultValue={wristRotate} onChange={(sliderVal, index) => onSliderChange(setWristRotate, sliderVal, index)} />
                                    <InputNumber disabled={disabled} {...minMaxStepComponentProps} value={wristRotate[1]} onInputNumberChange={inputNum => onMaxChange(setWristRotate, wristRotate, inputNum)}  />
                                </td>
                            </tr>
                        )
                    }
                    {
                        craneJoints.includes('Wrist') && (
                            <tr className="p-configPage__tableRow">
                                <td className="p-configPage__tableSettingNameCrane">
                                    Wrist
                                </td>
                                <td className="p-configPage__tableSettingValueCrane">
                                    <InputNumber disabled={disabled} {...minMaxStepComponentProps} value={wrist[0]} onInputNumberChange={inputNum => onMinChange(setWrist, wrist, inputNum)}  />
                                    <Slider disabled={disabled} {...minMaxStepComponentProps} value={wrist} defaultValue={wrist} onChange={(sliderVal, index) => onSliderChange(setWrist, sliderVal, index)} />
                                    <InputNumber disabled={disabled} {...minMaxStepComponentProps} value={wrist[1]} onInputNumberChange={inputNum => onMaxChange(setWrist, wrist, inputNum)}  />
                                </td>
                            </tr>
                        )
                    }
                    {
                        craneJoints.includes('Elbow Rotate') && (
                            <tr className="p-configPage__tableRow">
                                <td className="p-configPage__tableSettingNameCrane">
                                    Elbow Rotate
                                </td>
                                <td className="p-configPage__tableSettingValueCrane">
                                    <InputNumber disabled={disabled} {...minMaxStepComponentProps} value={elbowRotate[0]} onInputNumberChange={inputNum => onMinChange(setElbowRotate, elbowRotate, inputNum)}  />
                                    <Slider disabled={disabled} {...minMaxStepComponentProps} value={elbowRotate} defaultValue={elbowRotate} onChange={(sliderVal, index) => onSliderChange(setElbowRotate, sliderVal, index)} />
                                    <InputNumber disabled={disabled} {...minMaxStepComponentProps} value={elbowRotate[1]} onInputNumberChange={inputNum => onMaxChange(setElbowRotate, elbowRotate, inputNum)}  />
                                </td>
                            </tr>
                        )
                    }
                    {
                        craneJoints.includes('Elbow') && (
                            <tr className="p-configPage__tableRow">
                                <td className="p-configPage__tableSettingNameCrane">
                                    Elbow
                                </td>
                                <td className="p-configPage__tableSettingValueCrane">
                                    <InputNumber disabled={disabled} {...minMaxStepComponentProps} value={elbow[0]} onInputNumberChange={inputNum => onMinChange(setElbow, elbow, inputNum)}  />
                                    <Slider disabled={disabled} {...minMaxStepComponentProps} value={elbow} defaultValue={elbow} onChange={(sliderVal, index) => onSliderChange(setElbow, sliderVal, index)} />
                                    <InputNumber disabled={disabled} {...minMaxStepComponentProps} value={elbow[1]} onInputNumberChange={inputNum => onMaxChange(setElbow, elbow, inputNum)}  />
                                </td>
                            </tr>
                        )
                    }
                    {
                        craneJoints.includes('Shoulder') && (
                            <tr className="p-configPage__tableRow">
                                <td className="p-configPage__tableSettingNameCrane">
                                    Shoulder
                                </td>
                                <td className="p-configPage__tableSettingValueCrane">
                                    <InputNumber disabled={disabled} {...minMaxStepComponentProps} value={shoulder[0]} onInputNumberChange={inputNum => onMinChange(setShoulder, shoulder, inputNum)}  />
                                    <Slider disabled={disabled} {...minMaxStepComponentProps} value={shoulder} defaultValue={shoulder} onChange={(sliderVal, index) => onSliderChange(setShoulder, sliderVal, index)} />
                                    <InputNumber disabled={disabled} {...minMaxStepComponentProps} value={shoulder[1]} onInputNumberChange={inputNum => onMaxChange(setShoulder, shoulder, inputNum)}  />
                                </td>
                            </tr>
                        )
                    }
                    {
                        craneJoints.includes('Base Rotate') && (
                            <tr className="p-configPage__tableRow">
                                <td className="p-configPage__tableSettingNameCrane">
                                    Base Rotate
                                </td>
                                <td className="p-configPage__tableSettingValueCrane">
                                    <InputNumber disabled={disabled} {...minMaxStepComponentProps} value={baseRotate[0]} onInputNumberChange={inputNum => onMinChange(setBaseRotate, baseRotate, inputNum)}  />
                                    <Slider disabled={disabled} {...minMaxStepComponentProps} value={baseRotate} defaultValue={baseRotate} onChange={(sliderVal, index) => onSliderChange(setBaseRotate, sliderVal, index)} />
                                    <InputNumber disabled={disabled} {...minMaxStepComponentProps} value={baseRotate[1]} onInputNumberChange={inputNum => onMaxChange(setBaseRotate, baseRotate, inputNum)}  />
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}

const mapStateToProps = (state: IRootState) => {
    return {
        scratchlinkConfig: state.project.scratchlinkConfig,
        hardwareModeAutoDetected: state.project.hardwareModeAutoDetected,
        deviceServos: {
            servo0: state.project.configPageSettingValues.servo0,
            servo1: state.project.configPageSettingValues.servo1,
            servo2: state.project.configPageSettingValues.servo2,
            servo3: state.project.configPageSettingValues.servo3,
            servo4: state.project.configPageSettingValues.servo4,
            servo5: state.project.configPageSettingValues.servo5,
            servo6: state.project.configPageSettingValues.servo6
        },
        deviceConnected: state.project.deviceConnected,
        deviceConnectionMethod: state.project.deviceConnectionMethod
    }
}

const mapDispatchToProps = {
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ConfigCrane);