import immer from 'immer';
import isDevelopment from 'utils/isDevelopment';
import { STORE_IMPORT } from '..';

import { ISettingsState, ISettingsActions } from '.';

import {
    SETTINGS_SET_FILENAME,
    SETTINGS_SET_REMOTE_USER_ID,
    SETTINGS_SET_JAVASCRIPT_EDITOR_TOOL_TYPE,
    SETTINGS_SET_DEVICE_DIRECT_IP_CONNECTION_IP,
    SETTINGS_SET_DEV_MODE,
    SETTINGS_SET_MONACO_FONT_SIZE
} from '.';

export const settingsInitialState: ISettingsState = {
    fileName: 'Untitled Workspace',
    remoteUserId: '',
    javascriptEditorToolType: 'auto',
    deviceDirectIpConnectionIp: '192.168.0.164',
    devMode: isDevelopment(),
    monacoFontSize: 18,
    workspaceVersion: '1.0'
}

export const settingsReducer = (state: ISettingsState = settingsInitialState, action: ISettingsActions): ISettingsState => {
    switch (action.type) {
        case SETTINGS_SET_FILENAME:
            return immer(state, (draftState: ISettingsState) => {
                draftState.fileName = action.fileName;
            });
        case SETTINGS_SET_REMOTE_USER_ID:
            return immer(state, (draftState: ISettingsState) => {
                draftState.remoteUserId = action.userId;
            });
        case SETTINGS_SET_JAVASCRIPT_EDITOR_TOOL_TYPE:
            return immer(state, (draftState: ISettingsState) => {
                draftState.javascriptEditorToolType = action.javascriptEditorToolType;
            });
        case SETTINGS_SET_DEVICE_DIRECT_IP_CONNECTION_IP:
            return immer(state, (draftState: ISettingsState) => {
                draftState.deviceDirectIpConnectionIp = action.deviceDirectIpConnectionIp;
            });
        case SETTINGS_SET_DEV_MODE:
            return immer(state, (draftState: ISettingsState) => {
                draftState.devMode = action.devMode;
            });
        case SETTINGS_SET_MONACO_FONT_SIZE:
            return immer(state, (draftState: ISettingsState) => {
                draftState.monacoFontSize = action.fontSize;
            });
        
        case STORE_IMPORT:
            return action.data.settings;
        default:
            return { ...state };
    }
}