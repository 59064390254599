import {
    ScratchLinkGamepadDataStandard,
    ScratchLinkGamepadDataSlClassic,
    ScratchLinkGamepadDataSlNunchuk,
    ScratchLinkGamepadDataRetro
} from '.';

export const scratchLinkGamepadEventsStandardArray = [
    'Y Button',
    'B Button',
    'A Button',
    'X Button',
    'D-Pad Left',
    'D-Pad Right',
    'D-Pad Up',
    'D-Pad Down',
    'Left Top Button',
    'Left Bottom Button',
    'Right Top Button',
    'Right Bottom Button',
    'Start',
    'Select/Back',
    'LHS Joystick Button',
    'LHS Joystick Left',
    'LHS Joystick Right',
    'LHS Joystick Up',
    'LHS Joystick Down',
    'LHS Joystick No Input',
    'RHS Joystick Button',
    'RHS Joystick Left',
    'RHS Joystick Right',
    'RHS Joystick Up',
    'RHS Joystick Down',
    'RHS Joystick No Input'
] as const;

export type ScratchLinkGamepadEventsStandard = typeof scratchLinkGamepadEventsStandardArray[number];

export const scratchLinkGamepadEventsSlClassicArray = [
    'Red Button',
    'White Button',
    'Black Button',
    'Blue Button',
    'Joystick Button',
    'Joystick Left',
    'Joystick Right',
    'Joystick Up',
    'Joystick Down',
    'Joystick No Input',
    'Y Button',
    'B Button',
    'A Button',
    'X Button',
    'D-Pad Left',
    'D-Pad Right',
    'D-Pad Up',
    'D-Pad Down',
    'Left Top Button',
    'Left Bottom Button',
    'Right Top Button',
    'Right Bottom Button',
    'Start',
    'Select',
    'Home',
    'LHS Joystick Left',
    'LHS Joystick Right',
    'LHS Joystick Up',
    'LHS Joystick Down',
    'LHS Joystick No Input',
    'RHS Joystick Left',
    'RHS Joystick Right',
    'RHS Joystick Up',
    'RHS Joystick Down',
    'RHS Joystick No Input'
] as const;

export type ScratchLinkGamepadEventsSlClassic = typeof scratchLinkGamepadEventsSlClassicArray[number];

export const scratchLinkGamepadEventsSlNunchukArray = [
    'Red Button',
    'White Button',
    'Black Button',
    'Blue Button',
    'Joystick Button',
    'Joystick Left',
    'Joystick Right',
    'Joystick Up',
    'Joystick Down',
    'Joystick No Input',
    'Top Button',
    'Bottom Button',
    'Nun Joystick Left',
    'Nun Joystick Right',
    'Nun Joystick Up',
    'Nun Joystick Down',
    'Nun Joystick No Input',
    'Gyro Roll Left',
    'Gyro Roll Right',
    'Gyro Pitch Down',
    'Gyro Pitch Up',
] as const;

export type ScratchLinkGamepadEventsSlNunchuk = typeof scratchLinkGamepadEventsSlNunchukArray[number];

export const scratchLinkGamepadEventsRetroArray = [
    'Y Button',
    'B Button',
    'A Button',
    'X Button',
    'D-Pad Left',
    'D-Pad Right',
    'D-Pad Up',
    'D-Pad Down',
    'Left Button',
    'Right Button',
    'Start',
    'Select',
]

export type ScratchLinkGamepadEventsRetro = typeof scratchLinkGamepadEventsRetroArray[number];

export type ScratchLinkGamepadEvents = ScratchLinkGamepadEventsStandard | ScratchLinkGamepadEventsSlClassic | ScratchLinkGamepadEventsSlNunchuk | ScratchLinkGamepadEventsRetro;

type ScratchLinkGamepadEventStandardEvalFunction = {
    [x in ScratchLinkGamepadEventsStandard]: (gamepadData: ScratchLinkGamepadDataStandard) => boolean;
}

export const scratchLinkGamepadEventsStandardEval: ScratchLinkGamepadEventStandardEvalFunction = {
    'Y Button': (gamepadData) => gamepadData.y,
    'B Button': (gamepadData) => gamepadData.b,
    'A Button': (gamepadData) => gamepadData.a,
    'X Button': (gamepadData) => gamepadData.x,
    'D-Pad Left': (gamepadData) => gamepadData.dPad.left,
    'D-Pad Right': (gamepadData) => gamepadData.dPad.right,
    'D-Pad Up': (gamepadData) => gamepadData.dPad.up,
    'D-Pad Down': (gamepadData) => gamepadData.dPad.down,
    'Left Top Button': (gamepadData) => gamepadData.bumper.leftTop,
    'Left Bottom Button': (gamepadData) => gamepadData.bumper.leftBottom,
    'Right Top Button': (gamepadData) => gamepadData.bumper.rightTop,
    'Right Bottom Button': (gamepadData) => gamepadData.bumper.rightBottom,
    'Start': (gamepadData) => gamepadData.start,
    'Select/Back': (gamepadData) => gamepadData.select,
    'LHS Joystick Button': (gamepadData) => gamepadData.lhsJoystick.digital.button,
    'LHS Joystick Left': (gamepadData) => gamepadData.lhsJoystick.digital.left,
    'LHS Joystick Right': (gamepadData) => gamepadData.lhsJoystick.digital.right,
    'LHS Joystick Up': (gamepadData) => gamepadData.lhsJoystick.digital.up,
    'LHS Joystick Down': (gamepadData) => gamepadData.lhsJoystick.digital.down,
    'LHS Joystick No Input': (gamepadData) => gamepadData.lhsJoystick.digital.noInput,
    'RHS Joystick Button': (gamepadData) => gamepadData.rhsJoystick.digital.button,
    'RHS Joystick Left': (gamepadData) => gamepadData.rhsJoystick.digital.left,
    'RHS Joystick Right': (gamepadData) => gamepadData.rhsJoystick.digital.right,
    'RHS Joystick Up': (gamepadData) => gamepadData.rhsJoystick.digital.up,
    'RHS Joystick Down': (gamepadData) => gamepadData.rhsJoystick.digital.down,
    'RHS Joystick No Input': (gamepadData) => gamepadData.rhsJoystick.digital.noInput
}

export type ScratchLinkGamepadEventSlClassicEvalFunction = {
    [x in ScratchLinkGamepadEventsSlClassic]: (gamepadData: ScratchLinkGamepadDataSlClassic) => boolean;
}

export const scratchLinkGamepadEventsSlClassicEval: ScratchLinkGamepadEventSlClassicEvalFunction = {
    'Red Button': (gamepadData) => gamepadData.red,
    'White Button': (gamepadData) => gamepadData.white,
    'Black Button': (gamepadData) => gamepadData.black,
    'Blue Button': (gamepadData) => gamepadData.blue,
    'Joystick Button': (gamepadData) => gamepadData.joystick.digital.button,
    'Joystick Left': (gamepadData) => gamepadData.joystick.digital.left,
    'Joystick Right': (gamepadData) => gamepadData.joystick.digital.right,
    'Joystick Up': (gamepadData) => gamepadData.joystick.digital.up,
    'Joystick Down': (gamepadData) => gamepadData.joystick.digital.down,
    'Joystick No Input': (gamepadData) => gamepadData.joystick.digital.noInput,
    'Y Button': (gamepadData) => gamepadData.y,
    'B Button': (gamepadData) => gamepadData.b,
    'A Button': (gamepadData) => gamepadData.a,
    'X Button': (gamepadData) => gamepadData.x,
    'D-Pad Left': (gamepadData) => gamepadData.dPad.left,
    'D-Pad Right': (gamepadData) => gamepadData.dPad.right,
    'D-Pad Up': (gamepadData) => gamepadData.dPad.up,
    'D-Pad Down': (gamepadData) => gamepadData.dPad.down,
    'Left Top Button': (gamepadData) => gamepadData.bumper.leftTop,
    'Left Bottom Button': (gamepadData) => gamepadData.bumper.leftBottom,
    'Right Top Button': (gamepadData) => gamepadData.bumper.rightTop,
    'Right Bottom Button': (gamepadData) => gamepadData.bumper.rightBottom,
    'Start': (gamepadData) => gamepadData.start,
    'Select': (gamepadData) => gamepadData.select,
    'Home': (gamepadData) => gamepadData.home,
    'LHS Joystick Left': (gamepadData) => gamepadData.lhsJoystick.digital.left,
    'LHS Joystick Right': (gamepadData) => gamepadData.lhsJoystick.digital.right,
    'LHS Joystick Up': (gamepadData) => gamepadData.lhsJoystick.digital.up,
    'LHS Joystick Down': (gamepadData) => gamepadData.lhsJoystick.digital.down,
    'LHS Joystick No Input': (gamepadData) => gamepadData.lhsJoystick.digital.noInput,
    'RHS Joystick Left': (gamepadData) => gamepadData.rhsJoystick.digital.left,
    'RHS Joystick Right': (gamepadData) => gamepadData.rhsJoystick.digital.right,
    'RHS Joystick Up': (gamepadData) => gamepadData.rhsJoystick.digital.up,
    'RHS Joystick Down': (gamepadData) => gamepadData.rhsJoystick.digital.down,
    'RHS Joystick No Input': (gamepadData) => gamepadData.rhsJoystick.digital.noInput,
};
  

export type ScratchLinkGamepadEventSlNunchukEvalFunction = {
    [x in ScratchLinkGamepadEventsSlNunchuk]: (gamepadData: ScratchLinkGamepadDataSlNunchuk) => boolean;
}

export const scratchLinkGamepadEventsSlNunchukEval: ScratchLinkGamepadEventSlNunchukEvalFunction = {
    'Red Button': (gamepadData) => gamepadData.red,
    'White Button': (gamepadData) => gamepadData.white,
    'Black Button': (gamepadData) => gamepadData.black,
    'Blue Button': (gamepadData) => gamepadData.blue,
    'Joystick Button': (gamepadData) => gamepadData.joystick.digital.button,
    'Joystick Left': (gamepadData) => gamepadData.joystick.digital.left,
    'Joystick Right': (gamepadData) => gamepadData.joystick.digital.right,
    'Joystick Up': (gamepadData) => gamepadData.joystick.digital.up,
    'Joystick Down': (gamepadData) => gamepadData.joystick.digital.down,
    'Joystick No Input': (gamepadData) => gamepadData.joystick.digital.noInput,
    'Top Button': (gamepadData) => gamepadData.bumper.top,
    'Bottom Button': (gamepadData) => gamepadData.bumper.bottom,
    'Nun Joystick Left': (gamepadData) => gamepadData.nunJoystick.digital.left,
    'Nun Joystick Right': (gamepadData) => gamepadData.nunJoystick.digital.right,
    'Nun Joystick Up': (gamepadData) => gamepadData.nunJoystick.digital.up,
    'Nun Joystick Down': (gamepadData) => gamepadData.nunJoystick.digital.down,
    'Nun Joystick No Input': (gamepadData) => gamepadData.nunJoystick.digital.noInput,
    'Gyro Roll Left': (gamepadData) => gamepadData.gyro.digital.left,
    'Gyro Roll Right': (gamepadData) => gamepadData.gyro.digital.right,
    'Gyro Pitch Down': (gamepadData) => gamepadData.gyro.digital.down,
    'Gyro Pitch Up': (gamepadData) => gamepadData.gyro.digital.up,
};

export type ScratchLinkGamepadEventRetroEvalFunction = {
    [x in ScratchLinkGamepadEventsRetro]: (gamepadData: ScratchLinkGamepadDataRetro) => boolean;
}

export const scratchLinkGamepadEventsRetroEval: ScratchLinkGamepadEventRetroEvalFunction = {
    'Y Button': (gamepadData) => gamepadData.y,
    'B Button': (gamepadData) => gamepadData.b,
    'A Button': (gamepadData) => gamepadData.a,
    'X Button': (gamepadData) => gamepadData.x,
    'D-Pad Left': (gamepadData) => gamepadData.dPad.left,
    'D-Pad Right': (gamepadData) => gamepadData.dPad.right,
    'D-Pad Up': (gamepadData) => gamepadData.dPad.up,
    'D-Pad Down': (gamepadData) => gamepadData.dPad.down,
    'Left Button': (gamepadData) => gamepadData.bumper.left,
    'Right Button': (gamepadData) => gamepadData.bumper.right,
    'Start': (gamepadData) => gamepadData.start,
    'Select': (gamepadData) => gamepadData.select,
};