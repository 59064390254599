import { ICodingMatrixEditorAction } from '../../';

export const CODING_PROJECT_FILE_BACKPACKMATRIXES_CREATE = 'CODING/PROJECT/FILE/MATRIXEDITOR/BACKPACK/BACKPACKMATRIXES/CREATE';
export const CODING_PROJECT_FILE_BACKPACKMATRIXES_EDIT_MATRIX = 'CODING/PROJECT/FILE/MATRIXEDITOR/BACKPACK/BACKPACKMATRIXES/EDIT_MATRIX';
export const CODING_PROJECT_FILE_BACKPACKMATRIXES_CLEAR_MATRIX = 'CODING/PROJECT/FILE/MATRIXEDITOR/BACKPACK/BACKPACKMATRIXES/CLEAR_MATRIX';
export const CODING_PROJECT_FILE_BACKPACKMATRIXES_INVERT_MATRIX = 'CODING/PROJECT/FILE/MATRIXEDITOR/BACKPACK/BACKPACKMATRIXES/INVERT_MATRIX';
export const CODING_PROJECT_FILE_BACKPACKMATRIXES_DUPLICATE_MATRIX = 'CODING/PROJECT/FILE/MATRIXEDITOR/BACKPACK/BACKPACKMATRIXES/DUPLICATE_MATRIX';
export const CODING_PROJECT_FILE_BACKPACKMATRIXES_DELETE_MATRIX = 'CODING/PROJECT/FILE/MATRIXEDITOR/BACKPACK/BACKPACKMATRIXES/DELETE_MATRIX';
export const CODING_PROJECT_FILE_BACKPACKMATRIXES_RENAME_MATRIX = 'CODING/PROJECT/FILE/MATRIXEDITOR/BACKPACK/BACKPACKMATRIXES/RENAME_MATRIX';
export const CODING_PROJECT_FILE_BACKPACKMATRIXES_SHIFT_UP_MATRIX = 'CODING/PROJECT/FILE/MATRIXEDITOR/BACKPACK/BACKPACKMATRIXES/SHIFT_UP_MATRIX';
export const CODING_PROJECT_FILE_BACKPACKMATRIXES_SHIFT_DOWN_MATRIX = 'CODING/PROJECT/FILE/MATRIXEDITOR/BACKPACK/BACKPACKMATRIXES/SHIFT_DOWN_MATRIX';
export const CODING_PROJECT_FILE_BACKPACKMATRIXES_SHIFT_LEFT_MATRIX = 'CODING/PROJECT/FILE/MATRIXEDITOR/BACKPACK/BACKPACKMATRIXES/SHIFT_LEFT_MATRIX';
export const CODING_PROJECT_FILE_BACKPACKMATRIXES_SHIFT_RIGHT_MATRIX = 'CODING/PROJECT/FILE/MATRIXEDITOR/BACKPACK/BACKPACKMATRIXES/SHIFT_RIGHT_MATRIX';

export interface ICodingBackpackMatrixesCreateMatrixAction extends ICodingMatrixEditorAction {
    type: typeof CODING_PROJECT_FILE_BACKPACKMATRIXES_CREATE;
    folderId: string;
    extraOptions?: Partial<ICodingBackpackMatrix>;
}

export interface ICodingBackpackMatrixesEditMatrixAction extends ICodingMatrixEditorAction {
    type: typeof CODING_PROJECT_FILE_BACKPACKMATRIXES_EDIT_MATRIX;
    matrix: ICodingBackpackMatrix;
    matrixId: string;
}

export interface ICodingBackpackMatrixesClearMatrixAction extends ICodingMatrixEditorAction {
    type: typeof CODING_PROJECT_FILE_BACKPACKMATRIXES_CLEAR_MATRIX;
    matrixId: string;
}

export interface ICodingBackpackMatrixesInvertMatrixAction extends ICodingMatrixEditorAction {
    type: typeof CODING_PROJECT_FILE_BACKPACKMATRIXES_INVERT_MATRIX;
    matrixId: string;
}

export interface ICodingBackpackMatrixesDuplicateMatrixAction extends ICodingMatrixEditorAction {
    type: typeof CODING_PROJECT_FILE_BACKPACKMATRIXES_DUPLICATE_MATRIX;
    matrixId: string;
    newMatrixId: string;
}

export interface ICodingBackpackMatrixesDeleteMatrixAction extends ICodingMatrixEditorAction {
    type: typeof CODING_PROJECT_FILE_BACKPACKMATRIXES_DELETE_MATRIX;
    matrixId: string | string[];
}

export interface ICodingBackpackMatrixesRenameMatrixAction extends ICodingMatrixEditorAction {
    type: typeof CODING_PROJECT_FILE_BACKPACKMATRIXES_RENAME_MATRIX;
    matrixId: string;
    name: string;
}

export interface ICodingBackpackMatrixesShiftUpMatrixAction extends ICodingMatrixEditorAction {
    type: typeof CODING_PROJECT_FILE_BACKPACKMATRIXES_SHIFT_UP_MATRIX;
    matrixId: string;
}

export interface ICodingBackpackMatrixesShiftDownMatrixAction extends ICodingMatrixEditorAction {
    type: typeof CODING_PROJECT_FILE_BACKPACKMATRIXES_SHIFT_DOWN_MATRIX;
    matrixId: string;
}

export interface ICodingBackpackMatrixesShiftLeftMatrixAction extends ICodingMatrixEditorAction {
    type: typeof CODING_PROJECT_FILE_BACKPACKMATRIXES_SHIFT_LEFT_MATRIX;
    matrixId: string;
}

export interface ICodingBackpackMatrixesShiftRightMatrixAction extends ICodingMatrixEditorAction {
    type: typeof CODING_PROJECT_FILE_BACKPACKMATRIXES_SHIFT_RIGHT_MATRIX;
    matrixId: string;
}

export type ICodingBackpackMatrixesActionTypes = ICodingBackpackMatrixesCreateMatrixAction | ICodingBackpackMatrixesEditMatrixAction | ICodingBackpackMatrixesClearMatrixAction | ICodingBackpackMatrixesInvertMatrixAction | ICodingBackpackMatrixesDuplicateMatrixAction | ICodingBackpackMatrixesDeleteMatrixAction | ICodingBackpackMatrixesRenameMatrixAction | ICodingBackpackMatrixesShiftUpMatrixAction | ICodingBackpackMatrixesShiftDownMatrixAction | ICodingBackpackMatrixesShiftLeftMatrixAction | ICodingBackpackMatrixesShiftRightMatrixAction;

/**
 * Data
 */

export interface ICodingBackpackMatrix {
    id: string;
    name: string;
    folderId: string;
    hex: string;
    stdBinary: string[];
}

export interface ICodingBackpackMatrixesState {
    byIds: {
        [id: string]: ICodingBackpackMatrix
    },
    allIds: string[]
}