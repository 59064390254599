import immer from 'immer';
import { STORE_IMPORT } from '..';

import { IConsoleState, IConsoleActions } from '.';

import {
    CONSOLE_ADD_CONSOLE_LOG,
    CONSOLE_ADD_CONSOLE_LOG_BATCH,
    CONSOLE_CLEAR_CONSOLE
} from '.';



export const consoleInitialState: IConsoleState = {
    messages: [],
    clearedTime: (new Date()).getTime()
}

export const consoleReducer = (state: IConsoleState = consoleInitialState, action: IConsoleActions): IConsoleState => {
    switch (action.type) {
        case CONSOLE_ADD_CONSOLE_LOG:
            return immer(state, (draftState: IConsoleState) => {
                draftState.messages.push(action.log);
                if(draftState.messages.length > 5000) draftState.messages = draftState.messages.slice(draftState.messages.length-5000, 5000); //Cap to 5000 messages total
            });
        case CONSOLE_ADD_CONSOLE_LOG_BATCH:
            return immer(state, (draftState: IConsoleState) => {
                draftState.messages.push(...action.logs);
                if(draftState.messages.length > 5000) draftState.messages = draftState.messages.slice(draftState.messages.length-5000, 5000); //Cap to 5000 messages total
            });
        case CONSOLE_CLEAR_CONSOLE:
            return immer(state, (draftState: IConsoleState) => {
                draftState.messages = [];
                draftState.clearedTime = (new Date()).getTime();
            });
    
        case STORE_IMPORT:
            return action.data.console;
        default:
            return { ...state };
    }
}