import { createSelector } from 'reselect';
import { IRootState, ICodingMatrixEditorState } from 'store';

export const selectorGetMatrixEditor = (codingProjectIndex: number, fileIndex?: number) => createSelector(
    (state: IRootState) => state.coding,
    (coding) => {
        const codingProject = coding.codingProjects[codingProjectIndex];
        if(fileIndex) return codingProject.files[fileIndex].matrixEditorData?.matrixEditor as ICodingMatrixEditorState

        const file = codingProject.files.find(file => file.type === 'matrixEditor');
        return file?.matrixEditorData?.matrixEditor as ICodingMatrixEditorState;
    }
);