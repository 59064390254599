import React, { useCallback } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { replace } from 'connected-react-router';
import { Route, Switch } from 'react-router';
import { IRootState, HardwareModes } from 'store';
import ReactTooltip from 'react-tooltip';
import { FaUsb, FaWifi } from 'react-icons/fa';

import Button from 'components/Button/Button';
import DeviceConnectionModal from 'containers/DeviceConnectionModal/DeviceConnectionModal';

import './DeviceConnection.scss';
type Props = PropsFromRedux & {

}

const DeviceConnection: React.FC<Props> = ({ replace, path, connected, deviceName, hardwareModeAutoDetected }) => {
    const handleConnectionModalOpen = () => {
        replace(`/${path.split('/')[1]}/deviceConnection`);
    };

    const handleConnectionModalClose = useCallback(() => {
        replace(`/${path.split('/')[1]}`)        
    }, [replace, path]);

    const isWifiSite = window.location.protocol === 'http:';

    return (
        <>
            <div className="c-deviceConnection">
                <span>
                    <Button className='flex items-center justify-center' type={connected ? 'success' : 'danger'} onClick={handleConnectionModalOpen}>
                        {
                            !isWifiSite && <FaUsb data-tip data-for='deviceconnection-method' className='text-2xl mr-2' />
                        }
                        {
                            isWifiSite && <FaWifi data-tip data-for='deviceconnection-method' className='text-2xl mr-2' />
                        }
                        <span data-tip data-for='deviceconnection-connected'>
                            {
                                connected ? deviceName ? (
                                    <div className='flex flex-col'>
                                        <span className='font-semibold'>{deviceName}</span>
                                        <span className='text-xs font-light'>{HardwareModes[hardwareModeAutoDetected]}</span>
                                    </div>
                                
                                ) : 'connected' : (
                                    <div className='flex flex-col'>
                                        <span className='text-sm font-light'>eBot Device</span>
                                        <span className='font-semibold'>Disconnected</span>
                                    </div>
                                )
                            }
                        </span>
                    </Button>
                </span>
                <Switch>
                    <Route path="/:page/deviceConnection">
                        <DeviceConnectionModal connectionModalOpen={true} handleConnectionModalClose={handleConnectionModalClose}/>
                    </Route>
                </Switch>
            </div>
            <ReactTooltip
                id='deviceconnection-method'
                place='bottom'
            >
                <span>
                    {
                        !isWifiSite && 'Connection Type: USB Cable/Modem'
                    }
                    {
                        isWifiSite && 'Connection Type: WiFi'
                    }
                </span>
            </ReactTooltip>
            <ReactTooltip
                id='deviceconnection-connected'
                place='bottom'
            >
                <span>
                    {
                        connected && deviceName && `Connected to ScratchLink Device: ${HardwareModes[hardwareModeAutoDetected]} named ${deviceName}`
                    }
                    {
                        connected && !deviceName && `Connected to ScratchLink Device`
                    }
                    {
                        !connected && `Not Connected - click to connect to ScratchLink Device`
                    }
                </span>
            </ReactTooltip>
        </>
    )
}

const mapStateToProps = (state: IRootState) => {
    return {
        connected: state.project.deviceConnected,
        hardwareModeAutoDetected: state.project.hardwareModeAutoDetected,
        deviceName: state.project.deviceName,
        path: state.router.location.pathname
    };
}

const mapDispatchToProps = {
    replace
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DeviceConnection);