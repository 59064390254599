import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { DeviceConnectionManager } from 'lib/DeviceConnection';
import { IRootState } from 'store';

import './SensorTable.scss';

type Props = PropsFromRedux & {

}

type SensorInfo = [string, any];

const BooleanEvents = {
    23: {eventName: 'LHS Ultra Min', default: false, opposite: 24},
    24: {eventName: 'LHS Ultra Min Off', default: true, opposite: 23},
    25: {eventName: 'LHS Ultra Max', default: false, opposite: 26},
    26: {eventName: 'LHS Ultra Max Off', default: true, opposite: 25},
    27: {eventName: 'RHS Ultra Min', default: false, opposite: 28},
    28: {eventName: 'RHS Ultra Min Off', default: true, opposite: 27},
    29: {eventName: 'RHS Ultra Max', default: false, opposite: 30},
    30: {eventName: 'RHS Ultra Max Off', default: true, opposite: 29},
    31: {eventName: 'Side LHS Ultra Min', default: false, opposite: 32},
    32: {eventName: 'Side LHS Ultra Min Off', default: true, opposite: 31},
    33: {eventName: 'Side LHS Ultra Max', default: false, opposite: 34},
    34: {eventName: 'Side LHS Ultra Max Off', default: true, opposite: 33},
    35: {eventName: 'Side RHS Ultra Min', default: false, opposite: 36},
    36: {eventName: 'Side RHS Ultra Min Off', default: true, opposite: 35},
    37: {eventName: 'Side RHS Ultra Max', default: false, opposite: 38},
    38: {eventName: 'Side RHS Ultra Max Off', default: true, opposite: 37},
    11: {eventName: 'LHS Black', default: false, opposite: 13},
    13: {eventName: 'LHS White', default: true, opposite: 11},
    12: {eventName: 'RHS Black', default: false, opposite: 14},
    14: {eventName: 'RHS White', default: true, opposite: 12},
    44: {eventName: 'Proximity 0 On', default: false, opposite: 45},
    45: {eventName: 'Proximity 0 Off', default: true, opposite: 44},
    46: {eventName: 'Proximity 1 On', default: false, opposite: 47},
    47: {eventName: 'Proximity 1 Off', default: true, opposite: 46},
    61: {eventName: 'Digital 0 On', default: true, opposite: 62},
    62: {eventName: 'Digital 0 Off', default: false, opposite: 61},
    63: {eventName: 'Digital 1 On', default: true, opposite: 64},
    64: {eventName: 'Digital 1 Off', default: false, opposite: 63},
    65: {eventName: 'Digital 2 On', default: true, opposite: 66},
    66: {eventName: 'Digital 2 Off', default: false, opposite: 65},
    67: {eventName: 'Digital 3 On', default: true, opposite: 68},
    68: {eventName: 'Digital 3 Off', default: false, opposite: 67},
}

const SensorTable: React.FC<Props> = ({ scratchlinkConfig }) => {
    const [booleans, setBooleans] = useState<{ [evt: number]: boolean}>(Object.entries(BooleanEvents).reduce((prev, BooleanEvent) => ({...prev, [BooleanEvent[0]]: BooleanEvent[1].default}), {}));

    const [, updateState] = useState(); //@ts-ignore
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const data = DeviceConnectionManager.data;

    useEffect(() => {
        DeviceConnectionManager.addEventListener('data', forceUpdate);
        return () => {
            DeviceConnectionManager.removeEventListener('data', forceUpdate);
        }
    }, [forceUpdate]);

    useEffect(() => {
        DeviceConnectionManager.sendCommand(`stream on;`); //Turn on stream when component loads
    }, []);

    const sonar1 = data.Ultras && data.Ultras[0];
    const sonar2 = data.Ultras && data.Ultras[1];
    const sonar3 = data.Ultras && data.Ultras[2];
    const sonar4 = data.Ultras && data.Ultras[3];
    const encoder1 = data.dst && data.dst[1];
    const encoder2 = data.dst && data.dst[0];
    const color = data.Color && data.Color;
    const red = data.RGB && data.RGB[0];
    const green = data.RGB && data.RGB[1];
    const blue = data.RGB && data.RGB[2];
    const white = data.RGB && data.RGB[3];
    const voltage = data.VA && data.VA[0];
    const current = data.VA && data.VA[1];
    const lineLHS = data.lgt && data.lgt[0];
    const lineRHS = data.lgt && data.lgt[1];
    const line = data.lgt && data.lgt[0];

    const rfidNo = data.RFID && data.RFID.join(',');
    const rfidTagName = data.TagName && data.TagName;
    const rfid = rfidTagName || rfidNo || null;

    const analog0 = data.Analog && data.Analog[0];
    const analog1 = data.Analog && data.Analog[1];
    const analog2 = data.Analog && data.Analog[2];
    const analog3 = data.Analog && data.Analog[3];
    const analog4 = data.Analog && data.Analog[4];
    const analog5 = data.Analog && data.Analog[5];

    const deviceProfile: string | null = data.Profile || null;
    const deviceProfileConfig = scratchlinkConfig.deviceProfiles.find((device: any) => device.profile === deviceProfile);

    const isSensorVisible = (name: string) => {
        if(!deviceProfileConfig) return true;
        if(deviceProfileConfig.rfid && name === 'RFID') return deviceProfileConfig.rfid; //use the rfd setting for these        

        return deviceProfileConfig.sensors && deviceProfileConfig.sensors.includes(name);
    }

    const normalSensors: SensorInfo[] = [
        ['Colour Guess', color],
        ['LHS Wheel Distance (mm)', encoder1],
        ['RHS Wheel Distance (mm)', encoder2],
        ['Colour Red', red],
        ['Colour Green', green],
        ['Colour Blue', blue],
        ['Total Light', white],
        ['RFID', rfid],
        ['Analog 0', analog0],
        ['Analog 1', analog1],
        ['Analog 2', analog2],
        ['Analog 3', analog3],
        ['Light Top', analog0],
        ['Light Bottom', analog1],
        ['Light Left', analog2],
        ['Light Right', analog3],
        ['Solar Voltage', analog4],
        ['Solar Current', analog5],
        ['Voltage (V)', voltage],
        ['Current (A)', current],
    ];

    if(data.evt) {
        const event: number = data.evt;
        
        if(booleans[event] !== undefined && booleans[event] === false) //Make event true if false
            setBooleans({
                ...booleans,
                [event]: true,
                [(BooleanEvents as any)[event].opposite]: false
            });
    }

    const getBooleanStatus = (evt: number) => booleans[evt] ? 'True' : 'False';
    const getBooleanCellClass = (evt: number) => booleans[evt] ? 'c-sensorTable__cell--green' : 'c-sensorTable__cell--red';
    const getBooleanCell = (evt: number) => data.evt ? <td className={getBooleanCellClass(evt)}>{getBooleanStatus(evt)}</td> : <td></td>;

    return (
        <>
            <table className="c-sensorTable">
                <thead>
                    <tr>
                        <th>Analog Reporter</th>
                        <th>Value</th>
                        <th>Digital Boolean</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        isSensorVisible('Front LHS Ultra (cm)') && (
                            <>
                                <tr>
                                    <td>Front LHS Ultra (cm)</td>
                                    <td>{sonar1}</td>
                                    <td>Front LHS Ultra Min</td>
                                    {getBooleanCell(23)}
                                </tr>
                                <tr>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td>Front LHS Ultra Max</td>
                                    {getBooleanCell(25)}
                                </tr>
                            </>
                        )
                    }
                    {
                        isSensorVisible('Front RHS Ultra (cm)') && (
                            <>
                                <tr>
                                    <td>Front RHS Ultra (cm)</td>
                                    <td>{sonar2}</td>
                                    <td>Front RHS Ultra Min</td>
                                    {getBooleanCell(27)}
                                </tr>
                                <tr>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td>Front RHS Ultra Max</td>
                                    {getBooleanCell(29)}
                                </tr>
                            </>
                        )
                    }
                    {
                        isSensorVisible('Side LHS Ultra (cm)') && (
                            <>
                                <tr>
                                    <td>Side LHS Ultra (cm)</td>
                                    <td>{sonar3}</td>
                                    <td>Side LHS Ultra Min</td>
                                    {getBooleanCell(31)}
                                </tr>
                                <tr>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td>Side LHS Ultra Max</td>
                                    {getBooleanCell(33)}
                                </tr>
                            </>
                        )
                    }
                    {
                        isSensorVisible('Side RHS Ultra (cm)') && (
                            <>
                                <tr>
                                    <td>Side RHS Ultra (cm)</td>
                                    <td>{sonar4}</td>
                                    <td>Side RHS Ultra Min</td>
                                    {getBooleanCell(35)}
                                </tr>
                                <tr>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td>Side RHS Ultra Max</td>
                                    {getBooleanCell(37)}
                                </tr>
                            </>
                        )
                    }
                    {
                        isSensorVisible('LHS Line') && (
                            <>
                                <tr>
                                    <td>LHS Line</td>
                                    <td>{lineLHS}</td>
                                    <td>LHS Line Black</td>
                                    {getBooleanCell(11)}
                                </tr>
                                <tr>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td>LHS Line White</td>
                                    {getBooleanCell(13)}
                                </tr>
                            </>
                        )
                    }
                    {
                        isSensorVisible('RHS Line') && (
                            <>
                                <tr>
                                    <td>RHS Line</td>
                                    <td>{lineRHS}</td>
                                    <td>RHS Line Black</td>
                                    {getBooleanCell(12)}
                                </tr>
                                <tr>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td>RHS Line White</td>
                                    {getBooleanCell(14)}
                                </tr>
                            </>
                        )
                    }
                    {
                        isSensorVisible('Proximity LHS') && (
                            <>
                                <tr>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td>Proximity LHS</td>
                                    {getBooleanCell(46)}
                                </tr>
                            </>
                        )
                    }
                    {
                        isSensorVisible('Proximity RHS') && (
                            <>
                                <tr>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td>Proximity RHS</td>
                                    {getBooleanCell(44)}
                                </tr>
                            </>
                        )
                    }
                    {
                        isSensorVisible('Ultra (cm)') && ( //CRS Ultra
                            <>
                                <tr>
                                    <td>Ultra (cm)</td>
                                    <td>{sonar1}</td>
                                    <td>Ultra Min</td>
                                    {getBooleanCell(23)}
                                </tr>
                                <tr>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td>Ultra Max</td>
                                    {getBooleanCell(25)}
                                </tr>
                            </>
                        )
                    }
                    {
                        isSensorVisible('Line') && (
                            <>
                                <tr>
                                    <td>Line</td>
                                    <td>{line}</td>
                                    <td>Line Black</td>
                                    {getBooleanCell(11)}
                                </tr>
                                <tr>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td>Line White</td>
                                    {getBooleanCell(13)}
                                </tr>
                            </>
                        )
                    }
                    {
                        isSensorVisible('Button') && (
                            <>
                                <tr>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td>Button</td>
                                    {getBooleanCell(64)}
                                </tr>
                            </>
                        )
                    }
                    {
                        isSensorVisible('Digital 0') && (
                            <>
                                <tr>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td>Digital 0</td>
                                    {getBooleanCell(61)}
                                </tr>
                            </>
                        )
                    }
                    {
                        isSensorVisible('Digital 1') && (
                            <>
                                <tr>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td>Digital 1</td>
                                    {getBooleanCell(63)}
                                </tr>
                            </>
                        )
                    }
                    {
                        isSensorVisible('Digital 2') && (
                            <>
                                <tr>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td>Digital 2</td>
                                    {getBooleanCell(65)}
                                </tr>
                            </>
                        )
                    }
                    {
                        isSensorVisible('Digital 3') && (
                            <>
                                <tr>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td>Digital 3</td>
                                    {getBooleanCell(67)}
                                </tr>
                            </>
                        )
                    }
                    {
                        isSensorVisible('End Stop 0') && (
                            <>
                                <tr>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td>End Stop 0</td>
                                    {getBooleanCell(61)}
                                </tr>
                            </>
                        )
                    }
                    {
                        isSensorVisible('End Stop 1') && (
                            <>
                                <tr>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td>End Stop 1</td>
                                    {getBooleanCell(63)}
                                </tr>
                            </>
                        )
                    }
                    {
                        isSensorVisible('Crosswalk Button 0') && (
                            <>
                                <tr>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td>Crosswalk Button 0</td>
                                    {getBooleanCell(65)}
                                </tr>
                            </>
                        )
                    }
                    {
                        isSensorVisible('Crosswalk Button 1') && (
                            <>
                                <tr>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td>Crosswalk Button 1</td>
                                    {getBooleanCell(67)}
                                </tr>
                            </>
                        )
                    }
                    {
                        normalSensors.map(sensor => (
                            isSensorVisible(sensor[0]) && (
                                <tr key={sensor[0]}>
                                    <td>{sensor[0]}</td>
                                    <td>{sensor[1]}</td>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                    <td className="c-sensorTable__cell--disabled"></td>
                                </tr>
                            )
                        ))
                    }
                </tbody>
            </table>
        </>
    )
};

const mapStateToProps = (state: IRootState) => {
    return {
        scratchlinkConfig: state.project.scratchlinkConfig
    }
}

const mapDispatchToProps = {
    
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SensorTable);