import React from 'react';
import Button from 'components/Button/Button';
import { restoreProjectStateFromLocalStorage } from 'lib/Storage';

interface Props {

}

const RestoreProjectNotification: React.FC<Props> = () => {
    return (
        <div className='flex justify-center items-center'>
            Restore Last Session State? <Button className='ml-2' type='success' onClick={restoreProjectStateFromLocalStorage}>Restore</Button>
        </div>
    )
};

export default RestoreProjectNotification;