import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRFIDTag, IRootState, RFIDSetTags } from 'store';
import { DeviceConnectionManager } from 'lib/DeviceConnection';
import { toast } from 'react-toastify';
import wait from 'utils/wait';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import Button from 'components/Button/Button';
import Input from 'components/Input/Input';

type Props = PropsFromRedux & {

}

const ConfigRFID: React.FC<Props> = ({ tagsState, RFIDSetTags }) => {
    const [tags, setTags] = useState(tagsState);
    const [tagsChanged, setTagsChanged] = useState(false);
    const [scanning, setScanning] = useState(false);
    const [activeTagID, setActiveTagID] = useState<number[] | undefined>();

    useEffect(() => {
        setTags(tagsState)
    }, [tagsState]);

    const onClickScan = () => {
        setScanning(!scanning);
    };

    const onNewDataPacket = React.useCallback((e: any) => { //Data event litsener
        if(e === undefined || e.detail === undefined) return;
        const data = e.detail;

        if(data['evt'] && (data['evt'] === 54 || data['evt'] === 55)) {
            //RFID Card
            if(!scanning) return;

            const newTags: IRFIDTag[] = JSON.parse(JSON.stringify(tags));
            const newTagID: number[] = DeviceConnectionManager.data['RFID'];
            const newTagName: string = DeviceConnectionManager.data['TagName'];
            const existingTag = tags.find(tag => tag.tagId.join() === newTagID.join());

            toast.success(`RFID Tag Scanned | ID: ${newTagID.join(',')} | Name: ${newTagName}`);
            setActiveTagID(newTagID);

            if(existingTag) { //Already exists
                const tagIndex = newTags.findIndex(tag => tag.tagId.toString() === newTagID.toString());

                newTags[tagIndex] = {
                    tagId: newTagID,
                    tagName: newTagName
                };
                
                setTags(newTags);
                setTagsChanged(true);
                return;
            } 

            newTags.push({
                tagId: newTagID,
                tagName: newTagName || ''
            });
            setTags(newTags);
            setTagsChanged(true);
        }
    }, [tags, scanning]);

    useEffect(() => {//Attach event litseners for data
        DeviceConnectionManager.addEventListener('data', onNewDataPacket);
        return () => {
            DeviceConnectionManager.removeEventListener('data', onNewDataPacket);
        }
    }, [onNewDataPacket]);

    const onTagNameChange = React.useCallback((changeEvent: React.ChangeEvent<HTMLInputElement>, tagID: number[]) => {
        let newTags = JSON.parse(JSON.stringify(tags))
        for(let i=0; i<newTags.length; i++) {
            if(newTags[i].tagId.join() === tagID.join()) {
                newTags[i].tagName = changeEvent.target.value;

                setTags(newTags);
                setTagsChanged(true);

                return;
            }
        }
    }, [tags]);

    const onClickSave = async () => {
        setTagsChanged(false);
        RFIDSetTags(tags);
        setScanning(false);
        setActiveTagID(undefined);
        
        for(let i=0; i<tags.length; i++) {
            await DeviceConnectionManager.sendCommand(`rfid config load ${tags[i].tagId.join(' ')} "${tags[i].tagName}";`);
            await wait(50);
        }
        await DeviceConnectionManager.sendCommand(`rfid config names save;`);
        
        toast.success(`RFID tags saved to eBot!`);
        toast.warn(`eBot needs to be restarted for RFID tags to be updated`)
    }

    useEffect(() => {
        return () => {
            setScanning(false);
        }
    }, [])

    return (
        <div className="p-configPage__tableContainer">
            <table className="p-configPage__table">
                <tbody>
                    <tr className="p-configPage__tableHeader">
                        <th>Tag ID</th>
                        <th>Tag Name</th>
                    </tr>
                    {
                        tags.map(tag => (
                            <tr className={`p-configPage__tableRow ${tag.tagId.join() === activeTagID?.join() ? 'p-configPage__tableRow--light' : ''}`} key={tag.tagId.join('')}>
                                <td className="p-configPage__tableSettingValue">{tag.tagId.join(',')}</td>
                                <td className="p-configPage__tableSettingValue"><Input value={tag.tagName} maxLength={10} onChange={val => onTagNameChange(val, tag.tagId)}/></td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <div className="p-configPage__RFIDButtons">
                <Button type="info" addStyles={['square']} onClick={onClickScan} >{scanning ? 'Scanning' : 'Scan Tag'} {scanning && <FontAwesomeIcon className="p-configPage__spinner" icon={faSpinner} />}</Button>
                <Button type="success" addStyles={['square']} onClick={onClickSave} disabled={!tagsChanged}>Save</Button>
            </div>
        </div>
    )
}

const mapStateToProps = (state: IRootState) => {
    return {
        tagsState: state.RFID.tags
    }
}

const mapDispatchToProps = {
    RFIDSetTags
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ConfigRFID);