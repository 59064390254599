import { createSelector } from 'reselect';
import { IRootState, selectorGetActiveCodingProject, ICodingUserSelectedRightPanel } from 'store';

/**
 * @returns [displayPanel, disablePanel]
 */
export const selectorGetActiveRightCodingPanel = createSelector(
    [
        (state: IRootState) => state.coding,
        (state: IRootState) => selectorGetActiveCodingProject(state).type
    ],
    (coding, codingProjectType): [ICodingUserSelectedRightPanel, ICodingUserSelectedRightPanel] => {
        if(codingProjectType !== 'hardwarejs' && codingProjectType !== 'js' && codingProjectType !== 'jsscratch' && codingProjectType !== 'hardwarepy') return [null, null];

        const shouldForceDisplayConsole = coding.running || coding.codeHasError ;

        if(shouldForceDisplayConsole || coding.userSelectedRightPanel === 'console') {
            if(shouldForceDisplayConsole && !coding.codeHasError) return ['console', 'help'];
            if(shouldForceDisplayConsole && coding.codeHasError) return ['console', null];
            return ['console', null];
        }

        if(coding.userSelectedRightPanel === 'help') return ['help', null];

        return [null, null];    
    }
);