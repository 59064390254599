import React, { memo } from 'react';

import MatrixSvg from 'components/MatrixSvg/MatrixSvg';

import './MatrixPreview.scss';

interface MatrixPreviewProps {
    name: string;
    stdBinary: string[];
    selected?: Boolean;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const MatrixPreview: React.FC<MatrixPreviewProps> = memo(({ name, stdBinary, selected, onClick }) => {
    const matrixPreviewClasses = `c-matrixPreview ${selected ? 'c-matrixPreview--selected' : ''}`;
    return (
        <div className="c-matrixPreview__container" onClick={onClick}>
            <div className={matrixPreviewClasses}>
                <MatrixSvg stdBinary={stdBinary} />
                <h5 className="c-matrixPreview__title">
                    {name}
                </h5>
            </div>
        </div>
    )
});

export default MatrixPreview;