import { ICodingMatrixEditorAction } from '../';

export const ANIMATION_SET_FPS = 'CODING/PROJECT/FILE/MATRIXEDITOR/ANIMATION/SET_FPS';
export const ANIMATION_SET_PLAYING = 'CODING/PROJECT/FILE/MATRIXEDITOR/ANIMATION/SET_PLAYING';
export const ANIMATION_START_PLAYING = 'CODING/PROJECT/FILE/MATRIXEDITOR/ANIMATION/START_PLAYING';
export const ANIMATION_STOP_PLAYING = 'CODING/PROJECT/FILE/MATRIXEDITOR/ANIMATION/STOP_PLAYING';
export const ANIMATION_TICK = 'CODING/PROJECT/FILE/MATRIXEDITOR/ANIMATION/TICK';

export interface IAnimationSetFpsAction extends ICodingMatrixEditorAction {
    type: typeof ANIMATION_SET_FPS;
    fps: number;
}

export interface IAnimationSetPlayingAction extends ICodingMatrixEditorAction {
    type: typeof ANIMATION_SET_PLAYING;
    playing: boolean;
}

export interface IAnimationTickAction extends ICodingMatrixEditorAction {
    type: typeof ANIMATION_TICK;
}


export type IAnimationActionTypes = IAnimationSetFpsAction | IAnimationSetPlayingAction | IAnimationTickAction;
export type IAnimationAllActionTypes = IAnimationActionTypes;

export interface IAnimationState {
    fps: number;
    playing: boolean;
}