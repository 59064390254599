import React, { useState, useEffect } from 'react';
import ReactSlider from 'react-slider';

import './Slider.scss';

export type SliderValue = number | number[];
export type SliderChange = (value: SliderValue, index: number) => void;

interface SliderProps {
    className?: string;
    value?: SliderValue;
    defaultValue?: SliderValue;
    min?: number;
    max?: number;
    minDistance?: number;
    step?: number;
    snapBack?: boolean;
    disabled?: boolean;
    vertical?: boolean;
    onChange?: SliderChange;
    onAfterChange?: SliderChange;
}

const Slider: React.FC<SliderProps> = ({ className, value, defaultValue, min, max, minDistance, step, snapBack, disabled, vertical = false, onChange, onAfterChange }) => {
    let classes = "c-slider ";
    if(vertical) classes += 'c-slider--vertical ';
    if(className) classes += className;

    const [sliderValue, setSliderValue] = useState<SliderValue>(defaultValue || 0);

    useEffect(() => {
        if(value) setSliderValue(value);
    }, [value])

    const onSliderChange = (value: SliderValue, index: number) => {
        if(onChange) onChange(value, index);
        setSliderValue(value);
    }

    const onSliderAfterChange = (value: SliderValue, index: number) => {
        if(snapBack && defaultValue !== undefined) {
            setSliderValue(defaultValue); //Snap back
            if(onAfterChange) onAfterChange(defaultValue, index);
            return;
        }
        if(onAfterChange) onAfterChange(value, index);
    }

    let sliderMinDistance = 0;
    if(minDistance) sliderMinDistance = minDistance;
    else if(min !== undefined && max !== undefined) sliderMinDistance = (max - min)*0.1;

    return (
        <ReactSlider
            className={classes}
            thumbClassName="c-slider__thumb"
            minDistance={sliderMinDistance}
            value={sliderValue}
            defaultValue={defaultValue}
            min={min}
            max={max}
            step={step}
            disabled={disabled}
            orientation={vertical ? 'vertical' : 'horizontal'}
            onChange={onSliderChange}
            onAfterChange={onSliderAfterChange}
            renderTrack={
                (props, state) => ( //@ts-ignore
                    <div 
                        {...props}
                        className={`c-slider__track ${vertical ? 'c-slider__track--vertical' : 'c-slider__track--horizontal'} ${state.index === 1 || snapBack ? 'c-slider__track--inactive': ''} ${disabled ? 'c-slider__track--disabled': ''}`}
                    />
                )
            }
            renderThumb={
                (props, state) => ( //@ts-ignore
                    <div 
                        {...props}
                        className={`c-slider__thumb ${vertical ? 'c-slider__thumb--vertical' : 'c-slider__thumb--horizontal'} ${disabled ? 'c-slider__thumb--disabled': ''}`}
                    />
                )
            }
        />
    )
};

export default Slider;