import JSZip from "jszip";
import { ICodingSprite } from 'store';

class SpriteFileDecoder {
    async decode(file: File): Promise<ICodingSprite | null> {
        try {
            const zip = await JSZip.loadAsync(file);
            const spriteJson = JSON.parse(
                await zip.file('sprite.json')?.async('string') || ''
            );
            const costumes = spriteJson.costumes; //get custumes from file

            let sprite: ICodingSprite = {
                name: spriteJson.name,
                scratchName: spriteJson.name,
                type: 'file',
                costumes: []
            };

            for(let i=0; i<costumes.length; i++) {
                const costumeData = await zip.file(costumes[i].md5ext)?.async('base64'); //get costume data from svg file in zip as base64

                if(!costumeData) {
                    console.error(`ERROR: Cant get costume file data - ${costumes[i].name} - ${costumes[i].md5ext}`);
                    return null; //cant get costume data
                }

                sprite.costumes.push({
                    name: costumes[i].name,
                    asset: `data:image/svg+xml;base64,${costumeData}`
                })
            }

            return Promise.resolve(sprite);
        } catch(e) {
            console.error(e);

            return null;
        }
    }
}


export default new SpriteFileDecoder();