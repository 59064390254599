import { createSelector } from 'reselect';
import { IRootState, HardwareModes } from 'store';

/*
    The final hardware mode being used for the Javascript Page. Will use the auto detected device profile if js selector is AUTO, else use the user selected hardware mode
*/
export const selectorGetProjectHardwareModeJavascriptDisplayName = createSelector(
    [
        (state: IRootState) => state.project.hardwareMode,
        (state: IRootState) => state.project.hardwareModeAutoDetected
    ],
    (hardwareMode, hardwareModeAutoDetected): string => {
        if(hardwareMode === 'AUTO') {
            if(hardwareModeAutoDetected === 'none') return 'eBot2WD';
            
            return getJSHardwareName(hardwareModeAutoDetected);
        }
        return getJSHardwareName(hardwareMode);
    }
);

const getJSHardwareName = (hardwareMode: string) => HardwareModes[hardwareMode]?.split('.').join('').split('-').join('').split(' ').join('') || 'unknownDeviceProfile';