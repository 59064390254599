import shortid from 'shortid';
import { ConsoleDecoratorNames } from 'components/ConsoleDecorator/ConsoleDecorator';
import store from 'store';

import {
    IConsoleSource,
    IConsoleLog,
    IConsoleAddConsoleLog,
    IConsoleAddConsoleLogBatch,
    IConsoleClearConsole
} from '.';

import {
    CONSOLE_ADD_CONSOLE_LOG,
    CONSOLE_ADD_CONSOLE_LOG_BATCH,
    CONSOLE_CLEAR_CONSOLE
} from '.';

const msToTimeString = (time: number) => {
    const ms = time % 1000;
    time = (time - ms) / 1000;
    const secs = time % 60;
    time = (time - secs) / 60;
    const mins = time % 60;
    const hrs = (time - mins) / 60;
    
    return hrs.toLocaleString('en-US', {minimumIntegerDigits: 2}) + ':' + mins.toLocaleString('en-US', {minimumIntegerDigits: 2}) + ':' + secs.toLocaleString('en-US', {minimumIntegerDigits: 2}) + '.' + ms.toLocaleString('en-US', {minimumIntegerDigits: 3});
}

export const consoleCreateLog = (content: string, source: IConsoleSource, decorator?: ConsoleDecoratorNames, customTime?: number): IConsoleLog => {
    const state = store.getState();
    
    const startTime = state.console.messages.length > 0 ? state.console.messages[0].timestampMiliseconds : (new Date()).getTime();
    const time = customTime || (new Date()).getTime();
    const timestamp = msToTimeString(time - startTime);

    return {
        id: shortid(),
        content,
        timestamp,
        timestampMiliseconds: time,
        source,
        decorator
    }
}

export const consoleAddConsoleLog = (content: string, source: IConsoleSource, decorator?: ConsoleDecoratorNames, customTime?: number): IConsoleAddConsoleLog => {
    const log = consoleCreateLog(content, source, decorator, customTime);    
    
    return {
        type: CONSOLE_ADD_CONSOLE_LOG,
        log
    }
}

export const consoleAddConsoleLogBatch = (logs: IConsoleLog[]): IConsoleAddConsoleLogBatch => {
    return {
        type: CONSOLE_ADD_CONSOLE_LOG_BATCH,
        logs
    }
}

export const consoleClearConsole = (): IConsoleClearConsole => {
    return {
        type: CONSOLE_CLEAR_CONSOLE
    }
}