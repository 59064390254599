import { GamePadTypes } from 'store';

import {
    ScratchLinkGamepad,
    ScratchLinkGamepadDataStandard,
    ScratchLinkGamepadDataSlClassic,
    ScratchLinkGamepadDataSlNunchuk,
    ScratchLinkGamepadDataRetro,
    ScratchLinkGamepadEventsRetro
} from '.';

import {
    ScratchLinkGamepadEventsStandard,
    ScratchLinkGamepadEventsSlClassic,
    ScratchLinkGamepadEventsSlNunchuk,
    scratchLinkGamepadEventsStandardEval,
    scratchLinkGamepadEventsSlClassicEval,
    scratchLinkGamepadEventsSlNunchukEval,
    scratchLinkGamepadEventsRetroEval
} from '.';

type ScratchLinkGamepadEventsStandardCache = {
    [x in ScratchLinkGamepadEventsStandard]: boolean | null;
}

type ScratchLinkGamepadEventsSlClassicCache = {
    [x in ScratchLinkGamepadEventsSlClassic]: boolean;
}

type ScratchLinkGamepadEventsSlNunchukCache = {
    [x in ScratchLinkGamepadEventsSlNunchuk]: boolean;
}

type ScratchLinkGamepadEventsRetroCache = {
    [x in ScratchLinkGamepadEventsRetro]: boolean;
}

type ScratchLinkGamepadEventsCache = ScratchLinkGamepadEventsStandardCache | ScratchLinkGamepadEventsSlClassicCache | ScratchLinkGamepadEventsSlNunchukCache | ScratchLinkGamepadEventsRetroCache;

export class ScratchLinkGamepadEventHandler extends EventTarget {
    private eventCache: ScratchLinkGamepadEventsCache = {} as ScratchLinkGamepadEventsCache;
    private gamepadType: GamePadTypes = 'no_gamepad';
    private gamepadNumber: number;

    constructor(gamepadNumber: number) {
        super();

        this.gamepadNumber = gamepadNumber;
    }

    handleGamepadData(gamepadData: ScratchLinkGamepad) {
        if(gamepadData.type === 'no_gamepad') return;

        if(this.gamepadType !== gamepadData.type) { // Gamepad type has changed so we need to create a new event cache
            this.eventCache = this.createEventCache(gamepadData.type);
            this.gamepadType = gamepadData.type;
        }

        if(gamepadData.type === 'xbox' || gamepadData.type === 't3') this.handleStandardGamepadData(gamepadData.data);
        if(gamepadData.type === 'retro') this.handleRetroGamepadData(gamepadData.data);
    }

    private handleStandardGamepadData(gamepadData: ScratchLinkGamepadDataStandard) {
        const eventNames = Object.keys(scratchLinkGamepadEventsStandardEval) as ScratchLinkGamepadEventsStandard[];
        for(const eventName of eventNames) {
            const value = scratchLinkGamepadEventsStandardEval[eventName](gamepadData);
            this.dispatchGamepadEvent(eventName, value);
        }
    }

    private handleSlClassicGamepadData(gamepadData: ScratchLinkGamepadDataSlClassic) {
        const eventNames = Object.keys(scratchLinkGamepadEventsSlClassicEval) as ScratchLinkGamepadEventsSlClassic[];
        for(const eventName of eventNames) {
            const value = scratchLinkGamepadEventsSlClassicEval[eventName](gamepadData);
            this.dispatchGamepadEvent(eventName, value);
        }
    }

    private handleSlNunchukGamepadData(gamepadData: ScratchLinkGamepadDataSlNunchuk) {
        const eventNames = Object.keys(scratchLinkGamepadEventsSlNunchukEval) as ScratchLinkGamepadEventsSlNunchuk[];
        for(const eventName of eventNames) {
            const value = scratchLinkGamepadEventsSlNunchukEval[eventName](gamepadData);
            this.dispatchGamepadEvent(eventName, value);
        }
    }

    private handleRetroGamepadData(gamepadData: ScratchLinkGamepadDataRetro) {
        const eventNames = Object.keys(scratchLinkGamepadEventsRetroEval) as ScratchLinkGamepadEventsRetro[];
        for(const eventName of eventNames) {
            const value = scratchLinkGamepadEventsRetroEval[eventName](gamepadData);
            this.dispatchGamepadEvent(eventName, value);
        }
    }

    dispatchGamepadEvent(eventName: string, value: boolean) {
        //@ts-ignore
        if(value !== this.eventCache[eventName]) {
            //@ts-ignore
            if(value && this.eventCache[eventName] !== null) {
                this.dispatchEvent(new CustomEvent('gamepadEvent', { detail: {gamepadNumber: this.gamepadNumber, eventName} }));
            }

            //@ts-ignore
            this.eventCache[eventName] = value;
        }
    }

    private createEventCache(gamepadType: GamePadTypes): ScratchLinkGamepadEventsCache {
        let events: string[];
        let cache: any;

        if(gamepadType === 'xbox' || gamepadType === 't3') {
            events = Object.keys(scratchLinkGamepadEventsStandardEval) as ScratchLinkGamepadEventsStandard[];
            cache = {} as ScratchLinkGamepadEventsStandardCache;
        } else if(gamepadType === 'retro') {
            events = Object.keys(scratchLinkGamepadEventsRetroEval) as ScratchLinkGamepadEventsRetro[];
            cache = {} as ScratchLinkGamepadEventsRetroCache;
        
        } else {
            throw new Error('Invalid gamepad type');
        }

        for(const event of events) {
            cache[event] = null;
        }

        return cache;
    }
}