import React, { useCallback, useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { saveAs } from 'file-saver';
import { ReactSortable, } from 'react-sortablejs';
import { Menu, Item, ItemParams } from 'react-contexify';
//import { renderToString } from 'react-dom/server';
import 'react-contexify/dist/ReactContexify.min.css';

import { 
    IRootState, 
    selectorGetMatrixEditor,
    selectorGetBackpackFolders, 
    selectorGetBackpackSelectedFolderName, 
    selectorGetBackpackMatrixesInSelectedFolder, 
    selectorGetBackpackSavedMatrixes, 
    codingBackpackNavigateToFolder, 
    codingBackpackChangeSelectedMatrix, 
    backpackFolderMoveFolder,
    codingBackpackFoldersMoveMatrix , 
    codingBackpackDeleteFolder,
    codingBackpackDeleteMatrix,
    codingBackpackDuplicateMatrix,
    codingBackpackCreateMatrix,
    codingBackpackFoldersRenameFolder,
    codingBackpackMatrixesRenameMatrix
} from 'store';

import { createDraggable } from 'utils/draggable';

import Modal from 'components/Modal/Modal';
import BackpackItem from 'components/BackpackItem/BackpackItem';
import BackpackFolder from 'components/BackpackFolder/BackpackFolder';
import MatrixPreview from 'components/MatrixPreview/MatrixPreview';
//import MatrixSvg from 'components/MatrixSvg/MatrixSvg';

import './Backpack.scss';

type OwnProps = {
    codingProjectIndex: number;
    fileIndex: number;
    allowNavigation: boolean;
    showRootFolder: boolean;
    allDragging: boolean;
}

type Props = PropsFromRedux & OwnProps;

const Backpack: React.FC<Props> = ({
    codingProjectIndex,
    fileIndex,
    codingBackpackFoldersById,
    selectedFolderName,
    codingBackpackFoldersProps,
    codingBackpackMatrixesProps,
    codingBackpackMatrixesById,
    selectedFolderIdProps,
    selectedMatrixId,
    animationPlaying,
    savedMatrixesProps,
    allowNavigation,
    allDragging,
    codingBackpackNavigateToFolder,
    codingBackpackChangeSelectedMatrix,
    backpackFolderMoveFolder,
    codingBackpackFoldersMoveMatrix,
    codingBackpackDeleteFolder,
    codingBackpackDeleteMatrix,
    codingBackpackDuplicateMatrix,
    codingBackpackCreateMatrix,
    codingBackpackFoldersRenameFolder,
    codingBackpackMatrixesRenameMatrix
}) => {
    const [renameMatrixName, setRenameMatrixName] = useState('');
    const [renameMatrixId, setRenameMatrixId] = useState('');
    const [renameMatrixModalIsOpen, setRenameMatrixModalIsOpen] = useState(false);
    const [renameAnimationName, setRenameAnimationName] = useState('');
    const [renameAnimationId, setRenameAnimationId] = useState('');
    const [renameAnimationModalIsOpen, setRenameAnimationModalIsOpen] = useState(false);

    const handleMatrixClick = (matrixNo: number) => {
        codingBackpackChangeSelectedMatrix(codingProjectIndex, fileIndex, codingBackpackMatrixes[matrixNo].id);
    }

    const handleFolderClick = (folderId: string) => {
        if(allowNavigation) codingBackpackNavigateToFolder(codingProjectIndex, fileIndex, folderId);
    }

    const handleAnimationContextDelete = (args: ItemParams<any, any>) => {
        const folderId: string = (args.props as any).id;

        codingBackpackDeleteFolder(codingProjectIndex, fileIndex, folderId);
    }

    const handleMatrixContextDelete = (args: ItemParams<any, any>) => {
        const matrixId: string = (args.props as any).id;

        codingBackpackDeleteMatrix(codingProjectIndex, fileIndex, matrixId);
    }

    const handleAnimationContextRename = (args: ItemParams<any, any>) => {
        const folderId: string = (args.props as any).id;
        const currentName = codingBackpackFoldersById[folderId].name;
        setRenameAnimationId(folderId);
        setRenameAnimationName(currentName);
        setRenameAnimationModalIsOpen(true);
    }

    const handleMatrixContextNew = (args: ItemParams<any, any>) => {
        codingBackpackCreateMatrix(codingProjectIndex, fileIndex);
    }

    const handleMatrixContextRename = (args: ItemParams<any, any>) => {
        const matrixId: string = (args.props as any).id;
        const currentName = codingBackpackMatrixesById[matrixId].name;
        setRenameMatrixId(matrixId);
        setRenameMatrixName(currentName);
        setRenameMatrixModalIsOpen(true);
    }

    const handleMatrixContextDuplicate = (args: ItemParams<any, any>) => {
        const matrixId: string = (args.props as any).id;
        codingBackpackDuplicateMatrix(codingProjectIndex, fileIndex, matrixId);
    }

    const handleMatrixContextExportFile = (args: ItemParams<any, any>) => {
        const matrixId: string = (args.props as any).id;
        const matrix = codingBackpackMatrixesById[matrixId];

        let exportName = matrix.name; //Add matrix index to matrixes in an animation
        if(selectedFolderId !== 'root') exportName += `-${codingBackpackMatrixesProps.findIndex(matrixProp => matrixProp.id === matrixId)}`;

        const blob = new Blob([JSON.stringify(matrix)], {type: "text/json;charset=utf-8"});
        saveAs(blob, `${exportName}.ScratchLinkMatrix`);
    }

    const handleAnimationContextExportFile = (args: ItemParams<any, any>) => {
        const animationId: string = (args.props as any).id;
        const animation = codingBackpackFoldersById[animationId];

        const blob = new Blob([JSON.stringify(animation)], {type: "text/json;charset=utf-8"});
        saveAs(blob, `${animation.name}.ScratchLinkAnimation`);
    }

    /* const handleMatrixContextExportSVG = (args: ItemParams<any, any>) => {
        const matrixId: string = (args.props as any).id;
        const matrix = codingBackpackMatrixesById[matrixId];

        const svgContent = renderToString(<MatrixSvg stdBinary={matrix.stdBinary} />);

        let exportName = matrix.name; //Add matrix index to matrixes in an animation
        if(selectedFolderId !== 'root') exportName += `-${codingBackpackMatrixesProps.findIndex(matrixProp => matrixProp.id === matrixId)}`;

        const blob = new Blob([svgContent], {type: "image/svg+xml;charset=utf-8"});
        saveAs(blob, `${exportName}.svg`);
    } */

    const handleCloseNewMatrixModal = () => {
        setRenameMatrixModalIsOpen(false);
    }

    const handleSaveRenameMatrixModal = () => {
        codingBackpackMatrixesRenameMatrix(codingProjectIndex, fileIndex, renameMatrixId, renameMatrixName);
        setRenameMatrixName('');
        setRenameMatrixId('');
        setRenameMatrixModalIsOpen(false);
    }

    const handleMatrixNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRenameMatrixName(e.target.value);
    }

    const handleMatrixNameKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key !== 'Enter') return;
        handleSaveRenameMatrixModal();
    }

    const handleCloseRenameAnimationModal = () => {
        setRenameAnimationModalIsOpen(false);
    }

    const handleSaveRenameAnimationModal = () => {
        codingBackpackFoldersRenameFolder(codingProjectIndex, fileIndex, renameAnimationId, renameAnimationName);
        setRenameAnimationName('');
        setRenameAnimationId('');
        setRenameAnimationModalIsOpen(false);
    }

    const handleAnimationNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRenameAnimationName(e.target.value);
    }

    const handleAnimationNameKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key !== 'Enter') return;
        handleSaveRenameAnimationModal();
    }

    const [codingBackpackFolders, setBackpackFolders] = useState(createDraggable(codingBackpackFoldersProps));
    const [codingBackpackMatrixes, setBackpackMatrixes] = useState(createDraggable(codingBackpackMatrixesProps));
    const [selectedFolderId, setSelectedFolderId] = useState(selectedFolderIdProps)
    const [savedMatrixes, setSavedMatrixes] = useState(savedMatrixesProps);

    useEffect(() => {
        setBackpackFolders(createDraggable(codingBackpackFoldersProps));
        setBackpackMatrixes(createDraggable(codingBackpackMatrixesProps));
        setSavedMatrixes(savedMatrixesProps)
        setSelectedFolderId(selectedFolderIdProps)
    }, [ codingBackpackFoldersProps, codingBackpackMatrixesProps, selectedFolderIdProps, savedMatrixesProps, codingBackpackChangeSelectedMatrix, codingBackpackNavigateToFolder ])

    const moveBackpackFolder =  useCallback(({oldIndex, newIndex}: {oldIndex: number, newIndex: number}) => {
        if(oldIndex === newIndex) return;

        backpackFolderMoveFolder(codingProjectIndex, fileIndex, oldIndex, newIndex);
    }, [ backpackFolderMoveFolder, codingProjectIndex, fileIndex ]);

    const moveBackpackMatrix = useCallback(({oldIndex, newIndex}: {oldIndex: number, newIndex: number}) => {
        if(oldIndex === newIndex) return;
        //console.log(`Moving ${oldIndex} to ${newIndex}`);
        const matrixId = codingBackpackMatrixes[oldIndex].id;
        const oldFolderId = codingBackpackMatrixes[oldIndex].folderId;
        const newFolderId = codingBackpackMatrixes[newIndex].folderId;

        codingBackpackFoldersMoveMatrix(codingProjectIndex, fileIndex, matrixId, oldIndex, newIndex, oldFolderId, newFolderId);
        codingBackpackChangeSelectedMatrix(codingProjectIndex, fileIndex, matrixId);
    }, [ codingBackpackMatrixes, codingProjectIndex, fileIndex, codingBackpackFoldersMoveMatrix, codingBackpackChangeSelectedMatrix ]);

    const handleFolderSort = useCallback((state) => {
        moveBackpackFolder({oldIndex: state.oldIndex, newIndex: state.newIndex})
    }, [ moveBackpackFolder ]);

    const handleMatrixSort = useCallback((state) => {
        moveBackpackMatrix({oldIndex: state.oldIndex, newIndex: state.newIndex})
    }, [ moveBackpackMatrix ]);

    let savedFolderRanges: {[folderId in string]: {folderId: string; start: number; end: number}} = {};
    for(let i=0; i<savedMatrixes[1].length; i++) {
        const start = 50+(i*50);
        const end = start+(savedMatrixes[1][i].length-1);
        savedFolderRanges[savedMatrixes[1][i][0].folderId] = {
            folderId: savedMatrixes[1][i][0].folderId,
            start,
            end
        };
    };

    return (
        <div className="c-backpack__container">
            <div className="c-backpack">
                {
                    (() => {
                        return (
                            <ReactSortable className="c-backpack__folders" list={codingBackpackFolders} setList={() => {}} animation={300} onEnd={handleFolderSort} disabled={!allDragging}>
                                {
                                    codingBackpackFolders.map((folder, index) => (
                                        <BackpackItem codingProjectIndex={codingProjectIndex} type="folder" key={folder.id} id={folder.id} index={index}>
                                            <BackpackFolder 
                                                name={folder.id === 'root' ? folder.name : `${folder.name} | ${savedFolderRanges[folder.id].start}-${savedFolderRanges[folder.id].end}`} 
                                                selected={folder.id === selectedFolderId} 
                                                onClick={() => handleFolderClick(folder.id)} 
                                            />
                                        </BackpackItem>
                                    ))
                                }
                            </ReactSortable>
                        )
                    })()
                }
                <Menu id={`contextMenu__backpackItem__matrix${codingProjectIndex}`}>
                    <Item onClick={handleMatrixContextNew}>Create New Matrix</Item>
                    <Item onClick={handleMatrixContextDelete}>Delete Matrix</Item>
                    <Item onClick={handleMatrixContextDuplicate}>Duplicate Matrix</Item>
                    <Item onClick={handleMatrixContextExportFile}>Export Matrix</Item>
                </Menu>
                <Menu id={`contextMenu__backpackItem__matrixRoot${codingProjectIndex}`}>
                    <Item onClick={handleMatrixContextNew}>Create New Matrix</Item>
                    <Item onClick={handleMatrixContextRename}>Rename Matrix</Item>
                    <Item onClick={handleMatrixContextDelete}>Delete Matrix</Item>
                    <Item onClick={handleMatrixContextDuplicate}>Duplicate Matrix</Item>
                    <Item onClick={handleMatrixContextExportFile}>Export Matrix</Item>
                </Menu>
                <Menu id={`contextMenu__backpackItem__folder${codingProjectIndex}`}>
                    <Item onClick={handleAnimationContextRename}>Rename Animation</Item>
                    <Item onClick={handleAnimationContextDelete}>Delete Animation</Item>
                    <Item onClick={handleAnimationContextExportFile}>Export Animation</Item>
                </Menu>
                <Modal title="Rename Matrix" isOpen={renameMatrixModalIsOpen} closeModal={handleCloseNewMatrixModal} type="save" onSave={handleSaveRenameMatrixModal}>
                    <h5 style={{margin: 0}}>Matrix Name</h5>
                    <input autoFocus className="c-backpack__renameMatrixNameInput" type="text" value={renameMatrixName} onChange={handleMatrixNameChange} onKeyPress={handleMatrixNameKeyPress} />
                </Modal>
                <Modal title="Rename Animation" isOpen={renameAnimationModalIsOpen} closeModal={handleCloseRenameAnimationModal} type="save" onSave={handleSaveRenameAnimationModal}>
                    <h5 style={{margin: 0}}>Animation Name</h5>
                    <input autoFocus className="c-backpack__renameMatrixNameInput" type="text" value={renameAnimationName} onChange={handleAnimationNameChange} onKeyPress={handleAnimationNameKeyPress} />
                </Modal>
                {
                    (() => {
                        let classes = 'c-backpack__matrixes';
                        classes += animationPlaying ? ' c-backpack--disabled' : '';
                        return (
                            <ReactSortable 
                                className={classes} 
                                list={codingBackpackMatrixes} 
                                setList={() => {}} 
                                animation={300} 
                                onEnd={handleMatrixSort}
                                revertOnSpill={true}
                                disabled={!allDragging}
                                >
                                {
                                    codingBackpackMatrixes.map((matrix, index) => (
                                        <BackpackItem codingProjectIndex={codingProjectIndex} type="matrix" key={matrix.id} id={matrix.id} index={index} folderId={selectedFolderId}>
                                            <MatrixPreview 
                                                name={`${selectedFolderId === 'root' ? matrix.name : selectedFolderName} | ${selectedFolderId === 'root' ? index : savedFolderRanges[selectedFolderId].start+index}`} 
                                                stdBinary={matrix.stdBinary} selected={selectedMatrixId === matrix.id} 
                                                onClick={() => handleMatrixClick(index)} 
                                            />
                                        </BackpackItem>
                                    ))
                                }
                            </ReactSortable>
                        )
                    })()
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state: IRootState, ownProps: OwnProps) => {
    return {
        codingBackpackFoldersById: selectorGetMatrixEditor(ownProps.codingProjectIndex, ownProps.fileIndex)(state).backpack.folders.byIds,
        selectedFolderName: selectorGetBackpackSelectedFolderName(ownProps.codingProjectIndex, ownProps.fileIndex)(state),
        codingBackpackFoldersProps: selectorGetBackpackFolders(ownProps.codingProjectIndex, ownProps.fileIndex, ownProps.showRootFolder)(state),
        codingBackpackMatrixesProps: selectorGetBackpackMatrixesInSelectedFolder(ownProps.codingProjectIndex, ownProps.fileIndex)(state),
        codingBackpackMatrixesById: selectorGetMatrixEditor(ownProps.codingProjectIndex, ownProps.fileIndex)(state).backpack.matrixes.byIds,
        selectedFolderIdProps: selectorGetMatrixEditor(ownProps.codingProjectIndex, ownProps.fileIndex)(state).backpack.selectedFolderId,
        selectedMatrixId: selectorGetMatrixEditor(ownProps.codingProjectIndex, ownProps.fileIndex)(state).backpack.selectedMatrixId,
        animationPlaying: selectorGetMatrixEditor(ownProps.codingProjectIndex, ownProps.fileIndex)(state).animation.playing,
        savedMatrixesProps: selectorGetBackpackSavedMatrixes(ownProps.codingProjectIndex, ownProps.fileIndex)(state)
    }
}

const mapDispatchToProps = {
    codingBackpackNavigateToFolder,
    codingBackpackChangeSelectedMatrix,
    backpackFolderMoveFolder,
    codingBackpackFoldersMoveMatrix,
    codingBackpackDeleteFolder,
    codingBackpackDeleteMatrix,
    codingBackpackDuplicateMatrix,
    codingBackpackCreateMatrix,
    codingBackpackFoldersRenameFolder,
    codingBackpackMatrixesRenameMatrix
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Backpack);