import { IRootState } from '.';

export const STORE_IMPORT = 'STORE_IMPORT';

export interface IStoreImportAction {
    type: typeof STORE_IMPORT;
    data: IRootState
}

export const storeImport = (data: IRootState): IStoreImportAction => {
    return {
        type: STORE_IMPORT,
        data
    }
}