import React, { } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState, projectSetDeviceConnected } from 'store';
import { DeviceConnectionManager } from 'lib/DeviceConnection';

import Button from 'components/Button/Button';

import './ConnectPhase.scss';

type Props = PropsFromRedux & {
}

const ConnectingPhase: React.FC<Props> = ({ projectSetDeviceConnected, deviceConnectionMethod }) => {

    const onStopClick = () => {
        projectSetDeviceConnected(false);
        DeviceConnectionManager.disconnect();
    }

    return (
        <div className="c-connectingPhase">
            <b>Connecting...</b>
            {
                (deviceConnectionMethod === 'serial' || deviceConnectionMethod === 'modem') && (
                    <>
                        <p className='mt-2'>Make sure that you have the USB driver installed for your eBot and are using Chrome or Edge.</p>
                        <p className='mb-2 mt-1'>If you do not have the USB driver already, it can be installed here:</p>
                        <div className='flex flex-col'>
                            <a className='py-1' href="https://scratchlink.au/wp-content/uploads/2023/04/CP210x_Windows_Drivers.zip" target='_blank'>
                                Windows Download
                            </a>
                            <a className='py-1' href="https://scratchlink.au/wp-content/uploads/2023/04/Mac_OSX_VCP_Driver.zip" target='_blank'>
                                Mac Download
                            </a>
                        </div>  
                    </>
                )
            }
            {
                deviceConnectionMethod === 'ap' && (
                    <p>Make sure that your eBot is connected to eBot Wifi Hotpot first.</p>
                )
            }
            {
                deviceConnectionMethod === 'ip' && (
                    <p>Make sure that your eBot is connected to the same Wifi Network as your eBot Device.</p>
                )
            }
            <Button type="danger" addStyles={['square', 'full width']} onClick={onStopClick}>Stop</Button>
        </div>
    )
}

const mapStateToProps = (state: IRootState) => {
    return {
        deviceConnectionMethod: state.project.deviceConnectionMethod
    }
}

const mapDispatchToProps = {
    projectSetDeviceConnected
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ConnectingPhase);