import { createSelector } from 'reselect';
import { IRootState, ICodingProject } from 'store';

export const selectorGetActiveHardwareBlockLevelDisplayNames = createSelector(
    (state: IRootState) => ({ coding: state.coding, scratchlinkConfig: state.project.scratchlinkConfig }), 
    ({ coding, scratchlinkConfig }): [string, string] => {
        const project = coding.codingProjects[coding.activeProjectIndex] as ICodingProject;
        
        if(project.type === 'matrix') return ['Matrix Hardware', ''];
        if(project.type === 'js' || project.type === 'jsscratch') return ['javascript', project.blockLevelMode];
        if(project.type === 'flowChart') return ['Flow Chart', ''];
        if(project.type === 'portfolio') return ['Portfolio', ''];

        const deviceProfile = scratchlinkConfig.deviceProfiles.find((deviceProfile: any) => deviceProfile.profile === project.hardwareMode);
        if(!deviceProfile) throw new Error(`No eBot profile found matching profile "${project.hardwareMode}" in editConfig`);

        return [deviceProfile.displayName, project.blockLevelMode];
    }
);