import { ICodingMatrixEditorAction } from '../../';

export const CODING_PROJECT_FILE_BACKPACKFOLDERS_CREATE_FOLDER = 'CODING/PROJECT/FILE/MATRIXEDITOR/BACKPACK/BACKPACKFOLDERS/CREATE_FOLDER';
export const CODING_PROJECT_FILE_BACKPACKFOLDERS_MOVE_FOLDER = 'CODING/PROJECT/FILE/MATRIXEDITOR/BACKPACK/BACKPACKFOLDERS/MOVE_FOLDER';
export const CODING_PROJECT_FILE_BACKPACKFOLDERS_DELETE_FOLDER = 'CODING/PROJECT/FILE/MATRIXEDITOR/BACKPACK/BACKPACKFOLDERS/DELETE_FOLDER';
export const CODING_PROJECT_FILE_BACKPACKFOLDERS_RENAME_FOLDER = 'CODING/PROJECT/FILE/MATRIXEDITOR/BACKPACK/BACKPACKFOLDERS/RENAME_FOLDER';
export const CODING_PROJECT_FILE_BACKPACKFOLDERS_MOVE_MATRIX = 'CODING/PROJECT/FILE/MATRIXEDITOR/BACKPACK/BACKPACKFOLDERS/MOVE_MATRIX';
export const CODING_PROJECT_FILE_BACKPACKFOLDERS_ADD_MATRIX = 'CODING/PROJECT/FILE/MATRIXEDITOR/BACKPACK/BACKPACKFOLDERS/ADD_MATRIX';
export const CODING_PROJECT_FILE_BACKPACKFOLDERS_DELETE_MATRIX = 'CODING/PROJECT/FILE/MATRIXEDITOR/BACKPACK/BACKPACKFOLDERS/DELETE_MATRIX';

export interface ICodingBackpackFoldersCreateFolderAction extends ICodingMatrixEditorAction {
    type: typeof CODING_PROJECT_FILE_BACKPACKFOLDERS_CREATE_FOLDER;
    name: string;
    folderId?: string;
}
export interface ICodingBackpackFoldersMoveFolderAction extends ICodingMatrixEditorAction {
    type: typeof CODING_PROJECT_FILE_BACKPACKFOLDERS_MOVE_FOLDER;
    oldIndex: number;
    newIndex: number;
}

export interface ICodingBackpackFoldersDeleteFolderAction extends ICodingMatrixEditorAction {
    type: typeof CODING_PROJECT_FILE_BACKPACKFOLDERS_DELETE_FOLDER;
    folderId: string;
}

export interface ICodingBackpackFoldersRenameFolderAction extends ICodingMatrixEditorAction {
    type: typeof CODING_PROJECT_FILE_BACKPACKFOLDERS_RENAME_FOLDER;
    folderId: string;
    name: string;
}

export interface ICodingBackpackFoldersMoveMatrixAction extends ICodingMatrixEditorAction {
    type: typeof CODING_PROJECT_FILE_BACKPACKFOLDERS_MOVE_MATRIX;
    matrixId: string;
    oldIndex: number;
    newIndex: number;
    oldFolderId: string;
    newFolderId: string;
}

export interface ICodingBackpackFoldersAddMatrixAction extends ICodingMatrixEditorAction {
    type: typeof CODING_PROJECT_FILE_BACKPACKFOLDERS_ADD_MATRIX;
    matrixId: string;
    folderId: string;
}

export interface ICodingBackpackFoldersDeleteMatrixAction extends ICodingMatrixEditorAction {
    type: typeof CODING_PROJECT_FILE_BACKPACKFOLDERS_DELETE_MATRIX;
    matrixId: string;
    folderId: string;
}

export type ICodingBackpackFoldersActionTypes = ICodingBackpackFoldersCreateFolderAction | ICodingBackpackFoldersMoveFolderAction | ICodingBackpackFoldersDeleteFolderAction | ICodingBackpackFoldersRenameFolderAction | ICodingBackpackFoldersMoveMatrixAction | ICodingBackpackFoldersAddMatrixAction | ICodingBackpackFoldersDeleteMatrixAction;

/**
 * Data
 */

export interface ICodingBackpackFolder {
    id: string;
    name: string;
    matrixIds: string[];
}

export interface ICodingBackpackFoldersState {
    byIds: {
        [id: string]: ICodingBackpackFolder
    },
    allIds: string[]
}