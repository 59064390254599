import React, { useState, useEffect, memo } from 'react';
import memoize from 'memoize-one';
import { FixedSizeList as List, areEqual, ListChildComponentProps } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import { connect, ConnectedProps } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { IRootState, IConsoleLog, IConsoleSource, consoleAddConsoleLog, consoleClearConsole } from 'store';

import ConsoleDecorator from 'components/ConsoleDecorator/ConsoleDecorator';

import './Console.scss';

type Props = PropsFromRedux & {
    acceptedSources: IConsoleSource[];
    name?: string;
    style?: React.CSSProperties;
    options?: {
        rawPacketTick?: boolean;
        rawPacketDefault?: boolean;
    }
}

const createConsoleRowData = memoize((messages: IConsoleLog[], shouldDecorate: boolean, acceptedSources: IConsoleSource[]) => ({
    messages: messages.filter(message => acceptedSources.includes(message.source)),
    shouldDecorate
}));

const CodeConsole: React.FC<Props> = ({ name, messages, acceptedSources, consoleClearConsole, style, options}) => {
    const [shouldScroll, setShouldScroll] = useState<boolean>(true);
    const [shouldDecorate, setShouldDecorate] = useState<boolean>(options?.rawPacketDefault || false);
    const listRef = React.useRef(null);

    const consoleRowData = createConsoleRowData(messages, shouldDecorate, acceptedSources);
    
    useEffect(() => {
        //@ts-ignore
        if(shouldScroll) listRef?.current?.scrollToItem(consoleRowData.messages.length)
    }, [shouldScroll, consoleRowData.messages]);

    const handleShouldScrollChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setShouldScroll(e.target.checked);
    }

    const handleShouldDecorateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setShouldDecorate(e.target.checked);
    }

    const handleClearClick = () => {
        consoleClearConsole();
    }

    return (
        <div className="c-console" style={style}>
            <div className="c-console__header">
                <h4>{name ? name : 'Console'}</h4>
                <input type="checkbox" defaultChecked={shouldScroll} onChange={handleShouldScrollChange}/>
                Scroll
                {
                    (options?.rawPacketTick === undefined || options.rawPacketTick) && (
                        <>
                            <input type="checkbox" defaultChecked={shouldDecorate} onChange={handleShouldDecorateChange}/>
                            Raw Packets
                        </>
                    )
                }
                <span className="c-console__clearConsole" onClick={handleClearClick}><FontAwesomeIcon icon={faTrash} /> Clear</span>
            </div>
            <AutoSizer>
                {
                    ({height, width}) => (
                        <List
                            className="c-console__body"
                            height={height-30}
                            itemCount={consoleRowData.messages.length}
                            itemSize={20}
                            width={width}
                            itemData={consoleRowData}
                            itemKey={(index, {messages}) => messages[index].id}
                            overscanCount={2}
                            ref={listRef}
                        >
                            { ConsoleRow }
                        </List>
                    )
                }
            </AutoSizer>
        </div>
    )
}

const ConsoleRow = memo<ListChildComponentProps<{messages: IConsoleLog[], shouldDecorate: boolean}>>(({data, index, style}) => {   
    const { messages, shouldDecorate } = data;
    const log = messages[index];
    
    return (
        <ConsoleDecorator key={log.id} style={style} className='c-console__log' msg={log.content} timeStamp={log.timestamp} decorator={log.decorator} shouldDecorate={shouldDecorate} />
    );
}, areEqual);

const mapStateToProps = (state: IRootState): StateProps => {
    return {
        messages: state.console.messages
    }
}

const mapDispatchToProps = {
    consoleAddConsoleLog,
    consoleClearConsole
}

interface StateProps {
    messages: IConsoleLog[];
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CodeConsole);