import React, { useState } from 'react';
import shortid from 'shortid';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState, ICodingProjectType, codingSetCreateProjectModelOpen, codingAddProject, codingSetActiveProjectIndex, matrixEditorInitialState, GamePadTypes } from 'store';
import { FaCheckCircle } from 'react-icons/fa';

import { IProjectsDataExampleProjects, IProjectsDataGamepadExampleProjects } from 'typings/scratchlink-projects-data';

import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';

const ScratchLinkProjectData = require('scratchlink-projects-data/exampleProjects.js');
const ExampleProjectsJS: IProjectsDataExampleProjects = ScratchLinkProjectData.getExampleProjectsByApp('webappJS');
const ExampleProjectsPY: IProjectsDataExampleProjects = ScratchLinkProjectData.getExampleProjectsByApp('webappPY');
const GamepadTypes: {[x in string]: string} = require('scratchlink-projects-data/constants').GAMEPAD_TYPES;
const GamepadExampleProjects: IProjectsDataGamepadExampleProjects = ScratchLinkProjectData.GamepadExampleProjects;
const HardwareTypes: {[x in string]: string} = require('scratchlink-projects-data/constants').HARDWARE_TYPES;

type OwnProps = {

}

type Props = PropsFromRedux & OwnProps;

enum PhaseEnum {
    'coding project type' = 0,
    'gamepad',
    'hardware',
    'challenge',
    'project type',
    'block level',
    'project name'
}

type Phase = keyof typeof PhaseEnum;

type ProjectType = ICodingProjectType | 'js+html';

const CodingProjectCreateModal: React.FC<Props> = ({ isOpen, codingProjectsLength, codingSetCreateProjectModelOpen, codingAddProject, codingSetActiveProjectIndex, hardwareModeAutodetected, gamepad0Type }) => {
    const [modalPhase, setModalPhase] = useState<Phase>('coding project type');
    const [gamepadState, setGamepadState] = useState('')
    const [hardwareState, setHardwareState] = useState('');
    const [challengeState, setChallengeState] = useState('');
    const [projectTypeState, setProjectTypeState] = useState('');
    const [blockLevelState, setBlockLevelState] = useState('');

    const [projectType, setProjectType] = useState<ProjectType | undefined>()
    const [projectName, setProjectName] = useState('');
    
    let ExampleProjects = ExampleProjectsJS;
    if(projectType === 'hardwarepy') ExampleProjects = ExampleProjectsPY;

    const onBack = () => {
        if(projectType === 'hardwarejs' || projectType === 'hardwarepy') {
            const backStageIndex = PhaseEnum[modalPhase] - 1;
            setModalPhase(PhaseEnum[backStageIndex] as Phase);
            return;
        }

        setModalPhase('coding project type');
    }

    const onCodingProjectTypeClick = (projectType: ProjectType) => {
        setProjectType(projectType);
        if(
            projectType === 'hardwarejs' || 
            projectType === 'hardwarepy' ||
            projectType === 'js+html' || 
            projectType === 'jsscratch' || 
            projectType === 'js'
        ) setModalPhase('gamepad');
        else setModalPhase('project name');
    }

    const onHardwareClick = (hardware: string) => {
        setHardwareState(hardware);
        setModalPhase('challenge');
    }

    const onGamepadClick = (gamepad: string) => {
        setGamepadState(gamepad);
        if(projectType === 'hardwarejs' || projectType === 'hardwarepy') return setModalPhase('hardware');
        setModalPhase('project name');
    }

    const onChallengeClick = (challenge: string) => {
        setChallengeState(challenge);
        setModalPhase('project type');

        if(gamepadState !== GamepadTypes.no_gamepad) return;

        const projectTypes = ExampleProjects[hardwareState][challenge];
        if(Object.values(projectTypes).length === 1 && projectTypes['Custom']) { //Skip Custom if only choice
            setProjectTypeState('Custom');
            setModalPhase('block level');
        }
    }

    const onProjectTypeClick = (projectType: string) => {
        setProjectTypeState(projectType);
        setModalPhase('block level');
    }

    const onBlockLevelClick = (blockLevel: string) => {
        setBlockLevelState(blockLevel);
        setModalPhase('project name');
    }

    const handleProjectNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProjectName(e.target.value);
    }

    const handleKeyPressProjectName = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key !== 'Enter') return;
        onProjectNameCreateClick();
    }

    const onProjectNameCreateClick = () => {
        if(projectType === 'hardwarejs' || projectType === 'hardwarepy') return onHardwareProjectCreate();
        if(projectType === 'js+html') return onHTMLHardwareCreate();
        if(projectType === 'jsscratch') return onJSScratchCreate();
        if(projectType === 'matrix') return onMatrixHardwareCreate();
        if(projectType === 'flowChart') return onFlowChartCreate();
        if(projectType === 'portfolio') return onPortfolioCreate();
    }

    const onHardwareProjectCreate = () => {
        //let exampleProject: IProjectsDataExampleProject | null = null;
        let exampleProject: any = null;

        //get gamepad mode from gamepad display name
        const gamepadMode = Object.keys(GamepadTypes).find(key => GamepadTypes[key] === gamepadState) as GamePadTypes;

        exampleProject = ExampleProjects[hardwareState][challengeState];

        if(gamepadState !== GamepadTypes.no_gamepad && challengeState === 'Remote Control') exampleProject = GamepadExampleProjects[gamepadState];
        exampleProject = exampleProject[projectTypeState][blockLevelState];

        if(projectType === 'hardwarejs' && exampleProject.webappJS) exampleProject = exampleProject.webappJS;
        if(projectType === 'hardwarepy' && exampleProject.webappPY) exampleProject = exampleProject.webappPY;
        if(exampleProject === null) throw new Error('Edge case found')

        //get hardware mode from hardware display name
        const hardwareMode = Object.keys(HardwareTypes).find(key => HardwareTypes[key] === hardwareState);

        setHardwareState('');
        setChallengeState('');
        setProjectTypeState('');
        setModalPhase('coding project type');
        setProjectType(undefined);
        setProjectName('');

        codingSetCreateProjectModelOpen(false);
        codingAddProject({
            ...exampleProject.codingProject,
            name: projectName,
            gamepadMode,
            hardwareMode, //use selected hardware not hardware from example project file
        });
        codingSetActiveProjectIndex(codingProjectsLength)
    }

    const onMatrixHardwareCreate = () => {
        codingSetCreateProjectModelOpen(false);
        
        codingAddProject({
            name: projectName,
            id: shortid(),
            type: 'matrix',
            files: [
                {
                    name: 'Matrix Editor',
                    id: shortid(),
                    type: 'matrixEditor',
                    matrixEditorData: {
                        matrixEditor: matrixEditorInitialState
                    }
                }
            ],
            activeFileIndex: 0,
            hardwareMode: '',
            blockLevelMode: 'Beginner',
            gamepadMode: 'no_gamepad',
            projectVersion: '1.0'
        });
        codingSetActiveProjectIndex(codingProjectsLength);

        setModalPhase('coding project type');
        setProjectType(undefined);
        setProjectName('');
    }

    const onJSScratchCreate = () => {
        codingSetCreateProjectModelOpen(false);
        
        //get gamepad mode from gamepad display name
        const gamepadMode = Object.keys(GamepadTypes).find(key => GamepadTypes[key] === gamepadState) as GamePadTypes;

        codingAddProject({
            name: projectName,
            id: shortid(),
            type: 'jsscratch',
            files: [
                {
                    name: 'Main',
                    id: shortid(),
                    type: 'js',
                    jsData: {
                        code: `console.log('hello world');`,
                    }
                },
                {
                    name: 'Sprites',
                    id: shortid(),
                    type: 'assetManager',
                    assetManagerData: {
                        sprites: [],
                        backdrops: []
                    }
                },
                {
                    name: 'Notes',
                    id: shortid(),
                    type: 'text',
                    textData: {
                        text: ''
                    }
                },
                {
                    name: 'Flow Chart 1',
                    id: shortid(),
                    flowChartData: {
                        data: {}
                    },
                    type: 'flowChart'
                }
            ],
            activeFileIndex: 0,
            hardwareMode: 'javascript',
            blockLevelMode: 'Beginner',
            gamepadMode: gamepadMode,
            projectVersion: '1.0'
        });
        codingSetActiveProjectIndex(codingProjectsLength);

        setModalPhase('coding project type');
        setProjectType(undefined);
        setProjectName('');
    }

    const onHTMLHardwareCreate = () => {
        codingSetCreateProjectModelOpen(false);

        //get gamepad mode from gamepad display name
        const gamepadMode = Object.keys(GamepadTypes).find(key => GamepadTypes[key] === gamepadState) as GamePadTypes;
        
        codingAddProject({
            name: projectName,
            id: shortid(),
            type: 'js',
            files: [
                {
                    name: 'HTML',
                    id: shortid(),
                    type: 'html',
                    htmlData: {
                        html: `<html>\n    <head>\n        <title>JS Project</title>\n    </head>\n    <body>\n\n    </body>\n</html>`
                    }
                },
                {
                    name: 'Main',
                    id: shortid(),
                    type: 'js',
                    jsData: {
                        code: 'console.log("hello world");',
                    }
                },
                {
                    name: 'Image Manager',
                    id: shortid(),
                    type: 'imageManager',
                    imageData: {
                        images: []
                    }
                },
                {
                    name: 'Notes',
                    id: shortid(),
                    type: 'text',
                    textData: {
                        text: ''
                    }
                },
                {
                    name: 'Flow Chart 1',
                    id: shortid(),
                    flowChartData: {
                        data: {}
                    },
                    type: 'flowChart'
                }
            ],
            activeFileIndex: 0,
            hardwareMode: 'javascript',
            blockLevelMode: 'Beginner',
            gamepadMode: gamepadMode,
            projectVersion: '1.0'
        });
        codingSetActiveProjectIndex(codingProjectsLength);

        setModalPhase('coding project type');
        setProjectType(undefined);
        setProjectName('');
    }

    const onFlowChartCreate = () => {
        codingSetCreateProjectModelOpen(false);
        
        codingAddProject({
            name: projectName,
            id: shortid(),
            type: 'flowChart',
            files: [
                {
                    name: 'Flow Chart 1',
                    id: shortid(),
                    type: 'flowChart',
                    flowChartData: {
                        data: {}
                    }
                }
            ],
            activeFileIndex: 0,
            hardwareMode: 'javascript',
            blockLevelMode: 'Beginner',
            gamepadMode: 'no_gamepad',
            projectVersion: '1.0'
        });
        codingSetActiveProjectIndex(codingProjectsLength);

        setModalPhase('coding project type');
        setProjectType(undefined);
    }

    const onPortfolioCreate = () => {
        codingSetCreateProjectModelOpen(false);
        
        codingAddProject({
            name: projectName,
            id: shortid(),
            type: 'portfolio',
            files: [
                {
                    name: 'Portfolio',
                    id: shortid(),
                    type: 'text',
                    textData: {
                        text: ''
                    }
                }
            ],
            activeFileIndex: 0,
            hardwareMode: 'javascript',
            blockLevelMode: 'Beginner',
            gamepadMode: 'no_gamepad',
            projectVersion: '1.0'
        });
        codingSetActiveProjectIndex(codingProjectsLength);

        setModalPhase('coding project type');
        setProjectType(undefined);
    }

    let modalTitle = 'New Project | Choose';
    if(modalPhase === 'gamepad') modalTitle = 'Choose GamePad';
    if(modalPhase === 'hardware') modalTitle = 'Choose Hardware';
    if(modalPhase === 'challenge') modalTitle = `Choose Challenge`;
    if(modalPhase === 'project type') modalTitle = `Choose Project Type`;
    if(modalPhase === 'block level') modalTitle = `Choose Challenge Level`;
    if(modalPhase === 'project name') modalTitle = `Set Project Name`;

    return (
        <>
            <Modal 
                title={modalTitle}
                isOpen={isOpen} 
                closeModal={() => codingSetCreateProjectModelOpen(false)} 
                type={modalPhase !== 'coding project type' ? 'back' : 'default'} 
                onBack={onBack} 
                size='md'
            >
                {
                    modalPhase === 'coding project type'&& (
                        <div className="p-1">
                            <div className="flex flex-col justify-center">
                                <div className="flex flex-wrap justify-center pb-3">
                                    <Button type='info' className="m-1" onClick={() => onCodingProjectTypeClick('hardwarejs')}>
                                        JS4eBot
                                    </Button>
                                    <Button type='info' className="m-1" onClick={() => onCodingProjectTypeClick('jsscratch')}>
                                        JS4Scratch
                                    </Button>
                                    <Button type='info' className="m-1" onClick={() => onCodingProjectTypeClick('js+html')}>
                                        HTML&JS
                                    </Button>
                                </div>
                                <div className="w-full flex flex-wrap justify-center py-3 border-t-2">
                                    <Button type='info' className="m-1" onClick={() => onCodingProjectTypeClick('hardwarepy')}>
                                        PY4eBot
                                    </Button>
                                </div>
                                <div className="w-full flex flex-wrap justify-center pt-3 border-t-2">
                                    <Button type='info' className="m-1" onClick={() => onCodingProjectTypeClick('matrix')}>
                                        Matrix Only
                                    </Button>
                                    <Button type='info' className="m-1" onClick={() => onCodingProjectTypeClick('flowChart')}>
                                        Flow Chart Only
                                    </Button>
                                    <Button type='info' className="m-1" onClick={() => onCodingProjectTypeClick('portfolio')}>
                                        Portfolio
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    modalPhase === 'gamepad' && (
                        <div className="p-1 ">
                            <div className="flex justify-center">
                                <div className="flex flex-wrap justify-center overflow-y-auto" style={{maxHeight: '50vh'}}>
                                    <Button type='info' className="m-1" style={{width: '35%', flexBasis: '40%'}} onClick={() => onGamepadClick(GamepadTypes.no_gamepad)}>
                                        {GamepadTypes.no_gamepad}
                                    </Button>
                                    {
                                        Object.keys(GamepadExampleProjects).map((gampadName) => (
                                            <Button 
                                                key={gampadName} 
                                                type={GamepadTypes[gamepad0Type] === gampadName ? 'success': 'info'}
                                                className="m-1 flex justify-center" 
                                                style={{width: '35%', flexBasis: '40%'}} 
                                                onClick={() => onGamepadClick(gampadName)}
                                            >
                                                {
                                                    GamepadTypes[gamepad0Type] === gampadName && <FaCheckCircle className='text-lg mr-1 text-white' />
                                                }
                                                {gampadName}
                                            </Button>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    modalPhase === 'hardware' && (
                        <div className="p-1 ">
                            <div className="flex justify-center">
                                <div className="flex flex-wrap justify-center overflow-y-auto" style={{maxHeight: '50vh'}}>
                                    {
                                        Object.keys(ExampleProjects).filter(hardware => !hardware.includes('Simulator')).map(hardware => (
                                            <Button 
                                                key={hardware} 
                                                type={HardwareTypes[hardwareModeAutodetected] === hardware ? 'success' : 'info'}
                                                className="m-1 flex justify-center"  
                                                style={{width: '35%', flexBasis: '40%'}} 
                                                onClick={() => onHardwareClick(hardware)}
                                            >
                                                {
                                                    HardwareTypes[hardwareModeAutodetected] === hardware && <FaCheckCircle className='text-lg mr-1 text-white' />
                                                }
                                                {hardware}
                                            </Button>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    modalPhase === 'challenge' && (
                        <div className="p-1 flex justify-center">
                            <div className="overflow-y-auto" style={{maxHeight: '50vh'}}>
                                {
                                    gamepadState === GamepadTypes.no_gamepad && Object.keys(ExampleProjects[hardwareState]).map(challenge => (
                                        <Button key={challenge} type='info' className="m-1" style={{width: '90%'}} onClick={() => onChallengeClick(challenge)}>
                                            {challenge}
                                        </Button>
                                    ))
                                }
                                {
                                    gamepadState !== GamepadTypes.no_gamepad && (
                                        <>
                                            {
                                                Object.keys(ExampleProjects[hardwareState]).filter(challenge => challenge !== 'Remote Control').map(challenge => (
                                                    <Button key={challenge} type='info' className="m-1" style={{width: '90%'}} onClick={() => onChallengeClick(challenge)}>
                                                        {challenge}
                                                    </Button>
                                                ))
                                            }
                                            <Button type='info' className="m-1" style={{width: '90%'}} onClick={() => onChallengeClick('Remote Control')}>
                                                Remote Control
                                            </Button>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    )
                }
                {
                    modalPhase === 'project type' && (
                        <div className="p-1 flex justify-center">
                            <div className="overflow-y-auto" style={{maxHeight: '50vh'}}>
                                {
                                    challengeState !== 'Remote Control' && Object.keys(ExampleProjects[hardwareState][challengeState]).map(projectType => (
                                        <Button key={projectType} type='info' className="m-1" style={{width: '90%'}} onClick={() => onProjectTypeClick(projectType)}>
                                            {projectType}
                                        </Button>
                                    ))
                                }
                                {
                                    challengeState === 'Remote Control' && gamepadState === GamepadTypes.no_gamepad && Object.keys(ExampleProjects[hardwareState][challengeState]).map(projectType => (
                                        <Button key={projectType} type='info' className="m-1" style={{width: '90%'}} onClick={() => onProjectTypeClick(projectType)}>
                                            {projectType}
                                        </Button>
                                    ))
                                }
                                {
                                    challengeState === 'Remote Control' && gamepadState !== GamepadTypes.no_gamepad && Object.keys(GamepadExampleProjects[gamepadState]).map(projectType => (
                                        <Button key={projectType} type='info' className="m-1" style={{width: '90%'}} onClick={() => onProjectTypeClick(projectType)}>
                                            {projectType}
                                        </Button>
                                    ))
                                }
                            </div>
                        </div>
                    )
                }
                {
                    modalPhase === 'block level' && (
                        <div className="p-1 flex justify-center">
                            <div className="overflow-y-auto" style={{maxHeight: '50vh'}}>
                                {
                                    challengeState !== 'Remote Control' && gamepadState !== GamepadTypes.no_gamepad && Object.keys(ExampleProjects[hardwareState][challengeState][projectTypeState]).map(blockLevel => (
                                        <Button key={blockLevel} type='info' className="m-1" style={{width: '90%'}} onClick={() => onBlockLevelClick(blockLevel)}>
                                            {blockLevel}
                                        </Button>
                                    ))
                                }
                                {
                                    challengeState !== 'Remote Control' && gamepadState === GamepadTypes.no_gamepad && Object.keys(ExampleProjects[hardwareState][challengeState][projectTypeState]).map(blockLevel => (
                                        <Button key={blockLevel} type='info' className="m-1" style={{width: '90%'}} onClick={() => onBlockLevelClick(blockLevel)}>
                                            {blockLevel}
                                        </Button>
                                    ))
                                }
                                {
                                    challengeState === 'Remote Control' && gamepadState !== GamepadTypes.no_gamepad && Object.keys(GamepadExampleProjects[gamepadState][projectTypeState]).map(blockLevel => (
                                        <Button key={blockLevel} type='info' className="m-1" style={{width: '90%'}} onClick={() => onBlockLevelClick(blockLevel)}>
                                            {blockLevel}
                                        </Button>
                                    ))
                                }
                            </div>
                        </div>
                    )
                }
                {
                    modalPhase === 'project name' && (
                        <>
                            <div className='w-full flex justify-center'>
                                <input autoFocus className='border-2 p-2 text-lg' type="text" placeholder='Project Name' value={projectName} onChange={handleProjectNameChange} onKeyPress={handleKeyPressProjectName} />
                                <Button className='ml-2' type='success' onClick={onProjectNameCreateClick}>Create</Button>
                            </div>
                        </>
                    )
                }
            </Modal>
        </>
    )
}

const mapStateToProps = (state: IRootState, ownProps: OwnProps) => {
    return {
        isOpen: state.coding.createProjectModalOpen,
        codingProjectsLength: state.coding.codingProjects.length,
        hardwareModeAutodetected: state.project.hardwareModeAutoDetected,
        gamepad0Type: state.project.gamepad0.type
    }
}

const mapDispatchToProps = {
    codingSetCreateProjectModelOpen,
    codingAddProject,
    codingSetActiveProjectIndex
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CodingProjectCreateModal);