export const monacoTypeScratchLinkGamepadDataStandard = `
{
    lhsJoystick: {
        analog: {
            x: number;
            y: number;
            distance: number;
            direction: number;
        },
        digital: {
            button: boolean;
            up: boolean;
            down: boolean;
            left: boolean;
            right: boolean;
            noInput: boolean;
        }
    },
    rhsJoystick: {
        analog: {
            x: number;
            y: number;
            distance: number;
            direction: number;
        },
        digital: {
            button: boolean;
            up: boolean;
            down: boolean;
            left: boolean;
            right: boolean;
            noInput: boolean;
        }
    },
    dPad: {
        up: boolean;
        down: boolean;
        left: boolean;
        right: boolean;
        noInput: boolean;
    },
    a: boolean;
    b: boolean;
    x: boolean;
    y: boolean;
    bumper: {
        leftTop: boolean;
        leftBottom: boolean;
        rightTop: boolean;
        rightBottom: boolean;
    },
    start: boolean;
    select: boolean;
}
`;

export const monacoTypeScratchLinkGamepadDataSlClassic = `
{
    lhsJoystick: {
        analog: {
            x: number;
            y: number;
            distance: number;
            direction: number;
        },
        digital: {
            up: boolean;
            down: boolean;
            left: boolean;
            right: boolean;
            noInput: boolean;
        }
    },
    rhsJoystick: {
        analog: {
            x: number;
            y: number;
            distance: number;
            direction: number;
        },
        digital: {
            up: boolean;
            down: boolean;
            left: boolean;
            right: boolean;
            noInput: boolean;
        }
    },
    joystick: {
        analog: {
            x: number;
            y: number;
            distance: number;
            direction: number;
        },
        digital: {
            button: boolean;
            up: boolean;
            down: boolean;
            left: boolean;
            right: boolean;
            noInput: boolean;
        }
    },
    dPad: {
        up: boolean;
        down: boolean;
        left: boolean;
        right: boolean;
        noInput: boolean;
    },
    a: boolean;
    b: boolean;
    x: boolean;
    y: boolean;
    red: boolean;
    white: boolean;
    black: boolean;
    blue: boolean;
    bumper: {
        leftTop: boolean;
        leftBottom: boolean;
        rightTop: boolean;
        rightBottom: boolean;
    },
    start: boolean;
    select: boolean;
    home: boolean;
}
`;

export const monacoTypeScratchLinkGamepadDataSlNunchuk = `
{
    nunJoystick: {
        analog: {
            x: number;
            y: number;
            distance: number;
            direction: number;
        },
        digital: {
            up: boolean;
            down: boolean;
            left: boolean;
            right: boolean;
            noInput: boolean;
        }
    },
    gyro: {
        analog: {
            x: number;
            y: number;
        },
        digital: {
            up: boolean;
            down: boolean;
            left: boolean;
            right: boolean;
        }
    },
    joystick: {
        analog: {
            x: number;
            y: number;
            distance: number;
            direction: number;
        },
        digital: {
            button: boolean;
            up: boolean;
            down: boolean;
            left: boolean;
            right: boolean;
            noInput: boolean;
        }
    },
    bumper: {
        top: boolean;
        bottom: boolean;
    }
    red: boolean;
    white: boolean;
    black: boolean;
    blue: boolean;
}`;

export const monacoTypeScratchLinkGamepadDataRetro = `
{
    dPad: {
        up: boolean;
        down: boolean;
        left: boolean;
        right: boolean;
        noInput: boolean;
    },
    a: boolean;
    b: boolean;
    x: boolean;
    y: boolean;
    bumper: {
        left: boolean;
        right: boolean;
    },
    start: boolean;
    select: boolean;
}
`