import React, { Children } from 'react';
import { useDrag } from 'react-dnd';
import { contextMenu } from 'react-contexify';

import './BackpackItem.scss';

interface BackpackItemProps {
    codingProjectIndex: number;
    children: any;
    id: string;
    index: number;
    type: 'matrix' | 'folder';
    endDrag?: (item: any) => void;
    folderId?: string;
}

const BackpackItem: React.FC<BackpackItemProps> = ({ codingProjectIndex, children, type, endDrag, id, index, folderId, ...otherProps }: BackpackItemProps) => {
    const child = Children.only(children); //Get only child

    const [, drag] = useDrag({
        item: { type, index },
        end: endDrag
    });

    const handleEvent = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();

        let contextId = 'contextMenu__backpackItem__';
        if(type === 'matrix' && folderId === 'root')  contextId += 'matrixRoot';
        else contextId += type;

        contextId += codingProjectIndex;

        contextMenu.show({
            id: contextId,
            event: e,
            props: {
                id
            }
        });
      };

    if(type === 'folder' && index === 0) return (
        <div className="c-backpackItem" ref={drag} {...otherProps}>
            { child }
        </div>
    )

    return (
        <div className="c-backpackItem" ref={drag} {...otherProps} onContextMenu={handleEvent}>
            { child }
        </div>
    )
};

export default BackpackItem;