import immer from 'immer';
import scratchlinkConfig from 'scratchlinkcodes-config/config.json';
import { STORE_IMPORT } from '../';

import numberMap from 'utils/numberMap';
import clamp from 'utils/clamp';

import { IProjectState, IProjectActions } from '.';

import {
    PROJECT_SET_PAGE_DIMENSIONS,
    PROJECT_SET_RIGHT_PANEL_DIMENSIONS,
    PROJECT_SET_CODING_NAVBAR_COLLAPSED,
    PROJECT_SET_CODING_HELP_POPOUT_SELECTED_SECTION_INDEX,
    PROJECT_SET_DEVICE_CONNECTED,
    PROJECT_SET_DEVICE_CONNECTING,
    PROJECT_SET_DEVICE_CONNECTION_METHOD,
    PROJECT_SET_DEVICE_DIRECT_IP,
    PROJECT_SET_DEVICE_SERIAL_PORT,
    PROJECT_SET_DEVICE_NAME,
    PROJECT_SET_DEVICE_BATTERY_VOLTAGE,
    PROJECT_SET_HARDWARE_MODE,
    PROJECT_SET_BLOCK_LEVEL_MODE,
    PROJECT_SET_HARDWARE_MODE_AUTO_DETECTED,
    PROJECT_SET_GAMEPAD_CONNECTED,
    PROJECT_SET_GAMEPAD_DATA,
    PROJECT_SET_CONFIG_PAGE_SETTING_VALUE,
    PROJECT_RESET_CONFIG_PAGE_SETTING_VALUES,
    PROJECT_SET_SCRATCHLINK_CONFIG
} from '.';
//Project is for temp settings to do with the entire app

export const projectInitialState: IProjectState = {
    pageDimensions: {
        width: 1280,
        height: 720
    },
    rightPanelDimensions: {
        width: 0,
        height: 0
    },
    codingNavBarCollapsed: false,
    codingHelpPopoutSelectedSectionIndex: null,
    deviceConnected: false,
    deviceConnecting: false,
    deviceConnectionMethod: 'none',
    deviceDirectIp: '192.168.0.44',
    deviceSerialPort: '',
    deviceName: '',
    deviceBatteryVoltage: 0,
    deviceBatteryPercent: 0,
    hardwareMode: 'AUTO',
    blockLevelMode: 'Core',
    hardwareModeAutoDetected: 'none',
    gamepad0: {
        connected: false,
        type: 'no_gamepad',
        data: undefined
    },
    gamepad1: {
        connected: false,
        type: 'no_gamepad',
        data: undefined
    },
    configPageSettingValues: {},
    scratchlinkConfig: scratchlinkConfig
}

export const projectReducer = (state: IProjectState = projectInitialState, action: IProjectActions): IProjectState => {
    switch (action.type) {
        case PROJECT_SET_PAGE_DIMENSIONS:
            return immer(state, (draftState: IProjectState) => {
                draftState.pageDimensions = action.pageDimensions;

                if(state.pageDimensions.width > 500 && action.pageDimensions.width < 500) { //Changing to below 500px now, collapse Vbar
                    draftState.codingNavBarCollapsed = true;
                } else if(state.pageDimensions.width < 500 && action.pageDimensions.width > 500) { //Changing to above 500px now, expand Vbar
                    draftState.codingNavBarCollapsed = false;
                }
            });
        case PROJECT_SET_RIGHT_PANEL_DIMENSIONS:
            return immer(state, (draftState: IProjectState) => {
                draftState.rightPanelDimensions = action.rightPanelDimensions;
            });
        case PROJECT_SET_CODING_NAVBAR_COLLAPSED:
            return immer(state, (draftState: IProjectState) => {
                draftState.codingNavBarCollapsed = action.collapsed;
            });
        case PROJECT_SET_CODING_HELP_POPOUT_SELECTED_SECTION_INDEX:
            return immer(state, (draftState: IProjectState) => {
                draftState.codingHelpPopoutSelectedSectionIndex = action.index;
            });
        case PROJECT_SET_DEVICE_CONNECTED:
            return immer(state, (draftState: IProjectState) => {
                draftState.deviceConnected = action.deviceConnected;
            });
        case PROJECT_SET_DEVICE_CONNECTING:
            return immer(state, (draftState: IProjectState) => {
                draftState.deviceConnecting = action.deviceConnecting;
            });
        case PROJECT_SET_DEVICE_CONNECTION_METHOD:
            return immer(state, (draftState: IProjectState) => {
                draftState.deviceConnectionMethod = action.deviceConnectionMethod;
            });
        case PROJECT_SET_DEVICE_DIRECT_IP:
            return immer(state, (draftState: IProjectState) => {
                draftState.deviceDirectIp = action.deviceDirectIp;
            });
        case PROJECT_SET_DEVICE_SERIAL_PORT: 
            return immer(state, (draftState: IProjectState) => {
                draftState.deviceSerialPort = action.serialPort;
            });
        case PROJECT_SET_DEVICE_NAME:
            return immer(state, (draftState: IProjectState) => {
                draftState.deviceName = action.name;
            });
        case PROJECT_SET_DEVICE_BATTERY_VOLTAGE: 
            return immer(state, (draftState: IProjectState) => {
                draftState.deviceBatteryVoltage = action.voltage;

                const deviceProfile = state.scratchlinkConfig.deviceProfiles.find((device: any) => device.profile === state.hardwareModeAutoDetected);
                let batteryPercent: null | number = 0;

                //If the device profile has a battery and the voltage is not 0
                if(deviceProfile && deviceProfile.battery && action.voltage !== 0 ) {
                    const batteryConfig = state.scratchlinkConfig.generalConfig?.batteryIndicator[deviceProfile.battery];

                    //If the battery config exists and has a min and max
                    if(batteryConfig && batteryConfig.min && batteryConfig.max) {
                        batteryPercent = clamp(
                            numberMap(action.voltage, 
                                batteryConfig.min, //Min Voltage 
                                batteryConfig.max,  //Max Voltage
                                0, 
                                100
                            ), 
                            0, 
                            100
                        );
                    } else console.error(`Battery config for ${deviceProfile.battery} not found or incomplete. Profile: ${deviceProfile.profile}.`);
                }

                //If the device profile doesn't have a battery set null
                if(!deviceProfile || !deviceProfile.battery) batteryPercent = null;

                draftState.deviceBatteryPercent = batteryPercent;
            });
        case PROJECT_SET_HARDWARE_MODE: 
            return immer(state, (draftState: IProjectState) => {
                draftState.hardwareMode = action.hardwareMode
            });
        case PROJECT_SET_BLOCK_LEVEL_MODE: 
            return immer(state, (draftState: IProjectState) => {
                draftState.blockLevelMode = action.blockLevelMode
            });
        case PROJECT_SET_HARDWARE_MODE_AUTO_DETECTED:
            return immer(state, (draftState: IProjectState) => {
                draftState.hardwareModeAutoDetected = action.mode
            });
        case PROJECT_SET_GAMEPAD_CONNECTED:
            return immer(state, (draftState: IProjectState) => {
                //@ts-ignore
                if(action.gamepadIndex === 0) draftState.gamepad0 = {
                    connected: action.gamepadConnected,
                    type: action.gamepadType,
                    data: undefined
                };
                //@ts-ignore
                if(action.gamepadIndex === 1) draftState.gamepad1 = {
                    connected: action.gamepadConnected,
                    type: action.gamepadType,
                    data: undefined
                };
            });
        case PROJECT_SET_GAMEPAD_DATA:
            return immer(state, (draftState: IProjectState) => {
                if(action.gamepadIndex === 0) draftState.gamepad0.data = action.gamepadData;
                if(action.gamepadIndex === 1) draftState.gamepad1.data = action.gamepadData;
            });
        case PROJECT_SET_CONFIG_PAGE_SETTING_VALUE:
            return immer(state, (draftState: IProjectState) => {
                //@ts-ignore
                draftState.configPageSettingValues[action.setting] = action.value;
            });
        case PROJECT_RESET_CONFIG_PAGE_SETTING_VALUES:
            return immer(state, (draftState: IProjectState) => {
                draftState.configPageSettingValues = {};
            });
        case PROJECT_SET_SCRATCHLINK_CONFIG:
            return immer(state, (draftState: IProjectState) => {
                draftState.scratchlinkConfig = JSON.parse(action.config);
            });
        case STORE_IMPORT:
            return {...state, ...action.data.project}; //Overwrite the project state with the imported project state so that unset values on import remain unchanged
        default:
            return { ...state };
    }
}