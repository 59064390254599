import {
    ICodingBackpackMatrix,
    ICodingBackpackMatrixesCreateMatrixAction,
    ICodingBackpackMatrixesEditMatrixAction,
    ICodingBackpackMatrixesClearMatrixAction,
    ICodingBackpackMatrixesInvertMatrixAction,
    ICodingBackpackMatrixesDuplicateMatrixAction,
    ICodingBackpackMatrixesDeleteMatrixAction,
    ICodingBackpackMatrixesRenameMatrixAction,
    ICodingBackpackMatrixesShiftUpMatrixAction,
    ICodingBackpackMatrixesShiftDownMatrixAction,
    ICodingBackpackMatrixesShiftLeftMatrixAction,
    ICodingBackpackMatrixesShiftRightMatrixAction
} from '.';

import {
    CODING_PROJECT_FILE_BACKPACKMATRIXES_CREATE,
    CODING_PROJECT_FILE_BACKPACKMATRIXES_EDIT_MATRIX,
    CODING_PROJECT_FILE_BACKPACKMATRIXES_CLEAR_MATRIX,
    CODING_PROJECT_FILE_BACKPACKMATRIXES_INVERT_MATRIX,
    CODING_PROJECT_FILE_BACKPACKMATRIXES_DUPLICATE_MATRIX,
    CODING_PROJECT_FILE_BACKPACKMATRIXES_DELETE_MATRIX,
    CODING_PROJECT_FILE_BACKPACKMATRIXES_RENAME_MATRIX,
    CODING_PROJECT_FILE_BACKPACKMATRIXES_SHIFT_UP_MATRIX,
    CODING_PROJECT_FILE_BACKPACKMATRIXES_SHIFT_DOWN_MATRIX,
    CODING_PROJECT_FILE_BACKPACKMATRIXES_SHIFT_LEFT_MATRIX,
    CODING_PROJECT_FILE_BACKPACKMATRIXES_SHIFT_RIGHT_MATRIX
} from '.';

export const codingBackpackMatrixesCreateMatrix = (codingProjectIndex: number, fileIndex: number, folderId: string, extraOptions?: Partial<ICodingBackpackMatrix>): ICodingBackpackMatrixesCreateMatrixAction => {
    return {
        type: CODING_PROJECT_FILE_BACKPACKMATRIXES_CREATE,
        codingProjectIndex,
        fileIndex,
        folderId: folderId,
        extraOptions
    }
}

export const codingBackpackMatrixesEditMatrix = (codingProjectIndex: number, fileIndex: number, matrix: ICodingBackpackMatrix, matrixId: string): ICodingBackpackMatrixesEditMatrixAction => {
    return {
        type: CODING_PROJECT_FILE_BACKPACKMATRIXES_EDIT_MATRIX,
        codingProjectIndex,
        fileIndex,
        matrix,
        matrixId
    }
}

export const codingBackpackMatrixesClearMatrix = (codingProjectIndex: number, fileIndex: number, matrixId: string): ICodingBackpackMatrixesClearMatrixAction => {
    return {
        type: CODING_PROJECT_FILE_BACKPACKMATRIXES_CLEAR_MATRIX,
        codingProjectIndex,
        fileIndex,
        matrixId
    }
}

export const codingBackpackMatrixesInvertMatrix = (codingProjectIndex: number, fileIndex: number, matrixId: string): ICodingBackpackMatrixesInvertMatrixAction => {
    return {
        type: CODING_PROJECT_FILE_BACKPACKMATRIXES_INVERT_MATRIX,
        codingProjectIndex,
        fileIndex,
        matrixId
    }
}

export const codingBackpackMatrixesDuplicateMatrix = (codingProjectIndex: number, fileIndex: number, matrixId: string, newMatrixId: string): ICodingBackpackMatrixesDuplicateMatrixAction => {
    return {
        type: CODING_PROJECT_FILE_BACKPACKMATRIXES_DUPLICATE_MATRIX,
        codingProjectIndex,
        fileIndex,
        matrixId,
        newMatrixId
    }
}

export const codingBackpackMatrixesDeleteMatrix = (codingProjectIndex: number, fileIndex: number, matrixId: string | string[]): ICodingBackpackMatrixesDeleteMatrixAction => {
    return {
        type: CODING_PROJECT_FILE_BACKPACKMATRIXES_DELETE_MATRIX,
        codingProjectIndex,
        fileIndex,
        matrixId
    }
}

export const codingBackpackMatrixesRenameMatrix = (codingProjectIndex: number, fileIndex: number, matrixId: string, name: string): ICodingBackpackMatrixesRenameMatrixAction => {
    return {
        type: CODING_PROJECT_FILE_BACKPACKMATRIXES_RENAME_MATRIX,
        codingProjectIndex,
        fileIndex,
        matrixId,
        name
    }
}

export const codingBackpackMatrixesShiftUpMatrix = (codingProjectIndex: number, fileIndex: number, matrixId: string): ICodingBackpackMatrixesShiftUpMatrixAction => {
    return {
        type: CODING_PROJECT_FILE_BACKPACKMATRIXES_SHIFT_UP_MATRIX,
        codingProjectIndex,
        fileIndex,
        matrixId
    }
}

export const codingBackpackMatrixesShiftDownMatrix = (codingProjectIndex: number, fileIndex: number, matrixId: string): ICodingBackpackMatrixesShiftDownMatrixAction => {
    return {
        type: CODING_PROJECT_FILE_BACKPACKMATRIXES_SHIFT_DOWN_MATRIX,
        codingProjectIndex,
        fileIndex,
        matrixId
    }
}

export const codingBackpackMatrixesShiftLeftMatrix = (codingProjectIndex: number, fileIndex: number, matrixId: string): ICodingBackpackMatrixesShiftLeftMatrixAction => {
    return {
        type: CODING_PROJECT_FILE_BACKPACKMATRIXES_SHIFT_LEFT_MATRIX,
        codingProjectIndex,
        fileIndex,
        matrixId
    }
}

export const codingBackpackMatrixesShiftRightMatrix = (codingProjectIndex: number, fileIndex: number, matrixId: string): ICodingBackpackMatrixesShiftRightMatrixAction => {
    return {
        type: CODING_PROJECT_FILE_BACKPACKMATRIXES_SHIFT_RIGHT_MATRIX,
        codingProjectIndex,
        fileIndex,
        matrixId
    }
}