export interface PyWorkerMessage {
    type: PyWorkerMessageType;
    data?: any;
}

export enum PyWorkerMessageType {
    Ready = 'Ready',
    Running = 'Running',
    Ended = 'Ended',
    Error = 'Error',

    Log = 'log',
    LogLibError = 'logLibError',
    Command = 'command',
}

export interface PyWorkerReadyMessage extends PyWorkerMessage {
    type: PyWorkerMessageType.Ready;
    data: {
        ready: boolean;
    }
}

export interface PyWorkerRunningMessage extends PyWorkerMessage {
    type: PyWorkerMessageType.Running;
    data: {
        running: boolean;
    }
}

export interface PyWorkerEndedMessage extends PyWorkerMessage {
    type: PyWorkerMessageType.Ended;
    data: {
        ended: boolean;
    }
}

export interface PyWorkerErrorMessage extends PyWorkerMessage {
    type: PyWorkerMessageType.Error;
    data: {
        error: string;
    }
}

export interface PyWorkerLogMessage extends PyWorkerMessage {
    type: PyWorkerMessageType.Log;
    msg: string;
}

export interface PyWorkerLogLibErrorMessage extends PyWorkerMessage {
    type: PyWorkerMessageType.LogLibError;
    msg: string;
}

export interface PyWorkerCommandMessage extends PyWorkerMessage {
    type: PyWorkerMessageType.Command;
    data: string
}

export type PyWorkerMessages = PyWorkerReadyMessage | PyWorkerRunningMessage | PyWorkerEndedMessage | PyWorkerErrorMessage | PyWorkerLogMessage | PyWorkerLogLibErrorMessage | PyWorkerCommandMessage;