import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Select from 'react-select';
import { IRootState, codingSetSettingsModelOpen, codingSetProjectHardwareMode, selectorGetActiveCodingProject } from 'store';

import Modal from 'components/Modal/Modal';

type OwnProps = {

}

type Props = PropsFromRedux & OwnProps;

const CodingProjectSettingsModal: React.FC<Props> = ({ 
    codingProjectSettingsModalOpen, 
    codingSetSettingsModelOpen,
    activeProjectIndex,
    activeCodingProject, 
    scratchlinkConfig, 
    codingSetProjectHardwareMode,
    //codingSetProjectBlockLevelMode
}) => {
    const hardwareDeviceProfileOptions: ReactSelectStringOption[] = scratchlinkConfig.deviceProfiles.map((deviceProfile: any) => ({
        value: deviceProfile.profile,
        label: deviceProfile.displayName
    }));

    /* const blockLevelModeOptions: ReactSelectStringOption[] = Object.keys(BlockLevelModes).map((blockLevel: string) => ({
        value: blockLevel,
        label: blockLevel
    })); */

    const getSelectedHardwareDeviceProfile = () => hardwareDeviceProfileOptions.find(hardwareDeviceProfileOption => hardwareDeviceProfileOption.value === activeCodingProject.hardwareMode)
    //const getSelectedBlockLevelMode = () => blockLevelModeOptions.find(blockLevelModeOption => blockLevelModeOption.value === activeCodingProject.blockLevelMode);

    const [selectedHardwareDeviceProfileOption, setSelectedHardwareDeviceProfileOption] = useState(getSelectedHardwareDeviceProfile());
    //const [selectedBlockLevelModeOption, setSelectedBlockLevelModeOption] = useState(getSelectedBlockLevelMode())

    useEffect(() => {
        setSelectedHardwareDeviceProfileOption(getSelectedHardwareDeviceProfile());
        //setSelectedBlockLevelModeOption(getSelectedBlockLevelMode());
    }, [activeCodingProject, scratchlinkConfig])

    const onHardwareDeviceProfileChange = (value: ReactSelectStringOption | null) => {
        if(value) setSelectedHardwareDeviceProfileOption(value);
    }

    /* const onBlockLevelModeChange = (value: ReactSelectStringOption | null) => {
        if(value) setSelectedBlockLevelModeOption(value);
    } */

    const onCloseModal = () => {
        codingSetSettingsModelOpen(false);
    }

    const onSaveModal = () => {
        if(selectedHardwareDeviceProfileOption?.value && selectedHardwareDeviceProfileOption.value !== activeCodingProject.hardwareMode) {
            window.confirm(`WARNING: Changing hardware settings may cause some code functions to no longer work. These will be underlined red in the code editor after changing hardware. Do you want to continue?`);
            codingSetProjectHardwareMode(activeProjectIndex, selectedHardwareDeviceProfileOption.value);
        }
        /* if(selectedBlockLevelModeOption?.value && selectedBlockLevelModeOption.value !== activeCodingProject.blockLevelMode) {
            const blockLevelModesArray = Object.keys(BlockLevelModes);

            if(blockLevelModesArray.indexOf(selectedBlockLevelModeOption.value) < blockLevelModesArray.indexOf(activeCodingProject.blockLevelMode)) 
                window.confirm(`WARNING: Downgrading challenge level may cause some code functions to no longer work. These will be underlined red in the code editor after changing challenge level. Do you want to continue?`);
            
            codingSetProjectBlockLevelMode(activeProjectIndex, selectedBlockLevelModeOption.value as keyof typeof BlockLevelModes);
        } */

        onCloseModal();
    }

    return (
        <>
            <Modal 
                title={`${activeCodingProject.name} Project Settings`}
                isOpen={codingProjectSettingsModalOpen} 
                closeModal={onCloseModal}
                onSave={onSaveModal}
                type='save'
                size='md'
            >
                <div className='w-full flex'>
                    <Select className='w-80' options={hardwareDeviceProfileOptions} value={selectedHardwareDeviceProfileOption} onChange={onHardwareDeviceProfileChange} />
                    {/* <Select className='w-40 ml-4' options={blockLevelModeOptions} value={selectedBlockLevelModeOption} onChange={onBlockLevelModeChange} /> */}
                </div>
            </Modal>
        </>
    )
}

const mapStateToProps = (state: IRootState, ownProps: OwnProps) => {
    return {
        codingProjectSettingsModalOpen: state.coding.projectSettingsModalOpen,
        activeCodingProject: selectorGetActiveCodingProject(state),
        activeProjectIndex: state.coding.activeProjectIndex,
        scratchlinkConfig: state.project.scratchlinkConfig
    }
}

const mapDispatchToProps = {
    codingSetSettingsModelOpen,
    codingSetProjectHardwareMode,
    //codingSetProjectBlockLevelMode
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CodingProjectSettingsModal);