import React from 'react';
import { IConsoleDecorator } from '../ConsoleDecorator';

export const DeviceInfoConsoleDecorator: IConsoleDecorator = ({ msgObject }) => {

    return (
        <>
            eBot Name: {msgObject['Name']} | eBot Profile: {msgObject['Profile']}
        </>
    )
};

DeviceInfoConsoleDecorator.decoratorTest = (msgObject) => {
    if(msgObject['Name'] && msgObject['Profile']) return true;

    return false;
}

DeviceInfoConsoleDecorator.consoleClass = () => 'c-consoleDecorator__DeviceInfo';