import { ICodingBackpackState, ICodingBackpackAllActionTypes, IAnimationState, IAnimationAllActionTypes } from '.';
import { IStoreImportAction } from '../..';

export const MATRIXEDITOR_SET_DEMO_PLAYING = 'CODING/PROJECT/FILE/MATRIXEDITOR/SET_DEMO_PLAYING';
export const MATRIXEDITOR_START_DEMO_PLAYING = 'CODING/PROJECT/FILE/MATRIXEDITOR/START_DEMO_PLAYING';
export const MATRIXEDITOR_STOP_DEMO_PLAYING = 'CODING/PROJECT/FILE/MATRIXEDITOR/STOP_DEMO_PLAYING';
export const MATRIXEDITOR_DEMO_TICK = 'CODING/PROJECT/FILE/MATRIXEDITOR/DEMO_TICK';

export interface ICodingMatrixEditorAction {
    codingProjectIndex: number;
    fileIndex: number;
}

export interface ICodingMatrixEditorSetDemoPlayingAction extends ICodingMatrixEditorAction {
    type: typeof MATRIXEDITOR_SET_DEMO_PLAYING;
    demoPlaying: boolean;
}

export interface ICodingMatrixEditorDemoTickAction extends ICodingMatrixEditorAction {
    type: typeof MATRIXEDITOR_DEMO_TICK;
}

export type ICodingMatrixEditorActionTypes = IStoreImportAction | ICodingMatrixEditorSetDemoPlayingAction | ICodingMatrixEditorDemoTickAction;
export type ICodingMatrixEditorAllActionTypes = ICodingMatrixEditorActionTypes | ICodingBackpackAllActionTypes | IAnimationAllActionTypes;

export interface ICodingMatrixEditorState {
    backpack: ICodingBackpackState;
    animation: IAnimationState;
    demoPlaying: boolean;
    currentAnimationPlayedFor: number;
}