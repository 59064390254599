import React from 'react';
import { IConsoleDecorator } from '../ConsoleDecorator';

export const StreamConsoleDecorator: IConsoleDecorator = ({ msgObject }) => {

    return (
        <>
            Streaming {msgObject['stream'] === 1 ? 'On' : 'Off'}
        </>
    )
};

StreamConsoleDecorator.decoratorTest = (msgObject) => {
    if(msgObject['stream'] !== undefined) return true;

    return false;
}

StreamConsoleDecorator.consoleClass = () => 'c-consoleDecorator__Stream';