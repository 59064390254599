import { IStoreImportAction, BlockLevelModes } from '..';

import { ICodingMatrixEditorState } from './matrixEditor';
import { GamePadTypes } from 'store';

export const CODING_SET_RUNNING = 'CODING/SET_RUNNING';
export const CODING_SET_CODE_HAS_ERROR = 'CODING/SET_CODE_HAS_ERROR';
export const CODING_SET_PY_ENV_READY = 'CODING/SET_PY_ENV_READY';
export const CODING_SET_USER_SELECTED_RIGHT_PANEL = 'CODING/SET_USER_SELECTED_RIGHT_PANEL';
export const CODING_SET_CREATE_PROJECT_MODAL_OPEN = 'CODING/SET_CREATE_PROJECT_MODAL_OPEN';
export const CODING_SET_PROJECT_SETTINGS_MODAL_OPEN = 'CODING/SET_PROJECT_SETTINGS_MODAL_OPEN';
export const CODING_SET_ACTIVE_PROJECT_INDEX = 'CODING/SET_ACTIVE_PROJECT_INDEX';
export const CODING_SET_PROJECT_SET_FILES = 'CODING/PROJECT/SET_FILES';
export const CODING_SET_PROJECT_NAME = 'CODING/PROJECT/SET_NAME';
export const CODING_SET_PROJECT_HARDWARE_MODE = 'CODING/PROJECT/SET_HARDWARE_MODE';
export const CODING_SET_PROJECT_BLOCK_LEVEL_MODE = 'CODING/PROJECT/SET_BLOCK_LEVEL_MODE';
export const CODING_SET_PROJECT_GAMEPAD_MODE = 'CODING/PROJECT/SET_GAMEPAD_MODE';
export const CODING_PROJECT_ADD = 'CODING/PROJECT/ADD';
export const CODING_PROJECT_DELETE = 'CODING/PROJECT/DELETE';
export const CODING_PROJECT_FILE_ADD = 'CODING/PROJECT/FILE/ADD';
export const CODING_SET_PROJECT_FILE_NAME = 'CODING/PROJECT/FILE/SET_NAME';
export const CODING_PROJECT_FILE_DELETE = 'CODING/PROJECT/FILE/DELETE';
export const CODING_SET_PROJECT_FILE_ACTIVE_INDEX = 'CODING/PROJECT/FILE/SET_ACTIVE_INDEX';
export const CODING_SET_PROJECT_FILE_BUTTONS = 'CODING/PROJECT/FILE/SET_BUTTONS';
export const CODING_DELETE_PROJECT_FILE_EVENT_BUTTONS_DELETE_BUTTON = 'CODING/PROJECT/FILE/EVENT_BUTTONS/DELETE_BUTTON';
export const CODING_SET_PROJECT_FILE_GLOBAL_VARS = 'CODING/PROJECT/FILE/GLOBAL_VARS/SET_VARIABLES';
export const CODING_SET_PROJECT_FILE_GLOBAL_VARS_JS4SCRATCH = 'CODING/PROJECT/FILE/GLOBAL_VARS_JS4SCRATCH/SET_VARIABLES';
export const CODING_SET_PROJECT_FILE_CODE = 'CODING/PROJECT/FILE/SET_CODE';
export const CODING_SET_PROJECT_FILE_TEXT = 'CODING/PROJECT/FILE/TEXT/SET_TEXT';
export const CODING_SET_PROJECT_FILE_ASSET_MANAGER_SPRITES = 'CODING/PROJECT/FILE/ASSET_MANAGER/SPRITES';
export const CODING_SET_PROJECT_FILE_ASSET_MANAGER_BACKDROPS = 'CODING/PROJECT/FILE/ASSET_MANAGER/BACKDROPS';
export const CODING_SET_PROJECT_FILE_IMAGE_MANAGER_IMAGES = 'CODING/PROJECT/FILE/IMAGE_MANAGER/IMAGES';
export const CODING_SET_PROJECT_FILE_FLOWCHART_DATA = 'CODING/PROJECT/FILE/FLOWCHART/SET_DATA';
export const CODING_IMPORT_PROJECT = 'CODING/PROJECT/IMPORT';

export interface ICodingSetRunning {
    type: typeof CODING_SET_RUNNING;
    running: boolean;
}

export interface ICodingSetCodeHasError {
    type: typeof CODING_SET_CODE_HAS_ERROR;
    codeHasError: boolean;
}

export interface ICodingSetPyEnvReady {
    type: typeof CODING_SET_PY_ENV_READY;
    pyEnvReady: boolean;
}

export interface ICodingSetUserSelectedRightPanel {
    type: typeof CODING_SET_USER_SELECTED_RIGHT_PANEL;
    userSelectedRightPanel: ICodingUserSelectedRightPanel;
}

export interface ICodingSetCreateProjectModelOpen {
    type: typeof CODING_SET_CREATE_PROJECT_MODAL_OPEN;
    isOpen: boolean;
}

export interface ICodingSetProjectSettingsModelOpen {
    type: typeof CODING_SET_PROJECT_SETTINGS_MODAL_OPEN;
    isOpen: boolean;
}

export interface ICodingSetActiveProjectIndex {
    type: typeof CODING_SET_ACTIVE_PROJECT_INDEX;
    activeProjectIndex: number;
}

export interface ICodingSetProjectFiles {
    type: typeof CODING_SET_PROJECT_SET_FILES;
    projectIndex: number;
    files: ICodingFile[];
}

export interface ICodingSetProjectName {
    type: typeof CODING_SET_PROJECT_NAME;
    projectIndex: number;
    projectName: string;
}

export interface ICodingSetProjectHardwareMode {
    type: typeof CODING_SET_PROJECT_HARDWARE_MODE;
    projectIndex: number;
    hardwareMode: string;
}

export interface ICodingSetProjectBlockLevelMode {
    type: typeof CODING_SET_PROJECT_BLOCK_LEVEL_MODE;
    projectIndex: number;
    blockLevelMode: keyof typeof BlockLevelModes;
}

export interface ICodingSetProjectGamepadMode {
    type: typeof CODING_SET_PROJECT_GAMEPAD_MODE;
    projectIndex: number;
    gamepadMode: GamePadTypes;
}

export interface ICodingAddProject {
    type: typeof CODING_PROJECT_ADD;
    codingProject: ICodingProject;
}

export interface ICodingDeleteProject {
    type: typeof CODING_PROJECT_DELETE;
    projectIndex: number;
}

export interface ICodingProjectFileAdd {
    type: typeof CODING_PROJECT_FILE_ADD;
    projectIndex: number;
    fileType: ICodingFileType;
    fileName: string;
}

export interface ICodingProjectFileDelete {
    type: typeof CODING_PROJECT_FILE_DELETE;
    projectIndex: number;
    fileIndex: number;
}

export interface ICodingProjectFileSetName {
    type: typeof CODING_SET_PROJECT_FILE_NAME;
    projectIndex: number;
    fileIndex: number;
    name: string;
}

export interface ICodingSetProjectFileActiveIndex {
    type: typeof CODING_SET_PROJECT_FILE_ACTIVE_INDEX;
    fileIndex: number;
}


export interface ICodingSetProjectFileCode {
    type: typeof CODING_SET_PROJECT_FILE_CODE;
    code: string;
    fileType: ICodingFileType;
    projectIndex: number;
    fileIndex: number;
}

export interface ICodingSetProjectFileText {
    type: typeof CODING_SET_PROJECT_FILE_TEXT;
    text: string;
    projectIndex: number;
    fileIndex: number;
}

export interface ICodingSetProjectFileButtons {
    type: typeof CODING_SET_PROJECT_FILE_BUTTONS;
    buttons: string[];
    projectIndex: number;
    fileIndex: number;
}

export interface ICodingDeleteProjectFileEventButtonsButton {
    type: typeof CODING_DELETE_PROJECT_FILE_EVENT_BUTTONS_DELETE_BUTTON;
    projectIndex: number;
    fileIndex: number;
    buttonIndex: number;
}

export interface ICodingSetProjectFileGlobalVars {
    type: typeof CODING_SET_PROJECT_FILE_GLOBAL_VARS;
    projectIndex: number;
    fileIndex: number;
    globalVars: ICodingGlobalVarVariable[];
}

export interface ICodingSetProjectFileGlobalVarsJS4Scratch {
    type: typeof CODING_SET_PROJECT_FILE_GLOBAL_VARS_JS4SCRATCH;
    projectIndex: number;
    fileIndex: number;
    globalVars: ICodingGlobalVarJS4ScratchVariable[];
}

export interface ICodingSetProjectFileAssetManagerSprites {
    type: typeof CODING_SET_PROJECT_FILE_ASSET_MANAGER_SPRITES;
    projectIndex: number;
    fileIndex: number;
    sprites: ICodingSprite[];
}

export interface ICodingSetProjectFileAssetManagerBackdrops {
    type: typeof CODING_SET_PROJECT_FILE_ASSET_MANAGER_BACKDROPS;
    projectIndex: number;
    fileIndex: number;
    backdrops: ICodingBackdrop[];
}

export interface ICodingSetProjectFileImageManagerImages {
    type: typeof CODING_SET_PROJECT_FILE_IMAGE_MANAGER_IMAGES;
    projectIndex: number;
    fileIndex: number;
    images: ICodingImage[];
}

export interface ICodingSetProjectFileFlowChartData {
    type: typeof CODING_SET_PROJECT_FILE_FLOWCHART_DATA;
    projectIndex: number;
    fileIndex: number;
    data: any;
}

export interface ICodingImportProject {
    type: typeof CODING_IMPORT_PROJECT;
    data: ICodingProject;
}

export type ICodingActions = IStoreImportAction | ICodingSetActiveProjectIndex | ICodingSetUserSelectedRightPanel | ICodingSetCreateProjectModelOpen | ICodingSetProjectSettingsModelOpen | ICodingSetProjectFiles | ICodingSetProjectName | ICodingSetProjectHardwareMode | ICodingSetProjectBlockLevelMode | ICodingSetProjectGamepadMode | ICodingAddProject | ICodingDeleteProject | ICodingProjectFileAdd | ICodingProjectFileDelete | ICodingProjectFileSetName | ICodingSetProjectFileCode | ICodingSetProjectFileText | ICodingSetRunning | ICodingSetCodeHasError | ICodingSetPyEnvReady | ICodingSetProjectFileActiveIndex | ICodingSetProjectFileButtons | ICodingDeleteProjectFileEventButtonsButton | ICodingSetProjectFileGlobalVars | ICodingSetProjectFileGlobalVarsJS4Scratch | ICodingSetProjectFileAssetManagerSprites | ICodingSetProjectFileAssetManagerBackdrops | ICodingSetProjectFileImageManagerImages | ICodingSetProjectFileFlowChartData | ICodingImportProject;

export type ICodingProjectType = 'hardwarejs' | 'hardwarepy' | 'simulatorjs' | 'matrix' | 'js' | 'jsscratch' | 'portfolio' | 'flowChart';
export type ICodingFileType = 'js' | 'py' | 'html' | 'eventButtons' | 'text' | 'globalVars' | 'globalVarsJS4Scratch' | 'matrixEditor' | 'assetManager' | 'flowChart' | 'imageManager';

export interface ICodingJSFileData {
    code: string;
}

export interface ICodingPyFileData {
    code: string;
}

export interface ICodingGlobalVarVariable {
    name: string;
    type: 'string' | 'number' | 'boolean' | 'any[]' | 'any' | 'function' | 'ScratchLinkTimer';
}

export const codingGlobalVarDefaults = {
    'string': `''`,
    'number': `0`,
    'boolean': `false`,
    'any[]': `[]`,
    'any': `{}`,
    'function': `function(){}`,
    'ScratchLinkTimer': 'new eBot.timer()'
};

export type ICodingUserSelectedRightPanel = null | 'console' | 'help';

export interface ICodingGlobalVarJS4ScratchVariable extends ICodingGlobalVarVariable {
    display: boolean;
}

export interface ICodingBackdrop {
    name: string;
    scratchName: string;
    asset: string;
    type: 'mit' | 'scratchlink'
}

export interface ICodingCostume {
    name: string;
    asset: string; //id for url or base64 data
}

export type ICodingSpriteType = 'mit' | 'scratchlink' | 'file';

export interface ICodingSprite {
    name: string;
    scratchName: string;
    type: ICodingSpriteType;
    costumes: ICodingCostume[];
}

export interface ICodingImage {
    name: string;
    data: string; //base64
    type: 'img' | 'video'
}

export interface ICodingFile  {
    name: string;
    id: string;
    jsData?: ICodingJSFileData;
    pyData?: ICodingPyFileData;
    htmlData?: {
        html: string;
    }
    eventButtonData?: {
        buttons: string[];
    }
    textData?: {
        text: string;
    }
    globalVarData?: {
        vars: ICodingGlobalVarVariable[];
    }
    globalVarJS4ScratchData?: {
        vars: ICodingGlobalVarJS4ScratchVariable[];
    }
    matrixEditorData?: {
        matrixEditor: ICodingMatrixEditorState;
    }
    assetManagerData?: {
        backdrops: ICodingBackdrop[];
        sprites: ICodingSprite[];
    }
    flowChartData?: {
        data: any;
    }
    imageData?: {
        images: ICodingImage[];
    }
    type: ICodingFileType;
}

export interface ICodingProject {
    name: string;
    id: string;
    type: ICodingProjectType;
    files: ICodingFile[];
    hardwareMode: string;
    blockLevelMode: keyof typeof BlockLevelModes;
    gamepadMode: GamePadTypes;
    activeFileIndex: number;
    projectVersion: '1.0';
}

export interface ICodingState {
    running: boolean;
    codeHasError: boolean;
    pyEnvReady: boolean;
    userSelectedRightPanel: ICodingUserSelectedRightPanel;
    displayHelpPopout: boolean;
    codingProjects: ICodingProject[];
    activeProjectIndex: number;
    createProjectModalOpen: boolean;
    projectSettingsModalOpen: boolean;
}