import { useState, useEffect } from "react";

export const useMultiKeyPress = (preventArrowKeyScroll = false) => {
  const [keysPressed, setKeyPressed] = useState(new Set<string>([]));

  const downHandler = (e: KeyboardEvent) => {
    if(preventArrowKeyScroll && e.key.includes('Arrow')) e.preventDefault();
    keysPressed.add(e.key)
    setKeyPressed(new Set(keysPressed));
  }

  const upHandler = ({ key }: KeyboardEvent) => {
    keysPressed.delete(key);
    setKeyPressed(new Set(keysPressed));
  };
  
  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }); // Empty array ensures that effect is only run on mount and unmount

  return keysPressed;
}