import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Select from 'react-select';
import { IRootState, codingSetProjectFileGlobalVars, ICodingGlobalVarVariable } from 'store';
import 'react-contexify/dist/ReactContexify.min.css';

import Input from 'components/Input/Input';
import Button from 'components/Button/Button';

type OwnProps = {
    codingProjectIndex: number;
    fileIndex: number;
}

type Props = PropsFromRedux & OwnProps;

const variableTypeSelect: ReactSelectStringOption[] = [
    {value: 'string', label: 'String'},
    {value: 'number', label: 'Number'},
    {value: 'boolean', label: 'Boolean'},
    {value: 'any[]', label: 'Array'},
    {value: 'any', label: 'Object'},
    {value: 'function', label: 'Function'},
    {value: 'ScratchLinkTimer', label: 'Timer'}
]

const CodingGlobalVariables: React.FC<Props> = ({ codingProjectIndex, fileIndex, codingFile, codingSetProjectFileGlobalVars }) => {
    if(codingFile.type !== 'globalVars') throw new Error('CodingGlobalVariables: codingFile.type is not globalVars');
    const [codingVariables, setCodingVariables] = useState(codingFile.globalVarData?.vars || []);
    const [codingVariablesChanged, setCodingVariablesChanged] = useState<undefined | ReturnType<typeof setTimeout>>(undefined)

    const sendReduxVariablesChanged = (variables: ICodingGlobalVarVariable[]) => {
        if(codingVariablesChanged) clearTimeout(codingVariablesChanged);
        setCodingVariablesChanged(undefined);

        setCodingVariablesChanged(setTimeout(() => {
            codingSetProjectFileGlobalVars(codingProjectIndex, fileIndex, variables);
        }, 350))
    }

    const handleAddVariableClick = () => {
        setCodingVariables([
            ...codingVariables,
            {
                name: '',
                type: 'string'
            }
        ]);
    }

    const onVariableNameChange = (changeEvent: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const newName = changeEvent.target.value;
        const newVariables: ICodingGlobalVarVariable[] = JSON.parse(JSON.stringify(codingVariables));

        newVariables[index] = {
            ...newVariables[index],
            name: newName.split('').filter(char => /[a-zA-Z_]/.test(char)).join('')
        }

        setCodingVariables(newVariables);
        sendReduxVariablesChanged(newVariables)
    }

    const onVariableTypeChange = (option: ReactSelectStringOption | null, index: number) => {
        const newVariables: ICodingGlobalVarVariable[] = JSON.parse(JSON.stringify(codingVariables));

        newVariables[index] = {
            ...newVariables[index], //@ts-ignore
            type: option.value
        }

        setCodingVariables(newVariables);
        codingSetProjectFileGlobalVars(codingProjectIndex, fileIndex, newVariables);
    }

    const onDeleteClick = (index: number) => {
        const newVariables: ICodingGlobalVarVariable[] = JSON.parse(JSON.stringify(codingVariables));

        newVariables.splice(index, 1);

        setCodingVariables(newVariables);
        codingSetProjectFileGlobalVars(codingProjectIndex, fileIndex, newVariables);
    }

    useEffect(() => {
        setCodingVariables(codingFile.globalVarData?.vars || [])
    }, [codingFile.globalVarData])

    return (
        <div className="h-full w-full p-2">
            <table className="w-full text-center border-2 border-gray-800">
                <thead className="bg-gray-400 text-white text-2xl  font-semibold">
                    <tr>
                        <th className="w-2/3 border-2 border-gray-800">
                            Variable Name
                        </th>
                        <th className="w-1/6 border-2 border-gray-800">
                            Type
                        </th>
                        <th className="w-1/6 border-2 border-gray-800">
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        codingVariables.map((codingVar, index) => (
                            <tr className="bg-white text-xl" key={index}>
                                <td className="border-2 border-gray-400"><Input className='w-2/3' maxLength={20} type='text' value={codingVar.name} onChange={e => onVariableNameChange(e, index)}/></td>
                                <td className="border-2 border-gray-400"><Select options={variableTypeSelect} value={variableTypeSelect.find(variable => variable.value === codingVar.type)} onChange={e => onVariableTypeChange(e, index)}></Select></td>
                                <td className="border-2 border-gray-400"><Button type='danger' addStyles={['square']} onClick={() => onDeleteClick(index)}>Delete</Button></td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <div className="mt-2 w-full">
                <Button className="float-right" type="success" addStyles={['square']} onClick={handleAddVariableClick}>Add Variable</Button>
            </div>
        </div>
    )
};

const mapStateToProps = (state: IRootState, ownProps: OwnProps) => {
    return {
        codingFile: state.coding.codingProjects[ownProps.codingProjectIndex].files[ownProps.fileIndex]
    }
}

const mapDispatchToProps = {
    codingSetProjectFileGlobalVars
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CodingGlobalVariables);