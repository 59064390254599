import React from 'react';

import './Divider.scss';

const Divider: React.FC = () => {
  return (
    <div className="o-divider"/>
  );
}


export default Divider;