import { createSelector } from 'reselect';
import { IRootState, IDeviceConnectionIp } from 'store';

export const selectorGetProjectDeviceConnectionIp = createSelector(
    [
        (state: IRootState) => state.project.deviceConnectionMethod,
        (state: IRootState) => state.project.deviceDirectIp
    ],
    (deviceConnectionMethod, deviceDirectIp): IDeviceConnectionIp => {
        if(deviceConnectionMethod === 'ap') return '192.168.5.1';
        else if(deviceConnectionMethod === 'ip') return deviceDirectIp;
        return 'none';
    }
);