import React, { useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState } from 'store';
import { saveAs } from 'file-saver';
import { Editor } from '@tinymce/tinymce-react';

type OwnProps = {
    text: string;
    onTextChange: (code: string) => void;
    fileName: string;
}

type Props = PropsFromRedux & OwnProps;

const TinyMCEEditor: React.FC<Props> = ({ text, onTextChange, fileName }) => {
    const editorRef = useRef<Editor>(null);

    return (
        <Editor
            apiKey="far5meg4hpgddiu0sg1340k5chxl73a7xdyhbncyoh3kdxex"
            ref={editorRef}
            value={text}
            init={{
                height: '100%',
                skin: "oxide-dark",
                menubar: 'customFile  edit view insert format tools table preview',
                menu: {
                    customFile: { title: 'File', items: 'code exportCustom' },
                    preview: { title: 'Preview', items: 'preview'}
                },
                plugins: [
                    'preview', 'searchreplace', 'autolink', 'directionality', 'code', 'visualblocks', 
                    'visualchars', 'fullscreen', 'image', 'link', 'codesample', 
                    'table', 'charmap', 'pagebreak', 'nonbreaking', 'anchor', 
                    'insertdatetime', 'advlist', 'lists', 'wordcount', 
                    'charmap', 'quickbars', 'emoticons'
                ],
                toolbar_mode: 'scrolling',
                toolbar: 'undo redo bold italic fontfamily blocks alignleft aligncenter alignright alignjustify outdent indent numlist bullist forecolor backcolor removeformat image media link ltr rtl codesample preview',
                codesample_languages: [
                    { text: 'JavaScript', value: 'javascript' },
                    { text: 'HTML', value: 'markup' }
                ],
                link_target_list: [
                    { text: 'Current window', value: '' }
                ],
                file_picker_types: 'image',
                image_advtab: true,
                link_assume_external_targets: true,
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; background: #1e1e1e; color: white;}',
                setup: function (editor) {
                    editor.ui.registry.addMenuItem('exportCustom', {
                        text: 'Export as HTML',
                        icon: 'save',
                        onAction: function () {
                            const blob = new Blob([editor.getContent()], {type: "text/html;charset=utf-8"});
                            saveAs(blob, `${fileName}.html`);
                        }
                    });
                }
            }}
            onEditorChange={(value) => onTextChange(value)}
        />
    )
}

const mapStateToProps = (state: IRootState, ownProps: OwnProps) => {
    return {
    }
}

const mapDispatchToProps = {
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TinyMCEEditor);