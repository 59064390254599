import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState } from 'store';

import Button from 'components/Button/Button';

import './SerialDriverDLPhase.scss';

type Props = PropsFromRedux & {
    onBackClick: () => void;
}

const SerialDriverDlPhase: React.FC<Props> = ({ onBackClick }) => {
    const onBackClickEvent = () => {
        onBackClick();
    }

    return (
        <div className="c-serialDriverDLPhase">
            <p className='mt-2'>Make sure that you have the USB driver installed for your eBot and are using Chrome or Edge.</p>
            <p className='mb-2 mt-1'>If you do not have the USB driver already, it can be installed here:</p>
            <div className='flex flex-col'>
                <a className='py-1' href="https://scratchlink.au/wp-content/uploads/2023/04/CP210x_Windows_Drivers.zip" target='_blank'>
                    Windows Download
                </a>
                <a className='py-1' href="https://scratchlink.au/wp-content/uploads/2023/04/Mac_OSX_VCP_Driver.zip" target='_blank'>
                    Mac Download
                </a>
            </div>
            <br />
            <Button type="danger" addStyles={['square']} onClick={onBackClickEvent}>Back</Button>
        </div>
    )
}

const mapStateToProps = (state: IRootState): StateProps => {
    return {
    }
}

const mapDispatchToProps = {
}

interface StateProps {
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SerialDriverDlPhase);