import store, { IThunkResult, selectorGetMatrixEditor } from 'store';
import shortid from 'shortid';
import { ICodingBackpackMatrix, ICodingBackpackFolder, codingBackpackMatrixesCreateMatrix, codingBackpackMatrixesDuplicateMatrix, codingBackpackMatrixesDeleteMatrix, codingBackpackFoldersCreateFolder, codingBackpackFoldersDeleteFolder, codingBackpackFoldersAddMatrix, codingBackpackFoldersDeleteMatrix } from '.';

import {
    animationStopPlaying
} from '../animation';

import {
    ICodingBackpackChangeSelectedFolderAction,
    ICodingBackpackChangeSelectedMatrixAction
} from '.';

import {
    CODING_PROJECT_FILE_BACKPACK_CHANGE_SELECTED_FOLDER,
    CODING_PROJECT_FILE_BACKPACK_CHANGE_SELECTED_MATRIX,
    CODING_PROJECT_FILE_BACKPACK_NAVIGATE_TO_FOLDER,
    CODING_PROJECT_FILE_BACKPACK_CREATE_FOLDER,
    CODING_PROJECT_FILE_BACKPACK_CREATE_MATRIX,
    CODING_PROJECT_FILE_BACKPACK_DUPLICATE_MATRIX,
    CODING_PROJECT_FILE_BACKPACK_DELETE_FOLDER,
    CODING_PROJECT_FILE_BACKPACK_DELETE_MATRIX
} from '.';

import { toast } from 'react-toastify';

export const codingBackpackChangeSelectedFolder = (codingProjectIndex: number, fileIndex: number, folderId: string): ICodingBackpackChangeSelectedFolderAction => {
    return {
        type: CODING_PROJECT_FILE_BACKPACK_CHANGE_SELECTED_FOLDER,
        codingProjectIndex,
        fileIndex,
        folderId
    }
}

export const codingBackpackChangeSelectedMatrix = (codingProjectIndex: number, fileIndex: number, matrixId: string): ICodingBackpackChangeSelectedMatrixAction => {
    return {
        type: CODING_PROJECT_FILE_BACKPACK_CHANGE_SELECTED_MATRIX,
        codingProjectIndex,
        fileIndex,
        matrixId
    }
}


export const codingBackpackNavigateToFolder = (codingProjectIndex: number, fileIndex: number, folderId: string): IThunkResult<any> => {
    return dispatch => {
        const state = store.getState();
        const matrixEditor = selectorGetMatrixEditor(codingProjectIndex, fileIndex)(state);
        const matrixId = matrixEditor.backpack.folders.byIds[folderId].matrixIds[0]; //First matrix in target folder

        dispatch({
            type: CODING_PROJECT_FILE_BACKPACK_NAVIGATE_TO_FOLDER,
            codingProjectIndex,
            fileIndex,
        })
        dispatch(codingBackpackChangeSelectedFolder(codingProjectIndex, fileIndex, folderId));
        dispatch(codingBackpackChangeSelectedMatrix(codingProjectIndex, fileIndex, matrixId));
        
        if(matrixEditor.animation.playing) dispatch(animationStopPlaying(codingProjectIndex, fileIndex));//Stop playing any animation if the folder changes
    }
}

export const codingBackpackCreateFolder = (codingProjectIndex: number, fileIndex: number, name: string): IThunkResult<any> => {
    return dispatch => {
        const folderId = shortid();

        dispatch({
            type: CODING_PROJECT_FILE_BACKPACK_CREATE_FOLDER,
            codingProjectIndex,
            fileIndex,
        })
        dispatch(codingBackpackFoldersCreateFolder(codingProjectIndex, fileIndex, name, folderId));
        dispatch(codingBackpackCreateMatrix(codingProjectIndex, fileIndex, folderId));
        dispatch(codingBackpackChangeSelectedFolder(codingProjectIndex, fileIndex, folderId));
    }
}

export const codingBackpackCreateMatrix = (codingProjectIndex: number, fileIndex: number, folderId?: string, extraOptions?: Partial<ICodingBackpackMatrix>): IThunkResult<any> => {
    return (dispatch) => {
        const state = store.getState();
        const matrixEditor = selectorGetMatrixEditor(codingProjectIndex, fileIndex)(state);
        const cFolderId = folderId || matrixEditor.backpack.selectedFolderId;
        const cFolderSize = matrixEditor.backpack.folders.byIds[cFolderId].matrixIds.length;
        const matrixId = shortid();

        if(cFolderSize > 49) { //Limit folder size to 50
            toast.error(`You have reached the max number of allowed matrixes for this folder.`)
            return;
        }

        dispatch({
            type: CODING_PROJECT_FILE_BACKPACK_CREATE_MATRIX,
            codingProjectIndex,
            fileIndex,
        });
        dispatch(codingBackpackMatrixesCreateMatrix(codingProjectIndex, fileIndex, cFolderId, {...extraOptions, id: matrixId}));
        dispatch(codingBackpackFoldersAddMatrix(codingProjectIndex, fileIndex, matrixId, cFolderId));
        dispatch(codingBackpackChangeSelectedMatrix(codingProjectIndex, fileIndex, matrixId));
    } 
}

export const codingBackpackDuplicateMatrix = (codingProjectIndex: number, fileIndex: number, matrixId: string): IThunkResult<any> => {
    return (dispatch) => {
        const state = store.getState();
        const matrixEditor = selectorGetMatrixEditor(codingProjectIndex, fileIndex)(state);
        const folderId = matrixEditor.backpack.matrixes.byIds[matrixId].folderId;
        const cFolderSize = matrixEditor.backpack.folders.byIds[folderId].matrixIds.length;
        
        const newMatrixId = shortid();

        if(cFolderSize > 49) { //Limit folder size to 50
            toast.error(`You have reached the max number of allowed matrixes for this folder.`)
            return;
        }

        dispatch({
            type: CODING_PROJECT_FILE_BACKPACK_DUPLICATE_MATRIX,
            codingProjectIndex,
            fileIndex,
        });
        dispatch(codingBackpackMatrixesDuplicateMatrix(codingProjectIndex, fileIndex, matrixId, newMatrixId));
        dispatch(codingBackpackFoldersAddMatrix(codingProjectIndex, fileIndex, newMatrixId, folderId));
        dispatch(codingBackpackChangeSelectedMatrix(codingProjectIndex, fileIndex, newMatrixId));
    } 
}

export const codingBackpackDeleteFolder = (codingProjectIndex: number, fileIndex: number, folderId: string): IThunkResult<any> => {
    return dispatch => {
        const state = store.getState();
        const matrixEditor = selectorGetMatrixEditor(codingProjectIndex, fileIndex)(state);

        const matrixIds = matrixEditor.backpack.folders.byIds[folderId].matrixIds;

        dispatch({
            type: CODING_PROJECT_FILE_BACKPACK_DELETE_FOLDER,
            codingProjectIndex,
            fileIndex,
        })
        dispatch(codingBackpackNavigateToFolder(codingProjectIndex, fileIndex, 'root'));
        dispatch(codingBackpackFoldersDeleteFolder(codingProjectIndex, fileIndex, folderId));
        dispatch(codingBackpackMatrixesDeleteMatrix(codingProjectIndex, fileIndex, matrixIds));
    }
}

export const codingBackpackImportFolder = (codingProjectIndex: number, fileIndex: number, folder: ICodingBackpackFolder): IThunkResult<any> => {
    return dispatch => {
        const state = store.getState();
        const matrixEditor = selectorGetMatrixEditor(codingProjectIndex, fileIndex)(state);

        dispatch(codingBackpackCreateFolder(codingProjectIndex, fileIndex, folder.name));
        //for(let i=0; i<folder.matrixIds)
    }
}

export const codingBackpackDeleteMatrix = (codingProjectIndex: number, fileIndex: number, matrixId: string): IThunkResult<any> => {
    return (dispatch) => {
        const state = store.getState();
        const matrixEditor = selectorGetMatrixEditor(codingProjectIndex, fileIndex)(state);

        const folderId = matrixEditor.backpack.matrixes.byIds[matrixId].folderId;
        const matrixIds = matrixEditor.backpack.folders.byIds[folderId].matrixIds;
        const matrixIdBefore = matrixIds[matrixIds.indexOf(matrixId)-1]; //Id of the matrix before the current one
        const matrixIdAfter = matrixIds[matrixIds.indexOf(matrixId)+1]; //Id of the matrix after the current one

        dispatch({
            type: CODING_PROJECT_FILE_BACKPACK_DELETE_MATRIX,
            codingProjectIndex,
            fileIndex,
        });
        dispatch(codingBackpackMatrixesDeleteMatrix(codingProjectIndex, fileIndex, matrixId)); //Delete matrix
        dispatch(codingBackpackFoldersDeleteMatrix(codingProjectIndex, fileIndex, matrixId, folderId)); //Delete matrix id from folder

        if(matrixIds.length > 1) dispatch(codingBackpackChangeSelectedMatrix(codingProjectIndex, fileIndex, matrixIdBefore || matrixIdAfter)) //There is another matrix in the folder to use, select that
        else {
            const newMatrixId = shortid(); //No other matrixes in folder, create a new one

            dispatch(codingBackpackMatrixesCreateMatrix(codingProjectIndex, fileIndex, folderId, {id: newMatrixId}));
            dispatch(codingBackpackFoldersAddMatrix(codingProjectIndex, fileIndex, newMatrixId, folderId))
            dispatch(codingBackpackChangeSelectedMatrix(codingProjectIndex, fileIndex, newMatrixId))
        }
    } 
}