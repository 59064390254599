import React, { useRef, useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState, projectSetScratchlinkConfig } from 'store';
import Editor, { useMonaco, OnMount } from '@monaco-editor/react';
import { editor, languages} from 'monaco-editor';
import { toast } from 'react-toastify';

import Page from 'containers/Page/Page';
import Button from 'components/Button/Button';

import commitVersion from 'commitVersion.json';

import './EditConfigPage.scss';

const origin = window.location.origin;

const schemas = {
    config: `${origin}/config-schema.json`,
    deviceProfile: `${origin}/deviceProfile-schema.json`,
    generalConfig: `${origin}/generalConfig-schema.json`,
    generalConfigCrane: `${origin}/generalConfigCrane-schema.json`,
    generalConfigCranePresets: `${origin}/generalConfigCranePresets-schema.json`,
    configPage: `${origin}/configPage-schema.json`,
    configPageCrane: `${origin}/configPageCrane-schema.json`,
    configPageCraneJoints: `${origin}/configPageCraneJoints-schema.json`,
    configPageDefaults: `${origin}/configPageDefaults-schema.json`,
    testRig: `${origin}/testRig-schema.json`,
    testRigButtons: `${origin}/testRigButtons-schema.json`,
    codingEditor: `${origin}/codingEditor-schema.json`,
    codingEditorHelpPopout: `${origin}/codingEditorHelpPopout-schema.json`
}

const getSchemas = (monaco: ReturnType<typeof useMonaco>): languages.json.DiagnosticsOptions["schemas"] => {
    if(!monaco) throw new Error(`monaco is null`);

    return [
        {
            uri: schemas.config,
            fileMatch: [''],
            schema: {
                type: 'object',
                properties: {
                    deviceProfiles: {
                        type: 'array',
                        items: {
                            $ref: schemas.deviceProfile
                        },
                        description: 'The global device profiles definitions'
                    },
                    generalConfig: {
                        $ref: schemas.generalConfig
                    },
                    configPage: {
                        $ref: schemas.configPage
                    },
                    testRig: {
                        $ref: schemas.testRig
                    },
                    codingEditor: {
                        $ref: schemas.codingEditor
                    }
                },
                required: ['deviceProfiles', 'generalConfig', 'configPage', 'testRig', 'codingEditor']
            }
        },
        {
            uri: schemas.deviceProfile,
            schema: {
                type: 'object',
                properties: {
                    profile: {
                        type: 'string',
                        description: 'The device profile as named in the firmware. No spaces or special characters. Has to all be lowercase.',
                    },
                    displayName: {
                        type: 'string',
                        description: 'The display name for the device profile like in the config page drop down for setting the config. Can include spaces and other chars.'
                    },
                    configCommands: {
                        type: 'array',
                        items: {
                            type: 'string'
                        },
                        description: 'The commands to be sent for configging the device. Can send multiple commands per line. Each line has a 300ms delay after it. It is best to keep as many commands on the same line as possible for the sake of performance.'
                    },
                    sensors: {
                        type: 'array',
                        items: {
                            enum: [
                                'LHS Ultra (cm)',
                                'RHS Ultra (cm)',
                                'LHS Light Sensor',
                                'RHS Light Sensor',
                                'Color Guess',
                                'LHS Wheel Distance (mm)',
                                'RHS Wheel Distance (mm)',
                                'Ultra (cm)',
                                'Color Red',
                                'Color Green',
                                'Color Blue',
                                'Color White',
                                'Proximity LHS',
                                'Proximity RHS',
                                'Button',
                                'Voltage (V)',
                                'Current (A)',
                                'Analog 0',
                                'Analog 1',
                                'Analog 2',
                                'Analog 3',
                                'Light Top',
                                'Light Bottom',
                                'Light Left',
                                'Light Right',
                                'Digital 0',
                                'Digital 1',
                                'Digital 2',
                                'Digital 3',
                                'End Stop 0',
                                'End Stop 1',
                                'Crosswalk Button 0',
                                'Crosswalk Button 1',
                            ]
                        },
                        description: 'The sensors this device profile includes. This will set what sensors are displayed in the sensor table.'
                    },
                    crane: {
                        type: 'string',
                        enum: [
                            '2DOF',
                            '3DOF',
                            '5DOF',
                            '7DOF'
                        ],
                        description: "What type of crane does this device have? If left undefined then it will not be defined with a crane."
                    },
                    rfid: {
                        type: 'boolean',
                        description: 'Does the device have an RFID scanner? Default to true if not defined'
                    },
                    stepperEbot: {
                        type: 'boolean',
                        description: 'Is the device a stepper ebot? Default to true if not defined. Will set if the wheels config settings should display.'
                    }
                },
                required: ['profile', 'displayName', 'configCommands']
            }
        },
        {
            uri: schemas.generalConfig,
            schema: {
                type: 'object',
                properties: {
                    crane: {
                        $ref: schemas.generalConfigCrane
                    }
                },
                required: ['crane']
            }
        },
        {
            uri: schemas.generalConfigCrane,
            schema: {
                type: 'object',
                properties: {
                    "2DOFPresets": {
                        type: 'array',
                        items: {
                            $ref: schemas.generalConfigCranePresets
                        }
                    },
                    "3DOFPresets": {
                        type: 'array',
                        items: {
                            $ref: schemas.generalConfigCranePresets
                        }
                    },
                    "5DOFPresets": {
                        type: 'array',
                        items: {
                            $ref: schemas.generalConfigCranePresets
                        }
                    },
                    "7DOFPresets": {
                        type: 'array',
                        items: {
                            $ref: schemas.generalConfigCranePresets
                        }
                    }
                },
                required: ['2DOFPresets', '3DOFPresets', '5DOFPresets', '7DOFPresets']
            }
        },
        {
            uri: schemas.generalConfigCranePresets,
            schema: {
                type: 'object',
                properties: {
                    name: {
                        type: 'string'
                    },
                    commands: {
                        type: 'array',
                        items: {
                            type: 'string'
                        }
                    }
                }
            }
        },
        {
            uri: schemas.configPage,
            schema: {
                type: 'object',
                properties: {
                    rebootDeviceAfterSetProfile: {
                        type: 'boolean',
                        description: 'Should the device be automatically rebooted after setting a new profile?'
                    },
                    crane: {
                        $ref: schemas.configPageCrane
                    },
                    defaults: {
                        $ref: schemas.configPageDefaults
                    }
                },
                required: ['rebootDeviceAfterSetProfile', 'crane', 'defaults']
            }
        },
        {
            uri: schemas.configPageCrane,
            schema: {
                type: 'object',
                properties: {
                    CraneMin: {
                        type: 'number',
                        description: 'The minimum allowable crane PWM output'
                    },
                    CraneMax: {
                        type: 'number',
                        description: 'The max allowable crane PWM output'
                    },
                    "2DOF": {
                        $ref: schemas.configPageCraneJoints,
                        description: 'Array of joints the 2DOF crane has and their order. i.e. [\'Claw\', \'Wrist\'] will have the following joints with this order "claw = servo 0" and "wrist = servo 1".'
                    },
                    "3DOF": {
                        $ref: schemas.configPageCraneJoints,
                        description: 'Array of joints the 3DOF crane has and their order. i.e. [\'Claw\', \'Wrist\'] will have the following joints with this order "claw = servo 0" and "wrist = servo 1".'
                    },
                    "5DOF": {
                        $ref: schemas.configPageCraneJoints,
                        description: 'Array of joints the 5DOF crane has and their order. i.e. [\'Claw\', \'Wrist\'] will have the following joints with this order "claw = servo 0" and "wrist = servo 1".'
                    },
                    "7DOF": {
                        $ref: schemas.configPageCraneJoints,
                        description: 'Array of joints the 7DOF crane has and their order. i.e. [\'Claw\', \'Wrist\'] will have the following joints with this order "claw = servo 0" and "wrist = servo 1".'
                    },
                    servoPins: {
                        type: 'array',
                        items: {
                            type: 'number'
                        },
                        description: 'This maps the servos to a pin number on the robot in the order of the array. i.e. [34, 36] will be "servo 0 = pin 34" and "servo 1 = pin 36"'
                    }
                },
                required: ['CraneMin', 'CraneMax', '2DOF', '3DOF', '5DOF', '7DOF', 'servoPins']
            }
        },
        {
            uri: schemas.configPageCraneJoints,
            schema: {
                type: 'array',
                items: {
                    enum: [
                        "Claw",
                        "Wrist",
                        "Wrist Rotate",
                        "Elbow Rotate",
                        "Elbow",
                        "Shoulder",
                        "Base Rotate"
                    ]
                }
            }
        },
        {
            uri: schemas.configPageDefaults,
            schema: {
                type: 'object',
                properties: {
                    LHSLightWhite: {
                        type: 'number'
                    },
                    RHSLightWhite: {
                        type: 'number'
                    },
                    LHSLightBlack: {
                        type: 'number'
                    },
                    RHSLightBlack: {
                        type: 'number'
                    },
                    LHSUtraMin: {
                        type: 'number'
                    },
                    RHSUtraMin: {
                        type: 'number'
                    },
                    LHSUtraMax: {
                        type: 'number'
                    },
                    RHSUtraMax: {
                        type: 'number'
                    },
                    CraneProximityLHSCutoff: {
                        type: 'number'
                    },
                    CraneProximityRHSCutoff: {
                        type: 'number'
                    },
                    WheelsCircumference: {
                        type: 'number'
                    },
                    WheelsTrack: {
                        type: 'number'
                    },
                    StreamFrequency: {
                        type: 'number'
                    },
                    CraneMin: {
                        type: 'number',
                        description: 'The default minimum crane PWM output if the device does not have one set on its firmware already.'
                    },
                    CraneMax: {
                        type: 'number',
                        description:  'The default max crane PWM output if the device does not have one set on its firmware already.'
                    }
                },
                required: ['LHSLightWhite', 'RHSLightWhite', 'WheelsCircumference', 'WheelsTrack', 'StreamFrequency', 'CraneMin', 'CraneMax'],
                description: 'The default values for the config page'
            }
        },
        {
            uri: schemas.testRig,
            schema: {
                type: 'object',
                properties: {
                    buttons: {
                        type: 'array',
                        items: {
                            $ref: schemas.testRigButtons
                        },
                        description: 'The buttons on the Test Rig page'
                    }
                }
            }
        },
        {
            uri: schemas.testRigButtons,
            schema: {
                type: 'object',
                properties: {
                    name: {
                        type: 'string',
                        description: 'The button name and label'
                    },
                    command: {
                        type: 'string',
                        description: 'The command(s) to be sent to the device'
                    },
                    color: {
                        enum: ['green', 'blue', 'red'],
                        description: 'The button color. "green" is default if not defined.'
                    },
                    column: {
                        type: 'number',
                        description: 'The column number the button is to be put in. Each button is then located within that column in the order based of its position in the array.'
                    }
                },
                required: ['name', 'command', 'column']
            }
        },
        {
            uri: schemas.codingEditor,
            schema: {
                type: 'object',
                properties: {
                    helpPopout: {
                        type: 'array',
                        items: {
                            $ref: schemas.codingEditorHelpPopout
                        }
                    }
                },
                required: ['helpPopout']
            }
        },
        {
            uri: schemas.codingEditorHelpPopout,
            schema: {
                type: 'object',
                properties: {
                    name: {
                        type: 'string'
                    },
                    iframeUrl: {
                        type: 'string'
                    }
                },
                required: ['name', 'iframeUrl']
            }
        }
    ]
}

type Props = PropsFromRedux & {

}

const EditConfigPage: React.FC<Props> = ({ scratchlinkConfig, projectSetScratchlinkConfig}) => {
    const [config, setConfig] = useState(JSON.stringify(scratchlinkConfig, null, 4));
    const editorRef = useRef<editor.IStandaloneCodeEditor>();
    const monaco = useMonaco();

    useEffect(() => {
        if(!monaco) return;

        monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
            validate: true,
            schemas: getSchemas(monaco)
        })

    }, [monaco]);

    const handleEditorOnMount: OnMount = (editor, monacoInstance) => {
        editorRef.current = editor;
    }

    const onSaveClick = () => {
        projectSetScratchlinkConfig(config);
        toast.success(`Saved Coding Editor Config`);
    }

    return (
        <Page
            title="Edit Config - Coding Editor | ScratchLink"
            background="#E6F0FF"
        >
            <div className="p-editConfigPage">
                <div className="p-editConfigPage__container">
                    <div style={{marginTop: '20px'}}>
                        Ctrl+Space will activate intellisense
                    </div>
                    <Editor 
                        language="json" 
                        height="80vh"
                        theme="vs-dark"
                        value={config}
                        options={{
                            quickSuggestions: true
                        }}
                        onChange={(value, ev) => {
                            setConfig(value || '');
                        }}
                        onMount={handleEditorOnMount}
                    />
                    <Button type="success" style={{marginTop: '10px'}} onClick={onSaveClick}> Save </Button>
                    <p><b>Edit Config Commit Url:</b> <a target="_blank" rel="noopener noreferrer" href={commitVersion.editConfig.commitUrl}>{commitVersion.editConfig.commitUrl}</a></p>
                    <p><b>Edit Config Commit Time:</b> {commitVersion.editConfig.commitTime}</p>
                </div>
            </div>
        </Page>
    )
}

const mapStateToProps = (state: IRootState) => {
    return {
        scratchlinkConfig: state.project.scratchlinkConfig
    }
}

const mapDispatchToProps = {
    projectSetScratchlinkConfig
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditConfigPage);