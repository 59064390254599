/* eslint import/no-webpack-loader-syntax: off */

import DeviceConnectionWebsocketWorker from 'worker-loader!./DeviceConnectionWebsocket.worker';
import { toast } from 'react-toastify';
import store, { projectSetDeviceBatteryVoltage, projectSetDeviceConnected, projectSetDeviceConnectionMethod, projectSetDeviceConneting, projectSetHardwareModeAutoDetected, projectSetDeviceName, selectorGetProjectDeviceConnectionIp, selectorGetProjectDeviceConnectionType, consoleAddConsoleLog, projectSetConfigPageSettingValue, projectResetConfigPageSettingValues, IDeviceConnectionType } from 'store';
import { IDeviceConnectionWorkerMessage, DeviceConnectionSerial } from '.';
import ScratchLinkJSRunner from 'lib/ScratchLinkJSRunner';

import wait from 'utils/wait';

import {
    IDeviceConnectionManagerMessageConnectWebsocket,
    IDeviceConnectionManagerMessageDisconnect,
    IDeviceConnectionManagerMessageSendCommand,
    IDeviceConnectionWorkerMessageSendConnectCommands
} from '.';

export class DeviceConnectionManagerClass extends EventTarget {
    websocketWorker: Worker;
    serialConnection: DeviceConnectionSerial;
    data: any = {};
    connectionType: IDeviceConnectionType | undefined;
    connected: boolean = false;
    connecting: boolean = false;
    connetIp: string | undefined;
    serialPort: string | undefined;
    storeSubscribed: boolean = false;
    rfidClearTimer: undefined | ReturnType<typeof setTimeout>;

    constructor() {
        super();
        
        this.refreshFromStore = this.refreshFromStore.bind(this);

        this.websocketWorker = new DeviceConnectionWebsocketWorker();

        this.websocketWorker.onmessage = ({ data }) => this.workerMessageHandler(data);

        this.serialConnection = new DeviceConnectionSerial(this);

        if(!store) return; //This is so sendCommand can be called from store actions that are created before the store is created
        this.subscribeStore();
    }

    async connect() {
        if(!this.storeSubscribed) this.subscribeStore();
        if(this.connetIp === undefined || this.connectionType === undefined) {
            this.refreshFromStore();
        }

        store.dispatch(projectSetDeviceConneting(true));

        if(this.connectionType === 'websocket') {
            this.websocketWorker.postMessage({
                type: 'DEVICE_CONNECTION_MANAGER_MESSAGE_CONNECT_WEBSOCKET',
                data: {
                    ip: this.connetIp
                }
            } as IDeviceConnectionManagerMessageConnectWebsocket);
            return;
        }

        if(this.connectionType === 'serial') {
            this.serialConnection.connectPort();
            return;
        }

        if(this.connectionType === 'modem') {
            this.serialConnection.connectPortModem();
            return;
        }
    }

    async silentConnectSerial() {
        if(!this.storeSubscribed) this.subscribeStore();
        store.dispatch(projectSetDeviceConnectionMethod('serial'));
        this.serialConnection.connectPortSilent();
    }

    disconnect() {
        if(!this.storeSubscribed) this.subscribeStore();
        console.log(`DISCONNECT CALLED`);
        if(this.connectionType === 'websocket') {
            this.websocketWorker.postMessage({
                type: 'DEVICE_CONNECTION_MANAGER_MESSAGE_DISCONNECT',
                data: {}
            } as IDeviceConnectionManagerMessageDisconnect);
        } else if(this.connectionType === 'serial') {
            this.serialConnection.disconnect();
        } else if(this.connectionType === 'modem') {
            this.serialConnection.disconnect();
        }
    }

    sendCommand(command: string): boolean {
        if(!this.storeSubscribed) this.subscribeStore();
        if(!this.connected) return false;

        if(command.length > 100) {
            throw new Error(`Command is longer than 100 chars. All commands have to be less than 100 chars due to websocket buffering. Command: ${command}`)
        };

        if(this.connectionType === 'websocket') {
            this.websocketWorker.postMessage({
                type: 'DEVICE_CONNECTION_MANAGER_MESSAGE_SEND_COMMAND',
                data: {
                    command
                }
            } as IDeviceConnectionManagerMessageSendCommand);
        } else if(this.connectionType === 'serial') {
            this.serialConnection.write(command);
        } else if(this.connectionType === 'modem') {
            this.serialConnection.write(command);
        }

        store.dispatch(consoleAddConsoleLog(command, 'ScratchLinkOutgoing', 'ScratchLinkOutgoingConsoleDecorator'));

        return true;
    }

    async workerMessageHandler(message: IDeviceConnectionWorkerMessage) {
        if(!this.storeSubscribed) this.subscribeStore();
        const state = store.getState();
        switch(message.type) {
            case 'DEVICE_CONNECTION_WORKER_MESSAGE_CONNECTED':
                store.dispatch<any>(projectSetDeviceConnected(true));
                this.workerMessageHandler({
                    type: 'DEVICE_CONNECTION_WORKER_MESSAGE_SEND_CONNECT_COMMANDS',
                    data: {}
                } as IDeviceConnectionWorkerMessageSendConnectCommands);
                break;
            case 'DEVICE_CONNECTION_WORKER_MESSAGE_SEND_CONNECT_COMMANDS':
                this.sendCommand(`config info; wifi config info;`);
                await wait(200);
                this.sendCommand(`stayalive; led flash;`);
                await wait(200);
                this.sendCommand(`stream config 350; read; stream on; stream config smart on;`);
                await wait(200);
                this.sendCommand(`**wifi config info;`) //check if modem is connected
                break;
            case 'DEVICE_CONNECTION_WORKER_MESSAGE_DISCONNECT':
                if(!this.connected && this.connecting) break; //Do not send disconnect event everytime
                store.dispatch<any>(projectSetDeviceConnected(false));
                store.dispatch<any>(projectSetHardwareModeAutoDetected('none'));
                store.dispatch<any>(projectSetDeviceName(''));
                store.dispatch<any>(projectResetConfigPageSettingValues());
                store.dispatch<any>(projectSetDeviceBatteryVoltage(0));
                store.dispatch<any>(consoleAddConsoleLog('eBot Disconnected', 'Info', 'JSSystemConsoleDecorator'));
                ScratchLinkJSRunner.stopWorker(); //Force stop the worker
                this.data = {};
                this.dispatchEvent(new Event('data'));
                break;
            case 'DEVICE_CONNECTION_WORKER_MESSAGE_LOST_CONNECTION':
                if(this.connecting) break;
                toast.error(`eBot WIFI connection lost. Is the robot still on?`);
                console.error(`SCRATCHLINK WIFI CONNECTION LOST`);
                break;
            case 'DEVICE_CONNECTION_WORKER_MESSAGE_RECOVERED_CONNECTION':
                toast.warn('eBot WIFI connection dropped but has reconnected')
                break;
            case 'DEVICE_CONNECTION_WORKER_FIRMWARE_ERROR':
                toast.error(`Firmware Error. No valid packets received.`);
                console.error(`Firmware Error. No valid packets received.`);
                break;
            case 'DEVICE_CONNECTION_WORKER_SECURITY_FAILED':
                toast.error(`Firmware security check failed.`);
                console.error(`Firmware security check failed.`);
                break;
            case 'DEVICE_CONNECTION_WORKER_MODEM_DETECTED':
                if(state.project.deviceConnectionMethod !== 'serial') break; //if using serial and modem detected then disconnect
                toast.warn(`USB Modem Detected. USB Modem must be connected through the "USB Modem (https site)" option in the device connection menu.`);
                break;
            case 'DEVICE_CONNECTION_WORKER_MESSAGE_MESSAGE_DATA':
                const messageData = JSON.stringify(message.data.messageData); //convert to string
                if(!state.settings.devMode && messageData === '{}') break; //Filter stayalive

                if(message.data.messageData['Ultras']) message.data.messageData['Ultras'] = [
                    (message.data.messageData['Ultras'][0]/10).toFixed(0), 
                    (message.data.messageData['Ultras'][1]/10).toFixed(0),
                    (message.data.messageData['Ultras'][2]/10).toFixed(0),
                    (message.data.messageData['Ultras'][3]/10).toFixed(0),
                ]; //Convert mm to cm

                if(message.data.messageData['lgt']) message.data.messageData['lgt'] = [
                    100-message.data.messageData['lgt'][0],
                    100-message.data.messageData['lgt'][1],
                    message.data.messageData['lgt'][2]
                ] //Invert light sensor values

                Object.assign(this.data, message.data.messageData); //Cache data
                this.dispatchEvent(new CustomEvent<any>('data', {detail: message.data.messageData}));

                if( //Check if modem is connected and usb was used to connect. Only the modem will send this packet
                    message.data.messageData['config'] && message.data.messageData['config'] === 'Wifi_TCPOut' &&
                    message.data.messageData['Enabled'] && message.data.messageData['Enabled'] === 1 &&
                    this.connectionType === 'serial'
                ) { 
                    await wait(200);
                    this.disconnect();
                    toast.error(`USB Modem Detected. USB Modem must be connected through the "USB Modem (https site)" option in the device connection menu.`, {autoClose: 6000});
                    return;
                }

                if(message.data.messageData['RFID'] && message.data.messageData['TagName'] && !message.data.messageData['webAppSystem']) { //RFID packet
                    if(this.rfidClearTimer) clearTimeout(this.rfidClearTimer)
                    this.rfidClearTimer = undefined;

                    this.rfidClearTimer = setTimeout(() => { //Clear RFID after 2 seconds
                        this.workerMessageHandler({
                            ...message,
                            data: {
                                messageData: {
                                    RFID: [],
                                    TagName: '',
                                    webAppSystem: 'RFID Cleared, Fake RFID packet emulated to clear RFID on eBot'
                                }
                            }
                        });
                    }, 3000)
                }

                if(message.data.messageData['evt']) {
                    if(message.data.messageData['evt'] === 200 && message.data.messageData['d'].startsWith('**')) break; //filter out modem command errors
                    store.dispatch(consoleAddConsoleLog(messageData, 'ScratchLinkIncomingEvent')); //Events
                    if(message.data.messageData['evt'] >= 200 && message.data.messageData['evt'] <= 255) {
                        if(message.data.messageData['evt'] === 211) break; //DEVICE_CONNECTION_WORKER_SECURITY_FAILED will handle this error
                        toast.error(`Error on eBot with code ${message.data.messageData['evt']} | ${message.data.messageData['i']} | ${message.data.messageData['d']}`);
                    }
                }
                else store.dispatch(consoleAddConsoleLog(messageData, 'ScratchLinkIncoming')); //All other packets

                if(message.data.messageData['VA']) store.dispatch(projectSetDeviceBatteryVoltage(message.data.messageData['VA'][0])); //Battery Voltage
                this.checkAndStoreConfigPageData(message.data.messageData); //Config Page Data
                break;
            case 'DEVICE_CONNECTION_WORKER_MESSAGE_JSON5_MALFORMED':
                toast.error(`Error | Recieved malformed JSON5 packet. Check chrome dev console for more information.`)
                break;
            case 'DEVICE_CONNECTION_WORKER_MESSAGE_DEVICE_INFO':
                store.dispatch<any>(projectSetHardwareModeAutoDetected(message.data.deviceProfile as any));
                store.dispatch<any>(projectSetDeviceName(message.data.deviceName));
                if(state.project.hardwareModeAutoDetected === 'none' && state.project.deviceName === '') toast.success(`Detected eBot Info | Name: ${message.data.deviceName} | Type: ${message.data.deviceProfile}`);
                break;
        }
    }

    private checkAndStoreConfigPageData(messageData: any) {
        if(!this.storeSubscribed) this.subscribeStore();
        const state = store.getState();
        
        if(messageData['Profile'] && messageData['Name']) {
            store.dispatch<any>(projectSetConfigPageSettingValue('deviceName', messageData['Name']));
        }
        if(messageData['config'] && messageData['config'] === 'Wifi' && messageData['Enabled'] !== undefined) {
            if(messageData['Enabled'] === 0) store.dispatch<any>(projectSetConfigPageSettingValue('wifiMode', 'Off'));
            else store.dispatch<any>(projectSetConfigPageSettingValue('wifiMode', messageData['Type']));
        }
        if(messageData['config'] && messageData['config'] === 'Wifi_Station' && state.project.configPageSettingValues.wifiMode === 'Station') {
            store.dispatch<any>(projectSetConfigPageSettingValue('wifiSSID', messageData['SSID']));
            store.dispatch<any>(projectSetConfigPageSettingValue('wifiPassword', messageData['Password']));
            store.dispatch<any>(projectSetConfigPageSettingValue('wifiIP', messageData['IP_Address']));
        }
        if(messageData['evt'] && messageData['evt'] === 156 && messageData['d'] && messageData['d'] === 'Station' && messageData['IP_address'] && state.project.configPageSettingValues.wifiMode === 'Station') {
            store.dispatch<any>(projectSetConfigPageSettingValue('wifiIP', messageData['IP_address']));
        }
        if(messageData['config'] && messageData['config'] === 'Wifi_AccessPoint' && state.project.configPageSettingValues.wifiMode === 'AccessPoint') {
            store.dispatch<any>(projectSetConfigPageSettingValue('wifiSSID', messageData['SSID']));
            store.dispatch<any>(projectSetConfigPageSettingValue('wifiPassword', messageData['Password']));
            store.dispatch<any>(projectSetConfigPageSettingValue('wifiIP', messageData['IP_Address']));
            store.dispatch<any>(projectSetConfigPageSettingValue('wifiChannel', messageData['Channel']));
        }
        if(messageData['config'] && messageData['config'] === 'Wifi_HTML' && messageData['Enabled'] !== undefined) {
            store.dispatch<any>(projectSetConfigPageSettingValue('htmlEnabled', messageData['Enabled'] === 1))
        }
        if(messageData['config'] && messageData['config'] === 'Wheels') {
            store.dispatch<any>(projectSetConfigPageSettingValue('wheelsCircum', messageData['Circum']));
            store.dispatch<any>(projectSetConfigPageSettingValue('wheelsTrack', messageData['Track']));
        }
        if(messageData['config'] && messageData['config'] === 'BW') {
            store.dispatch<any>(projectSetConfigPageSettingValue('lineLHSWhite', messageData['lhs_white']));
            store.dispatch<any>(projectSetConfigPageSettingValue('lineRHSWhite', messageData['rhs_white']));
            store.dispatch<any>(projectSetConfigPageSettingValue('lineLHSBlack', messageData['lhs_black']));
            store.dispatch<any>(projectSetConfigPageSettingValue('lineRHSBlack', messageData['rhs_black']));
        }
        if(messageData['config'] && messageData['config'] === 'Ultra') {
            if(messageData['Num'] === 0) {
                store.dispatch<any>(projectSetConfigPageSettingValue('ultraLHSMin', (messageData['EventMin']/10).toFixed(0)));
                store.dispatch<any>(projectSetConfigPageSettingValue('ultraLHSMax', (messageData['EventMax']/10).toFixed(0)));
            } else if(messageData['Num'] === 1) {
                store.dispatch<any>(projectSetConfigPageSettingValue('ultraRHSMin', (messageData['EventMin']/10).toFixed(0)));
                store.dispatch<any>(projectSetConfigPageSettingValue('ultraRHSMax', (messageData['EventMax']/10).toFixed(0)));
            }
        }
        if(messageData['config'] && messageData['config'] === 'Proximity') {
            if(messageData['Num'] === 0) {
                store.dispatch<any>(projectSetConfigPageSettingValue('craneProximityLHSCutoff', messageData['CutOff']));
            }
            else if(messageData['Num'] === 1) store.dispatch<any>(projectSetConfigPageSettingValue('craneProximityRHSCutoff', messageData['CutOff']));
        }
        if(messageData['config'] && messageData['config'] === 'Servo') {
            const servo: any = `servo${messageData['Num']}`;
            store.dispatch<any>(projectSetConfigPageSettingValue(servo, [
                messageData['Low'],
                messageData['High']
            ] as any));
        }
        if(messageData['sec'] && messageData['s']) {
            store.dispatch<any>(projectSetConfigPageSettingValue('securityKey', messageData['sec']));
            store.dispatch<any>(projectSetConfigPageSettingValue('securityKeyEnabled', messageData['Enabled'] === 1));
            store.dispatch<any>(projectSetConfigPageSettingValue('serialNumber', messageData['s']));
        }
        if(messageData['config'] && messageData['config'] === 'firmware' && messageData['Version']) {
            store.dispatch<any>(projectSetConfigPageSettingValue('esp32FirmwareVersion', 'v'+messageData['Version'][0]));
        }
        if(messageData['config'] && messageData['config'] === 'Wheels' && messageData['Major'] && messageData['Minor']) {
            store.dispatch<any>(projectSetConfigPageSettingValue('nanoFirmwareVersion', `v${messageData['Major']}.${messageData['Minor']}`));
        }
        if(messageData['config'] && messageData['config'] === 'RFID' && messageData['Version']) {
            store.dispatch<any>(projectSetConfigPageSettingValue('rfidVersion', messageData['Version']));
        }
    }


    //Subscribe to store
    private subscribeStore() {
        store.subscribe(this.refreshFromStore);
        this.refreshFromStore();
        this.storeSubscribed = true;
    }

    //Refresh class with data from store
    private refreshFromStore() {
        const state = store.getState();

        this.connected = state.project.deviceConnected;
        this.connecting = state.project.deviceConnecting;
        this.connetIp = selectorGetProjectDeviceConnectionIp(state);
        this.serialPort = state.project.deviceSerialPort;
        this.connectionType = selectorGetProjectDeviceConnectionType(state);
    }
}

export const DeviceConnectionManager = new DeviceConnectionManagerClass();