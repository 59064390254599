import React, { useState, useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState, codingSetActiveProjectIndex, projectSetCodingNavBarCollapsed, codingSetCreateProjectModelOpen, codingDeleteProject, codingSetProjectName, ICodingProject } from 'store';
import { push } from 'connected-react-router';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { Menu, Item, contextMenu, ItemParams } from 'react-contexify';
import Select from 'react-select';
import { openLocalProject } from 'lib';
import 'react-contexify/dist/ReactContexify.min.css';

import { FaNodeJs, FaChevronLeft, FaPython } from 'react-icons/fa';
import { BsGrid3X3Gap } from 'react-icons/bs';
import { SiScratch, SiProbot } from 'react-icons/si';
import { SlNotebook } from 'react-icons/sl';
import { GiSpiderWeb } from 'react-icons/gi';
import { RiOrganizationChart } from 'react-icons/ri';

import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import ScratchLinkJSRunner from 'lib/ScratchLinkJSRunner';
import JSRunner from 'lib/JSRunner';

type OwnProps = {
    
}

type Props = PropsFromRedux & OwnProps;

const ProjectLocalImport: React.FC<{ className: string }> = ({ className }) => {
    const inputRef = useRef(null);

    //@ts-ignore
    const handleClick = () => {
        //@ts-ignore
        inputRef?.current?.click();
    };

    const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        let reader = new FileReader();
        reader.onload = r => {
            openLocalProject(r?.target?.result as string);
        };
        reader.onerror = e => {
            console.error(e);
        }
        //@ts-ignore
        reader.readAsText(files[0]);
        //@ts-ignore
        e.target.value = null;
    };

    return (
        <div style={{padding: 0}}>
            <input type="file" ref={inputRef} accept=".ScratchLinkProject" onChange={onChangeFile} hidden/>
            <Button className={`text-sm mt-1 text-gray-100 hover:underline ${className}`} type="transparent" addStyles={['square']} onClick={handleClick}>Import Project</Button>
        </div>
    )
}

const CodingPojectsNavBar: React.FC<Props> = ({ codingProjects, activeIndex, codingNavBarCollapsed, codingSetActiveProjectIndex, projectSetCodingNavBarCollapsed, codingSetCreateProjectModelOpen, codingSetProjectName, codingDeleteProject, scratchlinkConfig }) => {
    const [renameCodingProjectName, setRenameCodingProjectName] = useState('');
    const [renameCodingProjectIndex, setRenameCodingProjectIndex] = useState(0);
    const [renameCodingProjectModalOpen, setRenameCodingProjectModalOpen] = useState(false);

    const handleProjectClick = (index: number) => {
        codingSetActiveProjectIndex(index);
        ScratchLinkJSRunner.stopWorker();
    }
    

    const handleEvent = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, projectIndex: number) => {
        e.preventDefault();
        let id = 'contextMenu__codingProjectsNavBar__codingProject';
        if(codingProjects[projectIndex].type === 'js' || codingProjects[projectIndex].type === 'jsscratch') id+='__html';
        if(codingProjects[projectIndex].type === 'hardwarejs' || codingProjects[projectIndex].type === 'hardwarepy') id+='__hardware';

        contextMenu.show({
            id,
            event: e,
            props: {
                projectIndex
            }
        });
    };

    const handleOnProjectExportHTMLJS4ScratchLink = (codingProject: ICodingProject) => {
        const [, iframeCode] = JSRunner.generateJS4ScratchIFrameCode(codingProject);

        const blob = new Blob([iframeCode], {type: "text/html;charset=utf-8"});
        saveAs(blob, `${codingProject.name}.html`);
    }

    const handleOnProjectExportHTMLJS4Web = (codingProject: ICodingProject) => {
        const [, iframeCode] = JSRunner.generateJSIFrameCode(codingProject);

        const blob = new Blob([iframeCode], {type: "text/html;charset=utf-8"});
        saveAs(blob, `${codingProject.name}.html`);
    }

    const handleOnProjectExportHTML = (args: ItemParams<any, any>) => {
        const projectIndex: number = (args.props as any).projectIndex;
        const codingProject = codingProjects[projectIndex];

        if(codingProject.type === 'js') handleOnProjectExportHTMLJS4Web(codingProject);
        if(codingProject.type === 'jsscratch') handleOnProjectExportHTMLJS4ScratchLink(codingProject);
    }

    const handleOnProjectExport = (args: ItemParams<any, any>) => {
        const projectIndex: number = (args.props as any).projectIndex;

        const blob = new Blob([JSON.stringify(codingProjects[projectIndex])], {type: "text/json;charset=utf-8"});
        saveAs(blob, `${codingProjects[projectIndex].name}.ScratchLinkProject`);
    }

    const handleOnProjectRename = (args: ItemParams<any, any>) => {
        const projectIndex: number = (args.props as any).projectIndex;

        setRenameCodingProjectName(codingProjects[projectIndex].name);
        setRenameCodingProjectIndex(projectIndex);
        setRenameCodingProjectModalOpen(true);
    }

    const handleCloseProjectRenameModal = () => {
        setRenameCodingProjectModalOpen(false);
    }

    const handleSaveProjectRenameModal = () => {
        codingSetProjectName(renameCodingProjectIndex, renameCodingProjectName);
        setRenameCodingProjectModalOpen(false);
    }

    const handleKeyPressProjectRename = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key !== 'Enter') return;
        handleSaveProjectRenameModal();
    }

    const handleProjectRenameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRenameCodingProjectName(e.target.value);
    }

    const handleOnProjectDelete = (args: ItemParams<any, any>) => {
        const projectIndex: number = (args.props as any).projectIndex;

        const projectType = codingProjects[projectIndex].type;
        if(projectType === 'matrix' && projectIndex === 0) return toast.error('Cannot delete first matrix project');

        codingDeleteProject(projectIndex);
    }

    const handleOnCollapseClick = () => {
        projectSetCodingNavBarCollapsed(!codingNavBarCollapsed);
    }

    return (
        <div className={`flex flex-col ${codingNavBarCollapsed ? 'w-12 min-w-0' : 'w-52'} text-white`} style={{background: '#0e0e4a', maxHeight: 'calc(100vh - 70px)', transition: 'width 0.1s ease-in-out'}}>
            <Modal title="Rename Project" isOpen={renameCodingProjectModalOpen} closeModal={handleCloseProjectRenameModal} type="save" onSave={handleSaveProjectRenameModal}>
                <input autoFocus className='border-2 p-2 text-lg' type="text" value={renameCodingProjectName} onChange={handleProjectRenameChange} onKeyPress={handleKeyPressProjectRename} />
            </Modal>
            <Menu id="contextMenu__codingProjectsNavBar__codingProject">
                <Item onClick={handleOnProjectDelete}>Delete Project</Item>
                <Item onClick={handleOnProjectRename}>Rename Project</Item>
                <Item onClick={handleOnProjectExport}>Export Project</Item>
            </Menu>
            <Menu id="contextMenu__codingProjectsNavBar__codingProject__hardware">
                <Item onClick={handleOnProjectDelete}>Delete Project</Item>
                <Item onClick={handleOnProjectRename}>Rename Project</Item>
                <Item onClick={handleOnProjectExport}>Export Project</Item>
            </Menu>
            <Menu id="contextMenu__codingProjectsNavBar__codingProject__html">
                <Item onClick={handleOnProjectDelete}>Delete Project</Item>
                <Item onClick={handleOnProjectRename}>Rename Project</Item>
                <Item onClick={handleOnProjectExport}>Export Project</Item>
                <Item onClick={handleOnProjectExportHTML}>Export HTML</Item>
            </Menu>
            <div>
                <h3 className={`w-52 font-bold text-center p-2 pt-3 none ${codingNavBarCollapsed ? 'opacity-0' : ''}`}>Coding Projects</h3>
            </div>
            <div style={{marginTop: '2px'}} className={`border-b-2 ${codingNavBarCollapsed ? 'opacity-0' : ''}`}/>
            <div className={`p-2 h-full overflow-y-auto ${codingNavBarCollapsed ? 'opacity-0' : ''}`}>
                {
                    codingProjects.map((codingProject, index) => (
                        <div 
                            key={index} 
                            className={`p-2 mb-2 flex cursor-pointer ${activeIndex === index ? 'bg-gray-400': ''}`} 
                            onClick={() => handleProjectClick(index)}
                            onContextMenu={e => handleEvent(e, index)}
                        >
                            {
                                codingProject.type === 'hardwarejs' && <SiProbot className="mr-1" style={{color: '#ffc66d'}} />
                            }
                            {
                                codingProject.type === 'hardwarepy' && <FaPython className="mr-1" style={{color: '#ffc66d'}} />
                            }
                            {
                                codingProject.type === 'js' && !codingProject.files.find(file => file.type === 'html') && <FaNodeJs className="mr-1" style={{color: '#ffc66d'}} /> //has no html, js oly
                            }
                            {
                                codingProject.type === 'js' && codingProject.files.find(file => file.type === 'html') && <GiSpiderWeb className="mr-1" style={{color: '#ffc66d'}} /> //has html
                            }
                            {
                                codingProject.type === 'jsscratch' && <SiScratch className="mr-1" style={{color: '#ffc66d'}} />
                            }
                            {
                                codingProject.type === 'matrix' && <BsGrid3X3Gap className="mr-1" style={{color: '#cc0000'}} />
                            }
                            {
                                codingProject.type === 'flowChart' &&  <RiOrganizationChart className="mr-1" style={{color: '#ffffff', marginTop: '1px'}} />
                            }
                            {
                                codingProject.type === 'portfolio' && <SlNotebook className="mr-1" style={{color: '#ffffff', marginTop: '1px'}} />
                            }
                            {codingProject.name}
                        </div>
                    ))
                }
            </div>
            <div className="justify-self-end w-full flex flex-col items-center justify-center p-2 pb-0">
                <Button type="success" className={codingNavBarCollapsed ? 'opacity-0' : ''} onClick={() => codingSetCreateProjectModelOpen(true)}>Create New Project</Button>
                <ProjectLocalImport className={codingNavBarCollapsed ? 'opacity-0' : ''} />
                <button className="mt-2 rounded-full hover:bg-gray-700" onClick={handleOnCollapseClick}>
                    <FaChevronLeft className='text-lg' style={{ marginRight: '2px', transform: codingNavBarCollapsed ? 'rotate(180deg)' : '' }} />
                </button>
                <div className={`${codingNavBarCollapsed ? 'opacity-0' : ''} pb-2 flex flex-row text-xs wrap text-gray-300 underline`}>
                    <button className='p-1 hover:text-white' onClick={() => window.open('https://scratchlink.au/privacy-and-security-policies/', '_blank')}>
                        Privacy Policy
                    </button>
                    <button className='p-1 hover:text-white' onClick={() => window.open('https://scratchlink.au/terms-of-use/', '_blank')}>
                        Terms of Service
                    </button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: IRootState, ownProps: OwnProps) => {
    return {
        codingProjects: state.coding.codingProjects,
        activeIndex: state.coding.activeProjectIndex,
        codingNavBarCollapsed: state.project.codingNavBarCollapsed,
        scratchlinkConfig: state.project.scratchlinkConfig
    }
}

const mapDispatchToProps = {
    codingSetActiveProjectIndex,
    projectSetCodingNavBarCollapsed,
    codingSetCreateProjectModelOpen,
    codingSetProjectName,
    codingDeleteProject
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CodingPojectsNavBar);