import JSON5 from 'json5';
import { toast } from 'react-toastify';
import wait from 'utils/wait';

type SerialPortId = 'serial1' | 'serial2';

class ModemBinding {
    serial1: SerialPort | undefined;
    serial2: SerialPort | undefined;

    serial1LineBuffer: string = "";
    serial1Writer: WritableStreamDefaultWriter | undefined;
    serial1Reader: ReadableStreamDefaultReader | undefined;
    serial1Connecting: boolean = false;
    serial1Connected: boolean = false;

    serial2LineBuffer: string = "";
    serial2Writer: WritableStreamDefaultWriter | undefined;
    serial2Reader: ReadableStreamDefaultReader | undefined;
    serial2Connecting: boolean = false;
    serial2Connected: boolean = false;

    modemPort: SerialPortId | undefined;

    async connectPort(): Promise<boolean> {
        if(!this.serial1Connecting && !this.serial1Connected) {
            try {
                this.serial1Connecting = true;
                this.serial1 = await navigator.serial.requestPort({ filters: [{usbProductId: 0xEA60, usbVendorId: 0x10C4}]});
                await this.serial1.open({ baudRate: 921600 });
    
                this.serial1LineBuffer = '';
                this.readLoopSerial1();

                setTimeout(() => {
                    this.writeSerial1('**wifi config info;');
                }, 2000)

                await wait(4000);    
            } catch(e) {
                console.log(`Error opening serial port 1`, e)
                toast.error(`Error opening serial port 1. Make sure you are using Chrome or Edge (Any Chromium Based Browser) and refresh.`);
            }

            console.log(this.serial1Connected)

            return this.serial1Connected;
        }

        if(!this.serial2Connecting && !this.serial2Connected) {
            try {
                this.serial2Connecting = true;
                this.serial2 = await navigator.serial.requestPort({ filters: [{usbProductId: 0xEA60, usbVendorId: 0x10C4}]});
                await this.serial2.open({ baudRate: 921600 });
    
                this.serial1LineBuffer = '';
                this.readLoopSerial2();
    
                //this.serial2Connected = true;  
                setTimeout(() => {
                    this.writeSerial2('**wifi config info;');
                }, 2000)

                await wait(4000);    
            } catch(e) {
                console.log(`Error opening serial port 2`, e)
                toast.error(`Error opening serial port 2. Make sure you are using Chrome or Edge (Any Chromium Based Browser) and refresh.`);
            }

            return this.serial2Connected;
        }

        return false;
    }

    async sync(ssid?: string, wifiPass: string = 'Password') {
        if(!this.serial1Connected || !this.serial2Connected || !this.modemPort) {
            toast.error(`Devices not both connected or couldn't identify modem`);
            this.disconnect();
            return;
        }
        if(!ssid) ssid = `ScratchLink${Math.floor(Math.random()*9999)}`;

        if(this.modemPort === 'serial1') {
            await this.writeSerial1(`**wifi config tcpskt on 8081 on '192.168.5.1';`);
            await wait(400);
            await this.writeSerial1(`**wifi config station '${ssid}' '${wifiPass}';`);
            await wait(400);
            await this.writeSerial1(`**wifi enable station on;`);

            await this.writeSerial2(`wifi config tcpskt on 8081;`);
            await wait(400);
            await this.writeSerial2(`wifi config AP '${ssid}' '${wifiPass}' '192.168.5.1';`);
            await wait(400);
            await this.writeSerial2(`config name '${ssid}';`);
            await wait(400);
            await this.writeSerial2(`wifi enable AP on;`);

            this.disconnect();
        } else if(this.modemPort === 'serial2') {
            await this.writeSerial2(`**wifi config tcpskt on 8081 on '192.168.5.1';`);
            await wait(400);
            await this.writeSerial2(`**wifi config station '${ssid}' '${wifiPass}';`);
            await wait(400);
            await this.writeSerial2(`**wifi enable station on;`);

            await this.writeSerial1(`wifi config tcpskt on 8081;`);
            await wait(400);
            await this.writeSerial1(`wifi config AP '${ssid}' '${wifiPass}' '192.168.5.1';`);
            await wait(400);
            await this.writeSerial1(`config name '${ssid}';`);
            await wait(400);
            await this.writeSerial1(`wifi enable AP on;`);

            this.disconnect();
        }

        toast.info(`eBot now has SSID: ${ssid}`)
    }

    async writeSerial1(data: string): Promise<void> {
        try {
            const encoder = new TextEncoder();
            if(this.serial1Writer) this.serial1Writer.releaseLock();
            this.serial1Writer = this.serial1?.writable?.getWriter();
            await this.serial1Writer?.write(encoder.encode(data));
            this.serial1Writer?.releaseLock();
        } catch(e) {
            console.error(`Error writing to serial port 1 | message: ${data}`, e);
        }
        
        return Promise.resolve();
    }

    async writeSerial2(data: string): Promise<void> {
        try {
            const encoder = new TextEncoder();
            if(this.serial2Writer) this.serial2Writer.releaseLock();
            this.serial2Writer = this.serial2?.writable?.getWriter();
            await this.serial2Writer?.write(encoder.encode(data));
            this.serial2Writer?.releaseLock();
        } catch(e) {
            console.error(`Error writing to serial port 2 | message: ${data}`, e);
        }
        
        return Promise.resolve();
    }

    async disconnect() {
        try {
            await this.serial1Reader?.cancel();
            await this.serial1?.close();
            await this.serial2Reader?.cancel();
            await this.serial2?.close();
        } catch(e) {
            console.error(`Error in disconnect function`, e);
        }

        if(this.serial1Connected) toast.error(`Disconnected from eBot 1`);
        if(this.serial2Connected) toast.error(`Disconnected from eBot 2`);

        this.serial1LineBuffer = '';
        this.serial2LineBuffer = '';

        this.serial1Connected = false;
        this.serial2Connected = false;
        this.serial1Connecting = false;
        this.serial2Connecting = false;
    }

    private async readLoopSerial1() {
        if(!this.serial1 || this.serial1.readable === null) {
            console.error(`Fatal error in WebSerial connection process`);
            return;
        };

        while(this.serial1.readable) {
            this.serial1Reader = this.serial1.readable.getReader();

            try {
                const { value, done } = await this.serial1Reader.read();
                this.serial1Reader.releaseLock();

                if (done) { //Reader Cancelled
                    console.log('done', done)
                    break;
                }

                const valueString = String.fromCharCode.apply(null, new Uint8Array(value) as any);
                this.serial1LineBuffer += valueString;

                const lines = this.serial1LineBuffer.split('\n');
                for(let i=0; i<lines.length-1; i++) {
                    this.handleMessageData(lines[i], 'serial1');
                    this.serial1LineBuffer = this.serial1LineBuffer.substr(this.serial1LineBuffer.indexOf('\n')+1, this.serial1LineBuffer.length);
                }

            } catch (error) {
                console.log(`Read Loop Error`, error);
                this.serial1LineBuffer = '';
            }
        }

        this.disconnect();
    }

    private async readLoopSerial2() {
        if(!this.serial2 || this.serial2.readable === null) {
            console.error(`Fatal error in WebSerial connection process`);
            return;
        };

        while(this.serial2.readable) {
            this.serial2Reader = this.serial2.readable.getReader();

            try {
                const { value, done } = await this.serial2Reader.read();
                this.serial2Reader.releaseLock();

                if (done) { //Reader Cancelled
                    console.log('done', done)
                    break;
                }

                const valueString = String.fromCharCode.apply(null, new Uint8Array(value) as any);
                this.serial2LineBuffer += valueString;

                const lines = this.serial2LineBuffer.split('\n');
                for(let i=0; i<lines.length-1; i++) {
                    this.handleMessageData(lines[i], 'serial2');
                    this.serial2LineBuffer = this.serial2LineBuffer.substr(this.serial2LineBuffer.indexOf('\n')+1, this.serial2LineBuffer.length);
                }

            } catch (error) {
                console.log(`Read Loop Error`, error);
                this.serial2LineBuffer = '';
            }
        }

        this.disconnect();
    }

    private handleMessageData(messageString: string, serialPortId: SerialPortId) {
        let messageData: any;

        try {
            messageData = JSON5.parse(messageString);
        } catch(e) {
            console.error(`Error passing JSON5 packet | ${messageString} |`, e);
            return {};
        }

        console.log(serialPortId, messageData);

        if(serialPortId === 'serial1') {
            this.serial1Connecting = false;
            this.serial1Connected = true;
        }
        if(serialPortId === 'serial2') {
            this.serial2Connecting = false;
            this.serial2Connected = true;
        }

        if(messageData.Modem && messageData.Modem === 1) { //If sends a Modem packet then it is the modem
            this.modemPort = serialPortId;
        }
    }
}

export default new ModemBinding();