import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router';
import { push } from 'connected-react-router';
import ReactResizeDetector from 'react-resize-detector';
import { IRootState, projectSetPageDimensions, settingsSetDevMode, codingCreateSelectedFlowChartProject } from 'store';
import { PageTypes } from 'pages/pages';
import queryString from 'query-string';

import CodingPage from 'pages/CodingPage/CodingPage';
import ConsolePage from 'pages/ConsolePage/ConsolePage';
import RemotePage from 'pages/RemotePage/RemotePage';
/* import TestPage from 'pages/TestPage/TestPage'; */
import ConfigPage from 'pages/ConfigPage/ConfigPage';
import ModemBindingPage from 'pages/ModemBinding/ModemBindingPage';
import EditConfigPage from 'pages/EditConfigPage/EditConfigPage';
import CodingHelpTestPage from 'pages/CodingHelpTestPage/CodingHelpTestPage';

import PyRunner from 'lib/Python/PyRunner';

PyRunner.startPyEnv();

type Props = PropsFromRedux & {
}

const Main: React.FC<Props> = ({ projectSetPageDimensions, settingsSetDevMode, push, location, codingCreateSelectedFlowChartProject }) => {
    if(window.location.pathname !== '/') {
        window.location.replace('/#'+window.location.pathname);
        return <div style={{paddingTop: '80px'}}>Redirecting...</div>
    }

    const handleResize = (width: number, height: number) => {
        projectSetPageDimensions({width, height});
    }

    const hash = window.location.hash.split('?');
    const queryParams = queryString.parse(hash.slice(1, hash.length).join('?'));

    if(queryParams.devMode !== undefined) {
        settingsSetDevMode(queryParams.devMode === 'true');
        push(location.pathname);
    }
    
    if(queryParams.createFlowChartProject) {
        codingCreateSelectedFlowChartProject();
    }

    return (
        <main>
            <ReactResizeDetector handleWidth handleHeight onResize={handleResize} refreshRate={100} refreshMode="debounce"/>
            <Switch location={location}>
                <Route path={PageTypes.Coding} component={CodingPage} />
                <Route path={PageTypes.Console} component={ConsolePage} />
                <Route path={PageTypes.Remote} component={RemotePage} />
                {/* <Route path={PageTypes.Test} component={TestPage} /> */}
                <Route path={PageTypes.Config} component={ConfigPage} />
                <Route path={PageTypes.ModemBinding} component={ModemBindingPage} />
                <Route path={PageTypes.EditConfig} component={EditConfigPage} />
                <Route path={PageTypes.CodingHelpTest} component={CodingHelpTestPage} />
                <Redirect to={PageTypes.Coding} /> { /* Redirect 404 to home */ }
            </Switch>
        </main>
    );
}

const mapStateToProps = (state: IRootState) => {
    return {
        location: state.router.location
    }
}


const mapDispatchToProps = {
    projectSetPageDimensions,
    settingsSetDevMode,
    push,
    codingCreateSelectedFlowChartProject
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Main);