import store, { selectorGetBackpackSavedMatrixes } from 'store';
import { DeviceConnectionManager } from 'lib';
import wait from 'utils/wait';
import {toast} from 'react-toastify';

const matrixDisplayHex = (hex: string) => {
    DeviceConnectionManager.sendCommand(`matrix show 0x${hex};`);
}

const matrixSaveHardwareMatrixes = async (codingProjectIndex: number, fileIndex: number) => {
    const state = store.getState();

    toast.warn(`Saving Matrixes To eBot`);

    const [singleSavedMatrixes, folderSavedMatrixes] = selectorGetBackpackSavedMatrixes(codingProjectIndex, fileIndex)(state);

    for(let i=0; i<singleSavedMatrixes.length; i++) {
        DeviceConnectionManager.sendCommand(`matrix save ${i} 0x${singleSavedMatrixes[i].hex};`);
        await wait(50);
    }


    for(let i=0; i<folderSavedMatrixes.length; i++) {
        const folder = folderSavedMatrixes[i];
        for(let j=0; j<folder.length; j++) {
            DeviceConnectionManager.sendCommand(`matrix save ${50+(i*50)+j} 0x${folderSavedMatrixes[i][j].hex};`);
            await wait(50);
        }
    }

    toast.success(`Saved Matrixes To eBot`);
    
    return Promise.resolve();
}

export const MatrixManager = {
    matrixDisplayHex,
    matrixSaveHardwareMatrixes
}