import immer from 'immer';

import { IAnimationState, IAnimationActionTypes } from '.';
import { ICodingMatrixEditorAction } from 'store';

import {
    ANIMATION_SET_FPS,
    ANIMATION_SET_PLAYING
} from '.';

export const animationInitialState: IAnimationState = {
    fps: 5,
    playing: false
}

export const animationReducer = (state: IAnimationState, action: IAnimationActionTypes, codingProjectIndex: number, fileIndex: number): IAnimationState => {   
    const currAction = action as ICodingMatrixEditorAction;
    if(currAction.codingProjectIndex !== codingProjectIndex || currAction.fileIndex !== fileIndex) return {...state};    

    switch (action.type) {   
        case ANIMATION_SET_FPS:
            return immer(state, (draftState: IAnimationState) => {
                draftState.fps = action.fps
            })

        case ANIMATION_SET_PLAYING:
            return immer(state, (draftState: IAnimationState) => {
                draftState.playing = action.playing;
            })
        
        default:
            return {...state};
    }
}