import { createSelector } from 'reselect';
import { IRootState } from 'store';

import { selectorGetMatrixEditor } from './selectorGetMatrixEditor';

export const selectorGetSelectedBackpackMatrix = (codingProjectIndex: number, fileIndex: number) => createSelector(
    [
        (state: IRootState) => selectorGetMatrixEditor(codingProjectIndex, fileIndex)(state).backpack.matrixes,
        (state: IRootState) => selectorGetMatrixEditor(codingProjectIndex, fileIndex)(state).backpack.selectedMatrixId
    ],
    (matrixes, selectedMatrixId) => matrixes.byIds[selectedMatrixId]
);