import React from 'react';
import { IConsoleDecorator } from '../ConsoleDecorator';

export const VAConsoleDecorator: IConsoleDecorator = ({ msgObject }) => {

    return (
        <>
            Voltage {msgObject['VA'][0]}V | Current {msgObject['VA'][1]}A
        </>
    )
};

VAConsoleDecorator.decoratorTest = (msgObject) => {
    if(msgObject['VA']) return true;

    return false;
}

VAConsoleDecorator.consoleClass = () => 'c-consoleDecorator__VA';