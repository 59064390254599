//import store from 'store';

import {
    ICodingBackpackFoldersCreateFolderAction,
    ICodingBackpackFoldersMoveFolderAction,
    ICodingBackpackFoldersDeleteFolderAction,
    ICodingBackpackFoldersRenameFolderAction,
    ICodingBackpackFoldersMoveMatrixAction,
    ICodingBackpackFoldersAddMatrixAction,
    ICodingBackpackFoldersDeleteMatrixAction
} from '.';

import {
    CODING_PROJECT_FILE_BACKPACKFOLDERS_CREATE_FOLDER,
    CODING_PROJECT_FILE_BACKPACKFOLDERS_MOVE_FOLDER,
    CODING_PROJECT_FILE_BACKPACKFOLDERS_DELETE_FOLDER,
    CODING_PROJECT_FILE_BACKPACKFOLDERS_RENAME_FOLDER,
    CODING_PROJECT_FILE_BACKPACKFOLDERS_MOVE_MATRIX,
    CODING_PROJECT_FILE_BACKPACKFOLDERS_ADD_MATRIX,
    CODING_PROJECT_FILE_BACKPACKFOLDERS_DELETE_MATRIX
} from '.';

export const codingBackpackFoldersCreateFolder = (codingProjectIndex: number, fileIndex: number, name: string, folderId?: string): ICodingBackpackFoldersCreateFolderAction => {
    return {
        type: CODING_PROJECT_FILE_BACKPACKFOLDERS_CREATE_FOLDER,
        codingProjectIndex,
        fileIndex,
        name,
        folderId
    }
}

export const backpackFolderMoveFolder = (codingProjectIndex: number, fileIndex: number, oldIndex: number, newIndex: number): ICodingBackpackFoldersMoveFolderAction => {
    return {
        type: CODING_PROJECT_FILE_BACKPACKFOLDERS_MOVE_FOLDER,
        codingProjectIndex,
        fileIndex,
        oldIndex,
        newIndex
    }
}

export const codingBackpackFoldersDeleteFolder = (codingProjectIndex: number, fileIndex: number, folderId: string): ICodingBackpackFoldersDeleteFolderAction => {
    return {
        type: CODING_PROJECT_FILE_BACKPACKFOLDERS_DELETE_FOLDER,
        codingProjectIndex,
        fileIndex,
        folderId
    }
}

export const codingBackpackFoldersRenameFolder = (codingProjectIndex: number, fileIndex: number, folderId: string, name: string): ICodingBackpackFoldersRenameFolderAction => {
    return {
        type: CODING_PROJECT_FILE_BACKPACKFOLDERS_RENAME_FOLDER,
        codingProjectIndex,
        fileIndex,
        folderId,
        name
    }
}

export const codingBackpackFoldersMoveMatrix = (codingProjectIndex: number, fileIndex: number, matrixId: string, oldIndex: number, newIndex: number, oldFolderId: string, newFolderId: string): ICodingBackpackFoldersMoveMatrixAction => {
    return {
        type: CODING_PROJECT_FILE_BACKPACKFOLDERS_MOVE_MATRIX,
        codingProjectIndex,
        fileIndex,
        matrixId,
        oldIndex,
        newIndex,
        oldFolderId,
        newFolderId
    }
}

export const codingBackpackFoldersAddMatrix = (codingProjectIndex: number, fileIndex: number, matrixId: string, folderId: string): ICodingBackpackFoldersAddMatrixAction => {
    return {
        type: CODING_PROJECT_FILE_BACKPACKFOLDERS_ADD_MATRIX,
        codingProjectIndex,
        fileIndex,
        matrixId,
        folderId
    }
}

export const codingBackpackFoldersDeleteMatrix = (codingProjectIndex: number, fileIndex: number, matrixId: string, folderId: string): ICodingBackpackFoldersDeleteMatrixAction => {
    return {
        type: CODING_PROJECT_FILE_BACKPACKFOLDERS_DELETE_MATRIX,
        codingProjectIndex,
        fileIndex,
        matrixId,
        folderId
    }
}