import React, { memo } from 'react';

interface MatrixSvgProps {
    stdBinary: string[];
}

const MatrixSvg: React.FC<MatrixSvgProps> = memo(({ stdBinary }) => {
    return (
        <svg height="100" width="100">
            {
                stdBinary.map((val, index) => {
                    const xPos = ((index % 16)*5)+(index % 16);
                    const yPos = Math.floor(index/16)*6;
                    const fill = val === '1' ? '#E20000' : '#e8e8e8';
                    return (
                        <circle key={index} cx={3+xPos} cy={3+yPos} r="2" stroke="black" strokeWidth="0" fill={fill} />
                    )
                })
            }
        </svg>
    )
}, PropsAreEqual);

function PropsAreEqual(prevProps: MatrixSvgProps, nextProps: MatrixSvgProps) {
    return prevProps.stdBinary === nextProps.stdBinary;
}

export default MatrixSvg;