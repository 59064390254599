import React, { useRef, useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { push } from 'connected-react-router';
import ReactTooltip from 'react-tooltip';
import { 
    IRootState, 
    GamePadTypes, 
    initalState, 
    codingInitialState, 
    settingsSetFileName, 
    codingSetProjectHardwareMode, 
    codingSetProjectGamepadMode, 
    projectSetHardwareMode, 
    codingSetActiveProjectIndex, 
    codingSetSettingsModelOpen, 
    codingSetRunning, 
    projectSetBlockLevelMode, 
    matrixEditorStartDemoPlaying, 
    matrixEditorStopDemoPlaying, 
    GamePadTypesEnum,
    HardwareModes,
    selectorGetActiveHardwareBlockLevelDisplayNames, 
    selectorGetActiveCodingProject 
} from 'store';
import Logo from '../../assets/logo.svg';
import { saveToLocalFile, openLocalWorkspace, } from 'lib';
import { getPageName, PageTypes, PagesHidden } from 'pages/pages';
import ScratchLinkJSRunner from 'lib/ScratchLinkJSRunner';
import PyRunner from 'lib/Python/PyRunner';
import createScratchJSCodeImportHeader from 'utils/createScratchJSCodeImportHeader';
import createScratchPYCodeImportHeader from 'utils/createScratchPYCodeImportHeader';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FaFileMedical, FaFileAlt, FaFileDownload, FaFileExport, FaRobot, FaStop, FaCheckCircle } from 'react-icons/fa';
import { GrGamepad } from 'react-icons/gr';
import { BsFillFlagFill } from 'react-icons/bs';
import { ImSpinner6 } from 'react-icons/im';

import DeviceConnection from 'containers/DeviceConnection/DeviceConnection';
//import RemoteFileOpen from 'components/RemoteFileOpen/RemoteFileOpen';
//import RemoteFileSaveModal from 'containers/RemoteFileSaveModal/RemoteFileSaveModal';
import DropDown from 'components/DropDown/DropDown';
import Divider from 'objects/Divider/Divider';
import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import Settings from 'containers/Settings/Settings';
import BatteryIndicator from 'containers/BatteryIndicator/BatteryIndicator';

import './NavBar.scss';

const WorkspaceLocalOpen: React.FC = () => {
    const inputRef = useRef(null);

    const handleClick = () => {
        //@ts-ignore
        inputRef?.current?.click();
    };

    const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        let reader = new FileReader();
        reader.onload = r => {
            openLocalWorkspace(r?.target?.result as string);
        };
        reader.onerror = e => {
            console.error(e);
        }
        //@ts-ignore
        reader.readAsText(files[0]);
        //@ts-ignore
        e.target.value = null;
    };

    return (
        <div style={{padding: 0}}>
            <input type="file" ref={inputRef} accept=".ScratchLink,.ScratchLinkDev" onChange={onChangeFile} hidden/>
            <button style={{display: 'flex', backgroundColor: 'inherit', padding: '0.6em', width: '100%', height: '100%', textAlign: 'left'}} onClick={handleClick}><FaFileAlt /> <span className='ml-1'>Open Local Workspace</span></button>
        </div>
    )
}

type Props = PropsFromRedux & {

}

const NavBar: React.FC<Props> = (props) => {
    const [fileName, setFileName] = useState(props.fileName);
    const [fileNameChangeFinished, setFileNameChangeFinished] = useState<undefined | ReturnType<typeof setTimeout>>(undefined);
    const [saveLocalModalIsOpen, setSaveLocalModalIsOpen] = useState(false);
    /* const [saveRemoteModalIsOpen, setSaveRemoteModalIsOpen] = useState(false);
    const [openRemoteFileModalIsOpen, setOpenRemoteFileModalIsOpen] = useState(false); */
    const [workspaceSettingsModalIsOpen, setWorkspaceSettingsModalIsOpen] = useState(false);
    //const [saveMatrixButtonText, setSaveMatrixButtonText] = useState<'Save To ScratchLink' | 'Saving...'>('Save To ScratchLink')

    useEffect(() => {
        setFileName(props.fileName);
    }, [props.fileName])

    const hardwareDeviceProfileOptions: ReactSelectStringOption[] = props.scratchlinkConfig.deviceProfiles.map((deviceProfile: any) => ({
        value: deviceProfile.profile,
        label: deviceProfile.displayName
    }));

    const getSelectedHardwareDeviceProfile = () => hardwareDeviceProfileOptions.find(hardwareDeviceProfileOption => hardwareDeviceProfileOption.value === props.activeCodingProjectHardwareMode)

    const gamepadDeviceProfileOptions = Object.entries(GamePadTypesEnum).map(gamepadType => ({
        value: gamepadType[0],
        label: gamepadType[1]
    }));

    const getSelectedGamepadDeviceProfile = () => gamepadDeviceProfileOptions.find(gamepadDeviceProfileOption => gamepadDeviceProfileOption.value === props.activeCodingProjectGamepadMode)

    const onMatrixOnlyPageClicked = () => {
        props.push('/coding');
        props.codingSetActiveProjectIndex(0);
    }

    const handleFileNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value
        setFileName(newValue);

        if(fileNameChangeFinished) clearTimeout(fileNameChangeFinished);
        setFileNameChangeFinished(undefined);

        setFileNameChangeFinished(setTimeout(() => {
            props.settingsSetFileName(newValue)
        }, 300))
    };

    const handleSaveAsLocal = () => {
        setSaveLocalModalIsOpen(true);
    }

    /* const handleMatrixDemo = () => {
        if(props.demoPlaying) props.matrixEditorStopDemoPlaying();
        else props.matrixEditorStartDemoPlaying();
    } */

    const handleCloseSaveLocalModal = () => {
        setSaveLocalModalIsOpen(false);
    }

    const onSaveAsLocal = () => {
        props.settingsSetFileName(fileName);
        saveToLocalFile();
        setSaveLocalModalIsOpen(false);
    }    

    const handleSettingsClick = () => {
        setWorkspaceSettingsModalIsOpen(true);
    }

    const handleSettingsCloseClick = () => {
        setWorkspaceSettingsModalIsOpen(false);
    }

    /* const handleOpenRemoteFile = () => {
        setOpenRemoteFileModalIsOpen(true);
    }

    const handleSaveAsRemote = () => {
        setSaveRemoteModalIsOpen(true);
    }

    const handleCloseRemoteFileModal = () => {
        setOpenRemoteFileModalIsOpen(false);
    } */

    const handleNewProjectClick = () => {
        const newState = initalState;
        //@ts-ignore
        newState.project = {}; //clear project so that robot stays connected

        newState.coding.codingProjects = [
            ...codingInitialState.codingProjects
        ]

        newState.coding.activeProjectIndex = 0

        openLocalWorkspace(JSON.stringify(newState));
    }

    const onCodeRunClick = () => {
        if(props.activeCodingProjectType === 'jsscratch') { //JS4Scratch
            props.codingSetRunning(!props.codeRunning);
            return;
        }
        
        if(props.activeCodingProjectType === 'js') { //JS4Web
            if(!props.activeCodingProjectFiles.find(file => file.type === 'html')) {

                if(!props.codeRunning) ScratchLinkJSRunner.runCode(
                    props.activeCodingProjectFiles, 
                    createScratchJSCodeImportHeader(props.activeCodingProjectHardwareMode),
                    props.hardwareModeAutoDetected,
                    props.activeCodingProjectHardwareMode
                );
                else ScratchLinkJSRunner.stopWorker();
                
            } else props.codingSetRunning(!props.codeRunning);
            return;
        }

        if(props.activeCodingProjectType === 'hardwarejs') { //JS4Hardware
            if(!props.codeRunning) { //make run
                //Halt if wrong profile, excluding example ebot profile
                if(props.activeCodingProjectHardwareMode !== props.hardwareModeAutoDetected && props.activeCodingProjectHardwareMode !== 'ebot_example') 
                    window.confirm(`You are using a eBot with the wrong hardware mode for this project. This may cause some functions to not work. Do you want to continue?`)
    
                ScratchLinkJSRunner.runCode(
                    props.activeCodingProjectFiles, 
                    createScratchJSCodeImportHeader(props.activeCodingProjectHardwareMode),
                    props.hardwareModeAutoDetected,
                    props.activeCodingProjectHardwareMode
                );
            } else ScratchLinkJSRunner.stopWorker();
            return;
        }

        if(props.activeCodingProjectType === 'hardwarepy') { //PY4Hardware
            if(!props.codeRunning) PyRunner.runCode(
                props.activeCodingProjectFiles,
                createScratchPYCodeImportHeader(props.activeCodingProjectHardwareMode),
                props.hardwareModeAutoDetected,
                props.activeCodingProjectHardwareMode
            ) 
            else PyRunner.stop();
        }
    }

    const onProjectSettingsClick = () => {
        props.codingSetSettingsModelOpen(true);
    }

    const onHardwareDropDownChange = (hardwareMode: string) => {
        if(hardwareMode !== props.activeCodingProjectHardwareMode) {
            if(window.confirm(`WARNING: Changing hardware settings may cause some code functions to no longer work. These will be underlined red in the code editor after changing hardware. Do you want to continue?`)) {
                props.codingSetProjectHardwareMode(props.activeCodingProjectIndex, hardwareMode);
            }
        }
    }

    const onGamepadDropDownChange = (gamepadMode: GamePadTypes) => {
        if(gamepadMode !== props.activeCodingProjectGamepadMode) {
            if(window.confirm(`WARNING: Changing gamepad settings may cause some code functions to no longer work. These will be underlined red in the code editor after changing gamepad. Do you want to continue?`)) {
                props.codingSetProjectGamepadMode(props.activeCodingProjectIndex, gamepadMode);
            }
        }
    }

    return (
        <nav className="c-navbar">
            <div className="c-navbar__left">
                <div className="c-navbar__logoContainer">
                    <div className="c-navbar__logo">
                        <img src={Logo} alt="ScratchLink logo" />
                    </div>
                </div>
                <DropDown 
                    title="Workspace"
                    name={props.fileName}
                    nameStyle={{fontSize: '0.9em'}}
                    buttonStyle={{background: '#66a6ff'}}
                    /* icon={<FaFile />} */
                    contents={[
                        {
                            name: 'New Workspace',
                            icon: <FaFileMedical />,
                            onClick: handleNewProjectClick
                        },
                        {
                            name: 'Open Local Workspace',
                            onClick: () => {},
                            customComponent: WorkspaceLocalOpen,
                        },
                        /* {
                            name: 'Remote File Open',
                            icon: <FaFileAlt />,
                            onClick: handleOpenRemoteFile
                        }, */
                        {
                            name: 'Save Local Workspace',
                            icon: <FaFileDownload />,
                            onClick: saveToLocalFile
                        },
                        {
                            name: 'Save As Local Workspace',
                            icon: <FaFileExport />,
                            onClick: handleSaveAsLocal
                        },
                        /* {
                            name: 'Save As Remote',
                            icon: <FaFileExport />,
                            onClick: handleSaveAsRemote
                        } */
                        {
                            name: 'Workspace Settings',
                            icon: <FontAwesomeIcon icon={faCog} />,
                            onClick: handleSettingsClick
                        }
                    ]}
                />
                <Modal title="Save As Local" isOpen={saveLocalModalIsOpen} closeModal={handleCloseSaveLocalModal} type="save" onSave={onSaveAsLocal}>
                    <h5 style={{margin: 0}}>File Name</h5>
                    <input className="c-navbar__projectFileNameInput border-2 p-2" type="text" value={fileName} onChange={handleFileNameChange} />
                </Modal>
                
                {/* <RemoteFileSaveModal saveRemoteModalIsOpen={saveRemoteModalIsOpen} setSaveRemoteModalIsOpen={setSaveRemoteModalIsOpen} />

                <Modal title="Open Remote File" isOpen={openRemoteFileModalIsOpen} closeModal={handleCloseRemoteFileModal} type="default" size="lg">
                    <RemoteFileOpen handleCloseRemoteFileModal={handleCloseRemoteFileModal} />
                </Modal> */}
                <Divider />
                <DropDown 
                    name={props.currentPageName}
                    contents={
                        [
                            {
                                name: 'Matrix Editor Only',
                                onClick: onMatrixOnlyPageClicked
                            },
                            ...Object.values(PageTypes).filter(address => !PagesHidden.includes(address)).map(address => {
                                return {
                                    name: getPageName(address),
                                    onClick: () => {props.push(address)}
                                }
                            }),
                            {
                                name: '1',
                                customComponent: () => <div className='w-full border-t-2' style={{padding: 0}} />
                            },
                            {
                                name: 'Resources',
                                link: 'https://scratchlink.au/resources/'
                            },
                            {
                                name: 'Block Coder',
                                link: 'https://scratchlink.io'
                            },
                            {
                                name: '2',
                                customComponent: () => <div className='w-full border-t-2' style={{padding: 0}} />
                            },
                            {
                                name: 'p',
                                customComponent: () => (
                                    <div>
                                        <a 
                                            className='w-full ml-1 text-xs text-white hover:underline hover:text-white cursor-pointer' 
                                            onClick={() => window.open('https://scratchlink.au/privacy-and-security-policies/', '_blank')}
                                        >
                                            Privacy & Security Policy
                                        </a>
                                    </div>
                                )
                               
                            },
                            {
                                name: 't',
                                customComponent: () => (
                                    <div>
                                        <a 
                                            className='w-full ml-1 text-xs text-white hover:underline hover:text-white cursor-pointer' 
                                            onClick={() => window.open('https://scratchlink.au/terms-of-use/', '_blank')}
                                        >
                                            Terms of Service
                                        </a>
                                    </div>
                                )
                            }
                        ]
                    }
                />
                <Divider />
                {
                    /* ([PageTypes.MatrixEditor] as string[]).includes(props.currentPageLocation) && 
                        <>
                            <Button type="success" onClick={handleSaveMatrix} disabled={saveMatrixButtonText === 'Saving...'}>{saveMatrixButtonText}</Button>
                            <Button type="info" onClick={handleMatrixDemo} style={{marginLeft: '20px'}}>Demo</Button>
                        </> */
                }
                {
                    ([PageTypes.Coding] as string[]).includes(props.currentPageLocation) && props.activeCodingProjectType !== 'matrix' && (
                        <>
                            <div className='flex'>
                                {
                                    (props.activeCodingProjectType === 'js' || props.activeCodingProjectType === 'jsscratch' || props.activeCodingProjectType === 'hardwarejs') && (
                                        <div className='flex'>
                                            {
                                                (!props.gamepad1.connected || props.gamepad1.type !== props.activeCodingProjectGamepadMode) && (
                                                    <DropDown 
                                                        icon={
                                                            <GrGamepad className='c-navbar__gamepadTypeLogo' />
                                                        }
                                                        name={getSelectedGamepadDeviceProfile()?.label || ''}
                                                        buttonStyle={{padding: '0.3em'}}
                                                        contents={
                                                            gamepadDeviceProfileOptions.map(gamepadDeviceProfileOption => ({
                                                                name: gamepadDeviceProfileOption.label,
                                                                icon: props.gamepad1.connected && gamepadDeviceProfileOption.value === props.gamepad1.type ? <FaCheckCircle className='text-lg mr-1 c-navbar__connectedHardwareLogo' /> : <></>,
                                                                onClick: () => onGamepadDropDownChange(gamepadDeviceProfileOption.value as GamePadTypes)
                                                            }))
                                                        }
                                                    />
                                                )
                                            }
                                            {
                                                props.gamepad1.connected && props.gamepad1.type === props.activeCodingProjectGamepadMode && (
                                                    <>
                                                        <GrGamepad className='c-navbar__gamepadTypeLogo' /> {GamePadTypesEnum[props.activeCodingProjectGamepadMode]}
                                                    </>
                                                )
                                            }
                                        </div>       
                                    )
                                }
                            </div>
                            <div className='flex'>
                                {
                                    props.activeCodingProjectType === 'hardwarejs' && (
                                        <div className='flex items-center'>
                                            {
                                                (!props.deviceConnected || props.activeCodingProjectHardwareMode !== props.hardwareModeAutoDetected) && (
                                                    <DropDown 
                                                        icon={
                                                            <FaRobot className='text-xl mb-1 mr-1' style={{color: '#ffc66d'}} />
                                                        }
                                                        name={getSelectedHardwareDeviceProfile()?.label || ''}
                                                        buttonStyle={{padding: '0.3em'}}
                                                        contents={
                                                            hardwareDeviceProfileOptions.map(hardwareDeviceProfileOption => ({
                                                                name: hardwareDeviceProfileOption.label,
                                                                icon: props.deviceConnected && hardwareDeviceProfileOption.value === props.hardwareModeAutoDetected ? <FaCheckCircle className='text-lg mr-1 c-navbar__connectedHardwareLogo' /> : <></>,
                                                                onClick: () => onHardwareDropDownChange(hardwareDeviceProfileOption.value)
                                                            }))
                                                        }
                                                    />
                                                )
                                            }
                                            {
                                                (props.deviceConnected && props.activeCodingProjectHardwareMode === props.hardwareModeAutoDetected) && (
                                                    <>
                                                        <FaRobot className='text-xl ml-2 mb-1 mr-1' style={{color: '#ffc66d'}} /> {HardwareModes[props.activeCodingProjectHardwareMode]}
                                                    </>
                                                )
                                            }
                                        </div>
                                    )
                                } 
                            </div>
                            <ReactTooltip
                                id='navbar-runButton'
                                place='bottom'
                                effect='solid'
                            >
                                <span>
                                    {props.codeRunning ? 'Stop Code' : 'Run Code'}
                                </span>
                            </ReactTooltip>
                            {
                                (props.activeCodingProjectType !== 'hardwarepy' || props.pyEnvReady) && (
                                    <Button 
                                        data-tip data-for='navbar-runButton'
                                        style={{marginLeft: '5px', padding: '0.8em'}} 
                                        disabled={
                                            (props.activeCodingProjectType !== 'js' && props.activeCodingProjectType !== 'jsscratch') && !props.deviceConnected
                                        } 
                                        type={props.codeRunning ? 'danger' : 'success'}
                                        onClick={onCodeRunClick}
                                    >
                                        {
                                            !props.codeRunning && (
                                                <BsFillFlagFill className='w-4 h-4' />
                                            )
                                        }
                                        {
                                            props.codeRunning && (
                                                <FaStop className='w-4 h-4' />
                                            )
                                        }
                                    </Button>
                                )
                            }
                            <ReactTooltip
                                id='navbar-pyLoader'
                                place='bottom'
                                effect='solid'
                            >
                                <span>
                                    Initializing Python Environment
                                </span>
                            </ReactTooltip>
                            {
                                (props.activeCodingProjectType === 'hardwarepy' && !props.pyEnvReady) && (
                                    <ImSpinner6
                                        data-tip data-for='navbar-pyLoader'
                                        className='c-navbar__loadingSpinner text-2xl ml-3' 
                                    />
                                )
                            }
                            {
                                /* props.activeCodingProjectType === 'hardwarejs' && (
                                    <Button className='ml-2' type='grey' onClick={onProjectSettingsClick}>
                                        <FontAwesomeIcon icon={faCog} />
                                    </Button>
                                ) */
                            }
                        </>
                    )
                }
            </div>
            <div className="c-navbar__right">
                <DeviceConnection />
                <div className='flex flex-col justify-center'>
                    <div className='c-navbar__gamepadIndicatorArea flex'>
                        <ReactTooltip
                            id='navbar-gamepad1Icon'
                            place='left'
                        >
                            <span>
                                {props.gamepad1.connected ? `${GamePadTypesEnum[props.gamepad1.type]} Connected As Gamepad 1` : 'Gamepad 1 Not Connected'}
                            </span>
                        </ReactTooltip>
                        <GrGamepad data-tip data-for='navbar-gamepad1Icon' className={`c-navbar__gamepadIndicatorIcon ${props.gamepad1.connected ? 'c-navbar__gamepadIndicatorIcon--connected' : 'c-navbar__gamepadIndicatorIcon--disconnected'}`} />
                        
                        <ReactTooltip
                            id='navbar-gamepad2Icon'
                            place='left'
                        >
                            <span>
                                {props.gamepad2.connected ? `${GamePadTypesEnum[props.gamepad2.type]} Connected As Gamepad 2` : 'Gamepad 2 Not Connected'}
                            </span>
                        </ReactTooltip>
                        <GrGamepad data-tip data-for='navbar-gamepad2Icon' className={`c-navbar__gamepadIndicatorIcon ${props.gamepad2.connected ? 'c-navbar__gamepadIndicatorIcon--connected' : 'c-navbar__gamepadIndicatorIcon--disconnected'}`} />
                    </div>
                    <BatteryIndicator />
                </div>
                <Modal title="Workspace Settings" isOpen={workspaceSettingsModalIsOpen} closeModal={handleSettingsCloseClick} type="default" size="lg">
                    <Settings />
                </Modal>
            </div>
        </nav>
    );
}

const mapStateToProps = (state: IRootState) => {
    return {
        codeRunning: state.coding.running,
        pyEnvReady: state.coding.pyEnvReady,
        activeCodingProjectType: selectorGetActiveCodingProject(state).type,
        activeCodingProjectIndex: state.coding.activeProjectIndex,
        activeCodingProjectHardwareMode: selectorGetActiveCodingProject(state).hardwareMode,
        activeCodingProjectBlockLevelMode: selectorGetActiveCodingProject(state).blockLevelMode,
        activeCodingProjectFiles: selectorGetActiveCodingProject(state).files,
        activeCodingProjectGamepadMode: selectorGetActiveCodingProject(state).gamepadMode,
        deviceConnected: state.project.deviceConnected,
        hardwareModeAutoDetected: state.project.hardwareModeAutoDetected,
        fileName: state.settings.fileName,
        currentPageLocation: state.router.location.pathname,
        currentPageName: getPageName(state.router.location.pathname),
        hardwareBlockLevelNames: selectorGetActiveHardwareBlockLevelDisplayNames(state),
        gamepad1: {
            connected: state.project.gamepad0.connected,
            type: state.project.gamepad0.type
        },
        gamepad2: {
            connected: state.project.gamepad1.connected,
            type: state.project.gamepad1.type
        },
        scratchlinkConfig: state.project.scratchlinkConfig
    }
}

const mapDispatchToProps = {
    settingsSetFileName,
    codingSetActiveProjectIndex,
    codingSetSettingsModelOpen,
    codingSetRunning,
    codingSetProjectHardwareMode,
    codingSetProjectGamepadMode,
    projectSetHardwareMode,
    projectSetBlockLevelMode,
    matrixEditorStartDemoPlaying,
    matrixEditorStopDemoPlaying,
    push
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(NavBar);