const createScratchPYCodeImportHeader = (hardwareMode: string) => {
    //TODO: Use JSScratchLink.${hardwareMode}({}) again once all the hardware modes are implemented in JSScratchLink
    return `
eBot = JSScratchLink.ebot_example({})
wheels = eBot.wheels
led = eBot.led
matrix = eBot.matrix
crane = eBot.crane
eventsEbot = eBot.eventsEbot
eventsGamepad1 = eBot.eventsGamepad1
eventsGamepad2 = eBot.eventsGamepad2

class ControlClass:
    def __init__(self):
        self.wait = eBot.wait
        self.forever = eBot.forever
        self.repeat = eBot.repeat
        self.waitUntil = eBot.waitUntil
        self.stopAll = eBot.stopAll

control = ControlClass()

class ConfigClass:
    def __init__(self):
        self.print = eBot.print

config = ConfigClass()

class ConsoleClass:
    def __init__(self):
        self.log = eBot.print

console = ConsoleClass()
    `;
}

export default createScratchPYCodeImportHeader;