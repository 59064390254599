import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import store, { history } from 'store';

import Main from 'Main';
import NavBar from 'containers/NavBar/NavBar';
import 'lib/GamepadConnection/GamepadConnection'; //start connection manager
//import NavBarVertical from 'containers/NavBarVertical/NavBarVertical';

import './App.scss';

const App: React.FC = () => {
    return (
        <Provider store={store}>
            <ConnectedRouter history={history} >
                <div className="c-app">
                    <header>
                        {/* <NavBarVertical /> */}
                        <NavBar />
                    </header>
                    <Main />
                </div>
                <ToastContainer autoClose={3000} position="bottom-left" />
            </ConnectedRouter>
        </Provider>
    );
}

export default App;