import React, { } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState, selectorGetProjectDeviceConnectionIp, selectorGetProjectDeviceConnectionType, IDeviceConnectionType } from 'store';
import { DeviceConnectionManager } from 'lib/DeviceConnection';

import Button from 'components/Button/Button';

import './ConnectedPhase.scss';

type Props = PropsFromRedux & {
}

const ConnectedPhase: React.FC<Props> = ({ deviceConnectionIp, deviceConnectionType }) => {

    const onClickDisconnect = () => {
        DeviceConnectionManager.disconnect();
    }

    const renderConnectedMessage = () => {
        if(deviceConnectionType === 'websocket') return <>Connected to eBot with IP: { deviceConnectionIp }</>
        else if(deviceConnectionType === 'serial') return <>Connected to eBot with Serial Port</>
        else if(deviceConnectionType === 'modem') return <>Connected to eBot with USB Modem</>
    }

    return (
        <div className="c-connectedPhase">
            {renderConnectedMessage()}
            <br />
            <Button type="danger" addStyles={['square', 'full width']} onClick={onClickDisconnect}>Disconnect</Button>
        </div>
    )
}

const mapStateToProps = (state: IRootState): StateProps => {
    return {
        deviceConnectionIp: selectorGetProjectDeviceConnectionIp(state),
        deviceConnectionType: selectorGetProjectDeviceConnectionType(state)
    }
}

const mapDispatchToProps = {

}

interface StateProps {
    deviceConnectionIp: string;
    deviceConnectionType: IDeviceConnectionType;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ConnectedPhase);