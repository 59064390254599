import { createSelector } from 'reselect';
import { IRootState } from 'store';

export const selectorGetCodingButtons = createSelector(
    (state: IRootState) => state.coding, 
    (coding) => {
        const files = coding.codingProjects[coding.activeProjectIndex].files.filter(file => file.type === 'eventButtons');
        if(files.length < 1) return [];

        return files[0].eventButtonData?.buttons;
    }
);