import { GamePadTypes } from 'store';

//T3 and Xbox
export interface ScratchLinkGamepadDataStandard {
    lhsJoystick: {
        analog: {
            x: number;
            y: number;
            distance: number;
            direction: number;
        },
        digital: {
            button: boolean;
            up: boolean;
            down: boolean;
            left: boolean;
            right: boolean;
            noInput: boolean;
        }
    },
    rhsJoystick: {
        analog: {
            x: number;
            y: number;
            distance: number;
            direction: number;
        },
        digital: {
            button: boolean;
            up: boolean;
            down: boolean;
            left: boolean;
            right: boolean;
            noInput: boolean;
        }
    },
    dPad: {
        up: boolean;
        down: boolean;
        left: boolean;
        right: boolean;
        noInput: boolean;
    },
    a: boolean;
    b: boolean;
    x: boolean;
    y: boolean;
    bumper: {
        leftTop: boolean;
        leftBottom: boolean;
        rightTop: boolean;
        rightBottom: boolean;
    },
    start: boolean;
    select: boolean;
}

export interface ScratchLinkGamepadDataSlClassic {
    lhsJoystick: {
        analog: {
            x: number;
            y: number;
            distance: number;
            direction: number;
        },
        digital: {
            up: boolean;
            down: boolean;
            left: boolean;
            right: boolean;
            noInput: boolean;
        }
    },
    rhsJoystick: {
        analog: {
            x: number;
            y: number;
            distance: number;
            direction: number;
        },
        digital: {
            up: boolean;
            down: boolean;
            left: boolean;
            right: boolean;
            noInput: boolean;
        }
    },
    joystick: {
        analog: {
            x: number;
            y: number;
            distance: number;
            direction: number;
        },
        digital: {
            button: boolean;
            up: boolean;
            down: boolean;
            left: boolean;
            right: boolean;
            noInput: boolean;
        }
    },
    dPad: {
        up: boolean;
        down: boolean;
        left: boolean;
        right: boolean;
        noInput: boolean;
    },
    a: boolean;
    b: boolean;
    x: boolean;
    y: boolean;
    red: boolean;
    white: boolean;
    black: boolean;
    blue: boolean;
    bumper: {
        leftTop: boolean;
        leftBottom: boolean;
        rightTop: boolean;
        rightBottom: boolean;
    },
    start: boolean;
    select: boolean;
    home: boolean;
}

export interface ScratchLinkGamepadDataSlNunchuk {
    nunJoystick: {
        analog: {
            x: number;
            y: number;
            distance: number;
            direction: number;
        },
        digital: {
            up: boolean;
            down: boolean;
            left: boolean;
            right: boolean;
            noInput: boolean;
        }
    },
    gyro: {
        analog: {
            x: number;
            y: number;
        },
        digital: {
            up: boolean;
            down: boolean;
            left: boolean;
            right: boolean;
        }
    },
    joystick: {
        analog: {
            x: number;
            y: number;
            distance: number;
            direction: number;
        },
        digital: {
            button: boolean;
            up: boolean;
            down: boolean;
            left: boolean;
            right: boolean;
            noInput: boolean;
        }
    },
    bumper: {
        top: boolean;
        bottom: boolean;
    }
    red: boolean;
    white: boolean;
    black: boolean;
    blue: boolean;
}

export interface ScratchLinkGamepadDataRetro {
    dPad: {
        up: boolean;
        down: boolean;
        left: boolean;
        right: boolean;
        noInput: boolean;
    },
    a: boolean;
    b: boolean;
    x: boolean;
    y: boolean;
    bumper: {
        left: boolean;
        right: boolean;
    },
    start: boolean;
    select: boolean;
}

export type ScratchLinkGamepadData = ScratchLinkGamepadDataStandard | ScratchLinkGamepadDataRetro;

export interface ScratchLinkGamepadBase {
    connected: boolean;
    type: GamePadTypes;
    data: ScratchLinkGamepadData | undefined;
}

export interface ScratchLinkGamepadNone extends ScratchLinkGamepadBase {
    type: 'no_gamepad';
    data: undefined;
}

export interface ScratchLinkGamepadStandard extends ScratchLinkGamepadBase {
    type: 'xbox' | 't3';
    data: ScratchLinkGamepadDataStandard;
}

export interface ScratchLinkGamepadRetro extends ScratchLinkGamepadBase {
    type: 'retro';
    data: ScratchLinkGamepadDataRetro;
}

export type ScratchLinkGamepad = ScratchLinkGamepadNone | ScratchLinkGamepadStandard | ScratchLinkGamepadRetro;

export const gamepadDataEmptyStandard: ScratchLinkGamepadDataStandard = {
    lhsJoystick: {
        analog: {
            x: 0,
            y: 0,
            distance: 0,
            direction: 0
        },
        digital: {
            button: false,
            up: false,
            down: false,
            left: false,
            right: false,
            noInput: true
        }
    },
    rhsJoystick: {
        analog: {
            x: 0,
            y: 0,
            distance: 0,
            direction: 0
        },
        digital: {
            button: false,
            up: false,
            down: false,
            left: false,
            right: false,
            noInput: true
        }
    },
    dPad: {
        up: false,
        down: false,
        left: false,
        right: false,
        noInput: true
    },
    a: false,
    b: false,
    x: false,
    y: false,
    bumper: {
        leftTop: false,
        leftBottom: false,
        rightTop: false,
        rightBottom: false
    },
    start: false,
    select: false
};

export const gamepadDataEmptySlClassic: ScratchLinkGamepadDataSlClassic = {
    lhsJoystick: {
        analog: {
            x: 0,
            y: 0,
            distance: 0,
            direction: 0
        },
        digital: {
            up: false,
            down: false,
            left: false,
            right: false,
            noInput: true
        }
    },
    rhsJoystick: {
        analog: {
            x: 0,
            y: 0,
            distance: 0,
            direction: 0
        },
        digital: {
            up: false,
            down: false,
            left: false,
            right: false,
            noInput: true
        }
    },
    joystick: {
        analog: {
            x: 0,
            y: 0,
            distance: 0,
            direction: 0
        },
        digital: {
            button: false,
            up: false,
            down: false,
            left: false,
            right: false,
            noInput: true
        }
    },
    dPad: {
        up: false,
        down: false,
        left: false,
        right: false,
        noInput: true
    },
    a: false,
    b: false,
    x: false,
    y: false,
    red: false,
    white: false,
    black: false,
    blue: false,
    bumper: {
        leftTop: false,
        leftBottom: false,
        rightTop: false,
        rightBottom: false
    },
    start: false,
    select: false,
    home: false
};

export const gamepadDataEmptySlNunchuk: ScratchLinkGamepadDataSlNunchuk = {
    nunJoystick: {
        analog: {
            x: 0,
            y: 0,
            distance: 0,
            direction: 0
        },
        digital: {
            up: false,
            down: false,
            left: false,
            right: false,
            noInput: true
        }
    },
    joystick: {
        analog: {
            x: 0,
            y: 0,
            distance: 0,
            direction: 0
        },
        digital: {
            button: false,
            up: false,
            down: false,
            left: false,
            right: false,
            noInput: true
        }
    },
    gyro: {
        analog: {
            x: 0,
            y: 0
        },
        digital: {
            up: false,
            down: false,
            left: false,
            right: false
        }
    },
    bumper: {
        top: false,
        bottom: false
    },
    red: false,
    white: false,
    black: false,
    blue: false,
};

export const gamepadDataEmptyRetro: ScratchLinkGamepadDataRetro = {
    dPad: {
        up: false,
        down: false,
        left: false,
        right: false,
        noInput: true
    },
    a: false,
    b: false,
    x: false,
    y: false,
    bumper: {
        left: false,
        right: false
    },
    start: false,
    select: false
};