import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState, animationSetFps, animationStartPlaying, animationStopPlaying, codingBackpackChangeSelectedMatrix, selectorGetMatrixEditor } from 'store';

import './AnimationControls.scss';

type OwnProps = {
    codingProjectIndex: number;
    fileIndex: number;
}

type Props = PropsFromRedux & OwnProps;

const AnimationControls: React.FC<Props> = (props: Props) => {
    const [fps, setFps] = useState(props.fps.toString());
    const [playing, setPlaying] = useState(props.playing);

    useEffect(() => {
        setFps(props.fps.toString());
        setPlaying(props.playing)
    }, [props.fps, props.playing])

    const handlePlayClick = () => {
        const newPlayingState = !playing;
        setPlaying(newPlayingState);
        
        if(newPlayingState) props.animationStartPlaying(props.codingProjectIndex, props.fileIndex);
        else props.animationStopPlaying(props.codingProjectIndex, props.fileIndex);
    }

    const handleFpsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        if(value === '' || value === '0') return setFps(value);

        const newFps = Number(value);
        if(isNaN(newFps)) return;

        //Upper and lower bounds
        if(newFps > 30) value = '30';
        else if(newFps < 0.1 && newFps !== 0) value = '0.1';

        //Limit decimal places
        const decimalPoint = value.indexOf('.');
        if(decimalPoint !== -1 ) value = value.substring(0, decimalPoint+2); //Contains

        setFps(value);
        props.animationSetFps(props.codingProjectIndex, props.fileIndex, newFps);
    }

    if(props.rootFolderSelected) return <div />; //Dont display for the root folder

    return (
        <div className="c-animationControls">
            Animation
            <button className="c-animationControls__button" onClick={handlePlayClick}>{playing ? 'Pause' : 'Play'}</button>
            <input className="c-animationControls__fps" value={fps} onChange={handleFpsChange}/> Frames/Second
        </div>
    )
}
const mapStateToProps = (state: IRootState, ownProps: OwnProps): StateProps => {
    return {
        fps: selectorGetMatrixEditor(ownProps.codingProjectIndex, ownProps.fileIndex)(state).animation.fps,
        playing: selectorGetMatrixEditor(ownProps.codingProjectIndex, ownProps.fileIndex)(state).animation.playing,
        rootFolderSelected: selectorGetMatrixEditor(ownProps.codingProjectIndex, ownProps.fileIndex)(state).backpack.selectedFolderId === 'root'
    }
}

const mapDispatchToProps = {
    animationSetFps,
    animationStartPlaying,
    animationStopPlaying,
    codingBackpackChangeSelectedMatrix
}

interface StateProps {
    fps: number;
    playing: boolean;
    rootFolderSelected: boolean;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AnimationControls);