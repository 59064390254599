import React from 'react';
import { IConsoleDecorator } from '../ConsoleDecorator';

export const WifiConnectedConsoleDecorator: IConsoleDecorator = ({ msgObject }) => {
    return (
        <>
            Wifi Connected | Mode: {msgObject['d']} | IP: {msgObject['IP_address']} | MAC: {msgObject['MAC']}
        </>
    )
};

WifiConnectedConsoleDecorator.decoratorTest = (msgObject) => msgObject['evt'] && msgObject['evt'] === 156;

WifiConnectedConsoleDecorator.consoleClass = () => "c-consoleDecorator__wifiConnected c-consoleDecorator__whiteTimestamp"