import React from 'react';
import ReactModal from 'react-modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import Button from 'components/Button/Button';

import './Modal.scss';

interface ModalProps {
    title: string;
    type: 'default' | 'save' | 'back';
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xlg';
    isOpen: boolean;
    footer?: boolean;
    footerLeftComponent?: React.Component | React.ReactElement;
    closeModal: () => void;
    onSave?: () => void;
    onBack?: () => void;
}

ReactModal.setAppElement('#root')

const Modal: React.FC<ModalProps> = ({ title, type, size = 'xs', footer = true, footerLeftComponent, isOpen, closeModal, children, onSave, onBack }) => {

    const renderSaveModalFooter = () => {
        return (
            <>
                <Button type="danger" size="sm" onClick={() => closeModal()} addStyles={['square']}>Cancel</Button>
                <Button style={{marginLeft: '10px'}} type="success" size="sm" onClick={() => onSave && onSave()} addStyles={['square']}>Save</Button>
            </>
        )
    }

    const renderBackModalFooter = () => {
        return (
            <>
                <Button className="self-start" type="info" size="sm" onClick={() => onBack &&  onBack()} addStyles={['square']}>Back</Button>
                <Button style={{marginLeft: '10px'}} type="danger" size="sm" onClick={() => closeModal()} addStyles={['square']}>Cancel</Button>
            </>
        )
    }

    let modalClass = 'c-modal';
    if(size) modalClass += ` c-modal__${size}`;

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={closeModal}
            className={modalClass}
            overlayClassName="c-modal__overlay c-modal__overlayCentered"
        >
            <div className="c-modal__header">
                <h4 className="c-modal__title">{ title }</h4>
                <FontAwesomeIcon className="c-modal__exit" icon={faTimes} onClick={() => closeModal()}/>
            </div>
            <div className="c-modal__body">
                { children }
            </div>
            {
                footer && (
                    <div className="c-modal__footer">
                        <div className="c-modal__footerLeft">
                            {
                                footerLeftComponent
                            }
                        </div>
                        <div className="c-modal__footerRight">
                            {
                                type === 'default' && <Button size="sm" onClick={() => closeModal()} addStyles={['square']}>Close</Button>
                            }
                            {
                                type === 'save' && renderSaveModalFooter()
                            }
                            {
                                type === 'back' && renderBackModalFooter()
                            }
                        </div>
                    </div>
                )
            }
        </ReactModal>
    )
};

export default Modal;