import React from 'react';
import { IConsoleDecorator } from '../ConsoleDecorator';

export const JSSystemConsoleDecorator: IConsoleDecorator = ({ msgObject }) => {

    return (
        <>
            <span>System: </span> { msgObject }
        </>
    )
};

JSSystemConsoleDecorator.decoratorTest = () => true;

JSSystemConsoleDecorator.consoleClass = () => 'c-consoleDecorator__jsSystem';