import store, { ICodingFile, consoleAddConsoleLog, codingSetCodeHasError } from 'store';

class JSErrorHandler {
    /**
     * @param ev - Error event
     * @param jsFiles - All js files for project
     * @param runCode - Entire final generated code that was run
     * @param isJS4Scratch - Is this a JS4Scratch error
     */
    handleError(ev: ErrorEvent, jsFiles: ICodingFile[], runCode: string) {
        let fileIndex: null | number = null;
        let lineNo: null | number = null;
        let consoleMsg = '';

        const codeLines = runCode.split('\n');

        for(let i=ev.lineno; i>0; i--) {
            const codeLine = codeLines[i];

            if(!codeLine) continue; //Skip empty lines
            
            if(codeLine.match(/\/\/JSFILE-[0-9]*-START/g)) { //Find file start header above error line no
                lineNo = ev.lineno-i-1;
                fileIndex = Number(codeLine.substring(
                    codeLine.indexOf('//JSFILE-')+9, 
                    codeLine.indexOf('-START')
                ));
                break;
            }
        }

        if(fileIndex !== null && lineNo !== null) { //found file number and line number
            const file = jsFiles[fileIndex];
            const fileName = file.name;
            const fileNoLines = file.jsData?.code.split('\n').length; //number of lines in file

            const filePrev = jsFiles[fileIndex-1]; //Get prev file for edge cases
            const fileNamePrev = filePrev?.name;
            const fileNamePrevlastLineNo = filePrev?.jsData?.code?.split('\n').length;

            if(fileNoLines && lineNo > fileNoLines) lineNo = fileNoLines; //If found line no is outside the file itself then default to last line

            consoleMsg = `JS Error | File: ${fileName}.js | Approx Line: ${lineNo} | ${ev.message} `;

            if(ev.message.includes('runCodeOnStart') && filePrev) consoleMsg = `JS Error | File: ${fileNamePrev}.js | Approx Line: ${fileNamePrevlastLineNo} | Uncaught SyntaxError: Unexpected identifier. Likely missing bracket.`;
        } else {
            consoleMsg = `JS Error | ${ev.message}`;
        }

        store.dispatch(codingSetCodeHasError(true));
        store.dispatch(consoleAddConsoleLog(consoleMsg, 'JSError', 'JSErrorConsoleDecorator'))
    }
}

export default new JSErrorHandler();