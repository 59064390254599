import React, { useRef, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState, ICodingProjectType, selectorGetProjectHardwareModeJavascript, selectorGetCodingButtons, selectorGetGlobalVars, selectorGetMatrixEditor, selectorGetActiveCodingProject } from 'store';
import createScratchJSCodeImportHeader from 'utils/createScratchJSCodeImportHeader';
import Editor, { useMonaco, OnMount } from '@monaco-editor/react';
import loader from '@monaco-editor/loader';
import { editor } from 'monaco-editor';
import {
    scratchLinkGamepadEventsStandardArray,
    scratchLinkGamepadEventsSlClassicArray,
    scratchLinkGamepadEventsSlNunchukArray,
    scratchLinkGamepadEventsRetroArray,
    monacoTypeScratchLinkGamepadDataStandard,
    monacoTypeScratchLinkGamepadDataSlClassic,
    monacoTypeScratchLinkGamepadDataSlNunchuk,
    monacoTypeScratchLinkGamepadDataRetro
} from 'lib/GamepadConnection'

//This contains a modified tsMode.js file that changes triggerCharacters (char to open intellisense on) to be more aggressive (chars = [".", "'", "\""])
loader.config({
    paths: {
        vs: `${window.location.origin}/monaco/min/vs`
    }
})

const ScratchLinkLibrary: string = require('scratchlinkjs/dist/Scratchlink.string.bundled.d.js');
//const ES5Library: string = require('scratchlinkjs/dist/es5.string.bundled.d.js');
const JSScratchLibrary: string = require('scratchlinkjs/dist/jsscratch.string.bundled.d.js');

type OwnProps = {
    codingProjectIndex: number;
    codingProjectType: ICodingProjectType;
    fileIndex: number;
    code: string;
    monacoFileName: string;
    fileType: 'javascript' | 'python' | 'html';
    onCodeChange: (code: string) => void;
}

type Props = PropsFromRedux & OwnProps;

//let monacoReactInstance: undefined | typeof import('monaco-editor/esm/vs/editor/editor.api');

const disableErrorCodeList: string[] = [
    //Allow await in the global scope as worker code is wrapped in an async IIFE
    '1375', //Top-level 'await' expressions are only allowed when the 'module' option is set to 'esnext' or 'system', and the 'target' option is set to 'es2017' or higher.
    '1378', //Top-level 'await' expressions are only allowed when the 'module' option is set to 'esnext' or 'system', and the 'target' option is set to 'es2017' or higher.
    '2705', //An async function or method in ES5/ES3 requires the 'Promise' constructor.  Make sure you have a declaration for the 'Promise' constructor or include 'ES2015' in your '--lib' option.
    '2582'
]

const MonacoEditor: React.FC<Props> = ({ code, fileType, codingProjectType, monacoFileName, onCodeChange, matrixEditor, codingButtons, globalVars, gamepadMode, hardwareMode, blockLevelMode, scratchlinkConfig, monacoFontSize }) => {
    const editorRef = useRef<editor.IStandaloneCodeEditor>();
    const monaco = useMonaco();
    //const [editorCodeChanged, setEditorCodeChanged] = useState<undefined | ReturnType<typeof setTimeout>>(undefined);
    //const [editorCode, setEditorCode] = useState(props.code)

    useEffect(() => {
        if(!monaco) return;
        monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
            noSemanticValidation: false,
            noSyntaxValidation: false,
        });

        monaco.languages.typescript.javascriptDefaults.setWorkerOptions({
            customWorkerPath: `${window.location.origin}/monacoCustomWorker.js`,
        })

        monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
            target: monaco.languages.typescript.ScriptTarget.ES2016,
            allowNonTsExtensions: true,
            allowJs: true,
            checkJs: true,
            noLib: true
        });

        if(codingProjectType === 'hardwarejs' || codingProjectType === 'js') monaco.languages.typescript.javascriptDefaults.addExtraLib(ScratchLinkLibrary, 'defaultLib:lib.es6.d.ts');
        if(codingProjectType === 'jsscratch') monaco.languages.typescript.javascriptDefaults.addExtraLib(JSScratchLibrary, 'defaultLib:lib.es6.d.ts');

        monaco.languages.typescript.javascriptDefaults.setEagerModelSync(true); 
    }, [monaco, codingProjectType]);

    useEffect(() => {
        if(!monaco || fileType === 'html' || fileType === 'python') return;
        let library = ScratchLinkLibrary;

        if(codingProjectType === 'hardwarejs' || codingProjectType === 'js') {
            if(matrixEditor) {
                const matrixFolders = matrixEditor.backpack.folders;
                const matrixes = matrixEditor.backpack.matrixes;
                //Add intellisence for matrix
                const matrixEnumArray: string[] = [];
                for(let i=0; i<matrixFolders.byIds['root'].matrixIds.length; i++) {
                    const matrixId = matrixFolders.byIds['root'].matrixIds[i];
                    const matrix = matrixes.byIds[matrixId];
                    matrixEnumArray.push(`'${matrix.name}' = '${matrix.hex}'`);
                }
                library = library.replace(/EbotMatrixesEnum {([^}]+)}/g, `EbotMatrixesEnum {${matrixEnumArray.join(',')}}`);
    
                const matrixAnimationEnumArray: string[] = [];
                for(let i=0; i<matrixFolders.allIds.length; i++) {
                    const matrixAnimation = matrixFolders.byIds[matrixFolders.allIds[i]];
                    if(matrixAnimation.id === 'root') continue;
                    matrixAnimationEnumArray.push(`'${matrixAnimation.name}' = '${matrixAnimation.id}'`);
                }
                library = library.replace(/EbotMatrixAnimationEnum {([^}]+)}/g, `EbotMatrixAnimationEnum {${matrixAnimationEnumArray.join(',')}}`);
            }
    
            //JS Buttons
            const eventsEnum = library.match(/ScratchLinkEventsEnum {([^}]+)}/g);
            if(eventsEnum !== null) {
                const eventsStart = eventsEnum[0].split('ScratchLinkEventsEnum {');
                const eventsEnumWithButtons = `ScratchLinkEventsEnum {${codingButtons.reduce((prev, curr) => prev+`'${curr}' = '${curr}',\n`, '')}${eventsStart[1]}`;
    
                library = library.replace(/ScratchLinkEventsEnum {([^}]+)}/g, eventsEnumWithButtons);
            }
    
            //Crane Joints
            let jointsEnumArray: string[] = [];
            const deviceProfile = scratchlinkConfig.deviceProfiles.find((deviceProfile: any) => deviceProfile.profile === hardwareMode);
            if(deviceProfile) {
                const hardwareDOFMode = deviceProfile.crane || 'none';
                const craneJoints: string[] = scratchlinkConfig.configPage.crane[hardwareDOFMode] || [];
    
                jointsEnumArray = craneJoints.map(craneJoint => `'${craneJoint}' = '${craneJoint.split(' ').join('-').toLowerCase()}'`);
            }
            library = library.replace(/CraneJointsEnum {([^}]+)}/g, `CraneJointsEnum {${jointsEnumArray.join(',')}}`);

            //Crane Presets
            let presetsEnumArray: string[] = [];
            if(deviceProfile) {
                const hardwareDOFMode = deviceProfile.crane || 'none';
                const cranePresets: {name: string; command: string}[] = scratchlinkConfig.generalConfig.crane[`${hardwareDOFMode}Presets`] || [];
    
                presetsEnumArray = cranePresets.map(cranePreset => `'${cranePreset.name}' = ''`);
            }
            library = library.replace(/CranePresetsEnum {([^}]+)}/g, `CranePresetsEnum {${presetsEnumArray.join(',')}}`);

    
            //Set javascript header
            const headerURI = monaco.Uri.parse('file:///scratchlink-header.js'); 
            const headerModel = monaco.editor.getModel(headerURI);

            const codeHeader = createScratchJSCodeImportHeader(hardwareMode)

            if(!headerModel) monaco.editor.createModel(codeHeader, 'javascript', headerURI);
            else headerModel.setValue(codeHeader);
        }

        if(codingProjectType === 'jsscratch')  {
            library = JSScratchLibrary;
            //Set sprites
        }
        
        //global variables
        for(let i=0; i<globalVars.length; i++) {
            const globalVar = globalVars[i];
            library += `\n declare var ${globalVar.name}: ${globalVar.type};`
        }

        //Gamepad Events
        const gamepadEventsEnum = library.match(/GamepadEventsEnum {([^}]+)}/g);
        if(gamepadEventsEnum !== null) {
            let events: string[] = [];
            
            if(gamepadMode === 'xbox' || gamepadMode === 't3') events = [...scratchLinkGamepadEventsStandardArray];
            if(gamepadMode === 'retro') events = [...scratchLinkGamepadEventsRetroArray];

            const gamepadEventsStart = gamepadEventsEnum[0].split('GamepadEventsEnum {');
            const gamepadEventsEnumWithEvents = `GamepadEventsEnum {${events.reduce((prev, curr) => prev+`'${curr}' = '${curr}',\n`, '')}${gamepadEventsStart[1]}`;
            
            library = library.replace(/GamepadEventsEnum {([^}]+)}/g, gamepadEventsEnumWithEvents);
        }

        //Gamepad Data
        const gamepadDataInterface = library.match(/IScratchLinkGamepadData {([^}]+)}/g);
        if(gamepadDataInterface !== null) {
            let data = '';
            if(gamepadMode === 'xbox' || gamepadMode === 't3') data = monacoTypeScratchLinkGamepadDataStandard;
            if(gamepadMode === 'retro') data = monacoTypeScratchLinkGamepadDataRetro;

            const gamepadDataInterfaceWithData = `IScratchLinkGamepadData ${data}`;
            library = library.replace(/IScratchLinkGamepadData {([^}]+)}/g, gamepadDataInterfaceWithData);
        }

        monaco.languages.typescript.javascriptDefaults.addExtraLib(library, 'defaultLib:lib.es6.d.ts');

        //TODO: Fix codingButtons repeat issue
    }, [monaco, matrixEditor, codingProjectType, codingButtons, hardwareMode, blockLevelMode, scratchlinkConfig, globalVars, fileType]);

    const handleEditorOnMount: OnMount = async (editor, monacoInstance) => {
        editorRef.current = editor;

        editor.onDidChangeModelDecorations(() => {
            let model = editor.getModel();
            if(!model) return console.error('MONACO ERROR: Could not find monaco model');
            let markers = monacoInstance.editor.getModelMarkers({ owner:'javascript', resource: model.uri }) //Get all javascript markers

            // We have to filter out any error that the editor gives from our global disable list
            let filtered = markers.filter( marker => !disableErrorCodeList.includes(marker.code as string))
            if(filtered.length !== markers.length) monacoInstance.editor.setModelMarkers(model, 'javascript', filtered)//Update markers if they have changed
        })

        const model = editor.getModel();
        if(!model) return console.error('MONACO ERROR: Could not find monaco model');
        if(!monacoInstance) return console.error('MONACO ERROR: Could not find monaco instance');
        const worker = await monacoInstance.languages.typescript.getJavaScriptWorker();
        if(!worker) return; //Not a javascript file
        const thisWorker = await worker(model.uri);

        const members = globalVars.map(globalVar => globalVar.name);
        //@ts-ignore
        thisWorker.setDynamicMembers(members)
    }

    return (
        <Editor 
            language={fileType}
            theme="vs-dark"
            path={monacoFileName}
            value={code}
            options={{
                quickSuggestions: {
                    other: true,
                    comments: true,
                    strings: true
                },
                suggest: {
                    showWords: false,
                    showKeywords: false,
                    showEnums: false,
                    showClasses: false,
                    showInterfaces: false
                },
                mouseWheelZoom: true,
                minimap: {
                    enabled: false
                },
                fontSize: monacoFontSize
            }}
            keepCurrentModel={false} //Remove models when chnaging coding projects
            onChange={(value) => {
                onCodeChange(value || '');
            }}
            onMount={handleEditorOnMount}
        />
    )
}

const mapStateToProps = (state: IRootState, ownProps: OwnProps) => {
    return {
        matrixEditor: selectorGetMatrixEditor(ownProps.codingProjectIndex)(state),
        codingButtons: selectorGetCodingButtons(state) || [],
        globalVars: selectorGetGlobalVars(state) || [],
        displayHardwareMode: selectorGetProjectHardwareModeJavascript(state),
        hardwareMode: selectorGetActiveCodingProject(state).hardwareMode,
        blockLevelMode: selectorGetActiveCodingProject(state).blockLevelMode,
        gamepadMode: selectorGetActiveCodingProject(state).gamepadMode,
        scratchlinkConfig: state.project.scratchlinkConfig,
        monacoFontSize: state.settings.monacoFontSize
    }
}

const mapDispatchToProps = {
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MonacoEditor);