import { ICodingBackpackFoldersState, ICodingBackpackMatrixesState, ICodingBackpackFoldersActionTypes, ICodingBackpackMatrixesActionTypes } from '.';
import { IAnimationTickAction } from '../animation';
import { ICodingMatrixEditorAction } from '../';

export const CODING_PROJECT_FILE_BACKPACK_CHANGE_SELECTED_FOLDER = 'CODING/PROJECT/FILE/MATRIXEDITOR/BACKPACK/CHANGE_SELECTED_FOLDER';
export const CODING_PROJECT_FILE_BACKPACK_CHANGE_SELECTED_MATRIX = 'CODING/PROJECT/FILE/MATRIXEDITOR/BACKPACK/CHANGE_SELECTED_MATRIX';
export const CODING_PROJECT_FILE_BACKPACK_NAVIGATE_TO_FOLDER = 'CODING/PROJECT/FILE/MATRIXEDITOR/BACKPACK/NAVIGATE_TO_FOLDER';
export const CODING_PROJECT_FILE_BACKPACK_CREATE_FOLDER = 'CODING/PROJECT/FILE/MATRIXEDITOR/BACKPACK/CREATE_FOLDER';
export const CODING_PROJECT_FILE_BACKPACK_CREATE_MATRIX = 'CODING/PROJECT/FILE/MATRIXEDITOR/BACKPACK/CREATE_MATRIX';
export const CODING_PROJECT_FILE_BACKPACK_DUPLICATE_MATRIX = 'CODING/PROJECT/FILE/MATRIXEDITOR/BACKPACK/DUPLICATE_MATRIX';
export const CODING_PROJECT_FILE_BACKPACK_DELETE_FOLDER = 'CODING/PROJECT/FILE/MATRIXEDITOR/BACKPACK/DELETE_FOLDER';
export const CODING_PROJECT_FILE_BACKPACK_DELETE_MATRIX = 'CODING/PROJECT/FILE/MATRIXEDITOR/BACKPACK/DELETE_MATRIX';
export const CODING_PROJECT_FILE_BACKPACK_IMPORT_FOLDER = 'CODING/PROJECT/FILE/MATRIXEDITOR/BACKPACK/IMPORT_FOLDER';

export interface ICodingBackpackChangeSelectedFolderAction extends ICodingMatrixEditorAction {
    type: typeof CODING_PROJECT_FILE_BACKPACK_CHANGE_SELECTED_FOLDER;
    folderId: string;
}

export interface ICodingBackpackChangeSelectedMatrixAction extends ICodingMatrixEditorAction {
    type: typeof CODING_PROJECT_FILE_BACKPACK_CHANGE_SELECTED_MATRIX;
    matrixId: string;
}

export type ICodingBackpackActionTypes = ICodingBackpackChangeSelectedFolderAction | ICodingBackpackChangeSelectedMatrixAction | IAnimationTickAction;
export type ICodingBackpackAllActionTypes = ICodingBackpackActionTypes | ICodingBackpackFoldersActionTypes | ICodingBackpackMatrixesActionTypes;

export interface ICodingBackpackState {
    folders: ICodingBackpackFoldersState;
    matrixes: ICodingBackpackMatrixesState;
    selectedFolderId: string;
    selectedMatrixId: string;
}