import React, { useState } from 'react';
import {UnControlled as ReactCodeMirror} from 'react-codemirror2';

import ScratchLink from 'scratchlinkjs';

import './CodeMirrorEditor.scss';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/addon/hint/show-hint.css';
//const CodeMirror = require('codemirror');
require('codemirror/lib/codemirror');
require('codemirror/mode/javascript/javascript');
require('codemirror/addon/hint/show-hint');
require('codemirror/addon/hint/javascript-hint');

type Props = {
    code: string;
    onCodeChange: (code: string) => void;
}

interface ICursorPosition {
    line: number;
    ch: number;
    sticky: any;
}

const CodeMirrorEditor: React.FC<Props> = (props) => {
    const [editorCodeChanged, setEditorCodeChanged] = useState<undefined | ReturnType<typeof setTimeout>>(undefined);

    const [cursorPosition, setCursorPosition] = useState<undefined | ICursorPosition>();

    return (
        <>
            <ReactCodeMirror
                value={props.code}
                options={{
                    lineNumbers: true,
                    theme: 'material',
                    mode: 'javascript',
                    styleActiveLine: true,
                    hintOptions: {
                        alignWithWord: false,
                        completeSingle: false,
                        globalScope: {
                            ScratchLink: ScratchLink
                        }
                    },
                    cursorBlinkRate: 700,
                    extraKeys: {
                        "Ctrl-Space": "autocomplete"
                    },
                    matchBrackets: true
                }}
                cursor={cursorPosition}
                onCursor={(editor, data: ICursorPosition) => {
                    setCursorPosition(data);
                }}
                /* onInputRead={(cm) => {
                    if (cm.state.completionActive) {
                        return;
                    }
                    var cur = cm.getCursor();
                    var token = cm.getTokenAt(cur);
                    if (token.type && token.type !== "comment") {
                        CodeMirror.commands.autocomplete(cm, null, {completeSingle: false});
                    }
                }} */
                onChange= {(editor, data, value) => {
                    if(editorCodeChanged) clearTimeout(editorCodeChanged);
                    setEditorCodeChanged(undefined);
                    setEditorCodeChanged(setTimeout(() => {
                        props.onCodeChange(value);
                    }, 500)); //Debounce
                }}
            />
        </>
    )
}

export default CodeMirrorEditor;