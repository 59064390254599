import { createSelector } from 'reselect';
import { IRootState } from 'store';

/*
    The final hardware mode being used for the Javascript Page. Will use the auto detected device profile if js selector is AUTO, else use the user selected hardware mode
*/
export const selectorGetProjectHardwareModeJavascript = createSelector(
    [
        (state: IRootState) => state.project.hardwareMode,
        (state: IRootState) => state.project.hardwareModeAutoDetected
    ],
    (hardwareMode, hardwareModeAutoDetected): string => {
        if(hardwareMode === 'AUTO') {
            if(hardwareModeAutoDetected === 'none') return 'ebot-2wd';
            return hardwareModeAutoDetected;
        }
        return hardwareMode;
    }
);