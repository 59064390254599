import React from 'react';
import { IConsoleDecorator } from '../ConsoleDecorator';

export const EventConsoleDecorator: IConsoleDecorator = ({ msgObject }) => {
    return (
        <>
            Event | Code {msgObject['evt']} | {msgObject['i']} | {msgObject['d']}
        </>
    )
};

EventConsoleDecorator.decoratorTest = (msgObject) => {
    if(msgObject['evt'] !== undefined && msgObject['i'] !== undefined) return true;

    return false;
}

EventConsoleDecorator.consoleClass = (msgObject) => {
    let classes = 'c-consoleDecorator__event';

    if(!isNaN(msgObject['evt'])) {
        if(msgObject['evt'] >= 150 && msgObject['evt'] <= 199) classes += 'Warning';
        else if(msgObject['evt'] >= 200  && msgObject['evt'] <= 255) classes += 'Error';
        else classes += 'SensorAction';
    }

    return `${classes} c-consoleDecorator__whiteTimestamp`;
}

//Error codes
//1-149 sensors or actions
//150-199 system info or warning
//200-255 errors