import React from 'react';

import './Input.scss';

interface InputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    className?: string;
}

const Input: React.FC<InputProps> = ({className, ...props}) => {
    let classes = "c-input ";
    if(className) classes += className;

    return (
        <input className={classes} {...props} />
    )
};

export default Input;

export type IOnInputChange = React.ChangeEvent<HTMLInputElement>;