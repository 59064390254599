import React from 'react';
import { IConsoleDecorator } from '../ConsoleDecorator';

export const UltraConsoleDecorator: IConsoleDecorator = ({ msgObject }) => {
    const frontLeft = msgObject['Ultras'][0] !== undefined ? `Front Left: ${msgObject['Ultras'][0]}` : '';
    const frontRight = msgObject['Ultras'][1] !== undefined ? `Front Right: ${msgObject['Ultras'][1]}` : '';
    const sideLeft = msgObject['Ultras'][2] !== undefined ? `Side Left: ${msgObject['Ultras'][2]}` : '';
    const sideRight = msgObject['Ultras'][3] !== undefined ? `Side Right: ${msgObject['Ultras'][3]}` : '';

    const ultras = [frontLeft, frontRight, sideLeft, sideRight].filter(ultra => ultra !== '');

    return (
        <>
            Ultra Packet | {ultras.join(' | ')}
        </>
    )
};

UltraConsoleDecorator.decoratorTest = (msgObject) => {
    if(msgObject['Ultras']) return true;

    return false;
}

UltraConsoleDecorator.consoleClass = () => 'c-consoleDecorator__Ultra';