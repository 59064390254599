import immer from 'immer';
import shortid from 'shortid';
import {stdBinaryTosrcBinary, binaryToHex} from 'utils/hexBinary';

import { ICodingBackpackMatrixesState, ICodingBackpackMatrixesActionTypes, ICodingBackpackMatrix } from '.';
import { ICodingMatrixEditorAction } from 'store';

import { 
    CODING_PROJECT_FILE_BACKPACKMATRIXES_CREATE,
    CODING_PROJECT_FILE_BACKPACKMATRIXES_EDIT_MATRIX,
    CODING_PROJECT_FILE_BACKPACKMATRIXES_CLEAR_MATRIX,
    CODING_PROJECT_FILE_BACKPACKMATRIXES_INVERT_MATRIX,
    CODING_PROJECT_FILE_BACKPACKMATRIXES_DUPLICATE_MATRIX,
    CODING_PROJECT_FILE_BACKPACKMATRIXES_DELETE_MATRIX,
    CODING_PROJECT_FILE_BACKPACKMATRIXES_RENAME_MATRIX,
    CODING_PROJECT_FILE_BACKPACKMATRIXES_SHIFT_UP_MATRIX,
    CODING_PROJECT_FILE_BACKPACKMATRIXES_SHIFT_DOWN_MATRIX,
    CODING_PROJECT_FILE_BACKPACKMATRIXES_SHIFT_LEFT_MATRIX,
    CODING_PROJECT_FILE_BACKPACKMATRIXES_SHIFT_RIGHT_MATRIX
} from '.';

const backpackMatrixCreateNew = (folderId: string = 'root', name?: string, id?: string): ICodingBackpackMatrix => ({
    id: id || shortid(),
    name: name || 'Matrix',
    folderId,
    hex: '0'.repeat(64),
    stdBinary: '0'.repeat(256).split('')
})

export const codingBackpackMatrixesInitialState: ICodingBackpackMatrixesState = {
    byIds: {
        initMatrix: {
            id: 'initMatrix',
            name: 'Smiley',
            folderId: 'root',
            hex: '0000000000000003040800060600000000000000000000c02010006060000000',
            stdBinary: '0000000000000000000000000000000000000000000000000000011001100000000001100110000000000000000000000000100000010000000001000010000000000011110000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'.split('')
        },
        initMatrix2: backpackMatrixCreateNew('root', 'Clear Matrix', 'initMatrix2'),
        initMatrix3: backpackMatrixCreateNew('root', 'Clear Matrix Two', 'initMatrix3'),
        countMatrix1: {
            id: 'countMatrix1',
            name: 'Count',
            folderId: 'countFolder',
            hex: '00000007070000000000020703010000000000f8f8c0c0c0c0c0c0c0c0c00000',
            stdBinary: '0000000000000000000000000000000000000001110000000000001111000000000001111100000000000010110000000000000011000000000000001100000000000000110000000000000011000000000000001100000000000111111110000000011111111000000000000000000000000000000000000000000000000000'.split('')
        },
        countMatrix2: {
            id: 'countMatrix2',
            name: 'Count',
            folderId: 'countFolder',
            hex: '0000000f0f0703010000060703000000000000f0f00080c0e06060e0c0000000',
            stdBinary: '0000000000000000000000000000000000000000000000000000001111000000000001111110000000000110011000000000000001100000000000001110000000000001110000000000001110000000000001110000000000001111111100000000111111110000000000000000000000000000000000000000000000000000'.split('')
        },
        countMatrix3: {
            id: 'countMatrix3',
            name: 'Count',
            folderId: 'countFolder',
            hex: '000000070f000000010100000c070300000000c0e0f07060e0e07070e0e08000',
            stdBinary: '0000000000000000000000111000000000000111111000000000110011100000000000000111000000000000011100000000000111100000000000011110000000000000011000000000000001110000000000001111000000001111111000000000011111000000000000000000000000000000000000000000000000000000'.split('')
        },
        countMatrix4: {
            id: 'countMatrix4',
            name: 'Count',
            folderId: 'countFolder',
            hex: '000000000000001f1f0c060301000000000000c0c0c0c0f0f0c0c0c0c0c00000',
            stdBinary: '0000000000000000000000000000000000000000110000000000000111000000000000111100000000000110110000000000110011000000000111111111000000011111111100000000000011000000000000001100000000000000110000000000000011000000000000000000000000000000000000000000000000000000'.split('')
        },
        countMatrix5: {
            id: 'countMatrix5',
            name: 'Count',
            folderId: 'countFolder',
            hex: '000000030f0c0000030f0c0f0f000000000000c0e0303030e0c000f0f0000000',
            stdBinary: '0000000000000000000000000000000000000000000000000000111111110000000011111111000000001100000000000000111111000000000000111110000000000000001100000000000000110000000011000011000000001111111000000000001111000000000000000000000000000000000000000000000000000000'.split('')
        },
        countMatrix6: {
            id: 'countMatrix6',
            name: 'Count',
            folderId: 'countFolder',
            hex: '00000003070e0c0c0f07070301000000000000c0e0703030f0e00080c0e00000',
            stdBinary: '0000000000000000000000000000000000000000111000000000000111000000000000111000000000000111000000000000011111100000000011111111000000001100001100000000110000110000000011100111000000000111111000000000001111000000000000000000000000000000000000000000000000000000'.split('')
        },
        countMatrix7: {
            id: 'countMatrix7',
            name: 'Count',
            folderId: 'countFolder',
            hex: '00000006070301000000000f0f000000000000000080c0e0603030f0f0000000',
            stdBinary: '0000000000000000000000000000000000000000000000000000111111110000000011111111000000000000001100000000000000110000000000000110000000000000111000000000000111000000000000111000000000000111000000000000011000000000000000000000000000000000000000000000000000000000'.split('')
        },
        countMatrix8: {
            id: 'countMatrix8',
            name: 'Count',
            folderId: 'countFolder',
            hex: '00000003070e0c0c0607030606070300000000c0e070303060e0c06060e0c000',
            stdBinary: '0000000000000000000000111100000000000111111000000000011001100000000001100110000000000011110000000000011111100000000001100110000000001100001100000000110000110000000011100111000000000111111000000000001111000000000000000000000000000000000000000000000000000000'.split('')
        },
        countMatrix9: {
            id: 'countMatrix9',
            name: 'Count',
            folderId: 'countFolder',
            hex: '0000000000000003070606030100000000000060606060e0e06060e0c0000000',
            stdBinary: '0000000000000000000000000000000000000000000000000000000111000000000000111110000000000110011000000000011001100000000001111110000000000011111000000000000001100000000000000110000000000000011000000000000001100000000000000000000000000000000000000000000000000000'.split('')
        },
        countMatrix10: {
            id: 'countMatrix10',
            name: 'Count',
            folderId: 'countFolder',
            hex: '0000007e7e191919197938180000000000000078cc8686868686cc7800000000',
            stdBinary: '0000000000000000000000000000000000000000000000000000000000000000000110000111100000111000110011000111100110000110000110011000011000011001100001100001100110000110000110011000011001111110110011000111111001111000000000000000000000000000000000000000000000000000'.split('')
        },
        arrowMatrix1: {
            id: 'arrowMatrix1',
            name: 'Arrow',
            folderId: 'arrowFolder',
            hex: '0000406030180c060c1830604000000000000000000000000000000000000000',
            stdBinary: '0000000000000000000000000000000000000000000000000100000000000000011000000000000000110000000000000001100000000000000011000000000000000110000000000000110000000000000110000000000000110000000000000110000000000000010000000000000000000000000000000000000000000000'.split('')
        },
        arrowMatrix2: {
            id: 'arrowMatrix2',
            name: 'Arrow',
            folderId: 'arrowFolder',
            hex: '0000446633190c060c19336644000000000000000080c060c080000000000000',
            stdBinary: '0000000000000000000000000000000000000000000000000100010000000000011001100000000000110011000000000001100110000000000011001100000000000110011000000000110011000000000110011000000000110011000000000110011000000000010001000000000000000000000000000000000000000000'.split('')
        },
        arrowMatrix3: {
            id: 'arrowMatrix3',
            name: 'Arrow',
            folderId: 'arrowFolder',
            hex: '0000446633190c060c19336644000000000040603098cc66cc98306040000000',
            stdBinary: '0000000000000000000000000000000000000000000000000100010001000000011001100110000000110011001100000001100110011000000011001100110000000110011001100000110011001100000110011001100000110011001100000110011001100000010001000100000000000000000000000000000000000000'.split('')
        },
        arrowMatrix4: backpackMatrixCreateNew('arrowFolder', 'Arrow', 'arrowMatrix4'),
    },
    allIds: [
        "initMatrix",
        "initMatrix2",
        "initMatrix3",
        "countMatrix1",
        "countMatrix2",
        "countMatrix3",
        "countMatrix4",
        "countMatrix5",
        "countMatrix6",
        "countMatrix7",
        "countMatrix8",
        "countMatrix9",
        "countMatrix10",
        "arrowMatrix1",
        "arrowMatrix2",
        "arrowMatrix3"
    ]
}

export const codingBackpackMatrixesReducer = (state: ICodingBackpackMatrixesState, action: ICodingBackpackMatrixesActionTypes, codingProjectIndex: number, fileIndex: number): ICodingBackpackMatrixesState => {
    const currAction = action as ICodingMatrixEditorAction;
    if(currAction.codingProjectIndex !== codingProjectIndex || currAction.fileIndex !== fileIndex) return {...state};
    
    switch (action.type) {
        case CODING_PROJECT_FILE_BACKPACKMATRIXES_CREATE:
            return immer(state, (draftState: ICodingBackpackMatrixesState) => {
                const newMatrix: ICodingBackpackMatrix = {
                    ...backpackMatrixCreateNew(action.folderId),
                    ...action.extraOptions,
                    folderId: action.folderId //Dont let this get overwritten
                }

                draftState.byIds[newMatrix.id] = newMatrix;
                draftState.allIds.push(newMatrix.id);
            });

        case CODING_PROJECT_FILE_BACKPACKMATRIXES_EDIT_MATRIX:
            return immer(state, (draftState: ICodingBackpackMatrixesState) => {
                draftState.byIds[action.matrixId] = action.matrix;
            })

        case CODING_PROJECT_FILE_BACKPACKMATRIXES_CLEAR_MATRIX:
            return immer(state, (draftState: ICodingBackpackMatrixesState) => {
                draftState.byIds[action.matrixId] = backpackMatrixCreateNew(state.byIds[action.matrixId].folderId, action.matrixId) //Replace everything except id and folder id with a new matrix object
            })

        case CODING_PROJECT_FILE_BACKPACKMATRIXES_INVERT_MATRIX:
            return immer(state, (draftState: ICodingBackpackMatrixesState) => {
                const newStdBinary = state.byIds[action.matrixId].stdBinary.map(dot => dot === '1' ? '0' : '1');

                draftState.byIds[action.matrixId].stdBinary = newStdBinary;
                draftState.byIds[action.matrixId].hex = binaryToHex(stdBinaryTosrcBinary(newStdBinary))
            })
        case CODING_PROJECT_FILE_BACKPACKMATRIXES_DUPLICATE_MATRIX:
            return immer(state, (draftState: ICodingBackpackMatrixesState) => {
                draftState.byIds[action.newMatrixId] = {...draftState.byIds[action.matrixId], id: action.newMatrixId};
                draftState.allIds.push(action.newMatrixId);
            })
        case CODING_PROJECT_FILE_BACKPACKMATRIXES_DELETE_MATRIX:
            return immer(state, (draftState: ICodingBackpackMatrixesState) => {
                let matrixIds: string[];
                if(Array.isArray(action.matrixId)) matrixIds = [...action.matrixId];
                else matrixIds = [action.matrixId];

                for(let i=0; i<matrixIds.length; i++) {
                    delete draftState.byIds[matrixIds[i]];
                    draftState.allIds.splice(draftState.allIds.indexOf(matrixIds[i]), 1); //Delet this
                }
            })
        case CODING_PROJECT_FILE_BACKPACKMATRIXES_RENAME_MATRIX:
            return immer(state, (draftState: ICodingBackpackMatrixesState) => {
                draftState.byIds[action.matrixId].name = action.name;
            })
        case CODING_PROJECT_FILE_BACKPACKMATRIXES_SHIFT_UP_MATRIX:
            return immer(state, (draftState: ICodingBackpackMatrixesState) => {
                const matrixBinary = state.byIds[action.matrixId].stdBinary;

                for(let i=0; i<16; i++) {
                    for(let j=0; j<16; j++) {
                        draftState.byIds[action.matrixId].stdBinary[i+(j*16)] = matrixBinary[i+((j === 15 ? -1 : j)*16)+16];
                    }
                }

                draftState.byIds[action.matrixId].hex = binaryToHex(stdBinaryTosrcBinary(draftState.byIds[action.matrixId].stdBinary));
            })
        case CODING_PROJECT_FILE_BACKPACKMATRIXES_SHIFT_DOWN_MATRIX:
            return immer(state, (draftState: ICodingBackpackMatrixesState) => {
                const matrixBinary = state.byIds[action.matrixId].stdBinary;

                for(let i=0; i<16; i++) {
                    for(let j=0; j<16; j++) {
                        draftState.byIds[action.matrixId].stdBinary[i+(j*16)] = matrixBinary[i+((j === 0 ? 16 : j)*16)-16];
                    }
                }

                draftState.byIds[action.matrixId].hex = binaryToHex(stdBinaryTosrcBinary(draftState.byIds[action.matrixId].stdBinary));
            })
        case CODING_PROJECT_FILE_BACKPACKMATRIXES_SHIFT_LEFT_MATRIX:
            return immer(state, (draftState: ICodingBackpackMatrixesState) => {
                const matrixBinary = state.byIds[action.matrixId].stdBinary;

                for(let i=0; i<16; i++) {
                    const startNo = i*16;
                    const matrixRow = matrixBinary.slice(startNo, startNo+16);
                    for(let j=0; j<matrixRow.length; j++) {
                        draftState.byIds[action.matrixId].stdBinary[startNo+j] = matrixRow[(j === 15 ? -1 : j)+1];
                    }
                }

                draftState.byIds[action.matrixId].hex = binaryToHex(stdBinaryTosrcBinary(draftState.byIds[action.matrixId].stdBinary));
            })
        case CODING_PROJECT_FILE_BACKPACKMATRIXES_SHIFT_RIGHT_MATRIX:
            return immer(state, (draftState: ICodingBackpackMatrixesState) => {
                const matrixBinary = state.byIds[action.matrixId].stdBinary;

                for(let i=0; i<16; i++) {
                    const startNo = i*16;
                    const matrixRow = matrixBinary.slice(startNo, startNo+16);
                    for(let j=0; j<matrixRow.length; j++) {
                        draftState.byIds[action.matrixId].stdBinary[startNo+j] = matrixRow[(j === 0 ? 16 : j)-1];
                    }
                }

                draftState.byIds[action.matrixId].hex = binaryToHex(stdBinaryTosrcBinary(draftState.byIds[action.matrixId].stdBinary));
            })
        default:
            return {...state};
    }
}