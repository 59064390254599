import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState } from 'store';

import Button from 'components/Button/Button';

import './SerialErrorPhase.scss';

type Props = PropsFromRedux & {
    onBackClick: () => void;
}

const SerialErrorPhase: React.FC<Props> = ({ onBackClick }) => {

    const onBackClickEvent = () => {
        onBackClick();
    }

    return (
        <div className="c-serialErrorPhase">
            <h4>Error Accessing Serial API</h4>
            <p>Make sure you are using Chrome or Edge (Any Chromium Based Browser).</p>
            <p>Update to the latest browser release.</p>
            <p>Enable WebSerial by navigating to one of the below links in your URL bar and enabling "Experimental Web Platform features":</p>
            <p>Chrome: <span className="c-serialErrorPhase__link">chrome://flags/#enable-experimental-web-platform-features</span></p>
            <p>Opera: <span className="c-serialErrorPhase__link">opera://flags/#enable-experimental-web-platform-features</span></p>
            <p>Edge: <span className="c-serialErrorPhase__link">edge://flags/#enable-experimental-web-platform-features</span></p>
            <br />
            <div className="c-serialErrorPhase__buttons">
                <Button type="danger" addStyles={['square']} onClick={onBackClickEvent}>Back</Button>
            </div>
        </div>
    )
}

const mapStateToProps = (state: IRootState): StateProps => {
    return {
    }
}

const mapDispatchToProps = {
}

interface StateProps {
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SerialErrorPhase);