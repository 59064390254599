export enum PageTypes {
    Coding = '/coding',
    Remote = '/remote',
    Console = '/console',
    /* Test = '/test', */
    Config = '/config',
    ModemBinding = '/modemBinding',
    EditConfig = '/editConfig',
    CodingHelpTest = '/codingHelpTest'
};

export const PageNames = {
    [PageTypes.Coding]: 'Coding',
    [PageTypes.Remote]: 'Remote Control',
    [PageTypes.Console]: 'Console',
    /* [PageTypes.Test]: 'Test Rig', */
    [PageTypes.Config]: 'Config',
    [PageTypes.ModemBinding]: 'Modem Binding',
    [PageTypes.EditConfig]: 'Edit Config',
    [PageTypes.CodingHelpTest]: 'Coding Help Test'
};

export const PagesHidden: string[] = [
    PageTypes.ModemBinding,
    PageTypes.EditConfig,
    PageTypes.CodingHelpTest
]

//Return the name of a page given an address
export const getPageName = (address: string): string => ((PageNames as any)[address]);