import React from 'react';
import { IConsoleDecorator } from '../ConsoleDecorator';

export const JSErrorConsoleDecorator: IConsoleDecorator = ({ msgObject }) => {

    return (
        <>
            <span></span> { msgObject }
        </>
    )
};

JSErrorConsoleDecorator.decoratorTest = () => true;

JSErrorConsoleDecorator.consoleClass = () => 'c-consoleDecorator__jsError';