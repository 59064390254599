import store, { IThunkResult, BlockLevelModes, GamePadTypes } from 'store';
import { toast } from 'react-toastify';
import shortid from 'shortid';
import { push } from 'connected-react-router';
import { DeviceConnectionManager } from 'lib';

import {
    ICodingSetRunning,
    ICodingSetCodeHasError,
    ICodingSetPyEnvReady,
    ICodingSetUserSelectedRightPanel,
    ICodingSetCreateProjectModelOpen,
    ICodingSetProjectSettingsModelOpen,
    ICodingSetActiveProjectIndex,
    ICodingSetProjectFiles,
    ICodingSetProjectName,
    ICodingAddProject,
    ICodingSetProjectHardwareMode,
    ICodingSetProjectBlockLevelMode,
    ICodingSetProjectGamepadMode,
    ICodingDeleteProject,
    ICodingProjectFileAdd,
    ICodingProjectFileDelete,
    ICodingProjectFileSetName,
    ICodingSetProjectFileButtons,
    ICodingDeleteProjectFileEventButtonsButton,
    ICodingSetProjectFileActiveIndex,
    ICodingSetProjectFileCode,
    ICodingSetProjectFileText,
    ICodingSetProjectFileGlobalVars,
    ICodingSetProjectFileGlobalVarsJS4Scratch,
    ICodingSetProjectFileAssetManagerSprites,
    ICodingSetProjectFileAssetManagerBackdrops,
    ICodingSetProjectFileImageManagerImages,
    ICodingSetProjectFileFlowChartData,
    ICodingImportProject
} from '.';

import {
    CODING_SET_RUNNING,
    CODING_SET_CODE_HAS_ERROR,
    CODING_SET_PY_ENV_READY,
    CODING_SET_USER_SELECTED_RIGHT_PANEL,
    CODING_SET_CREATE_PROJECT_MODAL_OPEN,
    CODING_SET_PROJECT_SETTINGS_MODAL_OPEN,
    CODING_SET_ACTIVE_PROJECT_INDEX,
    CODING_SET_PROJECT_SET_FILES,
    CODING_SET_PROJECT_NAME,
    CODING_PROJECT_ADD,
    CODING_SET_PROJECT_HARDWARE_MODE,
    CODING_SET_PROJECT_BLOCK_LEVEL_MODE,
    CODING_SET_PROJECT_GAMEPAD_MODE,
    CODING_PROJECT_DELETE,
    CODING_PROJECT_FILE_ADD,
    CODING_PROJECT_FILE_DELETE,
    CODING_SET_PROJECT_FILE_NAME,
    CODING_SET_PROJECT_FILE_BUTTONS,
    CODING_DELETE_PROJECT_FILE_EVENT_BUTTONS_DELETE_BUTTON,
    CODING_SET_PROJECT_FILE_ACTIVE_INDEX,
    CODING_SET_PROJECT_FILE_CODE,
    CODING_SET_PROJECT_FILE_TEXT,
    CODING_SET_PROJECT_FILE_GLOBAL_VARS,
    CODING_SET_PROJECT_FILE_GLOBAL_VARS_JS4SCRATCH,
    CODING_SET_PROJECT_FILE_ASSET_MANAGER_SPRITES,
    CODING_SET_PROJECT_FILE_ASSET_MANAGER_BACKDROPS,
    CODING_SET_PROJECT_FILE_IMAGE_MANAGER_IMAGES,
    CODING_SET_PROJECT_FILE_FLOWCHART_DATA,
    CODING_IMPORT_PROJECT
} from '.';

import {
    ICodingProject,
    ICodingFile,
    ICodingFileType,
    ICodingGlobalVarVariable,
    ICodingGlobalVarJS4ScratchVariable,
    ICodingUserSelectedRightPanel,
    ICodingSprite,
    ICodingBackdrop,
    ICodingImage
} from '.';

import {
    projectSetCodingHelpPopoutSelectedSectionIndex
} from 'store/project';

export const codingSetRunning = (running: boolean): IThunkResult<any> => {
    return dispatch => {
        dispatch({
            type: CODING_SET_RUNNING,
            running
        } as ICodingSetRunning);

        if(!running) DeviceConnectionManager.sendCommand('wheels off; led clear; matrix clear;');
        if(running) dispatch(codingSetCodeHasError(false))
    }
}

export const codingSetCodeHasError = (codeHasError: boolean): ICodingSetCodeHasError => {
    return {
        type: CODING_SET_CODE_HAS_ERROR,
        codeHasError
    }
}

export const codingSetPyEnvReady = (pyEnvReady: boolean): ICodingSetPyEnvReady => {
    return {
        type: CODING_SET_PY_ENV_READY,
        pyEnvReady
    }
}

export const codingSetUserSelectedRightPanel = (userSelectedRightPanel: ICodingUserSelectedRightPanel): IThunkResult<any> => {
    return dispatch => {
        dispatch({
            type: CODING_SET_USER_SELECTED_RIGHT_PANEL,
            userSelectedRightPanel
        } as ICodingSetUserSelectedRightPanel);

        dispatch(codingSetCodeHasError(false))
    }
}

export const codingSetCreateProjectModelOpen = (isOpen: boolean): ICodingSetCreateProjectModelOpen => {
    return {
        type: CODING_SET_CREATE_PROJECT_MODAL_OPEN,
        isOpen
    }
}

export const codingSetSettingsModelOpen = (isOpen: boolean): ICodingSetProjectSettingsModelOpen => {
    return {
        type: CODING_SET_PROJECT_SETTINGS_MODAL_OPEN,
        isOpen
    }
}

export const codingSetActiveProjectIndex = (index: number): IThunkResult<any> => {
    return dispatch => {
        dispatch({
            type: CODING_SET_ACTIVE_PROJECT_INDEX,
            activeProjectIndex: index
        } as ICodingSetActiveProjectIndex);

        dispatch(codingSetRunning(false));
        dispatch(codingSetUserSelectedRightPanel('help'));
        dispatch(projectSetCodingHelpPopoutSelectedSectionIndex(null));
    }
}

export const codingSetProjectFiles = (projectIndex: number, files: ICodingFile[]): ICodingSetProjectFiles => {
    return {
        type: CODING_SET_PROJECT_SET_FILES,
        projectIndex,
        files
    }
}

export const codingSetProjectName = (projectIndex: number, projectName: string): ICodingSetProjectName => {
    return {
        type: CODING_SET_PROJECT_NAME,
        projectIndex,
        projectName
    }
}

export const codingSetProjectHardwareMode = (projectIndex: number, hardwareMode: string): ICodingSetProjectHardwareMode => {
    return {
        type: CODING_SET_PROJECT_HARDWARE_MODE,
        projectIndex,
        hardwareMode
    }
}

export const codingSetProjectBlockLevelMode = (projectIndex: number, blockLevelMode: keyof typeof BlockLevelModes): ICodingSetProjectBlockLevelMode => {
    return {
        type: CODING_SET_PROJECT_BLOCK_LEVEL_MODE,
        projectIndex,
        blockLevelMode
    }
}

export const codingSetProjectGamepadMode = (projectIndex: number, gamepadMode: GamePadTypes): ICodingSetProjectGamepadMode => {
    return {
        type: CODING_SET_PROJECT_GAMEPAD_MODE,
        projectIndex,
        gamepadMode
    }
}

export const codingAddProject = (codingProject: ICodingProject): ICodingAddProject => {
    return {
        type: CODING_PROJECT_ADD,
        codingProject
    }
}

export const codingDeleteProject = (projectIndex: number): IThunkResult<any> => {
    return dispatch => {
        const state = store.getState();

        if(state.coding.codingProjects.length < 2) {
            toast.error(`Cannot Delete Only Coding Project`)
            dispatch({
                type: CODING_PROJECT_DELETE,
                projectIndex: -1
            } as ICodingDeleteProject);
            return;
        }

        let newActiveIndex = state.coding.activeProjectIndex;
        if(projectIndex === newActiveIndex) newActiveIndex--; //using that project now
        if(newActiveIndex === -1) newActiveIndex = 0; //correct for first project
        if(newActiveIndex === state.coding.codingProjects.length-1) newActiveIndex--; //make sure if using last then update

        dispatch(codingSetActiveProjectIndex(newActiveIndex));

        toast.warn(`Deleted Coding Project: ${state.coding.codingProjects[projectIndex].name}`)
        dispatch({
            type: CODING_PROJECT_DELETE,
            projectIndex
        } as ICodingDeleteProject);
    }
}

export const codingSetProjectFileName = (projectIndex: number, fileIndex: number, name: string): ICodingProjectFileSetName => {
    return {
        type: CODING_SET_PROJECT_FILE_NAME,
        projectIndex,
        fileIndex,
        name
    }
}

export const codingAddProjectFile = (projectIndex: number, fileType: ICodingFileType, fileName: string): ICodingProjectFileAdd => {
    if(fileName === '') fileName = 'File';

    return {
        type: CODING_PROJECT_FILE_ADD,
        projectIndex,
        fileType,
        fileName
    }
}

export const codingDeleteProjectFile = (projectIndex: number, fileIndex: number): IThunkResult<any> => {
    return dispatch => {
        const state = store.getState();
        const codingProject = state.coding.codingProjects[projectIndex];

        const jsFilesNo = codingProject.files.filter(file => file.type === 'js').length;

        if(codingProject.type === 'jsscratch' && codingProject.files[fileIndex].type === 'js' && jsFilesNo < 2) {
            toast.error(`Cannot Delete Only Javascript Project File`)
            dispatch({
                type: CODING_PROJECT_FILE_DELETE,
                projectIndex,
                fileIndex: -1
            } as ICodingProjectFileDelete);
            return;
        }

        if(codingProject.files.length < 2) {
            toast.error(`Cannot Delete Only Coding Project File`)
            dispatch({
                type: CODING_PROJECT_FILE_DELETE,
                projectIndex,
                fileIndex: -1
            } as ICodingProjectFileDelete);
            return;
        }

        let newActiveIndex = codingProject.activeFileIndex;
        if(fileIndex === newActiveIndex) newActiveIndex--;
        if(newActiveIndex === -1) newActiveIndex = 0;
        if(newActiveIndex === codingProject.files.length-1) newActiveIndex--; //make sure if using last then update

        dispatch(codingSetProjectFileActiveIndex(newActiveIndex));

        dispatch({
            type: CODING_PROJECT_FILE_DELETE,
            projectIndex,
            fileIndex
        } as ICodingProjectFileDelete);
    }
}

export const codingSetProjectFileButtons = (buttons: string[], projectIndex: number, fileIndex: number): ICodingSetProjectFileButtons => {
    return {
        type: CODING_SET_PROJECT_FILE_BUTTONS,
        buttons,
        projectIndex,
        fileIndex
    }
}

export const codingDeleteProjectFileEventButtonsButton = (projectIndex: number, fileIndex: number, buttonIndex: number): ICodingDeleteProjectFileEventButtonsButton => {
    return {
        type: CODING_DELETE_PROJECT_FILE_EVENT_BUTTONS_DELETE_BUTTON,
        projectIndex,
        fileIndex,
        buttonIndex
    }
}

export const codingSetProjectFileGlobalVars = (projectIndex: number, fileIndex: number, globalVars: ICodingGlobalVarVariable[]): ICodingSetProjectFileGlobalVars => {
    return {
        type: CODING_SET_PROJECT_FILE_GLOBAL_VARS,
        projectIndex,
        fileIndex,
        globalVars
    }
}

export const codingSetProjectFileGlobalVarsJS4Scratch = (projectIndex: number, fileIndex: number, globalVars: ICodingGlobalVarJS4ScratchVariable[]): ICodingSetProjectFileGlobalVarsJS4Scratch => {
    return {
        type: CODING_SET_PROJECT_FILE_GLOBAL_VARS_JS4SCRATCH,
        projectIndex,
        fileIndex,
        globalVars
    }
}

export const codingSetProjectFileAssetManagerSprites = (projectIndex: number, fileIndex: number, sprites: ICodingSprite[]): ICodingSetProjectFileAssetManagerSprites => {
    return {
        type: CODING_SET_PROJECT_FILE_ASSET_MANAGER_SPRITES,
        projectIndex,
        fileIndex,
        sprites
    }
}

export const codingSetProjectFileAssetManagerBackdrops = (projectIndex: number, fileIndex: number, backdrops: ICodingBackdrop[]): ICodingSetProjectFileAssetManagerBackdrops => {
    return {
        type: CODING_SET_PROJECT_FILE_ASSET_MANAGER_BACKDROPS,
        projectIndex,
        fileIndex,
        backdrops
    }
}

export const codingSetProjectFileImageManagerImages = (projectIndex: number, fileIndex: number, images: ICodingImage[]): ICodingSetProjectFileImageManagerImages => {
    return {
        type: CODING_SET_PROJECT_FILE_IMAGE_MANAGER_IMAGES,
        projectIndex,
        fileIndex,
        images
    }
}


export const codingSetProjectFileActiveIndex = (fileIndex: number): ICodingSetProjectFileActiveIndex => {
    return {
        type: CODING_SET_PROJECT_FILE_ACTIVE_INDEX,
        fileIndex
    }
}

export const codingSetProjectFileCode = (code: string, fileType: ICodingFileType, projectIndex: number, fileIndex: number): ICodingSetProjectFileCode => {
    return {
        type: CODING_SET_PROJECT_FILE_CODE,
        code,
        fileType,
        projectIndex,
        fileIndex,
    }
}

export const codingSetProjectFileText = (text: string, projectIndex: number, fileIndex: number): ICodingSetProjectFileText => {
    return {
        type: CODING_SET_PROJECT_FILE_TEXT,
        text,
        projectIndex,
        fileIndex,
    }
}

export const codingSetProjectFileFlowChartData = (data: any, projectIndex: number, fileIndex: number): ICodingSetProjectFileFlowChartData => {
    return {
        type: CODING_SET_PROJECT_FILE_FLOWCHART_DATA,
        data,
        projectIndex,
        fileIndex,
    }
}

export const codingImportProject = (data: ICodingProject): IThunkResult<any> => {
    return dispatch => {
        const state = store.getState();
        const newProjectIndex = state.coding.codingProjects.length;

        dispatch({
            type: CODING_IMPORT_PROJECT,
            data
        } as ICodingImportProject);

        dispatch(codingSetActiveProjectIndex(newProjectIndex));
    }
}

export const codingCreateSelectedFlowChartProject = (): IThunkResult<any> => {
    return dispatch => {
        const state = store.getState();
        const newProjectIndex = state.coding.codingProjects.length;

        dispatch(codingAddProject({
            name: 'Flow Chart',
            id: shortid(),
            type: 'flowChart',
            files: [
                {
                    name: 'Flow Chart 1',
                    id: shortid(),
                    type: 'flowChart',
                    flowChartData: {
                        data: {
                            s: [
                                {
                                    templateKey: 'text',
                                    position: {
                                        x: 84,
                                        y: 36
                                    },
                                    detail: '1. Create your flow chart\n2. Export your flowchart (png)\n3. Insert png into the ScratchLink Block Coder portfolio tab',
                                    styles: null
                                }
                            ]
                        }
                    }
                }
            ],
            activeFileIndex: 0,
            hardwareMode: 'javascript',
            blockLevelMode: 'Beginner',
            gamepadMode: 'no_gamepad',
            projectVersion: '1.0'
        }))

        dispatch(codingSetActiveProjectIndex(newProjectIndex));

        dispatch(push('/coding'));
    }
}

