import _ from 'lodash';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import reduxThunk, { ThunkAction } from 'redux-thunk';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { createHashHistory, History } from 'history';
import { save } from 'redux-localstorage-simple';

import {
    consoleReducer,
    IConsoleActions,
    consoleInitialState
} from './console';

import { 
    projectReducer,
    IProjectActions,
    projectInitialState
} from './project';

import {
    RFIDReducer,
    IRFIDActions,
    RFIDInitialState
} from './RFID';

import {
    codingReducer,
    ICodingActions,
    codingInitialState
} from './coding';

import {
    settingsReducer,
    ISettingsActions,
    settingsInitialState
} from './settings';

export const cleanStateForSave = (state: IRootState): IRootState => {
    return _.assign({}, state, {
        project: {}, //Dont include project state in save so that robot stays connected
        console: consoleInitialState,
        coding: {
            ...state.coding,
            running: false,
            pyEnvReady: false,
            createProjectModalOpen: false,
            projectSettingsModalOpen: false
        }
    } as Partial<IRootState>);
}

export const history = createHashHistory()

const createRootReducer = (history: History) => combineReducers({
    router: connectRouter(history),
    console: consoleReducer,
    project: projectReducer,
    RFID: RFIDReducer,
    coding: codingReducer,
    settings: settingsReducer
});

export const initalState = Object.assign({}, {
    console: consoleInitialState,
    project: projectInitialState,
    RFID: RFIDInitialState,
    coding: codingInitialState,
    settings: settingsInitialState
});

const localStorageName = `reduxStorage_${Date.now()}`; //use current time

const middleware: any[] = [reduxThunk, routerMiddleware(history), save({ debounce: 500, namespace: localStorageName })];

//@ts-ignore
const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    actionsDenylist: ['CONSOLE/ADD_CONSOLE_LOG', 'CODING/PROJECT/FILE/JS/SET_CODE', 'CODING/PROJECT/FILE/TEXT/SET_TEXT', 'PROJECT/SET_DEVICE_BATTERY_VOLTAGE', 'PROJECT/SET_GAMEPAD_DATA'] //Exclude console logs from dev tools due to clutter
});

const composeEnhancers = reduxDevTools || compose;

const store = createStore(
    createRootReducer(history), 
    {}, 
    composeEnhancers(applyMiddleware(...middleware))
);

export default store;

interface IAction {
    type: string;
}

export type IAllActionTypes = IAction | IConsoleActions | IProjectActions | IRFIDActions | ICodingActions | ISettingsActions;

export type IThunkResult<R> = ThunkAction<R, IRootState, undefined, IAllActionTypes>;

export type IRootState = ReturnType<ReturnType<typeof createRootReducer>>;