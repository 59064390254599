import React from 'react';
import { IConsoleDecorator } from '../ConsoleDecorator';

export const ConfigConsoleDecorator: IConsoleDecorator = ({ msgObject }) => {
    return (
        <>
            {JSON.stringify(msgObject)}
        </>
    )
};

ConfigConsoleDecorator.decoratorTest = (msgObject) => {
    if( msgObject['config'] !== undefined) return true;

    return false;
}

ConfigConsoleDecorator.consoleClass = () => 'c-consoleDecorator__config c-consoleDecorator__whiteTimestamp';