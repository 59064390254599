import React, { useEffect, useState, useCallback } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState } from 'store';
import { DeviceConnectionManager } from 'lib/DeviceConnection';
import { useMultiKeyPress } from 'utils/hooks';
import { areKeysPressed } from 'utils/areKeysPressed';
import { Joystick, IJoystickUpdateEvent } from 'react-joystick-component/build/lib/Joystick';
import { JoystickShape } from 'react-joystick-component';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faStop } from "@fortawesome/free-solid-svg-icons";
import { SiSpeedtest } from 'react-icons/si';
import { GrGamepad } from 'react-icons/gr';

import Page from 'containers/Page/Page';
import InputNumber from 'components/InputNumber/InputNumber';
import SensorTable from 'containers/SensorTable/SensorTable';
import Slider, { SliderChange, SliderValue } from 'components/Slider/Slider';
import GamepadConnection, { GamepadConnectionEvents } from 'lib/GamepadConnection/GamepadConnection';
import clamp from 'utils/clamp'

import './RemotePage.scss';
import Button from 'components/Button/Button';

type Props = PropsFromRedux & {

}

let lastDriveValue: string;
let buttonPressed = false;

type remoteTypes = 'buttons' | 'joystick' | 'tank' | 'gamepad';
type craneOperationTypes = 'sliders' | 'presets' | 'gamepad';

const CraneTypes = [
    '1DOF',
    '2DOF',
    '3DOF',
    '5DOF',
    '7DOF',
    'none'
] as const;

type CraneType = typeof CraneTypes[number];
type SelectorSet = React.Dispatch<React.SetStateAction<number>>;

const RemotePage: React.FC<Props> = ({ scratchlinkConfig, hardwareModeAutoDetected, gamepad0Connected }) => {
    const [speed, setSpeed] = useState<number | undefined>(50);
    const [remoteType, setRemoteType] = useState<remoteTypes>('buttons');
    const [craneOperationType, setCraneOperationType] = useState<craneOperationTypes>('sliders')
    const [sendOmniCommands, setSendOmniCommands] = useState(false);

    const [flipper, setFlipper] = useState(50);
    const [claw, setClaw] = useState(50);
    const [wrist, setWrist] = useState(50);
    const [wristRotate, setWristRotate] = useState(50);
    const [elbowRotate, setElbowRotate] = useState(50);
    const [elbow, setElbow] = useState(50);
    const [shoulder, setShoulder] = useState(50);
    const [baseRotate, setBaseRotate] = useState(50);

    const [sendCraneCommand, setSendCraneCommand] = useState<undefined | ReturnType<typeof setTimeout>>(); //Debounce

    const onSpeedSliderChange: SliderChange = (sliderValue) => {
        if(Array.isArray(sliderValue)) return;
        setSpeed(sliderValue);
    }

    const onSpeedInputChange = (speed: number | undefined) => {
        setSpeed(speed);
    }

    const onRemoteSelectorButtonsClick = () => {
        setRemoteType('buttons')
    }

    const onRemoteSelectorJoystickClick = () => {
        setRemoteType('joystick')
    }

    /* const onRemoteSelectorTankClick = () => {
        setRemoteType('tank')
    } */

    const onRemoteSelectorGamepadClick = () => {
        setRemoteType('gamepad');
    }

    const onCraneSelectorSlidersClick = () => {
        setCraneOperationType('sliders');
    }

    const onCraneSelectorPresetsClick = () => {
        setCraneOperationType('presets');
    }

    const onCraneSelectorGamepadClick = () => {
        setCraneOperationType('gamepad');
    }

    const handleKeyPress = (direction: string) => {
        drive(direction, false);
    }

    const handleButtonPress = (direction: string) => {
        drive(direction, true);
    }

    const handleStopButtonPress = () => {
        drive('stop', true);
    }

    const drive = (direction: string, button: boolean) => {
        if(!speed) return;
        if(lastDriveValue === direction && button === buttonPressed) return;

        if(direction === 'stop') DeviceConnectionManager.sendCommand(`wheels off;`); //Stop is same for everything
        else if(direction !== 'stop') {
            if(sendOmniCommands) { //Omni drive directions without stop
                if(direction === 'f') DeviceConnectionManager.sendCommand(`wheels drive f ${speed};`);
                if(direction === 'lt') DeviceConnectionManager.sendCommand(`wheels drive RD ${speed};`);
                if(direction === 'rt') DeviceConnectionManager.sendCommand(`wheels drive LD ${speed};`);

                if(direction === 'hlt') DeviceConnectionManager.sendCommand(`wheels drive RS ${speed};`);
                if(direction === 'hrt') DeviceConnectionManager.sendCommand(`wheels drive LS ${speed};`);

                if(direction === 'blt') DeviceConnectionManager.sendCommand(`wheels drive LD ${-speed};`);
                if(direction === 'brt') DeviceConnectionManager.sendCommand(`wheels drive RD ${-speed};`);

                if(direction === 'b') DeviceConnectionManager.sendCommand(`wheels drive b ${speed};`);
            } else { //Normal ebot without stop
                if(direction !== 'blt' && direction !== 'brt') DeviceConnectionManager.sendCommand(`wheels drive ${direction} ${speed};`);
                if(direction === 'blt') DeviceConnectionManager.sendCommand(`wheels drive lt ${-speed};`); //create proxy back commands as they do not exist
                if(direction === 'brt') DeviceConnectionManager.sendCommand(`wheels drive rt ${-speed};`);
            }            
        }
        lastDriveValue = direction;
        buttonPressed = button;
    }  
    
    //Handle Keyboard
    const keysPressed = useMultiKeyPress(true);
    const leftUpPressed = areKeysPressed(['ArrowLeft', 'ArrowUp'], keysPressed);
    const rightUpPressed = areKeysPressed(['ArrowRight', 'ArrowUp'], keysPressed);
    const upPressed = areKeysPressed(['ArrowUp'], keysPressed);
    const downPressed = areKeysPressed(['ArrowDown'], keysPressed);
    const leftPressed = areKeysPressed(['ArrowLeft'], keysPressed);
    const rightPressed = areKeysPressed(['ArrowRight'], keysPressed);

    if(leftUpPressed) {
        handleKeyPress('lt');
    } else if(rightUpPressed) {
        handleKeyPress('rt');
    } else if(upPressed) {
        handleKeyPress('f');
    } else if(downPressed) {
        handleKeyPress('b');
    } else if(rightPressed) {
        handleKeyPress('hrt');
    } else if(leftPressed) {
        handleKeyPress('hlt');
    } else if(!leftUpPressed && !rightUpPressed && !upPressed && !downPressed && !leftPressed && !rightPressed) {
        handleKeyPress('stop');
    }

    const getCraneType = (): CraneType => {
        const scratchLinkProfile = scratchlinkConfig.deviceProfiles.find((deviceProfile: any) => deviceProfile.profile === hardwareModeAutoDetected); //Get profile
        if(!scratchLinkProfile) return '7DOF'; //Did not find a valid profile so display all joints
        if(scratchLinkProfile.crane === undefined) return 'none';
        if(CraneTypes.includes(scratchLinkProfile.crane)) return scratchLinkProfile.crane; //If found a defined crane type then use that as it is valid
        return '7DOF'; //Not found anything then display all
    }

    const craneJoints: string[] = scratchlinkConfig.configPage.crane[getCraneType()];

    const handleJoyStickMove = (event: IJoystickUpdateEvent) => {
        if(event.type !== 'move' || !event.x || !event.y || !event.distance) return;

        const joyX = event.x*2;
        const joyY = event.y*2;
        const distance = event.distance;

        let left = distance;
        let right = distance;

        if(joyX <= 0 && joyY >= 0) { //Top Left
            left = distance + joyX;
        }
        if(joyX > 0 && joyY >= 0) { //Top Right
            right = distance - joyX;
        }
        if(joyX < 0 && joyY < 0) { //Bottom Left
            left = joyY;
        }
        if(joyX > 0 && joyY < 0) { //Bottom Right
            right = joyY;
        }
        if(joyX > -15 && joyX < 15 && joyY < 0) { //Direct Bottom
            left = -distance;
            right = -distance;
        }

        //console.log(`JoyX ${joyX.toFixed(0)}, JoyY ${joyY.toFixed(0)}, distance ${distance.toFixed(0)}, left ${left.toFixed(0)}, right ${right.toFixed(0)}`  );

        //console.log(`JoyX ${joyX.toFixed(0)}, JoyY ${joyY.toFixed(0)}, left ${left.toFixed(0)}, right ${right.toFixed(0)}`);
        DeviceConnectionManager.sendCommand(`wheels power ${left} ${right};`);
    }

    const handleJoyStickStop = (event: IJoystickUpdateEvent) => {
        if(event.type !== 'stop') return;
        DeviceConnectionManager.sendCommand(`wheels off;`)
    }

    const onCraneSliderChange = (set: SelectorSet, sliderVal: SliderValue, joint: string) => {
        set(sliderVal as number);
        
        if(sendCraneCommand) clearTimeout(sendCraneCommand);
        setSendCraneCommand(setTimeout(() => {
            DeviceConnectionManager.sendCommand(`crane ${joint} ${sliderVal};`);
        }, 100))
    }

    const onFlipperSliderChange = (set: SelectorSet, sliderVal: SliderValue) => {
        set(sliderVal as number);
        
        if(sendCraneCommand) clearTimeout(sendCraneCommand);
        setSendCraneCommand(setTimeout(() => {
            DeviceConnectionManager.sendCommand(`servo ${sliderVal};`);
        }, 100))
    }

    const handleSendOmniCommandsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSendOmniCommands(e.target.checked)
    }

    const displaySendOmniCommands = hardwareModeAutoDetected.toLowerCase().includes('omni');
    const cranePresets = scratchlinkConfig.generalConfig.crane[`${getCraneType()}Presets`] || [];

    const handleCranePresetButtonClick = (presetCommands: string[]) => {
        presetCommands.forEach(presetCommand => DeviceConnectionManager.sendCommand(presetCommand));
    }

    //GAMEPAD
    const handleGamepadMove = useCallback((event: GamepadConnectionEvents['gamepad1Data']) => {
        if(remoteType !== 'gamepad') return;
        const gamepad = event.detail.data;

        if((gamepad.type === 'xbox' || gamepad.type === 't3') && displaySendOmniCommands) { //Handle omni commands
            const topLHS = gamepad.data.bumper.leftTop;
            const topRHS = gamepad.data.bumper.rightTop;

            if(topLHS) return DeviceConnectionManager.sendCommand('wheels drive RS 100;');
            else if(topRHS) return DeviceConnectionManager.sendCommand('wheels drive LS 100;');
        }

        if(gamepad.type === 'retro' && displaySendOmniCommands) {
            const lhs = gamepad.data.bumper.left;
            const rhs = gamepad.data.bumper.right;

            if(lhs) return DeviceConnectionManager.sendCommand('wheels drive RS 100;');
            else if(rhs) return DeviceConnectionManager.sendCommand('wheels drive LS 100;');
        }

        let joyX: number = 0;
        let joyY: number = 0;
        let distance: number = 0;

        if(gamepad.type === 'xbox' || gamepad.type === 't3') {
            joyX = gamepad.data.lhsJoystick.analog.x;
            joyY = gamepad.data.lhsJoystick.analog.y;
            distance = gamepad.data.lhsJoystick.analog.distance;
        }
        if(gamepad.type === 'retro') {
            if(gamepad.data.dPad.up) joyY = 100;
            if(gamepad.data.dPad.right) joyX = 100;
            if(gamepad.data.dPad.down) joyY = -100;
            if(gamepad.data.dPad.left) joyX = -100;

            if(joyY !== 0 || joyX !== 0) distance = 100;
        }

        let left = distance;
        let right = distance;

        if(joyX <= 0 && joyY >= 0) { //Top Left
            left = distance + joyX;
        }
        if(joyX > 0 && joyY >= 0) { //Top Right
            right = distance - joyX;
        }
        if(joyX < 0 && joyY < 0) { //Bottom Left
            left = joyY;

        }
        if(joyX > 0 && joyY < 0) { //Bottom Right
            right = joyY;
        }
        if(joyX > -15 && joyX < 15 && joyY < 0) { //Direct Bottom
            left = -distance;
            right = -distance;
        }

        left = clamp(left*1.4, -100, 100);
        right = clamp(right*1.4, -100, 100);

        //console.log(`JoyX ${joyX.toFixed(0)}, JoyY ${joyY.toFixed(0)}, distance ${distance.toFixed(0)}, left ${left.toFixed(0)}, right ${right.toFixed(0)}`);
        DeviceConnectionManager.sendCommand(`wheels power ${left} ${right};`);
    }, [remoteType, displaySendOmniCommands]);

    useEffect(() => {
        GamepadConnection.addEventListener('gamepad1Data', handleGamepadMove);
        return () => {
            GamepadConnection.removeEventListener('gamepad1Data', handleGamepadMove);
        }
    }, [remoteType, displaySendOmniCommands]);

    const craneGamepadSpeed = 0.02;
    const handleGamepadCraneMove = (step: number, currentVal: number, joint: string, setter: SelectorSet) => {
        const nextVal = Number((currentVal + (step * craneGamepadSpeed)).toFixed(0));
        if(nextVal < 0 || nextVal > 100) return;

        setter(nextVal);
        DeviceConnectionManager.sendCommand(`crane ${joint} ${nextVal};`);
    }

    const handleFlipperGamepadMove = (direction: 'up' | 'down') => {
        if(direction === 'up') {
            setFlipper(0);
            DeviceConnectionManager.sendCommand(`servo 0;`);
        }
        if(direction === 'down') {
            setFlipper(100);
            DeviceConnectionManager.sendCommand(`servo 100;`);
        }
    }

    const handleGamepadCrane = useCallback((event: GamepadConnectionEvents['gamepad1Data']) => {
        if(craneOperationType !== 'gamepad' || !craneJoints) return;

        const gamepad = event.detail.data;

        let joyX: number = 0;
        let joyY: number = 0;

        if(gamepad.type === 'xbox'|| gamepad.type === 't3') {
            joyX = gamepad.data.rhsJoystick.analog.x;
            joyY = gamepad.data.rhsJoystick.analog.y;

            if(getCraneType() === '1DOF') {
                if(gamepad.data.a && craneJoints.includes('Flipper')) handleFlipperGamepadMove('down');
                if(gamepad.data.y && craneJoints.includes('Flipper')) handleFlipperGamepadMove('up');
            } else {
                if(joyX !== 0 && craneJoints.includes('Base Rotate')) handleGamepadCraneMove(joyX, baseRotate, 'base-rotate', setBaseRotate);
                if(joyY !== 0 && craneJoints.includes('Elbow')) handleGamepadCraneMove(joyY, elbow, 'elbow', setElbow);

                if(gamepad.data.a && craneJoints.includes('Shoulder')) handleGamepadCraneMove(-100, shoulder, 'shoulder', setShoulder);
                if(gamepad.data.y && craneJoints.includes('Shoulder')) handleGamepadCraneMove(100, shoulder, 'shoulder', setShoulder);

                if(gamepad.data.b && craneJoints.includes('Claw')) handleGamepadCraneMove(-100, claw, 'claw', setClaw);
                if(gamepad.data.x && craneJoints.includes('Claw')) handleGamepadCraneMove(100, claw, 'claw', setClaw);

                if(gamepad.data.dPad.left && craneJoints.includes('Wrist')) handleGamepadCraneMove(100, wrist, 'wrist', setWrist);
                if(gamepad.data.dPad.right && craneJoints.includes('Wrist')) handleGamepadCraneMove(-100, wrist, 'wrist', setWrist);
            }
        }

        if(gamepad.type === 'retro') {
            if(getCraneType() === '1DOF') {
                if(gamepad.data.b && craneJoints.includes('Flipper')) handleFlipperGamepadMove('down');
                if(gamepad.data.x && craneJoints.includes('Flipper')) handleFlipperGamepadMove('up');
            } else {
                if(gamepad.data.b && craneJoints.includes('Shoulder')) handleGamepadCraneMove(-100, shoulder, 'shoulder', setShoulder);
                if(gamepad.data.x && craneJoints.includes('Shoulder')) handleGamepadCraneMove(100, shoulder, 'shoulder', setShoulder);

                if(gamepad.data.a && craneJoints.includes('Claw')) handleGamepadCraneMove(-100, claw, 'claw', setClaw);
                if(gamepad.data.y && craneJoints.includes('Claw')) handleGamepadCraneMove(100, claw, 'claw', setClaw);

                if(gamepad.data.dPad.left && craneJoints.includes('Wrist')) handleGamepadCraneMove(100, wrist, 'wrist', setWrist);
                if(gamepad.data.dPad.right && craneJoints.includes('Wrist')) handleGamepadCraneMove(-100, wrist, 'wrist', setWrist);

                if(gamepad.data.dPad.up && craneJoints.includes('Elbow')) handleGamepadCraneMove(100, elbow, 'elbow', setElbow);
                if(gamepad.data.dPad.down && craneJoints.includes('Elbow')) handleGamepadCraneMove(-100, elbow, 'elbow', setElbow);

                if(gamepad.data.bumper.left && craneJoints.includes('Base Rotate')) handleGamepadCraneMove(-100, baseRotate, 'base-rotate', setBaseRotate);
                if(gamepad.data.bumper.right && craneJoints.includes('Base Rotate')) handleGamepadCraneMove(100, baseRotate, 'base-rotate', setBaseRotate);
            }
        }
    }, [craneOperationType, shoulder, wrist, claw, elbow, baseRotate, craneJoints]);

    useEffect(() => {
        GamepadConnection.addEventListener('gamepad1Data', handleGamepadCrane);
        return () => {
            GamepadConnection.removeEventListener('gamepad1Data', handleGamepadCrane);
        }
    }, [craneOperationType, flipper, shoulder, wrist, claw, elbow, baseRotate, craneJoints]);

    return (
        <Page
            title="Coding Editor | ScratchLink"
            background="#E6F0FF"
        >
            <div className="p-remotePage">
                <div className="p-remotePage__remote p-remotePage__remote--grid">
                    <div className={`p-remotePage__remoteController ${displaySendOmniCommands ? 'p-remotePage__remoteController--omni' : ''}`}>
                        <div className="p-remotePage__remoteInner">
                            {
                                remoteType === 'buttons' && (
                                    <>
                                        <div className="p-remotePage__remoteButtons">
                                            <div>
                                                <button onClick={() => handleButtonPress('lt')}><FontAwesomeIcon icon={faArrowDown} style={{transform: 'rotate(135deg)'}}/></button>
                                                <button onClick={() => handleButtonPress('f')}><FontAwesomeIcon icon={faArrowDown} style={{transform: 'rotate(180deg)'}}/></button>
                                                <button onClick={() => handleButtonPress('rt')}><FontAwesomeIcon icon={faArrowDown} style={{transform: 'rotate(225deg)'}}/></button>
                                            </div>
                                            <div>
                                                <button onClick={() => handleButtonPress('hlt')}><FontAwesomeIcon icon={faArrowDown} style={{transform: 'rotate(90deg)'}}/></button>
                                                <button onClick={handleStopButtonPress}><FontAwesomeIcon icon={faStop}/></button>
                                                <button onClick={() => handleButtonPress('hrt')}><FontAwesomeIcon icon={faArrowDown} style={{transform: 'rotate(270deg)'}}/></button>
                                            </div>
                                            <div>
                                                <button onClick={() => handleButtonPress('blt')}><FontAwesomeIcon icon={faArrowDown} style={{transform: 'rotate(45deg)'}}/></button>
                                                <button onClick={() => handleButtonPress('b')}><FontAwesomeIcon icon={faArrowDown}/></button>
                                                <button onClick={() => handleButtonPress('brt')}><FontAwesomeIcon icon={faArrowDown} style={{transform: 'rotate(315deg)'}}/></button>
                                            </div>
                                        </div>
                                        <div className="p-remotePage__remoteSpeed">
                                            <Slider
                                                className="p-remotePage__remoteSpeedSlider"
                                                min={1}
                                                max={100}
                                                value={speed}
                                                onChange={onSpeedSliderChange}
                                            />
                                            <SiSpeedtest style={{fontSize: '1.8em', marginLeft: '5px'}} />
                                            <InputNumber 
                                                className="p-remotePage__remoteSpeedInput" 
                                                value={speed} 
                                                onInputNumberChange={onSpeedInputChange} 
                                                min={1} 
                                                max={100}
                                            />
                                        </div>
                                        <div className="p-remotePage__remoteOmniCommands" style={{display: displaySendOmniCommands ? '' : 'none'}}>
                                            <input type="checkbox" checked={sendOmniCommands} onChange={handleSendOmniCommandsChange} />
                                            <h5>Use Omni Commands</h5>
                                        </div>
                                    </>
                                )
                            }
                            {
                                remoteType === 'joystick' && (
                                    <>
                                        <div className="p-remotePage__remoteJoystick">
                                            <Joystick 
                                                size={100} 
                                                sticky={false}
                                                throttle={50}
                                                baseColor="#888" 
                                                stickColor="#075FE8"
                                                minDistance={20}
                                                controlPlaneShape={JoystickShape.Square}
                                                move={handleJoyStickMove} 
                                                stop={handleJoyStickStop} 
                                            />
                                        </div>
                                    </>
                                )
                            }
                            {/* {
                                remoteType === 'tank' && (
                                    <div className="p-remotePage__remoteTank">
                                        <Slider 
                                            min={-100}
                                            max={100}
                                            defaultValue={0}
                                            vertical
                                            snapBack
                                            onChange={val => onTankSliderChange(val, 'left')}
                                        />
                                        <Slider 
                                            min={-100}
                                            max={100}
                                            defaultValue={0}
                                            vertical
                                            snapBack
                                            onChange={val => onTankSliderChange(val, 'right')}
                                        />
                                    </div>
                                )
                            } */}
                            {
                                remoteType === 'gamepad' && (
                                    <div className="h-full flex justify-center items-center flex-col">
                                        <GrGamepad className={`p-remotePage__gamepadIcon ${gamepad0Connected ? 'p-remotePage__gamepadIcon--connected' : 'p-remotePage__gamepadIcon--disconnected'}`} />
                                        <h5>{gamepad0Connected ? 'Connected' : 'Disconnected'}</h5>
                                    </div>
                                )
                            }
                        </div>
                        <div className="p-remotePage__remoteSelector">
                            <button className={`p-remotePage__remoteSelectorButton ${remoteType === 'buttons' ? 'p-remotePage__remoteSelectorButton--active': ''}`} onClick={onRemoteSelectorButtonsClick}>Buttons</button>
                            <button className={`p-remotePage__remoteSelectorButton ${remoteType === 'joystick' ? 'p-remotePage__remoteSelectorButton--active': ''}`} onClick={onRemoteSelectorJoystickClick}>Joystick</button>
                            {/* <button className={`p-remotePage__remoteSelectorButton ${remoteType === 'tank' ? 'p-remotePage__remoteSelectorButton--active': ''}`} onClick={onRemoteSelectorTankClick}>Tank</button> */}
                            <button className={`p-remotePage__remoteSelectorButton ${remoteType === 'gamepad' ? 'p-remotePage__remoteSelectorButton--active': ''}`} onClick={onRemoteSelectorGamepadClick}>Gamepad</button>
                        </div>
                    </div>
                    {
                        craneJoints && (
                            <div className="p-remotePage__crane">
                                <div className="p-remotePage__craneInner">
                                    <h4 className="p-remotePage__craneTitle">Crane</h4>
                                    {
                                        craneOperationType === 'sliders' && (
                                            <>
                                                {
                                                    craneJoints.includes('Flipper') && (
                                                        <div>
                                                            <div className="p-remotePage__craneJointTitleArea">
                                                                <h5>Flipper</h5>
                                                                <span>{flipper}</span>
                                                            </div>
                                                            <Slider 
                                                                min={0}
                                                                max={100}
                                                                value={flipper}
                                                                onChange={(val) => onFlipperSliderChange(setFlipper, val)}
                                                            />
                                                        </div>
                                                    )
                                                }
                                                {
                                                    craneJoints.includes('Claw') && (
                                                        <div>
                                                            <div className="p-remotePage__craneJointTitleArea">
                                                                <h5>Claw</h5>
                                                                <span>{claw}</span>
                                                            </div>
                                                            <Slider 
                                                                min={0}
                                                                max={100}
                                                                value={claw}
                                                                onChange={(val) => onCraneSliderChange(setClaw, val, 'claw')}
                                                            />
                                                        </div>
                                                    )
                                                }
                                                {
                                                    craneJoints.includes('Wrist Rotate') && (
                                                        <div>
                                                            <div className="p-remotePage__craneJointTitleArea">
                                                                <h5>Wrist Rotate</h5>
                                                                <span>{wristRotate}</span>
                                                            </div>
                                                            <Slider 
                                                                min={0}
                                                                max={100}
                                                                value={wristRotate}
                                                                onChange={(val) => onCraneSliderChange(setWristRotate, val, 'wrist-rotate')}
                                                            />
                                                        </div>
                                                    )
                                                }
                                                {
                                                    craneJoints.includes('Wrist') && (
                                                        <div>
                                                            <div className="p-remotePage__craneJointTitleArea">
                                                                <h5>Wrist</h5>
                                                                <span>{wrist}</span>
                                                            </div>
                                                            <Slider 
                                                                min={0}
                                                                max={100}
                                                                value={wrist}
                                                                onChange={(val) => onCraneSliderChange(setWrist, val, 'wrist')}
                                                            />
                                                        </div>
                                                    )
                                                }
                                                {
                                                    craneJoints.includes('Elbow Rotate') && (
                                                        <div>
                                                            <div className="p-remotePage__craneJointTitleArea">
                                                                <h5>Elbow Rotate</h5>
                                                                <span>{elbowRotate}</span>
                                                            </div>
                                                            <Slider 
                                                                min={0}
                                                                max={100}
                                                                value={elbowRotate}
                                                                onChange={(val) => onCraneSliderChange(setElbowRotate, val, 'elbow-rotate')}
                                                            />
                                                        </div>
                                                    )
                                                }
                                                {
                                                    craneJoints.includes('Elbow') && (
                                                        <div>
                                                            <div className="p-remotePage__craneJointTitleArea">
                                                                <h5>Elbow</h5>
                                                                <span>{elbow}</span>
                                                            </div>
                                                            <Slider 
                                                                min={0}
                                                                max={100}
                                                                value={elbow}
                                                                onChange={(val) => onCraneSliderChange(setElbow, val, 'elbow')}
                                                            />
                                                        </div>
                                                    )
                                                }
                                                {
                                                    craneJoints.includes('Shoulder') && (
                                                        <div>
                                                            <div className="p-remotePage__craneJointTitleArea">
                                                                <h5>Shoulder</h5>
                                                                <span>{shoulder}</span>
                                                            </div>
                                                            <Slider 
                                                                min={0}
                                                                max={100}
                                                                value={shoulder}
                                                                onChange={(val) => onCraneSliderChange(setShoulder, val, 'shoulder')}
                                                            />
                                                        </div>
                                                    )
                                                }
                                                {
                                                    craneJoints.includes('Base Rotate') && (
                                                        <div>
                                                            <div className="p-remotePage__craneJointTitleArea">
                                                                <h5>Base Rotate</h5>
                                                                <span>{baseRotate}</span>
                                                            </div>
                                                            <Slider 
                                                                min={0}
                                                                max={100}
                                                                value={baseRotate}
                                                                onChange={(val) => onCraneSliderChange(setBaseRotate, val, 'base-rotate')}
                                                            />
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    }
                                    {
                                        craneOperationType === 'presets' && (
                                            <div className="p-remotePage__cranePresets">
                                                {
                                                    cranePresets.map((cranePreset: any) => (
                                                        <div className="p-remotePage__cranePreset">
                                                            <Button type="success" addStyles={['full width']} onClick={() => handleCranePresetButtonClick(cranePreset.commands)}>{cranePreset.name}</Button>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        )
                                    }
                                    {
                                        craneOperationType === 'gamepad' && (
                                            <div className="h-full flex justify-center items-center flex-col">
                                                <GrGamepad className={`p-remotePage__gamepadIcon ${gamepad0Connected ? 'p-remotePage__gamepadIcon--connected' : 'p-remotePage__gamepadIcon--disconnected'}`} />
                                                <h5>{gamepad0Connected ? 'Connected' : 'Disconnected'}</h5>
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="p-remotePage__craneSelector">
                                    <button className={`p-remotePage__remoteSelectorButton ${craneOperationType === 'sliders' ? 'p-remotePage__remoteSelectorButton--active': ''}`} onClick={onCraneSelectorSlidersClick}>Sliders</button>
                                    <button className={`p-remotePage__remoteSelectorButton ${craneOperationType === 'presets' ? 'p-remotePage__remoteSelectorButton--active': ''}`} onClick={onCraneSelectorPresetsClick}>Presets</button>
                                    <button className={`p-remotePage__remoteSelectorButton ${craneOperationType === 'gamepad' ? 'p-remotePage__remoteSelectorButton--active': ''}`} onClick={onCraneSelectorGamepadClick}>Gamepad</button>
                                </div>
                            </div>
                        )
                    }
                </div>
                <SensorTable />
            </div>
        </Page>
    )
}

const mapStateToProps = (state: IRootState) => {
    return {
        scratchlinkConfig: state.project.scratchlinkConfig,
        hardwareModeAutoDetected: state.project.hardwareModeAutoDetected,
        gamepad0Connected: state.project.gamepad0.connected
    }
}

const mapDispatchToProps = {
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RemotePage);