import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImages } from "@fortawesome/free-regular-svg-icons";

import './BackpackFolder.scss';

interface BackpackFolderProps {
    name: string;
    selected?: boolean;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const BackpackFolder: React.FC<BackpackFolderProps> = (({ name, onClick, selected = false }) => {
    let classes = 'c-backpackFolder';
    if(selected) classes += ' c-backpackFolder--selected';

    return (
        <div className={classes} onClick={onClick}>
            <FontAwesomeIcon icon={faImages} size="6x"/>
            <div className="c-backpackFolder__title">
                { name }
            </div>
        </div>
    )
});

export default BackpackFolder;