import React, { useEffect, useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState, selectorGetActiveCodingProject, codingSetRunning } from 'store';
import JSRunner from 'lib/JSRunner';
import GamepadConnection from 'lib/GamepadConnection/GamepadConnection';

import Modal from 'components/Modal/Modal';
import Console from 'containers/Console/Console';

type OwnProps = {

}

type Props = PropsFromRedux & OwnProps;

const CodingProjectCreateModal: React.FC<Props> = ({ codingIsRunning, codingProject, codingSetRunning }) => {
    const isOpen = codingIsRunning && codingProject.type === 'jsscratch';
    const iframeRef = useRef<HTMLIFrameElement>(null);

    const renderIframe = () => {
        const [blobUrl, iframeCode] = JSRunner.generateJS4ScratchIFrameCode(codingProject);

        console.log(iframeCode);

        return (
            <>
                <iframe ref={iframeRef} title="JS Scratch" style={{width: '480px', height: '360px'}} className='border-2 border-gray-800' src={blobUrl} />
            </>
        )
    }
 
    const onCloseModal = () => {
        codingSetRunning(false);
    }

    const handleGamepadEvent = (event: any) => {
        if(!isOpen) return;
        if(!iframeRef.current) return;

        const iframeWindow = iframeRef.current.contentWindow;
        if(!iframeWindow) return;

        iframeWindow.postMessage({type: 'gamepadEvent', data: event.detail}, '*');
    }

    const handleGamepad1Data = (event: any) => {
        handleGamepadData(event, 1);
    }

    const handleGamepad2Data = (event: any) => {
        handleGamepadData(event, 2);
    }

    const handleGamepadData = (event: any, gamepadNumber: number) => {
        if(!isOpen) return;
        if(!iframeRef.current) return;

        const iframeWindow = iframeRef.current.contentWindow;
        if(!iframeWindow) return;
        
        iframeWindow.postMessage({type: 'gamepadData', data:{
            gamepadNumber,
            gamepadPacket: event.detail.data
        }}, '*');
    }

    useEffect(() => {
        GamepadConnection.gamepad1EventHandler.addEventListener('gamepadEvent', handleGamepadEvent)
        GamepadConnection.gamepad2EventHandler.addEventListener('gamepadEvent', handleGamepadEvent)
        GamepadConnection.addEventListener('gamepad1Data', handleGamepad1Data)
        GamepadConnection.addEventListener('gamepad2Data', handleGamepad2Data)

        return () => {
            GamepadConnection.gamepad1EventHandler.removeEventListener('gamepadEvent', handleGamepadEvent)
            GamepadConnection.gamepad2EventHandler.removeEventListener('gamepadEvent', handleGamepadEvent)
            GamepadConnection.removeEventListener('gamepad1Data', handleGamepad1Data)
            GamepadConnection.removeEventListener('gamepad2Data', handleGamepad2Data)
        }
    }, [iframeRef.current, isOpen])

    return (
        <Modal 
            title={`${codingProject.name} Stage`}
            isOpen={isOpen}
            closeModal={onCloseModal}
            type="default"
            size="lg"
        >
            <div className='flex flex-row justify-center'>
                {
                    isOpen && renderIframe()
                }
                <Console 
                    name='JS Console'
                    style={{height: '360px', marginLeft: '5px', maxWidth: '480px'}} 
                    acceptedSources={['JSSystem', 'JSLog', 'JSLibError', 'JSError']} 
                    options={{
                        rawPacketTick: false
                    }}
                />
            </div>
        </Modal>
    )
}

const mapStateToProps = (state: IRootState, ownProps: OwnProps) => {
    return {
        codingIsRunning: state.coding.running,
        codingProject: selectorGetActiveCodingProject(state)
    }
}

const mapDispatchToProps = {
    codingSetRunning
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CodingProjectCreateModal);