import React, { useEffect, useRef, useState, useCallback } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState, codingSetProjectFileFlowChartData } from 'store';
import ReactResizeDetector from 'react-resize-detector';
import { debounce } from 'lodash';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';

import flowchartCanvasCss from './flowchartCanvasCss';

import './CodingFlowChartEditor.css';

const dgrmFactory = require('dgrm/dist/diagram-npm-package/factory');
const dgrmSerial = require('dgrm/dist/diagram-npm-package/serializable');

type OwnProps = {
    codingProjectIndex: number;
    fileIndex: number;
}

type Props = PropsFromRedux & OwnProps;

type PNGOpenProps = {
    handlePNGData: any
}

const PNGLocalOpen: React.FC<PNGOpenProps> = ({ handlePNGData }) => {
    const inputRef = useRef(null);

    const handleClick = () => {
        //@ts-ignore
        inputRef?.current?.click();
    };

    const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        //@ts-ignore
        handlePNGData(files[0])
    };

    return (
        <div style={{padding: 0}}>
            <input type="file" ref={inputRef} accept=".png" onChange={onChangeFile} hidden/>
            <button className='hover:underline' onClick={handleClick}>Import Flow Chart</button>
        </div>
    )
}

const CodingFlowChartEditor: React.FC<Props> = ({ codingProjectIndex, fileIndex, codingFile, codingSetProjectFileFlowChartData, rightPanelWidth }) => {
    const digramRef = useRef(null);
    const [diagram, setDiagram] = useState<any>(null);
    const [finishedDiagramInit, setFinishedDiagramInit] = useState(false);
    const [menuButtonDownType, setMenuButtonDownType] = useState<string | null>(null);

    const handleMenuPointerDown = (evt: any, targetName: string) => {
        setMenuButtonDownType(targetName);
    }

    const handleMenuPointerUp = () => {
        setMenuButtonDownType(null)
    }

    const handleMenuPointerLeave = (evt: any) => {
        if(!menuButtonDownType || !diagram) return;

        diagram.shapeActiveAdd({ //add new shape
            templateKey: menuButtonDownType,
            position: {
                x: evt.clientX,
                y: evt.clientY
            },
            props: {
                text: {
                    textContent: 'Title'
                }
            }
        })
    }

    const handleResize = (widthEl: number, heightEl: number) => {
        //@ts-ignore
        window.dgrmOffsetX = window.innerWidth-widthEl-rightPanelWidth;
        //@ts-ignore
        window.dgrmOffsetY = window.innerHeight-heightEl;
    }

    const handlePNGImport = async (data: any) => {
        if(!await diagram.pngLoad(data)) toast.error('ERROR: Could not read file. Make sure you import a Flow Chart made in this editor');
    }

    const handlePNGExport = () => {
        diagram.pngCreate((png: any) => {
            saveAs(png, `${codingFile.name}.png`);
        })
    }

    const saveDiagramData = debounce(() => {      
        if(!diagram) return;

        const dataCurrent = diagram.dataGet();
        const dataStore = JSON.stringify(codingFile.flowChartData?.data);

        if(!dataCurrent || !dataStore) return;

        //only save real changes
        if(JSON.stringify(dataCurrent) !== dataStore) codingSetProjectFileFlowChartData(dataCurrent, codingProjectIndex, fileIndex);
    }, 100)

    useEffect(() => { //library init start
        if(!digramRef || !digramRef.current) return; //no ref
        if(diagram) return; //already defined
        const svg = digramRef.current;

        const newDiagram = new dgrmSerial.AppDiagramSerializable(svg, dgrmFactory.appDiagramFactory(svg))
        .on('add', (_: any) => {
            //@ts-ignore
            svg.style.removeProperty('pointer-events');
        }, { once: true });

        newDiagram.dataSet(JSON.parse(JSON.stringify(codingFile.flowChartData?.data)));

        setDiagram(newDiagram)
    }, [digramRef, diagram])

    useEffect(() => { //attach events. This is not done during init because diagram is not set inside saveDiagramData during init useEffect function
        if(!diagram || finishedDiagramInit) return;

        diagram
            //.on('add', () => saveDiagramData(newDiagram)) //not needed since other events will be called after dropping shape and this causes the mouse to lose focus on shape
            .on('del', saveDiagramData)
            .on('select', saveDiagramData)
            .on('connect', saveDiagramData)
            .on('disconnect', saveDiagramData)
            .on('moveend', saveDiagramData)
            .on('scale', saveDiagramData)
            .on('canvmove', saveDiagramData)
            .on('text', saveDiagramData)

        setFinishedDiagramInit(true);
    }, [diagram])

    useEffect(() => {//load redux
        if(!diagram) return;

        const dataCurrent = diagram.dataGet();
        const dataStore = JSON.stringify(codingFile.flowChartData?.data);
        if(!dataCurrent || !dataStore) return;

        //only reload if current svgs do not match redux store (outside change)
        if(JSON.stringify(dataCurrent) !== dataStore) diagram.dataSet(JSON.parse(JSON.stringify(codingFile.flowChartData?.data)));
    }, [diagram, codingFile.flowChartData])

    return (
        <div className='w-full h-full relative' onPointerUp={handleMenuPointerUp}>
            <div className='absolute bottom-0 left-0 p-1 shadow-lg bg-white rounded-lg ml-2 mb-2'>
                <PNGLocalOpen handlePNGData={handlePNGImport} />
            </div>
            <div className='absolute bottom-0 right-0 p-1 shadow-lg bg-white rounded-lg mr-2 mb-2'>
                <button className='hover:underline' onClick={handlePNGExport}>Export Flow Chart</button>
            </div>
            <ReactResizeDetector handleWidth handleHeight onResize={handleResize} refreshRate={300} refreshMode="debounce"/>
            <div id="menu" className="c-flowChart__menu select-none" style={{touchAction: 'none'}} onPointerLeave={handleMenuPointerLeave} >
                <div className="c-flowChart__content">
                    <svg data-cmd="shapeAdd" className="" style={{paddingLeft: '20px'}} onPointerDown={e => handleMenuPointerDown(e, 'circle')}>
                        <circle r="20" fill="#ff6600" stroke="#fff" cx="21" cy="21"></circle>
                    </svg>
                    <svg data-cmd="shapeAdd" className="big" onPointerDown={e => handleMenuPointerDown(e, 'rect')}>
                        <rect x="1" y="1" fill="#1aaee5" stroke="#fff" width="60" height="40" rx="15" ry="15"></rect>
                    </svg>
                    <svg data-cmd="shapeAdd" className="big" onPointerDown={e => handleMenuPointerDown(e, 'rhomb')}>
                        <g transform="translate(1,1)">
                            <path d="M0 20 L30 0 L60 20 L30 40 Z" strokeWidth="2" fill="#1D809F" stroke="#fff" strokeLinejoin="round"></path>
                        </g>
                    </svg>
                    <svg data-cmd="shapeAdd" onPointerDown={e => handleMenuPointerDown(e, 'text')}>
                        <text x="5" y="40" fontSize="52px" fill="#344767" strokeWidth="0">T</text>
                    </svg>
                    <svg data-cmd="shapeAdd" onPointerDown={e => handleMenuPointerDown(e, 'connectorNode')}>
                        <circle r="10" cx="21" fill="#495057" stroke="#fff" cy="21"></circle>
                    </svg>
                </div>
            </div>
            <svg className='w-full h-full select-none' id="diagram" ref={digramRef} xmlns="http://www.w3.org/2000/svg" style={{touchAction: 'none', backgroundColor: '#fff', display: 'block'}}>
            <style>
                {flowchartCanvasCss}
            </style>
            <defs>
                <g data-templ="path">
                    <path data-key="outer" d="M0 0" stroke="transparent" strokeWidth="20" fill="none" />
                    <path data-key="path" d="M0 0" stroke="#333" strokeWidth="1.8" fill="none"
                        style={{pointerEvents: 'none'}} />
                    <path data-key="selected" d="M0 0" stroke="#333" strokeWidth="1.8" fill="none"
                        style={{pointerEvents: 'none'}} />
                    <g data-key="arrow">
                        <circle r="10" strokeWidth="0" fill="transparent" stroke="transparent" />
                        <path d="M-7 7 l 7 -7 l -7 -7" stroke="#333" strokeWidth="1.8" fill="none"></path>
                    </g>
                </g>

                <g data-templ="connect-end" data-connect="in" data-connect-point="0,0" data-connectable="true" data-center="0,0">
                    <circle r="10" data-evt-z-index="1" />
                </g>

                <g data-templ="circle" data-center="0,0">
                    <circle data-key="outer" data-evt-no-click="" data-evt-z-index="1" r="72" fill="transparent"
                        stroke="transparent" strokeWidth="1" />
                    <circle data-key="main" r="48" fill="#ff6600" stroke="#fff" strokeWidth="1" className="main" data-text-for="text" />

                    <text data-key="text" data-line-height="20" data-vertical-middle="10" x="0" y="0" textAnchor="middle"
                        alignmentBaseline="central" fill="#fff">&nbsp;</text>

                    <circle data-key="outright" data-connect="out" data-connect-point="48,0" data-connect-dir="right"
                        data-evt-z-index="1" r="10" cx="48" cy="0" />
                    <circle data-key="outleft" data-connect="out" data-connect-point="-48,0" data-connect-dir="left"
                        data-evt-z-index="1" r="10" cx="-48" cy="0" />
                    <circle data-key="outbottom" data-connect="out" data-connect-point="0,48" data-connect-dir="bottom"
                        data-evt-z-index="1" r="10" cx="0" cy="48" />
                    <circle data-key="outtop" data-connect="out" data-connect-point="0,-48" data-connect-dir="top"
                        data-evt-z-index="1" r="10" cx="0" cy="-48" />

                    <circle data-key="inright" data-connect="in" data-connect-point="48,0" data-connect-dir="right"
                        data-evt-z-index="1" r="10" cx="48" cy="0" />
                    <circle data-key="inleft" data-connect="in" data-connect-point="-48,0" data-connect-dir="left"
                        data-evt-z-index="1" r="10" cx="-48" cy="0" />
                    <circle data-key="inbottom" data-connect="in" data-connect-point="0,48" data-connect-dir="bottom"
                        data-evt-z-index="1" r="10" cx="0" cy="48" />
                    <circle data-key="intop" data-connect="in" data-connect-point="0,-48" data-connect-dir="top"
                        data-evt-z-index="1" r="10" cx="0" cy="-48" />
                </g>

                <g data-templ="rect" data-center="48,24">
                    <rect data-key="outer" data-evt-no-click="" data-evt-z-index="1" width="144" height="96" rx="15"
                        ry="15" x="-24" y="-24" fill="transparent" stroke="transparent" strokeWidth="1"></rect>
                    <rect data-key="main" width="96" height="48" rx="15" ry="15" fill="#1aaee5" stroke="#fff"
                        strokeWidth="1" className="main" data-text-for="text"></rect>

                    <text data-key="text" data-line-height="20" data-vertical-middle="34" x="48" y="24" textAnchor="middle"
                        alignmentBaseline="central" fill="#fff">&nbsp;</text>

                    <circle data-key="outright" data-connect="out" data-connect-point="96,24" data-connect-dir="right"
                        data-evt-z-index="1" r="10" cx="96" cy="24" />
                    <circle data-key="outleft" data-connect="out" data-connect-point="0,24" data-connect-dir="left"
                        data-evt-z-index="1" r="10" cx="0" cy="24" />
                    <circle data-key="outtop" data-connect="out" data-connect-point="48,0" data-connect-dir="top"
                        data-evt-z-index="1" r="10" cx="48" cy="0" />
                    <circle data-key="outbottom" data-connect="out" data-connect-point="48,48" data-connect-dir="bottom"
                        data-evt-z-index="1" r="10" cx="48" cy="48" />

                    <circle data-key="inright" data-connect="in" data-connect-point="94,24" data-connect-dir="right"
                        data-evt-z-index="1" r="10" cx="96" cy="24" />
                    <circle data-key="inleft" data-connect="in" data-connect-point="0,24" data-connect-dir="left"
                        data-evt-z-index="1" r="10" cx="0" cy="24" />
                    <circle data-key="intop" data-connect="in" data-connect-point="48,0" data-connect-dir="top"
                        data-evt-z-index="1" r="10" cx="48" cy="0" />
                    <circle data-key="inbottom" data-connect="in" data-connect-point="48,48" data-connect-dir="bottom"
                        data-evt-z-index="1" r="10" cx="48" cy="48" />
                </g>

                <g data-templ="text" data-center="48,24">
                    <rect data-key="outer" data-evt-no-click="" data-evt-z-index="1" width="144" height="96" rx="15"
                        ry="15" x="-24" y="-24" fill="transparent" stroke="transparent" strokeWidth="1"></rect>
                    <rect data-key="main" width="96" height="48" rx="15" ry="15" fill="transparent" stroke="transparent"
                        strokeWidth="1" className="main" data-text-for="text"></rect>

                    <text data-key="text" data-line-height="20" data-vertical-middle="34" x="8" y="24"
                        alignmentBaseline="central" fill="#495057">&nbsp;</text>

                    <circle data-key="outright" data-connect="out" data-connect-point="96,24" data-connect-dir="right"
                        data-evt-z-index="1" r="10" cx="96" cy="24" />
                    <circle data-key="outleft" data-connect="out" data-connect-point="0,24" data-connect-dir="left"
                        data-evt-z-index="1" r="10" cx="0" cy="24" />
                    <circle data-key="outtop" data-connect="out" data-connect-point="48,0" data-connect-dir="top"
                        data-evt-z-index="1" r="10" cx="48" cy="0" />
                    <circle data-key="outbottom" data-connect="out" data-connect-point="48,48" data-connect-dir="bottom"
                        data-evt-z-index="1" r="10" cx="48" cy="48" />

                    <circle data-key="inright" data-connect="in" data-connect-point="94,24" data-connect-dir="right"
                        data-evt-z-index="1" r="10" cx="96" cy="24" />
                    <circle data-key="inleft" data-connect="in" data-connect-point="0,24" data-connect-dir="left"
                        data-evt-z-index="1" r="10" cx="0" cy="24" />
                    <circle data-key="intop" data-connect="in" data-connect-point="48,0" data-connect-dir="top"
                        data-evt-z-index="1" r="10" cx="48" cy="0" />
                    <circle data-key="inbottom" data-connect="in" data-connect-point="48,48" data-connect-dir="bottom"
                        data-evt-z-index="1" r="10" cx="48" cy="48" />
                </g>

                <g data-templ="rhomb" data-center="48,48">
                    <path data-key="outer" data-evt-no-click="" data-evt-z-index="1" d="M-24 48 L48 -24 L120 48 L48 120 Z"
                        strokeWidth="1" stroke="transparent" fill="transparent" />
                    <path data-key="border" d="M9 48 L48 9 L87 48 L48 87 Z" strokeWidth="20" stroke="#fff"
                        fill="transparent" strokeLinejoin="round" />
                    <path data-key="main" d="M9 48 L48 9 L87 48 L48 87 Z" strokeWidth="18" strokeLinejoin="round"
                        stroke="#1D809F" fill="#1D809F" data-text-for="text" />

                    <text data-key="text" data-line-height="20" data-vertical-middle="58" x="48" y="24" textAnchor="middle"
                        alignmentBaseline="central" fill="#fff">&nbsp;</text>

                    <circle data-key="outleft" data-connect="out" data-connect-point="0,48" data-connect-dir="left"
                        data-evt-z-index="1" r="10" cx="0" cy="48" />
                    <circle data-key="outright" data-connect="out" data-connect-point="96,48" data-connect-dir="right"
                        data-evt-z-index="1" r="10" cx="96" cy="48" />
                    <circle data-key="outtop" data-connect="out" data-connect-point="48,0" data-connect-dir="top"
                        data-evt-z-index="1" r="10" cx="48" cy="0" />
                    <circle data-key="outbottom" data-connect="out" data-connect-point="48,96" data-connect-dir="bottom"
                        data-evt-z-index="1" r="10" cx="48" cy="96" />

                    <circle data-key="inleft" data-connect="in" data-connect-point="0,48" data-connect-dir="left"
                        data-evt-z-index="1" r="10" cx="0" cy="48" />
                    <circle data-key="inright" data-connect="in" data-connect-point="96,48" data-connect-dir="right"
                        data-evt-z-index="1" r="10" cx="96" cy="48" />
                    <circle data-key="intop" data-connect="in" data-connect-point="48,0" data-connect-dir="top"
                        data-evt-z-index="1" r="10" cx="48" cy="0" />
                    <circle data-key="inbottom" data-connect="in" data-connect-point="48,96" data-connect-dir="bottom"
                        data-evt-z-index="1" r="10" cx="48" cy="96" />
                </g>

                <g data-templ="connectorNode" data-center="0,0">
                    <circle data-key="outer" data-evt-no-click="" data-evt-z-index="1" r="36" fill="transparent"
                        stroke="transparent" strokeWidth="1" />
                    <circle data-key="main" r="12" fill="#495057" stroke="#fff" strokeWidth="1" className="main" data-text-for="text" />

                    <text data-key="text" data-line-height="20" data-vertical-middle="10" x="0" y="0" textAnchor="middle"
                        alignmentBaseline="central" fill="#fff">&nbsp;</text>

                    <circle data-key="outright" data-connect="out" data-connect-point="12,0" data-connect-dir="right"
                        data-evt-z-index="1" r="6" cx="12" cy="0" />
                    <circle data-key="outleft" data-connect="out" data-connect-point="-12,0" data-connect-dir="left"
                        data-evt-z-index="1" r="6" cx="-12" cy="0" />
                    <circle data-key="outbottom" data-connect="out" data-connect-point="0,12" data-connect-dir="bottom"
                        data-evt-z-index="1" r="6" cx="0" cy="12" />
                    <circle data-key="outtop" data-connect="out" data-connect-point="0,-12" data-connect-dir="top"
                        data-evt-z-index="1" r="6" cx="0" cy="-12" />

                    <circle data-key="inright" data-connect="in" data-connect-point="12,0" data-connect-dir="right"
                        data-evt-z-index="1" r="6" cx="12" cy="0" />
                    <circle data-key="inleft" data-connect="in" data-connect-point="-12,0" data-connect-dir="left"
                        data-evt-z-index="1" r="6" cx="-12" cy="0" />
                    <circle data-key="inbottom" data-connect="in" data-connect-point="0,12" data-connect-dir="bottom"
                        data-evt-z-index="1" r="6" cx="0" cy="12" />
                    <circle data-key="intop" data-connect="in" data-connect-point="0,-12" data-connect-dir="top"
                        data-evt-z-index="1" r="6" cx="0" cy="-12" />
                </g>

                <circle data-templ="select-start" r="10" />
                <rect data-templ="select" width="0" height="0" rx="10" ry="10" fill="rgb(108 187 247 / 20%)"
                    stroke="rgb(108 187 247)" strokeWidth="1"></rect>
            </defs>
                <g data-key="canvas"></g>
            </svg>
        </div>
    )
}

const mapStateToProps = (state: IRootState, ownProps: OwnProps) => {
    return {
        codingFile: state.coding.codingProjects[ownProps.codingProjectIndex].files[ownProps.fileIndex],
        rightPanelWidth: state.project.rightPanelDimensions.width
    }
}

const mapDispatchToProps = {
    codingSetProjectFileFlowChartData
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CodingFlowChartEditor);