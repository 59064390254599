import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ICodingBackpackMatrix, IRootState, codingBackpackMatrixesEditMatrix, codingBackpackMatrixesClearMatrix, codingBackpackMatrixesInvertMatrix, codingBackpackMatrixesShiftUpMatrix, codingBackpackMatrixesShiftDownMatrix, codingBackpackMatrixesShiftLeftMatrix, codingBackpackMatrixesShiftRightMatrix, selectorGetMatrixEditor, selectorGetSelectedBackpackMatrix } from 'store';
import { MatrixManager } from 'lib';

import { binaryToHex, hexToBinary, srcBinaryTostdBinary, stdBinaryTosrcBinary } from 'utils/hexBinary';

import Button from 'components/Button/Button';

import './MatrixEditor.scss';

type OwnProps = {
    codingProjectIndex: number;
    fileIndex: number;
}

type Props = PropsFromRedux & OwnProps;

const MatrixEditor: React.FC<Props> = (props: Props) => {
    const [matrixValue, setMatrixValue] = useState(props.selectedMatrix); //The matrix data for the matrix that is being edited
    const [drawState, setDrawState] = useState('1'); //1 = draw, 0 = erase, -1 = dont draw
    const [mouseLeftMatrixArea, setMouseLeftMatrixArea] = useState<undefined | ReturnType<typeof setTimeout>>(undefined); //Stores the current mouseLeftMatrixArea timer

    useEffect(() => {
        setMatrixValue(props.selectedMatrix);
        MatrixManager.matrixDisplayHex(props.selectedMatrix.hex);
    }, [props.selectedMatrix, props.animationPlaying, props.connected]);

    const handleHexInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newHexValue = e.target.value;
        const newMatrixValue = { ...matrixValue, stdBinary: srcBinaryTostdBinary(hexToBinary(newHexValue)), hex: newHexValue };
        setMatrixValue(newMatrixValue);
        props.codingBackpackMatrixesEditMatrix(props.codingProjectIndex, props.fileIndex, newMatrixValue, props.selectedMatrixId);
    }

    const handleMatrixMouseUp = () => {
        //When the mouse button is released, stop the drawing state and update redux with the new matrix value
        if(drawState === '-1') return; //If we are not actually darwing anything, dont update redux and the draw state
        props.codingBackpackMatrixesEditMatrix(props.codingProjectIndex, props.fileIndex, matrixValue, props.selectedMatrixId);
        setDrawState('-1');
    };

    const handleMatrixMouseEnter = () => {
        if(mouseLeftMatrixArea) clearTimeout(mouseLeftMatrixArea); //When the mouse enters, it will clear the mouseLeftMatrixArea timer if there is an active one
        setMouseLeftMatrixArea(undefined);
    }

    const handleMatrixMouseLeave = () => {
        //Allows for the mouse to leave the matrix area for up to 500ms before it stops the current drawing state and update redux with the new matrix value
        if(mouseLeftMatrixArea || drawState === '-1') return; //If there is an active timer or we are not actually darwing anything, dont start a new timer
        setMouseLeftMatrixArea(setTimeout(() => {
            props.codingBackpackMatrixesEditMatrix(props.codingProjectIndex, props.fileIndex, matrixValue, props.selectedMatrixId);
            setDrawState('-1');
        }, 500));
    }

    const handleDotMouseOver = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
        if(e.buttons !== 1) return; //Check if the primary mouse button is down
        handleDotChange(index);
    }

    const handleDotMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
        e.preventDefault();

        const newDrawState = matrixValue.stdBinary[index] === '1' ? '0' : '1'; //Determine the current drawing state by taking the inverse of the dot the mouse is currently over e.g. no filled dot under mouse = start drawing filled dots
        setDrawState(newDrawState);
        handleDotChange(index, newDrawState);
    }

    const handleDotTouchStart = (e: React.TouchEvent, index: number) => {
        e.stopPropagation();  
        
        const newDrawState = matrixValue.stdBinary[index] === '1' ? '0' : '1'; //Determine the current drawing state by taking the inverse of the dot the mouse is currently over e.g. no filled dot under mouse = start drawing filled dots
        setDrawState(newDrawState);
        handleDotChange(index);
    }

    const handleDotChange = (index: number, newDrawState?: string) => {
        if(drawState === '-1' && !newDrawState) return;
        const currentDrawState = newDrawState || drawState;

        let newStdBinaryValue = [...matrixValue.stdBinary];
        newStdBinaryValue.splice(index, 1, currentDrawState); //Replace the dot's binary value
        setMatrixValue({ ...matrixValue, stdBinary: newStdBinaryValue, hex: binaryToHex(stdBinaryTosrcBinary(newStdBinaryValue)) }); 
    }

    const clearMatrix = () => {
        props.codingBackpackMatrixesClearMatrix(props.codingProjectIndex, props.fileIndex, props.selectedMatrixId);
    }

    const invertMatrix = () => {
        props.codingBackpackMatrixesInvertMatrix(props.codingProjectIndex, props.fileIndex, props.selectedMatrixId);
    }

    const shiftMatrix = (direction: 'up' | 'down' | 'left' | 'right') => {
        if(direction === 'up') props.codingBackpackMatrixesShiftUpMatrix(props.codingProjectIndex, props.fileIndex, props.selectedMatrixId);
        if(direction === 'down') props.codingBackpackMatrixesShiftDownMatrix(props.codingProjectIndex, props.fileIndex, props.selectedMatrixId);
        if(direction === 'left') props.codingBackpackMatrixesShiftLeftMatrix(props.codingProjectIndex, props.fileIndex, props.selectedMatrixId);
        if(direction === 'right') props.codingBackpackMatrixesShiftRightMatrix(props.codingProjectIndex, props.fileIndex, props.selectedMatrixId);
    }

    const onSaveToDeviceClicked = () => {
        MatrixManager.matrixSaveHardwareMatrixes(props.codingProjectIndex, props.fileIndex)
    }

    let editorClasses = 'c-matrixEditor';
    editorClasses += props.animationPlaying ? ' c-matrixEditor--disabled' : '';

    return (
        <div className={editorClasses} >
            <div className="c-matrixEditor__left">
                <div className="c-matrixEditor__matrix" onMouseLeave={handleMatrixMouseLeave} onMouseEnter={handleMatrixMouseEnter} onMouseUp={handleMatrixMouseUp}>
                    {
                        matrixValue.stdBinary.map((active, index) => 
                            <div key={index} className={`o-matrixDot ${active === '1' ? 'o-matrixDot--active' : ''}`} onMouseOver={(e) => handleDotMouseOver(e, index)} onMouseDown={(e) => handleDotMouseDown(e, index)} onTouchStart={(e) => handleDotTouchStart(e, index)} onTouchEnd={e => e.preventDefault()}/>
                        )
                    }
                </div>
                <div className="c-matrixEditor__hex">
                    <span>Hex Code:</span><input className="c-matrixEditor__hexInput" maxLength={64} value={matrixValue.hex} onChange={handleHexInputChange}/>
                </div>
            </div>
            <div className="c-matrixEditor__right">
                <div>
                    <Button type='info' addStyles={['square']} className="c-matrixEditor__button" style={{marginBottom: '20px'}} onClick={clearMatrix} disabled={props.animationPlaying}>Clear</Button>
                </div>
                <div>
                    <Button type='info' addStyles={['square']} className="c-matrixEditor__button" style={{marginBottom: '20px'}} onClick={invertMatrix} disabled={props.animationPlaying}>Invert</Button>
                </div>
                <div>
                    <Button type='info' addStyles={['square']} className="c-matrixEditor__button" onClick={() => shiftMatrix('up')} disabled={props.animationPlaying}>Shift Up</Button>
                </div>
                <div>
                    <Button type='info' addStyles={['square']} className="c-matrixEditor__button" onClick={() => shiftMatrix('down')} disabled={props.animationPlaying}>Shift Down</Button>
                </div>
                <div>
                    <Button type='info' addStyles={['square']} className="c-matrixEditor__button" onClick={() => shiftMatrix('left')} disabled={props.animationPlaying}>Shift Left</Button>
                </div>
                <div>
                    <Button type='info' addStyles={['square']} className="c-matrixEditor__button" style={{marginBottom: '40px'}} onClick={() => shiftMatrix('right')} disabled={props.animationPlaying}>Shift Right</Button>
                </div>
                {
                    props.connected && (
                        <div>
                            <Button type='success' addStyles={['square']} className="c-matrixEditor__button" onClick={onSaveToDeviceClicked} disabled={props.animationPlaying}>Save Matrixes To eBot</Button>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state: IRootState, ownProps: OwnProps): StateProps => {
    return {
        selectedMatrixId: selectorGetMatrixEditor(ownProps.codingProjectIndex, ownProps.fileIndex)(state).backpack.selectedMatrixId,
        selectedMatrix: selectorGetSelectedBackpackMatrix(ownProps.codingProjectIndex, ownProps.fileIndex)(state),
        animationPlaying: selectorGetMatrixEditor(ownProps.codingProjectIndex, ownProps.fileIndex)(state).animation.playing,
        connected: state.project.deviceConnected
    }
}

const mapDispatchToProps = {
    codingBackpackMatrixesEditMatrix,
    codingBackpackMatrixesClearMatrix,
    codingBackpackMatrixesInvertMatrix,
    codingBackpackMatrixesShiftUpMatrix,
    codingBackpackMatrixesShiftDownMatrix,
    codingBackpackMatrixesShiftLeftMatrix,
    codingBackpackMatrixesShiftRightMatrix
}

interface StateProps {
    selectedMatrixId: string;
    selectedMatrix: ICodingBackpackMatrix;
    animationPlaying: boolean;
    connected: boolean;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MatrixEditor);