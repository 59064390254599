import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState } from 'store';
import { DeviceConnectionManager } from 'lib/DeviceConnection';

type Props = PropsFromRedux & {

}

const ConfigInfoTab: React.FC<Props> = ({ configPageSettingValues, deviceConnected }) => {
    useEffect(() => {
        const sendSecInfo = () => DeviceConnectionManager.sendCommand('sec info; rfid config info;');
        sendSecInfo();

        setTimeout(sendSecInfo, 1000);
    }, [deviceConnected]);

    return (
        <div className="p-configPage__tableContainer">
            <table className="p-configPage__table">
                <tbody>
                    <tr className="p-configPage__tableHeader">
                        <th>Setting Name</th>
                        <th>Current Value</th>
                    </tr>
                    <tr className="p-configPage__tableRow">
                        <td className="p-configPage__tableSettingName">Valid Key</td>
                        <td className="p-configPage__tableSettingValue">{configPageSettingValues.securityKeyEnabled === undefined ? '' : configPageSettingValues.securityKeyEnabled ? 'Yes' : 'No'}</td>
                    </tr>
                    <tr className="p-configPage__tableRow">
                        <td className="p-configPage__tableSettingName">Security Key</td>
                        <td className="p-configPage__tableSettingValue">{configPageSettingValues.securityKey}</td>
                    </tr>
                    <tr className="p-configPage__tableRow">
                        <td className="p-configPage__tableSettingName">Chip Serial</td>
                        <td className="p-configPage__tableSettingValue">{configPageSettingValues.serialNumber}</td>
                    </tr>
                    <tr className="p-configPage__tableRow">
                        <td className="p-configPage__tableSettingName">ESP32 Firmware Version</td>
                        <td className="p-configPage__tableSettingValue">{configPageSettingValues.esp32FirmwareVersion}</td>
                    </tr>
                    <tr className="p-configPage__tableRow">
                        <td className="p-configPage__tableSettingName">Nano Firmware Version</td>
                        <td className="p-configPage__tableSettingValue">{configPageSettingValues.nanoFirmwareVersion}</td>
                    </tr>
                    <tr className="p-configPage__tableRow">
                        <td className="p-configPage__tableSettingName">RFID Version</td>
                        <td className="p-configPage__tableSettingValue">{configPageSettingValues.rfidVersion}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

const mapStateToProps = (state: IRootState) => {
    return {
        configPageSettingValues: state.project.configPageSettingValues,
        deviceConnected: state.project.deviceConnected
    }
}

const mapDispatchToProps = {
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ConfigInfoTab);