import React, { useState, useEffect, useCallback } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState, codingSetUserSelectedRightPanel, selectorGetActiveCodingProject, codingSetRunning, codingSetProjectFileActiveIndex, codingSetProjectFiles, codingAddProjectFile, codingDeleteProjectFile, codingSetProjectFileName, ICodingFile, ICodingFileType, selectorGetActiveRightCodingPanel, projectSetRightPanelDimensions } from 'store';
import { TabPanel, useTabs } from 'react-headless-tabs';
import { DndProvider } from "react-dnd";
import MultiBackend from 'react-dnd-multi-backend';
import ReactResizeDetector from 'react-resize-detector';
//@ts-ignore
//Like a pro typescript developer
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
import { Menu, Item, contextMenu, ItemParams } from 'react-contexify';
import { saveAs } from 'file-saver';
import JSRunner from 'lib/JSRunner';

import { VscDebugConsole } from 'react-icons/vsc';
import { BiHelpCircle } from 'react-icons/bi';

import Page from 'containers/Page/Page';
import CodingProjectsNavBar from 'containers/CodingProjectsNavBar/CodingProjectsNavBar';
import CodingProjectsHelpPopout from 'containers/CodingProjectHelpPopout/CodingProjectHelpPopout';
import CodingProjectCreateModal from 'containers/CodingProjectCreateModal/CodingProjectCreateModal';
import CodingProjectSettingsModal from 'containers/CodingProjectSettingsModal/CodingProjectSettingsModal';
import CodingFileTab from 'components/CodingFileTab/CodingFileTab';
import CodingFileContent from 'components/CodingFileContent/CodingFileContent';
import CodingJSModal from 'containers/CodingJSModal/CodingJSModal';
import CodingJSScratchModal from 'containers/CodingJSScratchModal/CodingJSScratchModal';
import Console from 'containers/Console/Console';
import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';

import './CodingPage.scss';

type Props = PropsFromRedux & {

}

type NewTabPhase = 'file type' | 'file name';

const CodingPage: React.FC<Props> = ({ codingProject, activeProjectIndex, codingSetRunning, userSelectedRightPanel, codingSetUserSelectedRightPanel, codingSetProjectFileActiveIndex, codingAddProjectFile, codingDeleteProjectFile, codingSetProjectFileName, codingSetProjectFiles, activeRightPanel, projectSetRightPanelDimensions }) => {
    const getTabs = useCallback(
        (files?: ICodingFile[]) => (files || codingProject.files).map(file => ({
            name: file.name,
            id: file.id,
            type: file.type
        })),
        [ codingProject.files ]
    );
    
    const [tabs, setTabs] = useState(getTabs());
    const [selectedTab, setSelectedTab] = useTabs(tabs.map(tab => tab.id)); //by id

    const [newTabModalOpen, setNewTabModalOpen] = useState(false);
    const [newTabPhase, setNewTabPhase] = useState<NewTabPhase>('file type');
    const [newTabFileType, setNewTabFileType] = useState<ICodingFileType | undefined>();
    const [fileNameNew, setFileNameNew] = useState('');

    const [renameFileName, setRenameFileName] = useState('');
    const [renameFileIndex, setRenameFileIndex] = useState(0);
    const [renameFileModelOpen, setRenameFileModelOpen] = useState(false);

    const activeFileIndex = codingProject.activeFileIndex;

    const onTabClick = (tabId: string) => {
        const tabIndex = tabs.findIndex(tab => tab.id === tabId);

        setSelectedTab(tabId);
        codingSetProjectFileActiveIndex(tabIndex);
    }

    const moveTab = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const dragTab = codingProject.files[dragIndex];

            const filesCopy = JSON.parse(JSON.stringify(codingProject.files));

            filesCopy.splice(dragIndex, 1);
            filesCopy.splice(hoverIndex, 0, dragTab);
    
            codingSetProjectFiles(activeProjectIndex, filesCopy);
            setTabs(getTabs(filesCopy));
            if(dragIndex === activeFileIndex) codingSetProjectFileActiveIndex(hoverIndex);
            if(hoverIndex === activeFileIndex) codingSetProjectFileActiveIndex(dragIndex);
        }, 
        [
            codingProject, activeProjectIndex, activeFileIndex, 
            getTabs, codingSetProjectFiles, codingSetProjectFileActiveIndex
        ]
    );

    const onNewTabClick = () => {
        setNewTabPhase('file type');
        setNewTabModalOpen(true);
    }

    const handleNewFileTypeClick = (fileType: ICodingFileType) => {
        setNewTabFileType(fileType);
        setNewTabPhase('file name');

        if(fileType === 'js') setFileNameNew(`JS ${codingProject.files.filter(file => file.type === 'js').length+1}`);
        if(fileType === 'html') setFileNameNew(`HTML`);
        if(fileType === 'text') {
            if(codingProject.type === 'js') setFileNameNew(`Visual HTML Editor ${codingProject.files.filter(file => file.type === 'text').length+1}`);
            else setFileNameNew(`Notes ${codingProject.files.filter(file => file.type === 'text').length+1}`);
        }
        if(fileType === 'matrixEditor') setFileNameNew(`Matrix Editor`);
        if(fileType === 'eventButtons') setFileNameNew(`Event Buttons`);
        if(fileType === 'globalVars') setFileNameNew(`Global Variables`);
        if(fileType === 'globalVarsJS4Scratch') setFileNameNew(`Global Variables`);
        if(fileType === 'flowChart') setFileNameNew(`Flow Chart ${codingProject.files.filter(file => file.type === 'flowChart').length+1}`);
    }

    const handleNewFileNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFileNameNew(e.target.value);
    }

    const handleNewFileNameKeypress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key !== 'Enter') return;
        handleNewFileCreate();
    }

    const handleNewFileCreate = () => {
        if(!newTabFileType) throw new Error('New Tab File Type Undefined');

        codingSetRunning(false);
        codingAddProjectFile(activeProjectIndex, newTabFileType, fileNameNew);
        setNewTabPhase('file type');
        setNewTabFileType(undefined);
        setNewTabModalOpen(false);
    }

    const handleOnNewFileTabBack = () => {
        setNewTabFileType(undefined);
        setNewTabPhase('file type');
    }

    const handleContentMenuEvent = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, fileIndex: number) => {
        e.preventDefault();

        contextMenu.show({
            id: 'contextMenu__codingProjectsFileTab',
            event: e,
            props: {
                fileIndex
            }
        });
    };

    const handleOnFileTabRename = (args: ItemParams<any, any>) => {
        const fileIndex: number = (args.props as any).fileIndex;

        setRenameFileName(codingProject.files[fileIndex].name);
        setRenameFileIndex(fileIndex);
        setRenameFileModelOpen(true);
    }

    const handleCloseFileRenameModal = () => {
        setRenameFileName('');
        setRenameFileIndex(0);
        setRenameFileModelOpen(false);
    }

    const handleSaveFileRenameModal = () => {
        codingSetProjectFileName(activeProjectIndex, renameFileIndex, renameFileName);
        handleCloseFileRenameModal();
    }

    const handleFileRenameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRenameFileName(e.target.value);
    }

    const handleFileRenameKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key !== 'Enter') return;
        handleSaveFileRenameModal();
    }

    const handleOnFileTabDelete = (args: ItemParams<any, any>) => {
        const fileIndex: number = (args.props as any).fileIndex;

        codingDeleteProjectFile(activeProjectIndex, fileIndex);
    }

    const handleNewTabModalClose = () => {
        setNewTabModalOpen(false);
    }

    const onClickConsoleDisplay = () => {
        if(activeRightPanel[1] === 'console') return; //disabled
        if(userSelectedRightPanel === 'console') return codingSetUserSelectedRightPanel(null);
        codingSetUserSelectedRightPanel('console');
    }

    const onClickHelpDisplay = () => {
        if(activeRightPanel[1] === 'help') return; //disabled
        if(userSelectedRightPanel === 'help') return codingSetUserSelectedRightPanel(null);
        codingSetUserSelectedRightPanel('help');
    }

    const handleResize = (width: number, height: number) => {
        projectSetRightPanelDimensions({
            width,
            height
        });
    }

    useEffect(() => {
        const newTabs = getTabs();

        setTabs(newTabs);
        setSelectedTab(newTabs[activeFileIndex].id);
    }, [codingProject, activeFileIndex, getTabs, setSelectedTab]);

    //Stop running on page unmount
    useEffect(() => {
        return () => {
            codingSetRunning(false);
        }
    }, []);

    const monacoFileIndexs = codingProject.files.reduce((prev: number[], curr, index) => curr.type === 'js' || curr.type === 'html' ? [...prev, index] : prev, []);

    //JS and text files are all rendered in a single tab due to only being able to run a single monaco instance
    return (
        <Page
            title="Coding Editor | ScratchLink"
            background="#E6F0FF"
            className="overflow-hidden"
            styles={{ minHeight: '100vh' }}
            inner={false}
        >
            <DndProvider backend={MultiBackend} options={HTML5toTouch}>
                <div className="flex flex-row w-full h-full">
                    <Modal title="New Coding Tab" isOpen={newTabModalOpen} closeModal={handleNewTabModalClose} onBack={handleOnNewFileTabBack} type={newTabPhase === 'file type' ? 'default' : 'back'}>
                        {
                            newTabPhase === 'file type' && (
                                <div className="flex flex-wrap">
                                    {
                                        codingProject.type === 'hardwarejs' && (
                                            <>
                                                <Button className="m-2" type="info" onClick={() => handleNewFileTypeClick('js')}>Javascript</Button>
                                                <Button className="m-2" type="info" onClick={() => handleNewFileTypeClick('text')}>Notes</Button>
                                                <Button className="m-2" type="info" onClick={() => handleNewFileTypeClick('eventButtons')}>Event Buttons</Button>
                                                <Button className="m-2" type="info" onClick={() => handleNewFileTypeClick('globalVars')}>Global Variables</Button>
                                                <Button className="m-2" type="info" onClick={() => handleNewFileTypeClick('matrixEditor')}>Matrix Editor</Button>
                                                <Button className="m-2" type="info" onClick={() => handleNewFileTypeClick('flowChart')}>Flow Chart</Button>
                                            </>
                                        )
                                    }
                                    {
                                        codingProject.type === 'hardwarepy' && (
                                            <>
                                                <Button className="m-2" type="info" onClick={() => handleNewFileTypeClick('text')}>Notes</Button>
                                                <Button className="m-2" type="info" onClick={() => handleNewFileTypeClick('eventButtons')}>Event Buttons</Button>
                                                <Button className="m-2" type="info" onClick={() => handleNewFileTypeClick('matrixEditor')}>Matrix Editor</Button>
                                                <Button className="m-2" type="info" onClick={() => handleNewFileTypeClick('flowChart')}>Flow Chart</Button>
                                            </>
                                        )
                                    }
                                    {
                                        codingProject.type === 'js' && (
                                            <>
                                                <Button className="m-2" type="info" onClick={() => handleNewFileTypeClick('html')}>HTML</Button>
                                                <Button className="m-2" type="info" onClick={() => handleNewFileTypeClick('js')}>Javascript</Button>
                                                <Button className="m-2" type="info" onClick={() => handleNewFileTypeClick('text')}>Visual HTML Editor</Button>
                                                <Button className="m-2" type="info" onClick={() => handleNewFileTypeClick('globalVars')}>Global Variables</Button>
                                                <Button className="m-2" type="info" onClick={() => handleNewFileTypeClick('imageManager')}>Image Manager</Button>
                                                <Button className="m-2" type="info" onClick={() => handleNewFileTypeClick('flowChart')}>Flow Chart</Button>
                                            </>
                                        )
                                    }
                                    {
                                        codingProject.type === 'jsscratch' && (
                                            <>
                                                <Button className="m-2" type="info" onClick={() => handleNewFileTypeClick('js')}>Javascript</Button>
                                                <Button className="m-2" type="info" onClick={() => handleNewFileTypeClick('text')}>Notes</Button>
                                                <Button className="m-2" type="info" onClick={() => handleNewFileTypeClick('globalVarsJS4Scratch')}>Global Variables</Button>
                                                <Button className="m-2" type="info" onClick={() => handleNewFileTypeClick('flowChart')}>Flow Chart</Button>
                                            </>
                                        )
                                    }
                                    {
                                        codingProject.type === 'flowChart' && (
                                            <>
                                                <Button className="m-2" type="info" onClick={() => handleNewFileTypeClick('flowChart')}>Flow Chart</Button>
                                            </>
                                        )
                                    }
                                    {
                                        codingProject.type === 'portfolio' && (
                                            <Button className="m-2" type="info" onClick={() => handleNewFileTypeClick('text')}>Notes</Button>
                                        )
                                    }
                                </div>
                            )
                        }
                        {
                            newTabPhase === 'file name' && (
                                <div className='w-full flex justify-center'>
                                    <input autoFocus className='border-2 p-2 text-lg' type="text" placeholder='File Name' value={fileNameNew} onChange={handleNewFileNameChange} onKeyPress={handleNewFileNameKeypress} />
                                    <Button className='ml-2' type='success' onClick={handleNewFileCreate}>Create</Button>
                                </div>
                            )
                        }
                    </Modal>
                    <Modal title="Rename File" isOpen={renameFileModelOpen} closeModal={handleCloseFileRenameModal} type="save" onSave={handleSaveFileRenameModal}>
                        <input autoFocus className='border-2 p-2 text-lg' type="text" value={renameFileName} onChange={handleFileRenameChange} onKeyPress={handleFileRenameKeyPress} />
                    </Modal>
                    <CodingJSModal />
                    <CodingJSScratchModal />
                    <CodingProjectCreateModal />
                    <CodingProjectSettingsModal />
                    <Menu id="contextMenu__codingProjectsFileTab">
                        <Item onClick={handleOnFileTabRename}>Rename File</Item>
                        <Item onClick={handleOnFileTabDelete}>Delete File</Item>
                    </Menu>
                    <CodingProjectsNavBar />
                    <div className="w-full h-full">
                        <div className="w-full flex justify-between" style={{background: "#27272a"}}>
                            <div className="flex">
                                <ul className="flex p-codingPage__scrollBarHorizontal h-full items-center border-r-2 border-gray-500" style={{maxWidth: 'calc(100vw - 390px)'}}>
                                    {tabs.map((tab, index) => (
                                        <CodingFileTab
                                            key={tab.id}
                                            isActive={tab.id === selectedTab}
                                            onClick={() => onTabClick(tab.id)}
                                            index={index}
                                            id={'tab-' + tab.id}
                                            moveTab={moveTab}
                                            onContextMenu={e => handleContentMenuEvent(e, index)}
                                            fileType={tab.type}
                                        >
                                            {tab.name}
                                        </CodingFileTab>
                                    ))}
                                </ul>
                                <div className="h-full flex items-center ml-2">
                                    {
                                        codingProject.type !== 'matrix' && (
                                            <button
                                                className="p-1 rounded-full text-sm text-gray-100 hover:bg-indigo-600 hover:text-white transition duration-300 ease-in-out"
                                                onClick={onNewTabClick}
                                                title="New tab"
                                            >
                                                <svg
                                                className="w-5 h-5"
                                                fill="none"
                                                stroke="currentColor"
                                                viewBox="0 0 24 24"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                                    ></path>
                                                </svg>
                                            </button>
                                        )
                                    }
                                </div>
                            </div>
                            <div>
                                <button 
                                    className={`mr-1 ${activeRightPanel[0] === 'console' ? 'text-yellow-300' : activeRightPanel[1] === 'console' ? 'text-gray-500' : 'text-white'}`} 
                                    onClick={onClickConsoleDisplay}
                                >
                                    <VscDebugConsole className={activeRightPanel[1] === 'console' ? ' cursor-not-allowed' : ''} style={{fontSize: '1.6em'}} />
                                </button>
                                <button 
                                    className={`mr-1 ${activeRightPanel[0] === 'help' ? 'text-yellow-300' : activeRightPanel[1] === 'help' ? 'text-gray-500' : 'text-white'}`} 
                                    onClick={onClickHelpDisplay} 
                                >
                                    <BiHelpCircle className={activeRightPanel[1] === 'help' ? ' cursor-not-allowed' : ''} style={{fontSize: '1.6em'}} />
                                </button>
                            </div>
                        </div>
                        <div className="w-full flex flex-row flex-nowrap" style={{height: 'calc(100vh - 124px)'}}>
                            <div className="w-full">
                                {
                                    monacoFileIndexs.length > 0 && ( //Render all monaco editors together
                                        <TabPanel className="h-full" hidden={!monacoFileIndexs.includes(activeFileIndex)}>
                                            {
                                                monacoFileIndexs.includes(activeFileIndex)  && <CodingFileContent codingProjectIndex={activeProjectIndex} fileIndex={activeFileIndex} fileType={codingProject.files[activeFileIndex].type} />
                                            }
                                        </TabPanel>
                                    )
                                }
                                {
                                    codingProject.files.map((file, index) => {
                                        if(monacoFileIndexs.includes(index)) return <div key={file.id}></div>
                                        if(file.type === 'flowChart' && activeFileIndex !== index) return; //make sure its impossible for two flowcharts to be rendered at the same time or it will causes a freeze
                                        return (
                                            <TabPanel className="h-full" key={file.id} hidden={activeFileIndex !== index}>
                                                <CodingFileContent codingProjectIndex={activeProjectIndex} fileIndex={index} fileType={file.type}  />
                                            </TabPanel>
                                        )
                                    })
                                }
                            </div>
                            <div className='relative h-full'>
                                <ReactResizeDetector handleWidth handleHeight onResize={handleResize} refreshRate={300} refreshMode="debounce"/>
                                {
                                    (codingProject.type === 'js' || codingProject.type === 'jsscratch' || codingProject.type === 'hardwarejs') && activeRightPanel[0] === 'console' && <Console name='JS Console' options={{rawPacketTick: false}} style={{minWidth: '350px'}} acceptedSources={['JSLog', 'JSSystem', 'JSError', 'JSLibError', 'Info']} />
                                }
                                {
                                    codingProject.type === 'hardwarepy' && activeRightPanel[0] === 'console' && <Console name='Python Console' options={{rawPacketTick: false}} style={{minWidth: '350px'}} acceptedSources={['PyLog', 'PySystem', 'PyError', 'PyLibError', 'Info']} />
                                }
                                {
                                    activeRightPanel[0] === 'help' && <CodingProjectsHelpPopout  />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </DndProvider>
        </Page>
    )
}

const mapStateToProps = (state: IRootState) => {
    return {
        codingProject: selectorGetActiveCodingProject(state),
        userSelectedRightPanel: state.coding.userSelectedRightPanel,
        activeProjectIndex: state.coding.activeProjectIndex,
        activeRightPanel: selectorGetActiveRightCodingPanel(state)
    }
}

const mapDispatchToProps = {
    projectSetRightPanelDimensions,
    codingSetRunning,
    codingSetUserSelectedRightPanel,
    codingSetProjectFileActiveIndex,
    codingSetProjectFiles,
    codingAddProjectFile,
    codingDeleteProjectFile,
    codingSetProjectFileName
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CodingPage);