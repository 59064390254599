import { createSelector } from 'reselect';
import { IRootState } from 'store';

/*
    The final hardware mode being used. Will use the auto detected device profile if js selector is AUTO, else use the user selected hardware mode
*/
export const selectorGetProjectHardwareMode = createSelector(
    [
        (state: IRootState) => state.project.hardwareMode,
        (state: IRootState) => state.project.hardwareModeAutoDetected
    ],
    (hardwareMode, hardwareModeAutoDetected): string => {
        if(hardwareMode === 'AUTO') return hardwareModeAutoDetected;
        return hardwareMode;
    }
);