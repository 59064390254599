import { createSelector } from 'reselect';
import { IRootState } from 'store';

export const selectorGetGlobalVars = createSelector(
    (state: IRootState) => state.coding, 
    (coding) => {
        const files = coding.codingProjects[coding.activeProjectIndex].files.filter(file => file.type === 'globalVars' || file.type === 'globalVarsJS4Scratch');

        if(files.length < 1) return [];

        return (files[0].globalVarData || files[0].globalVarJS4ScratchData)?.vars;
    }
);