import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState, codingSetProjectFileCode, codingSetProjectFileText } from 'store';
import ReactResizeDetector from 'react-resize-detector';

import CodeMirrorEditor from './CodeMirrorEditor/CodeMirrorEditor';
import MonacoEditor from './MonacoEditor/ManacoEditor';
import TinyMCEEditor from './TinyMCEEditor/TinyMCEEditor';

import './CodeEditor.scss';

type OwnProps = {
    codingProjectIndex: number;
    fileIndex: number;
};

type Props = PropsFromRedux & OwnProps;

const CodeEditor: React.FC<Props> = ({codingProjectId, codingProjectType, file, codingProjectIndex, fileIndex, javascriptEditorToolType, pageDimensions, codingSetProjectFileCode, codingSetProjectFileText}) => {
    const fileType = file.type;
    if(fileType !== 'js' && fileType !== 'text' && fileType !== 'html' && fileType !== 'py') throw new Error(`CodeEditor: file.type is not js, text or html`);

    const [size, setSize] = useState([0, 0]) //width, height

    let code = 'Wrong File Type';
    if(fileType === 'js') code = file.jsData?.code || '';
    if(fileType === 'py') code = file.pyData?.code || '';
    if(fileType === 'text') code = file.textData?.text || '';
    if(fileType === 'html') code = file.htmlData?.html || '';

    const monacoFileName = `projectIndex${codingProjectIndex}-${codingProjectId}-fileIndex${fileIndex}-${file.id}-${file.name}`;

    const handleCodeChange = (newCode: string) => {
        codingSetProjectFileCode(newCode, fileType, codingProjectIndex, fileIndex);
    }

    const onTextChange = (text: string) => {
        codingSetProjectFileText(text, codingProjectIndex, fileIndex)
    }

    if(fileType === 'text') return (
        <div className="c-codeEditor">
            <TinyMCEEditor text={code} onTextChange={onTextChange} fileName={file.name}/>
        </div>
    )

    if((pageDimensions.width < 1100 && javascriptEditorToolType !== 'monaco') || javascriptEditorToolType === 'codemirror') return (
        <div className="c-codeEditor">
            <CodeMirrorEditor code={code} onCodeChange={handleCodeChange} />
        </div>
    )

    return (
        <div className="c-codeEditor">
            <ReactResizeDetector 
                handleWidth
                handleHeight
                onResize={(width, height) => setSize([width, height])}
            >
                <div className='relative w-full h-full' style={{zIndex: -1}} />       
            </ReactResizeDetector>
            <div className='absolute w-full h-full left-0 top-0' style={{width: size[0], height: size[1]}}>
                <MonacoEditor 
                    codingProjectIndex={codingProjectIndex}
                    codingProjectType={codingProjectType}
                    fileType={
                        fileType === 'js' ? 'javascript' : 
                        fileType === 'py' ? 'python' :
                        'html'
                    }
                    fileIndex={fileIndex}
                    code={code}
                    monacoFileName={monacoFileName}
                    onCodeChange={handleCodeChange}
                />
            </div>
        </div>
    )
}

const mapStateToProps = (state: IRootState, ownProps: OwnProps) => {
    return {
        codingProjectId: state.coding.codingProjects[ownProps.codingProjectIndex].id,
        codingProjectType: state.coding.codingProjects[ownProps.codingProjectIndex].type,
        file: state.coding.codingProjects[ownProps.codingProjectIndex].files[ownProps.fileIndex],
        running: state.coding.running,
        pageDimensions: state.project.pageDimensions,
        javascriptEditorToolType: state.settings.javascriptEditorToolType
    }
}

const mapDispatchToProps = {
    codingSetProjectFileCode,
    codingSetProjectFileText
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CodeEditor);