import { createSelector } from 'reselect';
import { IRootState, ICodingBackpackMatrix } from 'store';

import { selectorGetMatrixEditor } from './selectorGetMatrixEditor';

export const selectorGetBackpackSavedMatrixes = (codingProjectIndex: number, fileIndex: number) => createSelector(
    [
        (state: IRootState) => selectorGetMatrixEditor(codingProjectIndex, fileIndex)(state).backpack.matrixes,
        (state: IRootState) => selectorGetMatrixEditor(codingProjectIndex, fileIndex)(state).backpack.folders
    ],
    (matrixes, folders): [ICodingBackpackMatrix[], ICodingBackpackMatrix[][]] => {
        const singleMatrixes = folders.byIds.root.matrixIds.map(matrixId => matrixes.byIds[matrixId]);
        const matrixFolders = folders.allIds.map(
                folderId => 
                ({
                    ...folders.byIds[folderId], 
                    matrixes: folders.byIds[folderId].matrixIds.map(matrixId => matrixes.byIds[matrixId]) //Get matrix array ref for folder
                })
            );

        let matrixFolderMatrixes: ICodingBackpackMatrix[][] = [];
        for(let i=1; i<matrixFolders.length; i++) {
            matrixFolderMatrixes.push(matrixFolders[i].matrixes)
        }

        return [singleMatrixes, matrixFolderMatrixes];
    }
);