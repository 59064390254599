import React from 'react';
import { useDrag, useDrop } from "react-dnd";
import { ICodingFileType } from 'store';
import { FaNodeJs, FaPython } from 'react-icons/fa';
import { BiJoystickButton } from 'react-icons/bi';
import { AiOutlineFileText } from 'react-icons/ai';
import { TiHtml5 } from 'react-icons/ti';
import { HiVariable } from 'react-icons/hi';
import { BsGrid3X3Gap, BsImages } from 'react-icons/bs';
import { SiThespritersresource } from 'react-icons/si';
import { RiOrganizationChart } from 'react-icons/ri';

type Props = {
    isActive: boolean;
    children: React.ReactNode;
    onClick: () => void;
    id: string;
    index: number;
    moveTab: (dragIndex: number, hoverIndex: number) => void;
    onContextMenu: React.DOMAttributes<HTMLDivElement>['onContextMenu'];
    fileType?: ICodingFileType;
}

interface DragItem {
    index: number;
    id: string;
    type: string;
}

const CodingFileTab: React.FC<Props> = ({ isActive, children, onClick, id, index, moveTab, onContextMenu, fileType }) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const [{ handlerId }, drop] = useDrop<
        DragItem,
        unknown,
        { handlerId: number }
    >({
        accept: 'tab',
        collect: (monitor) => ({
        handlerId: monitor.getHandlerId() as unknown as number,
    }),
    hover(item, monitor) {
        if (!ref.current) {
            return;
        }

        const dragIndex = item.index;
        const hoverIndex = index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect();

        // Get horizontal middle
        const hoverMiddleX =
            (hoverBoundingRect.left - hoverBoundingRect.right) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the side
        const hoverClientX = clientOffset!.x - hoverBoundingRect.right;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
            return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
            return;
        }

        // Time to actually perform the action
        moveTab(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex;
    }});

    const [, drag] = useDrag({
        item: { type: 'tab', index, id },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    
    drag(drop(ref));

    return (//@ts-ignore
        <li className="relative" data-handler-id={handlerId} ref={ref} onContextMenu={onContextMenu}>
            <button
                className={`pr-4 group inline-flex items-center py-4 pl-2 border-0 border-solid border-b-2 font-medium text-sm leading-5 cursor-pointer whitespace-nowrap ${
                    isActive
                    ? "border-indigo-500 text-indigo-600 bg-gray-400 hover:bg-gray-400"
                    : "border-transparent text-gray-100 hover:text-gray-300 hover:border-gray-400"
                }`}
                onClick={onClick}
                role="tab"
                aria-selected={isActive ? "true" : "false"}
            >
                {
                    fileType === 'js' && (
                        <FaNodeJs className="mr-1" style={{color: '#ffc66d'}} />
                    )
                }
                {
                    fileType === 'py' && (
                        <FaPython className="mr-1" style={{color: '#ffc66d'}} />
                    )
                }
                {
                    fileType === 'html' && (
                        <TiHtml5 className='mr-1' style={{color: '#ffc66d'}}/>
                    )
                }
                {
                    fileType === 'eventButtons' && (
                        <BiJoystickButton className="mr-1" style={{color: '#00C851'}} />
                    )
                }
                {
                    fileType === 'text' && (
                        <AiOutlineFileText className="mr-1" style={{color: '#ffffff'}} />
                    )
                }
                {
                    (fileType === 'globalVars' || fileType === 'globalVarsJS4Scratch') && (
                        <HiVariable className="mr-1" style={{color: '#ffc66d'}} />
                    )
                }
                {
                    fileType === 'matrixEditor' && ( 
                        <BsGrid3X3Gap className="mr-1" style={{color: '#cc0000'}} />
                    )
                }
                {
                    fileType === 'assetManager' && (
                        <SiThespritersresource className="mr-1" style={{color: '#cc0000'}} />
                    )
                }
                {
                    fileType === 'imageManager' && (
                        <BsImages className="mr-1" style={{color: '#ffc66d'}} />
                    )
                }
                {
                    fileType === 'flowChart' && (
                        <RiOrganizationChart className="mr-1" style={{color: '#ffffff', fontSize: '17px'}} />
                    )
                }
                {children}
                {
                    fileType === 'js' && <>.js</>
                }
                {
                    fileType === 'py' && <>.py</>
                }
            </button>
            {/* <span className="flex items-center absolute top-0 right-0 bottom-0">
                <button
                    className="-ml-8 p-px text-white rounded-full text-sm hover:bg-indigo-600 focus:bg-indigo-600 focus:outline-none focus:shadow-outline transition duration-300 ease-in-out"
                    onClick={onClose}
                    title="Close tab"
                >
                    <svg
                        className="w-3 h-3"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </button>
            </span> */}
        </li>
    )
}

export default CodingFileTab;