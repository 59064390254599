import { toast } from 'react-toastify';
import store, { GamePadTypes, GamePadTypesEnum, projectSetGamepadConnected, projectSetGamepadData } from "store";

import { ScratchLinkGamepadEventHandler } from '.';

import { 
    gamepadDataEmptyStandard, 
    gamepadDataEmptySlClassic, 
    gamepadDataEmptySlNunchuk,
    gamepadDataEmptyRetro,
    ScratchLinkGamepad,
    ScratchLinkGamepadDataStandard,
    ScratchLinkGamepadDataSlClassic,
    ScratchLinkGamepadData, 
    ScratchLinkGamepadDataSlNunchuk,
    ScratchLinkGamepadDataRetro
} from '.';


export interface GamepadConnectionEvents {
    'gamepad1Connected': CustomEvent<{type: GamePadTypes}>;
    'gamepad2Connected': CustomEvent<{type: GamePadTypes}>;
    'gamepad1Disconnected': CustomEvent<{type: GamePadTypes}>;
    'gamepad2Disconnected': CustomEvent<{type: GamePadTypes}>;
    'gamepad1Data': CustomEvent<{data: ScratchLinkGamepad}>;
    'gamepad2Data': CustomEvent<{data: ScratchLinkGamepad}>;
}

interface GamepadConnectionTarget extends EventTarget {
    addEventListener<K extends keyof GamepadConnectionEvents>(
        type: K,
        listener: (ev: GamepadConnectionEvents[K]) => void,
        options?: boolean | AddEventListenerOptions
    ): void;
    addEventListener(
        type: string,
        callback: EventListenerOrEventListenerObject | null,
        options?: EventListenerOptions | boolean
    ): void;
    removeEventListener<K extends keyof GamepadConnectionEvents>(
        type: K,
        listener: (ev: GamepadConnectionEvents[K]) => void,
        options?: boolean | EventListenerOptions
    ): void;
    removeEventListener(
        type: string,
        callback: EventListenerOrEventListenerObject | null,
        options?: EventListenerOptions | boolean
    ): void;
}

const typedGamepadConnectionTarget = EventTarget as {new(): GamepadConnectionTarget; prototype: GamepadConnectionTarget};

class GamepadConnectionClass extends typedGamepadConnectionTarget {
    gamepad1Connected: boolean = false;
    gamepad2Connected: boolean = false;

    gamepad1Connecting: boolean = false;
    gamepad2Connecting: boolean = false;

    gamepad1Type: GamePadTypes = 'no_gamepad';
    gamepad2Type: GamePadTypes = 'no_gamepad';

    gamepad1PollInterval: ReturnType<typeof setInterval> | null = null;
    gamepad2PollInterval: ReturnType<typeof setInterval> | null = null;

    gamepad1EventHandler = new ScratchLinkGamepadEventHandler(1);
    gamepad2EventHandler = new ScratchLinkGamepadEventHandler(2);

    interval: number = 50;
    xboxConnectionPollInterval: number = 100;

    init() {
        //@ts-ignore
        window.addEventListener('gamepadconnected', (event: GamepadEvent) => {
            if(this.handleXboxConnection(event)) return;
            
            if(event.gamepad.index === 0) {
                this.gamepad1Connected = true;
                this.gamepad1Type = this.getGamepadTypeFromId(event.gamepad.id);
                //@ts-ignore
                store.dispatch(projectSetGamepadConnected(0, true, this.gamepad1Type))
                toast.success(`Gamepad 1 connected: ${GamePadTypesEnum[this.gamepad1Type]}`)
                this.pollGamepad1()
            } else if(event.gamepad.index === 1) {
                this.gamepad2Connected = true;
                this.gamepad2Type = this.getGamepadTypeFromId(event.gamepad.id);
                //@ts-ignore
                store.dispatch(projectSetGamepadConnected(1, true, this.gamepad2Type))
                toast.success(`Gamepad 2 connected: ${GamePadTypesEnum[this.gamepad2Type]}`)
                this.pollGamepad2()
            }
        });

        //@ts-ignore
        window.addEventListener('gamepaddisconnected', (event: GamepadEvent) => {
            if(event.gamepad.index === 0) this.disconnectGamepad1()
            else if(event.gamepad.index === 1) this.disconnectGamepad2()
        });

        return this;
    }

    disconnectGamepad1() {
        if(this.gamepad1PollInterval) clearInterval(this.gamepad1PollInterval);
        this.gamepad1PollInterval = null;
        const newType = 'no_gamepad';

        //@ts-ignore
        store.dispatch(projectSetGamepadConnected(0, false, newType))
        this.dispatchEvent(new CustomEvent<{type: GamePadTypes}>('gamepad1Disconnected', {
            detail: {
                type: newType
            }
        }));

        let emptyData: ScratchLinkGamepadData = gamepadDataEmptyStandard;
        if(this.gamepad1Type === 'retro') emptyData = gamepadDataEmptyRetro;
        store.dispatch(projectSetGamepadData(0, emptyData));
        this.dispatchEvent(new CustomEvent<{data: ScratchLinkGamepadData}>('gamepad1Data', {
            detail: {
                data: emptyData
            }
        }));

        this.gamepad1Type = newType;
        this.gamepad1Connected = false;
        toast.error(`Gamepad 1 disconnected!`)
    }

    disconnectGamepad2() {
        if(this.gamepad2PollInterval) clearInterval(this.gamepad2PollInterval);
        this.gamepad2PollInterval = null;
        const newType = 'no_gamepad';

        //@ts-ignore
        store.dispatch(projectSetGamepadConnected(1, false, newType))
        this.dispatchEvent(new CustomEvent<{type: GamePadTypes}>('gamepad2Disconnected', {
            detail: {
                type: newType
            }
        }));

        let emptyData: ScratchLinkGamepadData = gamepadDataEmptyStandard;
        if(this.gamepad2Type === 'retro') emptyData = gamepadDataEmptyRetro;
        store.dispatch(projectSetGamepadData(0, emptyData));
        this.dispatchEvent(new CustomEvent<{data: ScratchLinkGamepadData}>('gamepad2Data', {
            detail: {
                data: emptyData
            }
        }));

        this.gamepad2Type = newType;
        this.gamepad2Connected = false;
        toast.error(`Gamepad 2 disconnected!`)
    }

    private handleXboxConnection(event: GamepadEvent) {
        if(
            !this.gamepad1Connecting && //already polling for xbox controller
            this.getGamepadTypeFromId(event.gamepad.id) === 'xbox' && //is xbox controller
            event.gamepad.axes[0].toFixed(5) !== "0.00392" //is xbox controller not on
        ) {
            if(event.gamepad.index === 0) {
                this.gamepad1Connecting = true;
                toast.warn(`Xbox Gamepad 1 detected, please turn it on with the silver button.`)
                const pollGamepad = setInterval(() => {
                    const gamepad = navigator.getGamepads()[0];

                    if(gamepad && gamepad.axes[0].toFixed(5) === "0.00392" && this.gamepad1Connecting && !this.gamepad1Connected) {
                        this.gamepad1Connecting = false;
                        //@ts-ignore
                        window.dispatchEvent(new GamepadEvent('gamepadconnected', {gamepad: gamepad}));
                        return 
                    }
    
                    if(gamepad && gamepad.axes[0].toFixed(5) === "-0.00392" && !this.gamepad1Connecting && this.gamepad1Connected) {
                        this.gamepad1Connecting = true;
                        //@ts-ignore
                        window.dispatchEvent(new GamepadEvent('gamepaddisconnected', {gamepad: gamepad}));
                        return;
                    }
    
                    if(!gamepad) {
                        clearInterval(pollGamepad); 
                        this.gamepad1Connecting = false;
                        return;
                    }
                }, this.xboxConnectionPollInterval);
            } else if(event.gamepad.index === 1) {
                this.gamepad2Connecting = true;
                toast.warn(`Xbox Gamepad 2 detected, please turn it on with the silver button.`)
                const pollGamepad = setInterval(() => {
                    const gamepad = navigator.getGamepads()[1];

                    if(gamepad && gamepad.axes[0].toFixed(5) === "0.00392" && this.gamepad2Connecting && !this.gamepad2Connected) {
                        this.gamepad2Connecting = false;
                        //@ts-ignore
                        window.dispatchEvent(new GamepadEvent('gamepadconnected', {gamepad: gamepad}));
                        return 
                    }
    
                    if(gamepad && gamepad.axes[0].toFixed(5) === "-0.00392" && !this.gamepad2Connecting && this.gamepad2Connected) {
                        this.gamepad2Connecting = true;
                        //@ts-ignore
                        window.dispatchEvent(new GamepadEvent('gamepaddisconnected', {gamepad: gamepad}));
                        return;
                    }
    
                    if(!gamepad) {
                        clearInterval(pollGamepad); 
                        this.gamepad2Connecting = false;
                        return;
                    }
                }, this.xboxConnectionPollInterval);
            }

            return true
        }

        return false
    }

    private getGamepadTypeFromId(id: string): GamePadTypes {
        if(id.indexOf('Vendor: 0810 Product: 0001') > -1) return 'xbox';
        if(id.indexOf('Vendor: 1949 Product: 0402') > -1) return 't3';
        if(id.indexOf('Vendor: 081f Product: e401') > -1) return 'retro';

        return 'no_gamepad';
    }

    private pollGamepad1() {
        if(this.gamepad1PollInterval) clearInterval(this.gamepad1PollInterval);
        this.gamepad1PollInterval = setInterval(() => {
            const gamepad = navigator.getGamepads()[0];
            if(!gamepad) return this.disconnectGamepad1();

            const data = this.getGamepadDataByType(gamepad, this.gamepad1Type);
            if(!data) return this.disconnectGamepad1();
            
            const gamepadData = { connected: true, type: this.gamepad1Type, data } as ScratchLinkGamepad;
            this.dispatchEvent(new CustomEvent<{data: ScratchLinkGamepad}>('gamepad1Data', {
                detail: {
                    data: gamepadData
                }
            }));

            this.gamepad1EventHandler.handleGamepadData(gamepadData);

            //@ts-ignore
            store.dispatch(projectSetGamepadData(0, gamepadData.data));
            
        }, this.interval);
    }

    private pollGamepad2() {
        if(this.gamepad2PollInterval) clearInterval(this.gamepad2PollInterval);
        this.gamepad2PollInterval = setInterval(() => {
            const gamepad = navigator.getGamepads()[1];
            if(!gamepad) return this.disconnectGamepad1();

            const data = this.getGamepadDataByType(gamepad, this.gamepad2Type);
            if(!data) return this.disconnectGamepad1();

            const gamepadData = { connected: true, type: this.gamepad2Type, data } as ScratchLinkGamepad;
            this.dispatchEvent(new CustomEvent<{data: ScratchLinkGamepad}>('gamepad2Data', {
                detail: {
                    data: gamepadData
                }
            }));

            this.gamepad2EventHandler.handleGamepadData(gamepadData);

            //@ts-ignore
            store.dispatch(projectSetGamepadData(1, gamepadData.data));
            
        }, this.interval);
    } 

    private getGamepadDataByType(gamepad: Gamepad, type: GamePadTypes): ScratchLinkGamepadData | undefined {
        if(type === 'xbox') return this.getXboxGamepadData(gamepad);
        if(type === 't3') return this.getT3GamepadData(gamepad);
        if(type === 'retro') return this.getRetroGamepadData(gamepad);
    }

    private getXboxGamepadData(gamepad: Gamepad): ScratchLinkGamepadDataStandard {
        const leftJoyX = gamepad.axes[0];
        const leftJoyY = gamepad.axes[1];
        const rightJoyX = gamepad.axes[5];
        const rightJoyY = gamepad.axes[2];

        const leftJoyXValue = leftJoyX.toFixed(1);
        const leftJoyYValue = leftJoyY.toFixed(1);
        const rightJoyXValue = rightJoyX.toFixed(1);
        const rightJoyYValue = rightJoyY.toFixed(1);
        const dPadValue = gamepad.axes[9].toFixed(1);

        return {
            lhsJoystick: {
                analog: {
                    x: this.convertAnalogToInt(leftJoyX),
                    y: -this.convertAnalogToInt(leftJoyY),
                    distance: this.convertJoystickToDistance(leftJoyX, leftJoyY),
                    direction: this.convertJoystickToDirection(leftJoyX, -leftJoyY)
                },
                digital: {
                    button: gamepad.buttons[10].pressed,
                    up: leftJoyYValue === '-1.0',
                    down: leftJoyYValue === '1.0',
                    left: leftJoyXValue === '-1.0',
                    right: leftJoyXValue === '1.0',
                    noInput: leftJoyXValue === '0.0' && leftJoyYValue === '0.0'
                }
            },
            rhsJoystick: {
                analog: {
                    x: this.convertAnalogToInt(rightJoyX),
                    y: -this.convertAnalogToInt(rightJoyY),
                    distance: this.convertJoystickToDistance(rightJoyX, rightJoyY),
                    direction: this.convertJoystickToDirection(rightJoyX, -rightJoyY)
                },
                digital: {
                    button: gamepad.buttons[11].pressed,
                    up: rightJoyYValue === '-1.0',
                    down: rightJoyYValue === '1.0',
                    left: rightJoyXValue === '-1.0',
                    right: rightJoyXValue === '1.0',
                    noInput: rightJoyXValue === '0.0' && rightJoyYValue === '0.0'
                }
            },
            dPad: {
                up: dPadValue === '-1.0',
                down: dPadValue === '0.1',
                left: dPadValue === '0.7',
                right: dPadValue === '-0.4',
                noInput: dPadValue === '3.3'
            },
            a: gamepad.buttons[2].pressed,
            b: gamepad.buttons[1].pressed,
            x: gamepad.buttons[3].pressed,
            y: gamepad.buttons[0].pressed,
            bumper: {
                leftTop: gamepad.buttons[4].pressed,
                rightTop: gamepad.buttons[5].pressed,
                leftBottom: gamepad.buttons[6].pressed,
                rightBottom: gamepad.buttons[7].pressed
            },
            start: gamepad.buttons[9].pressed,
            select: gamepad.buttons[8].pressed
        }
    }

    private getT3GamepadData(gamepad: Gamepad): ScratchLinkGamepadDataStandard {
        const leftJoyX = gamepad.axes[0];
        const leftJoyY = gamepad.axes[1];
        const rightJoyX = gamepad.axes[5];
        const rightJoyY = gamepad.axes[2];

        const leftJoyXValue = leftJoyX.toFixed(1);
        const leftJoyYValue = leftJoyY.toFixed(1);
        const rightJoyXValue = rightJoyX.toFixed(1);
        const rightJoyYValue = rightJoyY.toFixed(1);
        const dPadValue = gamepad.axes[9].toFixed(1);

        return {
            lhsJoystick: {
                analog: {
                    x: this.convertAnalogToInt(leftJoyX),
                    y: -this.convertAnalogToInt(leftJoyY),
                    distance: this.convertJoystickToDistance(leftJoyX, leftJoyY),
                    direction: this.convertJoystickToDirection(leftJoyX, -leftJoyY)
                },
                digital: {
                    button: gamepad.buttons[10].pressed,
                    up: leftJoyYValue === '-1.0',
                    down: leftJoyYValue === '1.0',
                    left: leftJoyXValue === '-1.0',
                    right: leftJoyXValue === '1.0',
                    noInput: leftJoyXValue === '0.0' && leftJoyYValue === '0.0'
                }
            },
            rhsJoystick: {
                analog: {
                    x: this.convertAnalogToInt(rightJoyX),
                    y: -this.convertAnalogToInt(rightJoyY),
                    distance: this.convertJoystickToDistance(rightJoyX, rightJoyY),
                    direction: this.convertJoystickToDirection(rightJoyX, -rightJoyY)
                },
                digital: {
                    button: gamepad.buttons[11].pressed,
                    up: rightJoyYValue === '-1.0',
                    down: rightJoyYValue === '1.0',
                    left: rightJoyXValue === '-1.0',
                    right: rightJoyXValue === '1.0',
                    noInput: rightJoyXValue === '0.0' && rightJoyYValue === '0.0'
                }
            },
            dPad: {
                up: dPadValue === '-1.0' || dPadValue === '1.0' || dPadValue === '-0.7',
                down: dPadValue === '0.1' || dPadValue === '0.4' || dPadValue === '-0.1',
                left: dPadValue === '0.7' || dPadValue === '1.0' || dPadValue === '0.4',
                right: dPadValue === '-0.4' || dPadValue === '-0.7' || dPadValue === '-0.1',
                noInput: dPadValue === '3.3'
            },
            a: gamepad.buttons[2].pressed,
            b: gamepad.buttons[1].pressed,
            x: gamepad.buttons[3].pressed,
            y: gamepad.buttons[0].pressed,
            bumper: {
                leftTop: gamepad.buttons[6].pressed,
                rightTop: gamepad.buttons[7].pressed,
                leftBottom: gamepad.buttons[8].pressed,
                rightBottom: gamepad.buttons[9].pressed
            },
            start: gamepad.buttons[11].pressed,
            select: gamepad.buttons[10].pressed
        }
    }

    private getSLClassicGamepadData(gamepad: Gamepad): ScratchLinkGamepadDataSlClassic {
        const leftJoyX = gamepad.axes[0];
        const leftJoyY = gamepad.axes[1];
        const rightJoyX = gamepad.axes[5];
        const rightJoyY = gamepad.axes[2];
        const joyX = gamepad.axes[3];
        const joyY = gamepad.axes[4];

        const joyXValue = joyX.toFixed(1);
        const joyYValue = joyY.toFixed(1);
        const dPadValue = gamepad.axes[9].toFixed(1);

        return {
            lhsJoystick: {
                analog: {
                    x: this.convertAnalogToInt(leftJoyX),
                    y: this.convertAnalogToInt(leftJoyY),
                    distance: this.convertJoystickToDistance(leftJoyX, leftJoyY),
                    direction: this.convertJoystickToDirection(leftJoyX, leftJoyY)
                },
                digital: {
                    up: gamepad.buttons[16].pressed,
                    down: gamepad.buttons[17].pressed,
                    left: gamepad.buttons[19].pressed,
                    right: gamepad.buttons[18].pressed,
                    noInput: !gamepad.buttons[16].pressed && !gamepad.buttons[17].pressed && !gamepad.buttons[18].pressed && !gamepad.buttons[19].pressed
                }
            },
            rhsJoystick: {
                analog: {
                    x: this.convertAnalogToInt(rightJoyX),
                    y: this.convertAnalogToInt(rightJoyY),
                    distance: this.convertJoystickToDistance(rightJoyX, rightJoyY),
                    direction: this.convertJoystickToDirection(rightJoyX, rightJoyY)
                },
                digital: {
                    up: gamepad.buttons[20].pressed,
                    down: gamepad.buttons[21].pressed,
                    left: gamepad.buttons[23].pressed,
                    right: gamepad.buttons[22].pressed,
                    noInput: !gamepad.buttons[20].pressed && !gamepad.buttons[21].pressed && !gamepad.buttons[22].pressed && !gamepad.buttons[23].pressed
                }
            },
            joystick: {
                analog: {
                    x: this.convertAnalogToInt(joyX),
                    y: this.convertAnalogToInt(joyY),
                    distance: this.convertJoystickToDistance(joyX, joyY),
                    direction: this.convertJoystickToDirection(joyX, joyY)
                },
                digital: {
                    button: gamepad.buttons[12].pressed,
                    up: joyYValue === '1.0',
                    down: joyYValue === '-1.0',
                    left: joyXValue === '-1.0',
                    right: joyXValue === '1.0',
                    noInput: joyX < 0.1 && joyX > -0.1 && joyY < 0.1 && joyY > -0.1 //Dont use 0.0 because of jitter
                }
            },
            dPad: {
                up: dPadValue === '-1.0' || dPadValue === '1.0' || dPadValue === '-0.7',
                down: dPadValue === '0.1' || dPadValue === '0.4' || dPadValue === '-0.1',
                left: dPadValue === '0.7' || dPadValue === '1.0' || dPadValue === '0.4',
                right: dPadValue === '-0.4' || dPadValue === '-0.7' || dPadValue === '-0.1',
                noInput: dPadValue === '1227133568.0'
            },
            a: gamepad.buttons[9].pressed,
            b: gamepad.buttons[10].pressed,
            x: gamepad.buttons[11].pressed,
            y: gamepad.buttons[12].pressed,
            red: gamepad.buttons[2].pressed,
            white: gamepad.buttons[3].pressed,
            black: gamepad.buttons[0].pressed,
            blue: gamepad.buttons[1].pressed,
            bumper: {
                leftTop: gamepad.buttons[7].pressed,
                rightTop: gamepad.buttons[8].pressed,
                leftBottom: gamepad.buttons[5].pressed,
                rightBottom: gamepad.buttons[6].pressed
            },
            start: gamepad.buttons[14].pressed,
            select: gamepad.buttons[15].pressed,
            home: gamepad.buttons[13].pressed
        }
    }

    private getSLNunchukGamepadData(gamepad: Gamepad): ScratchLinkGamepadDataSlNunchuk {
        const nunJoyX = gamepad.axes[2];
        const nunJoyY = gamepad.axes[5];
        const joyX = gamepad.axes[3];
        const joyY = gamepad.axes[4];
        const gyroX = gamepad.axes[0];
        const gyroY = gamepad.axes[1];

        const joyXValue = joyX.toFixed(1);
        const joyYValue = joyY.toFixed(1);

        return {
            nunJoystick: {
                analog: {
                    x: this.convertAnalogToInt(nunJoyX),
                    y: this.convertAnalogToInt(nunJoyY),
                    distance: this.convertJoystickToDistance(nunJoyX, nunJoyY),
                    direction: this.convertJoystickToDirection(nunJoyX, nunJoyY)
                },
                digital: {
                    up: gamepad.buttons[7].pressed,
                    down: gamepad.buttons[8].pressed,
                    left: gamepad.buttons[10].pressed,
                    right: gamepad.buttons[9].pressed,
                    noInput: !gamepad.buttons[7].pressed && !gamepad.buttons[8].pressed && !gamepad.buttons[9].pressed && !gamepad.buttons[10].pressed
                }
            },
            joystick: {
                analog: {
                    x: this.convertAnalogToInt(joyX),
                    y: this.convertAnalogToInt(joyY),
                    distance: this.convertJoystickToDistance(joyX, joyY),
                    direction: this.convertJoystickToDirection(joyX, joyY)
                },
                digital: {
                    button: gamepad.buttons[12].pressed,
                    up: joyYValue === '1.0',
                    down: joyYValue === '-1.0',
                    left: joyXValue === '-1.0',
                    right: joyXValue === '1.0',
                    noInput: joyX < 0.1 && joyX > -0.1 && joyY < 0.1 && joyY > -0.1 //Dont use 0.0 because of jitter
                }
            },
            gyro: {
                analog: {
                    x: this.convertAnalogToInt(gyroX),
                    y: this.convertAnalogToInt(gyroY),
                },
                digital: {
                    up: gyroY > 0.5,
                    down: gyroY < -0.5,
                    left: gyroX < -0.5,
                    right: gyroX > 0.5,
                }
            },
            bumper: {
                top: gamepad.buttons[5].pressed,
                bottom: gamepad.buttons[6].pressed
            },
            red: gamepad.buttons[2].pressed,
            white: gamepad.buttons[3].pressed,
            black: gamepad.buttons[0].pressed,
            blue: gamepad.buttons[1].pressed,
        }
    }

    private getRetroGamepadData(gamepad: Gamepad): ScratchLinkGamepadDataRetro {
        const dPadXValue = gamepad.axes[0].toFixed(1);
        const dPadYValue = gamepad.axes[1].toFixed(1);

        return {
            dPad: {
                up: dPadYValue === '-1.0',
                down: dPadYValue === '1.0',
                left: dPadXValue === '-1.0',
                right: dPadXValue === '1.0',
                noInput: dPadXValue === '-0.0' && dPadYValue === '-0.0'
            },
            bumper: {
                left: gamepad.buttons[4].pressed,
                right: gamepad.buttons[5].pressed
            },
            x: gamepad.buttons[0].pressed,
            y: gamepad.buttons[3].pressed,
            a: gamepad.buttons[1].pressed,
            b: gamepad.buttons[2].pressed,
            select: gamepad.buttons[8].pressed,
            start: gamepad.buttons[9].pressed
        }
    }

    private convertAnalogToInt(data: number): number {
        data *= 100;
        return Number(data.toFixed(0));
    }

    private convertJoystickToDistance(x: number, y: number): number {
        if(Math.abs(x).toFixed(0) === '0' && Math.abs(y).toFixed(0) === '0') return 0;

        return Number((Math.hypot(-x, y) * 100 / 1.41).toFixed(0));
    }

    private convertJoystickToDirection(x: number, y: number): number {
        if(Math.abs(x).toFixed(0) === '0' && Math.abs(y).toFixed(0) === '0') return 90;

        let direction = Number((90 - (Math.atan2(y, x) * 180 / Math.PI)).toFixed(0));

        if(direction < 0) direction = 270 + (90 + direction);

        return direction;
    }
}

const GamepadConnection = (new GamepadConnectionClass()).init();

export default GamepadConnection;