//import immer from 'immer';

import { ICodingMatrixEditorState, ICodingMatrixEditorActionTypes, animationReducer, animationInitialState, backpackReducer, backpackInitialState } from '.';
import { ICodingMatrixEditorAction } from 'store';

import {
    MATRIXEDITOR_SET_DEMO_PLAYING,
    MATRIXEDITOR_DEMO_TICK
} from '.';

export const matrixEditorInitialState: ICodingMatrixEditorState = {
    backpack: backpackInitialState,
    animation: animationInitialState,
    demoPlaying: false,
    currentAnimationPlayedFor: 0
}

export const matrixEditorReducer = (state: ICodingMatrixEditorState = matrixEditorInitialState, action: ICodingMatrixEditorActionTypes, codingProjectIndex: number, fileIndex: number): ICodingMatrixEditorState => {
    const backpack = backpackReducer(state.backpack, action as any, codingProjectIndex, fileIndex);
    const animation = animationReducer(state.animation, action as any, codingProjectIndex, fileIndex);

    //This checks that the action being dispatched is meant to mutate the state fot the current reducers coding project index and file index
    //Each matrixEditor reducer has is responsible for its own coding project index and file index
    const currAction = action as ICodingMatrixEditorAction;
    if(currAction.codingProjectIndex !== codingProjectIndex || currAction.fileIndex !== fileIndex) return { ...state, backpack, animation };

    switch (action.type) {
        case MATRIXEDITOR_SET_DEMO_PLAYING:
            return { ...state, backpack, animation, demoPlaying: action.demoPlaying };
        
        case MATRIXEDITOR_DEMO_TICK: 
            const cFolder = backpack.folders.byIds[backpack.selectedFolderId];
            const selectedBackpackMatrixIndex = cFolder.matrixIds.indexOf(backpack.selectedMatrixId);
            let nextMatrixIndex = selectedBackpackMatrixIndex+1;

            if(nextMatrixIndex > cFolder.matrixIds.length-1) { //Last matrix in this folder
                const selectedFolderIndex = backpack.folders.allIds.indexOf(backpack.selectedFolderId);
                const nextFolderIndex = selectedFolderIndex+1

                if(nextFolderIndex > backpack.folders.allIds.length-1) { //Last matrix total
                    const finalNewFolderId = backpack.folders.allIds[0]; //Reset to start
                    const finalNewMatrixId = backpack.folders.byIds[finalNewFolderId].matrixIds[0];
                    return { ...state, backpack: { ...backpack, selectedFolderId: finalNewFolderId, selectedMatrixId: finalNewMatrixId}, animation, demoPlaying: false}
                }

                const finalNewFolder = backpack.folders.byIds[backpack.folders.allIds[nextFolderIndex]];
                const finalNewFolderId = finalNewFolder.id;
                const finalNewMatrixId = finalNewFolder.matrixIds[0];

                return { ...state, backpack: { ...backpack, selectedFolderId: finalNewFolderId, selectedMatrixId: finalNewMatrixId}, animation };
            }
            
            const finalNewMatrixId = cFolder.matrixIds[nextMatrixIndex];

            return { ...state, backpack: { ...backpack, selectedMatrixId: finalNewMatrixId}, animation};
        default:
            return { ...state, backpack, animation };
    }
}