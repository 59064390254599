import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState } from 'store';
import { toast } from 'react-toastify';

import ModemBinding from 'lib/ModemBinding';
import { DeviceConnectionManager } from 'lib';
import wait from 'utils/wait';

import Input from 'components/Input/Input';
import Page from 'containers/Page/Page';
import Button from 'components/Button/Button';

import './ModemBindingPage.scss';

type Props = PropsFromRedux & {

}

const ModemBindingPage: React.FC<Props> = ({ appDeviceConnected }) => {
    const [device1Connected, setDevice1Connected] = useState<'connected' | 'connecting' | 'disconnected'>('disconnected');
    const [device2Connected, setDevice2Connected] = useState<'connected' | 'connecting' | 'disconnected'>('disconnected');
    const [syncing, setSyncing] = useState(false);
    const [name, setName] = useState(`eBot${Math.floor(Math.random()*9999)}`);
    const [password, setPassword] = useState('');

    const isWifiSite = () => window.location.protocol === 'http:' && window.location.hostname !== 'localhost';

    const redirectToUsbSite = () => {
        if(!window.confirm('Cannot use Modem Binding on the Wifi Site. Are you sure you want to be redirected to the USB site? Please save your workspace now before switching or you will lose any unsaved work.')) return;
        window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
    }

    const handleDevice1Click = async () => {
        if(isWifiSite()) return redirectToUsbSite(); 
        if(appDeviceConnected) return toast.error('Cannot connect if app is already connected to a eBot. Please disconnect the currently connected eBot in the connection menu.');
        setDevice1Connected('connecting');
        const connected = await ModemBinding.connectPort();
        await wait(100);
        setDevice1Connected(connected ? 'connected' : 'disconnected');
        if(connected) toast.success('Connected eBot 1');
        else {
            toast.error(`Error connecting to eBot 1. Check console.`);
            handleDisconnectClick();
        }
    }

    const handleDevice2Click = async () => {
        if(isWifiSite()) return redirectToUsbSite(); 
        if(appDeviceConnected) return toast.error('Cannot connect if app is already connected to a eBot. Please disconnect the currently connected eBot in the connection menu.');
        setDevice2Connected('connecting');
        const connected = await ModemBinding.connectPort();
        await wait(100);
        setDevice2Connected(connected ? 'connected' : 'disconnected');
        if(connected) toast.success('Connected eBot 2');
        else {
            toast.error(`Error connecting to eBot 2. Check console.`);
            handleDisconnectClick();
        }
    }

    const handleSyncClick = async () => {
        setSyncing(true);
        await ModemBinding.sync(name, password);
        setSyncing(false);
        setDevice1Connected('disconnected');
        setDevice2Connected('disconnected');
        toast.success('Devices synced successfully');
    }

    const handleDisconnectClick = async () => {
        await ModemBinding.disconnect();
        setDevice1Connected('disconnected');
        setDevice2Connected('disconnected');
    }

    const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newVal = e.target.value.split('').filter(char => /[a-zA-Z1-9]/.test(char)).join('');
        setName(newVal);
    }

    const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newVal = e.target.value.split('').filter(char => /[a-zA-Z1-9]/.test(char)).join('');
        setPassword(newVal);
    }

    useEffect( () => { //Disconnect on Mount and Un-Mount
        DeviceConnectionManager.disconnect();
        return () => { 
            ModemBinding.disconnect();
        }
    }, [] );

    return (
        <Page
            title="Coding Editor | ScratchLink"
            background="#E6F0FF"
        >
            <div className="p-modemBinding">
                <Button type="info" onClick={handleDevice1Click} disabled={device1Connected === 'connected'}>{device1Connected === 'disconnected' ? 'Connect eBot 1' : device1Connected === 'connecting' ? 'Connecting eBot 1' : 'Connect eBot 1'}</Button>
                <Button type="info" onClick={handleDevice2Click} disabled={device2Connected === 'connected'}>{device2Connected === 'disconnected' ? 'Connect eBot 2' : device2Connected === 'connecting' ? 'Connecting eBot 2' : 'Connect eBot 2'}</Button>
                <Button type="success" onClick={handleSyncClick} disabled={!(device1Connected === 'connected' && device2Connected === 'connected') || syncing}>Sync Devices</Button>
                <Button type="danger" onClick={handleDisconnectClick} disabled={device1Connected === 'disconnected' && device2Connected === 'disconnected'}>Disconnect Devices</Button>

                <div className="p-modemBinding__input">
                    <span><b>Name: </b></span>
                    <Input value={name} onChange={onNameChange} />
                </div>
                <div className="p-modemBinding__input" style={{marginTop: '10px'}}>
                    <span><b>Wifi Password (Optional): </b></span>
                    <Input value={password} onChange={onPasswordChange} />
                </div>
                <div className="p-modemBinding__text">
                    <p><b>Purpose of Modem Binding:</b> This config tool binds the eBot USB Modem to a particular eBot which means that you don't have to configure the WIFI of either separately.  Specifically, the USB Modem will be configured as a WIFI Hotspot with a random SSID and the eBot will be configured as a WIFI client of that Modem(Station).  This means that after Modem Binding, the eBot will automatically join the USB Modem's WIFI Hotspot without you having to know about SSID etc.</p>

                    <p><b>Instructions:</b></p>

                    <p><b>1.</b> Connect the eBot USB modem to a USB port</p>
                    <p><b>2.</b> Using a Micro USB cable, connect eBot to another USB port ensuring that eBot is powered on first</p>
                    <p><b>3.</b> Click on "Connect eBot 1" button and select the first "CP2101 USB to UART Bridge Controller" Com port- the button should go from blue to grey- wait about 4 seconds until a green connection notification appears</p>
                    <p><b>4.</b> Click on "Connect eBot 2" button and select the second "CP2101 USB to UART Bridge Controller" Com port- the button should go from blue to grey- wait about 4 seconds until a green connection notification appears</p>
                    <p><b>5.</b> Click the "Sync Devices" button which should have turned from grey to green</p>
                    <p><b>6.</b> Wait until all the sync notifications appear saying that it was synced after which it will automatically disconnect both devices.</p>
                    <p><b>7.</b> You may now connect to the modem in the normal way either in ScratchLink Blocks or Coding Editor (using connection button in top right) but only after you turn eBot off then back on again, after which it will automatically connect to the ScratchLink USB Modem and be ready for ScratchLink Blocks or Coding Editor communications.</p>
                </div>
            </div>
        </Page>
    )
}

const mapStateToProps = (state: IRootState) => {
    return {
        appDeviceConnected: state.project.deviceConnected
    }
}

const mapDispatchToProps = {
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ModemBindingPage);