import immer from 'immer';
import { STORE_IMPORT } from '..';

import { IRFIDState, IRFIDActions } from '.';

import {
    RFID_SET_TAGS
} from '.';

export const RFIDInitialState: IRFIDState = {
    tags: []
}

export const RFIDReducer = (state: IRFIDState = RFIDInitialState, action: IRFIDActions): IRFIDState => {
    switch (action.type) {
        case RFID_SET_TAGS:
            return immer(state, (draftState: IRFIDState) => {
                draftState.tags = action.tags;
            });
    
        case STORE_IMPORT:
            return action.data.RFID;
        default:
            return { ...state };
    }
}