import immer from 'immer';
import shortid from 'shortid';
import { ICodingBackpackFoldersState, ICodingBackpackFolder, ICodingBackpackFoldersActionTypes } from '.';
import { ICodingMatrixEditorAction } from 'store';

import {
    CODING_PROJECT_FILE_BACKPACKFOLDERS_CREATE_FOLDER,
    CODING_PROJECT_FILE_BACKPACKFOLDERS_MOVE_FOLDER,
    CODING_PROJECT_FILE_BACKPACKFOLDERS_DELETE_FOLDER,
    CODING_PROJECT_FILE_BACKPACKFOLDERS_RENAME_FOLDER,
    CODING_PROJECT_FILE_BACKPACKFOLDERS_MOVE_MATRIX,
    CODING_PROJECT_FILE_BACKPACKFOLDERS_ADD_MATRIX,
    CODING_PROJECT_FILE_BACKPACKFOLDERS_DELETE_MATRIX
} from '.';

export const codingBackpackFoldersInitialState: ICodingBackpackFoldersState = {
    byIds: {
        root: {
            id: "root",
            name: "Single Matrixes",
            matrixIds: ['initMatrix', 'initMatrix2', 'initMatrix3']
        },
        countFolder: {
            id: 'countFolder',
            name: 'Count',
            matrixIds: ['countMatrix1','countMatrix2','countMatrix3','countMatrix4','countMatrix5','countMatrix6','countMatrix7','countMatrix8','countMatrix9','countMatrix10']
        },
        arrowFolder: {
            id: 'arrowFolder',
            name: 'Arrow',
            matrixIds: ['arrowMatrix1', 'arrowMatrix2', 'arrowMatrix3', 'arrowMatrix4']
        },
    },
    allIds: [
        'root',
        'countFolder',
        'arrowFolder'
    ]
}

export const codingBackpackFoldersReducer = (state: ICodingBackpackFoldersState, action: ICodingBackpackFoldersActionTypes, codingProjectIndex: number, fileIndex: number): ICodingBackpackFoldersState => {
    const currAction = action as ICodingMatrixEditorAction;
    if(currAction.codingProjectIndex !== codingProjectIndex || currAction.fileIndex !== fileIndex) return {...state};
    
    switch (action.type) {
        case CODING_PROJECT_FILE_BACKPACKFOLDERS_CREATE_FOLDER:
            return immer(state, (draftState: ICodingBackpackFoldersState) => {
                const folderId = action.folderId || shortid();

                const newFolder: ICodingBackpackFolder = {
                    id: folderId,
                    name: action.name,
                    matrixIds: []
                }

                draftState.byIds[folderId] = newFolder;
                draftState.allIds.push(folderId);
            })

        case CODING_PROJECT_FILE_BACKPACKFOLDERS_MOVE_FOLDER:
            return immer(state, (draftState: ICodingBackpackFoldersState) => {
                draftState.allIds.splice(action.oldIndex, 1);
                draftState.allIds.splice(action.newIndex, 0, state.allIds[action.oldIndex]);
            })

        case CODING_PROJECT_FILE_BACKPACKFOLDERS_DELETE_FOLDER:
            return immer(state, (draftState: ICodingBackpackFoldersState) => {
                delete draftState.byIds[action.folderId];
                draftState.allIds.splice(draftState.allIds.indexOf(action.folderId), 1);
            })

        case CODING_PROJECT_FILE_BACKPACKFOLDERS_RENAME_FOLDER:
            return immer(state, (draftState: ICodingBackpackFoldersState) => {
                draftState.byIds[action.folderId].name = action.name;
            })

        case CODING_PROJECT_FILE_BACKPACKFOLDERS_MOVE_MATRIX:
            return immer(state, (draftState: ICodingBackpackFoldersState) => {
                draftState.byIds[action.oldFolderId].matrixIds.splice(action.oldIndex, 1); //Delete old
                draftState.byIds[action.newFolderId].matrixIds.splice(action.newIndex, 0, action.matrixId); //Add new
            })

        case CODING_PROJECT_FILE_BACKPACKFOLDERS_ADD_MATRIX:
            return immer(state, (draftState: ICodingBackpackFoldersState) => {
                draftState.byIds[action.folderId].matrixIds.push(action.matrixId)
            })

        case CODING_PROJECT_FILE_BACKPACKFOLDERS_DELETE_MATRIX:
            return immer(state, (draftState: ICodingBackpackFoldersState) => {
                const matrixIds = draftState.byIds[action.folderId].matrixIds;
                matrixIds.splice(matrixIds.indexOf(action.matrixId), 1);
            })

        default:
            return {...state};
    }
}