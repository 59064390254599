import React, { useState, useCallback, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState, projectSetDeviceConneting } from 'store';

import Modal from 'components/Modal/Modal';

import ConnectPhase from './DeviceConnectionModalPhases/ConnectPhase';
import ConnectingPhase from './DeviceConnectionModalPhases/ConnectingPhase';
import ConnectedPhase from './DeviceConnectionModalPhases/ConnectedPhase';
import DirectIpPhase from './DeviceConnectionModalPhases/DirectIpPhase';
import ChangeProtocolPhase from './DeviceConnectionModalPhases/ChangeProtocolPhase';
import SerialErrorPhase from './DeviceConnectionModalPhases/SerialErrorPhase';
import SerialDriverDLPhase from './DeviceConnectionModalPhases/SerialDriverDLPhase';

import { DeviceConnectionManager } from 'lib';

import './DeviceConnectionModal.scss';

type DeviceConnectionModalPhases = 'Connect' | 'Direct IP' | 'Connecting' | 'Connected' | 'Change Protocol' | 'Serial Error' | 'Serial Driver DL';

type Props = PropsFromRedux & {
    connectionModalOpen: boolean;
    handleConnectionModalClose: () => void;
}

const DeviceConnectionModal: React.FC<Props> = ({ connectionModalOpen, handleConnectionModalClose, deviceConnected, deviceConnecting, projectSetDeviceConneting }) => {
    let [phase, setPhase] = useState<DeviceConnectionModalPhases>('Connect');
    
    useEffect(() => {
        if(!deviceConnected && !deviceConnecting) { //Not connected or connecting
            setPhase('Connect');
        } 
        if(!deviceConnected && deviceConnecting) { //Not connected but connecting
            setPhase('Connecting');
        }
        if(deviceConnected && !deviceConnecting) { //Connected and not currently connecting
            setPhase('Connected');
        }
    }, [deviceConnected, deviceConnecting])

    const onClickDirectIp = useCallback(() => {
        setPhase('Direct IP');
    }, [])

    const onClickChangeProtocol = useCallback(() => {
        setPhase('Change Protocol');
    }, [])

    const onBackClick = useCallback(() => {
        setPhase('Connect');
    }, [])

    const onSerialError = useCallback(() => {
        setPhase('Serial Error');
    }, [])

    const modalClose = useCallback(() => {
        if(phase === 'Connecting') DeviceConnectionManager.disconnect();
        projectSetDeviceConneting(false);
        handleConnectionModalClose();
    }, [handleConnectionModalClose, phase, projectSetDeviceConneting])

    return (
        <Modal 
            title="eBot Connection" 
            type='default'
            isOpen={connectionModalOpen} 
            closeModal={modalClose}
            size={phase === 'Serial Error' ? 'md' : 'xs'}
            footer={
                phase === 'Connect' ? true : false
            }
            onBack={onBackClick}
            footerLeftComponent={
                phase === 'Connect' ? <a className='hover:underline cursor-pointer' onClick={() => setPhase('Serial Driver DL')}>Download USB Driver</a> : <></>
            }
        >
            {
                phase === 'Connect' && (
                    <ConnectPhase onClickDirectIp={onClickDirectIp} onClickChangeProtocol={onClickChangeProtocol} onSerialError={onSerialError}/>
                )
            }
            {
                phase === 'Connecting' && (
                    <ConnectingPhase />
                )
            }
            {
                phase === 'Connected' && (
                    <ConnectedPhase />
                )
            }
            {
                phase === 'Direct IP' && (
                    <DirectIpPhase onBackClick={onBackClick}/>
                )
            }
            {
                phase === 'Change Protocol' && (
                    <ChangeProtocolPhase onBackClick={onBackClick}/>
                )
            }
            {
                phase === 'Serial Error' && (
                    <SerialErrorPhase onBackClick={onBackClick} />
                )
            }
            {
                phase === 'Serial Driver DL' && (
                    <SerialDriverDLPhase onBackClick={onBackClick} />
                )
            }
        </Modal>
    )
}
const mapStateToProps = (state: IRootState): StateProps => {
    return {
        deviceConnected: state.project.deviceConnected,
        deviceConnecting: state.project.deviceConnecting
    }
}

const mapDispatchToProps = {
    projectSetDeviceConneting
}

interface StateProps {
    deviceConnected: boolean;
    deviceConnecting: boolean;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DeviceConnectionModal);