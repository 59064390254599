import {
    IRFIDSetTags,
} from '.';

import {
    RFID_SET_TAGS
} from '.';

import {
    IRFIDTag
} from '.';

export const RFIDSetTags = (tags: IRFIDTag[]): IRFIDSetTags => {
    return {
        type: RFID_SET_TAGS,
        tags
    }
}